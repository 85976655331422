import React, { useState } from 'react'

const Broadcast = (props) => {

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23.016" viewBox="0 0 23 23.016">
      <defs>
        <clipPath id="clip-path">
          <rect id="Rectangle_724" data-name="Rectangle 724" width="23" height="23.016" transform="translate(0 0)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
        </clipPath>
      </defs>
      <g id="Group_1407" data-name="Group 1407" transform="translate(0 0)" clip-path="url(#clip-path)">
        <path id="Path_31765" data-name="Path 31765" d="M10,14.7l3,4.5a1.9,1.9,0,0,1-.5,2.5,1.782,1.782,0,0,1-2.5-.5L6,14.7" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2" />
        <path id="Path_31766" data-name="Path 31766" d="M13,6.7H5a4,4,0,0,0,0,8h8l9,5V1.7Z" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2" />
      </g>
    </svg>

  )
};

export default Broadcast;

