import React, { useState } from 'react'

const QuantityLess = (props) => {

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="1" viewBox="0 0 21 1">
    <line id="Line_39" data-name="Line 39" x2="21" transform="translate(0 0.5)" fill="none" stroke-width="1"/>
  </svg>
  
  )
};

export default QuantityLess;

