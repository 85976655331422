import React from 'react'
import Select from 'react-select';
import { Form } from "react-bootstrap";
import './InputSelect.scss'

const InputSelect = (props) => {
  const colourStyles = {
    option: (styles, { isSelected }) => ({
      ...styles,
      background: isSelected
        ? '#89CB08'
        : '#ffffff',
      zIndex: 1,
    }),
    menu: base => ({
      ...base,
      zIndex: 1000,
    }),
  }
  const error = props.error
  return (
    <div className='input-select'>
      <div className='form-label'>{props.label} <span>{props.labelinfo}</span></div>
      <div className='group-wrap d-flex'>
        <Form.Control
          type={props.type}
          id={props.id}
          placeholder={props.placeholder}
          value={props.inputValue} // Controlled input
          onChange={props.onInputChange} // Input change handler
          onKeyUp={props.onKeyUp}
          maxLength={props.maxLength}
          disabled={props.disabled}
        />
        <Select
          classNamePrefix='cust-options'
          name={props.name}
          placeholder={props.placeholder}
          styles={colourStyles}
          value={props.selectValue} // Controlled select
          onChange={props.onSelectChange} // Select change handler
          isSearchable={false}
          options={props.options}
          isDisabled={props.isDisabled} />
      </div>
      {error &&
        error !== "" && (
          <div>
            <Form.Text className="red">
              {error}
            </Form.Text>
          </div>
        )}
    </div>
  )
};

export default InputSelect;

