import React from 'react'
import { Form,OverlayTrigger, Tooltip } from 'react-bootstrap';
import './CustTextArea.scss'
import Info from '../assets/img/icons/InfoTooltip'

const CustTextArea = (props) => {
  return (
    <Form className='textarea-group'>
      <Form.Group>
        <Form.Label>{props.label}
          {props.subText &&<OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{props.subText}</Tooltip>}>
              <span className='tooltip-wrap'><Info /></span>
            </OverlayTrigger>
          }
          </Form.Label>
        
        <Form.Control as="textarea"
          id={props.id}
          name={props.name}
          type={props.type}
          className={props.errorMessage && props.errorMessage !== '' ? 'error' : ""}
          placeholder={props.placeholder}
          onChange={props.onChange}
          value={props.value}
          readOnly={props.readOnly}
          maxLength={props.maxLength}
        />
        {props.errorMessage && props.errorMessage !== '' ? <Form.Text className='error'>
          {props.errorMessage}
        </Form.Text> : ""}
      </Form.Group>
    </Form>
  )
};

export default CustTextArea;

