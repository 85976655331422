import React from 'react'
import './LocationZipcode.scss'
import { Col } from 'react-bootstrap';
import LocationRemove from '../assets/img/icons/LocatoinRemove';

const LocationZipcode = (props) => {
  const data = props.data
  const handleChange = props.delete
  const disabled = props.disabled
  return (
    <Col className='location-zipcode d-flex justify-content-between align-items-center'>

      {data.address_type === 1 && <div>
        <span className='d-block'>Address 1</span>
        {data.d_address1.trim()}, {data.d_city.trim()}, {data.d_state.trim()}, {data.d_country.trim()}, {data.d_zip.trim()}
      </div>}

      {data.address_type === 2 && <div>
        <span className='d-block'>Address 2</span>
        {data.address.trim()}, {data.city.trim()}, {data.state.trim()}, {data.country.trim()}, {data.zip.trim()}
      </div>}

      {data.address_type === 3 && <div>
        <span className='d-block'>Zipcode</span>
        {data.zipcode}
      </div>}

      {disabled ? <div>
        <LocationRemove />
      </div> :
        <div className='hover-cursor-pointer' onClick={() => handleChange(data.address_id, data.address_type)}>
          <LocationRemove />
        </div>}

    </Col>
  )
};

export default LocationZipcode;

