import React, { useState } from 'react'
const Link = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="12" viewBox="0 0 24 12">
      <g id="link-2" transform="translate(1 1)">
        <path id="Path_764" data-name="Path 764" d="M15,7h3a5,5,0,1,1,0,10H15M9,17H6A5,5,0,1,1,6,7H9" transform="translate(-1 -7)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
        <line id="Line_49" data-name="Line 49" x2="8" transform="translate(7 5.147)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
      </g>
    </svg>
  )
};

export default Link;

