import React, { useState } from 'react'

const PdfIcn = (props) => {

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="17.742" height="22" viewBox="0 0 17.742 22">
      <g id="pdf" transform="translate(-14 -2)">
        <g id="Group_807" data-name="Group 807" transform="translate(14 2)">
          <g id="Group_804" data-name="Group 804" transform="translate(0)">
            <g id="Group_803" data-name="Group 803">
              <path id="Path_759" data-name="Path 759" d="M29.968,24H15.774A1.776,1.776,0,0,1,14,22.226V3.774A1.776,1.776,0,0,1,15.774,2h7.219a1.762,1.762,0,0,1,1.254.52l6.975,6.975a1.762,1.762,0,0,1,.52,1.254V22.226A1.776,1.776,0,0,1,29.968,24Z" transform="translate(-14 -2)" fill="#fff" stroke='none' />
              <g id="Group_808" data-name="Group 808" transform="translate(2.139 5.752)">
                <g id="Group_805" data-name="Group 805">
                  <path id="Path_760" data-name="Path 760" d="M44.8,48.61a.611.611,0,0,0-.178-.306,2.473,2.473,0,0,0-1.6-.377,13.19,13.19,0,0,0-1.92.141,4.783,4.783,0,0,1-.89-.615A6.5,6.5,0,0,1,38.6,44.958c.023-.091.043-.17.062-.252a16.229,16.229,0,0,0,.283-2.922.849.849,0,0,0-.05-.209l-.033-.085a.692.692,0,0,0-.631-.484L38.043,41h0a.713.713,0,0,0-.728.457,8.464,8.464,0,0,0,.443,3.811l-.112.271c-.312.763-.7,1.529-1.047,2.2l-.045.087c-.364.71-.693,1.314-.993,1.825l-.308.163c-.023.012-.552.292-.675.366a3.777,3.777,0,0,0-1.864,1.906.468.468,0,0,0,.178.519l.3.151a.916.916,0,0,0,.406.1c.749,0,1.618-.933,2.816-3.023a28.01,28.01,0,0,1,4.337-1.03,7.483,7.483,0,0,0,3.16,1,1.462,1.462,0,0,0,.372-.041.645.645,0,0,0,.37-.252A1.34,1.34,0,0,0,44.8,48.61ZM33.431,52.289a5.2,5.2,0,0,1,1.477-1.767c.05-.041.174-.157.286-.265A5.339,5.339,0,0,1,33.431,52.289Zm4.734-10.9c.24,0,.377.606.389,1.175a2.415,2.415,0,0,1-.286,1.264,6.134,6.134,0,0,1-.2-1.577S38.055,41.387,38.165,41.387Zm-1.413,7.768q.253-.45.521-.952a17.829,17.829,0,0,0,.913-1.993,6.593,6.593,0,0,0,1.508,1.871c.075.062.155.126.236.19A19.146,19.146,0,0,0,36.752,49.156Zm7.631-.068a1.115,1.115,0,0,1-.42.072,4.93,4.93,0,0,1-1.742-.526c.292-.021.561-.033.8-.033a3.076,3.076,0,0,1,1,.108C44.453,48.819,44.459,49.041,44.383,49.088Z" transform="translate(-32.697 -41)" fill="#e55b13" stroke='none' />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
};

export default PdfIcn;

