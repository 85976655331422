/**
 * @file   src\
 * @brief  This file is responsible for
 * @date   Oct, 2022
 * @author ZCO Engineer
 * @copyright (c) 2022, ZCO
 */

import React, { useState, useEffect } from "react";
import { Button, Col, Row, Form, Breadcrumb, Modal } from "react-bootstrap";
import InnerLayout from "../components/InnerLayout";
import Input from "../components/Input";
import Strings from "../assets/strings/Strings.json";
import Products from "../components/Products";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { WeekChange } from "../helpers/validationHelper";
import CustSelectLabel from "../components/SelectLabel";
import FileUpload from "../components/InputFile";
import Plan from "../components/Plan";
import {
  _vendorProfile,
  _vendorCategoryList,
  resetResponse,
  _vendorListProductMessageList,
  _addSubscription,
} from "../actions/VendorAction";

import {
  _addProduct,
  _draftproducts,
  _listProductToApp,
  _productImagePreSignedURL,
  _removeProduct,
  _createProductPayment,
  _productPaymentStatus,
  _deleteProduct,
  _productFreshnessList,
  _scheduleTimeList,
} from "../actions/ProductAction";

import { validateImageTypes } from "../helpers/authHelper";
import * as alerts from "../utils/Messages";
import Food from "../components/Food";
import ThingsDo from "../components/ThingsDo";
import Services from "../components/Services";
import AdsJobs from "../components/AdsJobs";
import PlanOneTime from "../components/PlanOneTime";
import ListProductPopUp from "../components/ListProductPopUp";
import * as constants from "../utils/constants";
import Coupon from "../components/Coupon";

const AddProduct = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  // category list start
  const categoryList = () => dispatch(_vendorCategoryList());
  const categoryListApiResponse = useSelector(
    (RootReducer) => RootReducer.VendorReducer.vendorCategoryListResponse
  );
  // category list ends

  // product image upload start
  const imagePreSignedUpload = (imageName) =>
    dispatch(_productImagePreSignedURL(imageName));
  const imagePreSignedUploadResponse = useSelector(
    (RootReducer) => RootReducer.ProductReducer.productImagePreSignedResponse
  );
  // product image upload  ends

  // draft product start
  const draftproducts = () => dispatch(_draftproducts());
  const draftproductResponse = useSelector(
    (RootReducer) => RootReducer.ProductReducer.draftproductsResponse
  );
  // add product ends
  // add product start
  const addProduct = (details) => dispatch(_addProduct(details));
  const addProductResponse = useSelector(
    (RootReducer) => RootReducer.ProductReducer.addProductResponse
  );
  // add product ends

  //  product freshness list start
  const freshnessList = () => dispatch(_productFreshnessList());
  const freshnessListResponse = useSelector(
    (RootReducer) => RootReducer.ProductReducer.productFreshnessListResponse
  );
  // product freshness list ends

  // Message list start
  const listProductMessageList = () =>
    dispatch(_vendorListProductMessageList());
  const listProductMessageListResponse = useSelector(
    (RootReducer) => RootReducer.VendorReducer.listProductMessageListResponse
  );

  // list Product To App
  const listProductToApp = (params) => dispatch(_listProductToApp(params));
  const listProductToAppResponse = useSelector(
    (RootReducer) => RootReducer.ProductReducer.listProductToAppResponse
  );

  //   remove image
  const removeProduct = (params) => dispatch(_removeProduct(params));
  const removeProductResponse = useSelector(
    (RootReducer) => RootReducer.ProductReducer.removeProductResponse
  );

  // vendor profile start
  const vendorProfile = () => dispatch(_vendorProfile());
  const vendorProfileResponse = useSelector(
    (RootReducer) => RootReducer.VendorReducer.vendorProfileResponse
  );

  // Add subscription response
  const addSubscription = (params) => dispatch(_addSubscription(params));
  const addSubscriptionApiResponse = useSelector(
    (RootReducer) => RootReducer.VendorReducer.addSubscriptionResponse
  );

  // Product list start
  const listScheduleTime = (details) => dispatch(_scheduleTimeList(details));
  const listScheduleTimeResponse = useSelector(
    (RootReducer) => RootReducer.ProductReducer.scheduleTimeListResponse
  );
  // Product list ends

  const deleteProducts = (productId) => dispatch(_deleteProduct(productId));

  const productPayment = (params) => dispatch(_createProductPayment(params));
  const productPaymentResponse = useSelector(
    (RootReducer) => RootReducer.ProductReducer.productPaymentResponse
  );

  const productPaymentStatus = (params) =>
    dispatch(_productPaymentStatus(params));
  const productPaymentStatusResponse = useSelector(
    (RootReducer) => RootReducer.ProductReducer.productPaymentStatusResponse
  );

  const createSubscriptionResponse = useSelector(
    (RootReducer) => RootReducer.VendorReducer.vendorCreateSubscriptionResponse
  );

  const intitalValuesProduct = {
    category_id: 0,
    subcategory_id: 0,
    video_link: "",
    prod_type: 0,
    prod_description: "",
    whats_great: "",
    prod_name: "",
    prod_price: "",
    prod_qty: 1,
    delivery_type: [],
    prod_id: 0,
    type: 1,
    is_nation_wide: "",
    deliveryOption:0
  };

  const intitalValuesServices = {
    category_id: 0,
    subcategory_id: 0,
    video_link: "",
    prod_availability: "",
    prod_description: "",
    prod_name: "",
    prod_price: "",
    prod_qty: 1,
    calendly_link: "",
    delivery_type: [],
    prod_id: 0,
    image_id: 0,
    type: 1,
    is_nation_wide: "",
  };

  const intitalValuesFood = {
    category_id: 0,
    subcategory_id: 0,
    video_link: "",
    prod_description: "",
    prod_name: "",
    prod_price: "",
    prod_qty: 1,
    prod_availability: "",
    delivery_type: [],
    prod_id: 0,
    image_id: 0,
    type: 1,
    is_nation_wide: "",
    freshness_id: "",
    deliveryOption:0
  };

  const intitalValuesThingsDo = {
    category_id: 0,
    subcategory_id: 0,
    video_link: "",
    posted_date: "",
    prod_description: "",
    prod_name: "",
    prod_price: "",
    prod_qty: 1,
    prod_id: 0,
    image_id: 0,
    type: 1,
    is_nation_wide: "",
  };

  const intitalValuesAdsJob = {
    category_id: 0,
    prod_qty: 1,
    subcategory_id: 0,
    video_link: "",
    website_link: "",
    prod_description: "",
    prod_name: "",
    posted_date: new Date(),
    valid_till: new Date(),
    prod_price: "",
    prod_id: 0,
    image_id: 0,
    type: 1,
    is_nation_wide: "",
  };
  const intitalValuesCoupons = {
    category_id: 0,
    whats_great: "",
    subcategory_id: 42,
    video_link: "",
    website_link: "",
    prod_description: "",
    prod_name: "",
    prod_qty: 1,
    posted_date: new Date(),
    valid_to: new Date(),
    prod_price: "",
    prod_id: 0,
    image_id: 0,
    type: 1,
    is_nation_wide: "",
    discountType: 1,
    couponName: "",
    discount: "",
    minPurchaseAmt: ""
  };

  const intitalFormErrorProduct = {
    category_idError: "",
    subcategory_idError: "",
    video_linkError: "",
    prod_typeError: "",
    prod_descriptionError: "",
    prod_nameError: "",
    prod_priceError: "",
    prod_qtyError: 0,
    delivery_typeError: "",
    delivery_optionError:"",
    availabilityError: "",
    calendly_linkError: "",
    posted_dateError: "",
    valid_tillError: "",
    product_displayType: "",
    freshness_idError: "",
    couponNameError: "",
    validToError: "",
    discountError: "",
    minPurchaseAmtError: ""
  };

  const productTypes = [
    { value: 1, label: "New Product" },
    { value: 2, label: "Used Product" },
    { value: 3, label: "Refurbished Product" },
  ];
  const DiscountType = [
    { label: "Percentage Discount", value: constants.STATUS_1 },
    { label: "Discount Amount", value: constants.STATUS_2 },
  ];
  const [selectedDiscountType, setSelectedDiscountType] = useState({
    label: "Percentage Discount",
    value: constants.STATUS_1,
  });

  const [addProductData, setAddProductData] = useState(intitalValuesProduct);
  const [addProductError, setAddProductError] = useState(
    intitalFormErrorProduct
  );
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [productImages, setproductImages] = useState([]);
  const [subCategoriesValue, setSubCategoriesValue] = useState("");
  const [categoriesValue, setCategoriesValue] = useState("");
  const [freshnessValue, setFreshnessValue] = useState("");
  const [productPicError, setProductPicError] = useState("");
  const [productPicConfirmation, setProductPicConfirmation] = useState(false);
  const [productPicConfirmedStatus, setProductPicConfirmedStatus] = useState(0);
  const [subscriptionInfoData, setSubscriptionInfoData] = useState({});
  const [subscriptionListData, setSubscriptionListData] = useState([]);
  const [date, setDate] = useState({
    posted_date: new Date(),
    valid_date: new Date(),
  });
  const [checked, setChecked] = useState({});
  const [productTypeValue, setProductTypeValue] = useState("");
  const [subscriptionShow, setSubscriptionShow] = useState(false);
  const [show, setShow] = useState(false);
  const [Paymentshow, setPaymentShow] = useState(false);
  const [productId, setproductId] = useState(0);
  const [productPicUpload, setProductPicUpload] = useState(false);
  const [listproductshow, Setlistproductshow] = useState(false);
  const [messagesMaster, setMessagesMaster] = useState([]);
  const [productImageChange, setProductImageChange] = useState(true);
  const [imageUploadSuccess, setImageUploadSuccess] = useState(true);
  const [isSaveClicked, setIsSaveClicked] = useState(false);
  const [enabledListProductBtn, setEnabledListProductBtn] = useState(false);
  const [enabledSaveProductBtn, setEnabledSaveProductBtn] = useState(true);
  const [defaultImageId, setdefaultImageId] = useState("");
  const [deliveryTypeList, setDeliveryTypeList] = useState([]);
  const [draftData, setDraftData] = useState("");
  const [categoryValue, setCategoryValue] = useState("");
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [verificationInfo, setVerificationInfo] = useState([])
  const [showVerificationModal, setShowVerificationModal] = useState(false)
  const [localCheckedBox, setLocalCheckedBox] = useState("")
  const [nationalCheckedBox, setNationalCheckedBox] = useState("")
  const [freshness, setFreshness] = useState([])
  const [showSchedule, setShowSchedule] = useState(false)
  const [isClickdedDoneListMessage, seIsClickdedDoneListMessage] = useState(false)
  const [scheduleTimeList, setScheduleTimeList] = useState([])
  const [proxyAddress, setProxyAddress] = useState({
    type: { value: 1, label: "Default Address" },
    zip_codes: [],
    proxy_details: []
  })
  const [scheduledata, setScheduledata] = useState({
    start_date: new Date(),
    end_date: new Date(),
    occurrence: 0,
    value: [],
    date: 0,
    time: "",
    startTime: "",
    endTime: "",
    timeZone: ""
  })
  const [planData, setPlanData] = useState({
    plan_id: "",
    amount: "",
    is_upgrade: "",
  });
  const [selected,setSelected] = useState([])
  const [showDelete,setShowDelete] = useState(false)
  const [imageDeleteId,setImageDeleteId] = useState('')
  const [inStoreChecked, setInStoreChecked] = useState("");
  const [shipToAddressChecked, setShipToAddressChecked] = useState(false);



  // calling category list api
  useEffect(() => {
    listScheduleTime();
    categoryList();
    freshnessList();
    draftproducts();
    listProductMessageList();
    vendorProfile();
  }, []);
  // calling category list api

  // removeProductResponse
  useEffect(() => {
    if (removeProductResponse && removeProductResponse.apiStatus) {
      if (removeProductResponse.apiErrorCode === constants.STATUS_200) {
      } else {
        toast.error(removeProductResponse.apiMessage);
        draftproducts();
      }
      resetResponse(dispatch, "removeProductResponse");
    }
  }, [removeProductResponse]);

  // category listing response
  useEffect(() => {
    if (categoryListApiResponse && categoryListApiResponse.apiStatus) {
      if (categoryListApiResponse.apiErrorCode === constants.STATUS_200) {
        if (categoryListApiResponse.apiResponse.delivery_typelist) {
          let deliveryArray = [];
          categoryListApiResponse.apiResponse.delivery_typelist.map((delivery) => {
            deliveryArray.push({
              value: delivery.deliverytype_id,
              label: delivery.deliverytype_name,
            });
          });
          setDeliveryTypeList(deliveryArray);
          // setDeliveryTypeList(
          //   categoryListApiResponse.apiResponse.delivery_typelist
          // );
        }
        if (categoryListApiResponse.apiResponse.category_info) {
          let categoryArray = [];
          categoryListApiResponse.apiResponse.category_info.map((category) => {
            categoryArray.push({
              value: category.category_id,
              label: category.category_name,
            });
          });
          setCategories(categoryArray);
        } else toast.error(categoryListApiResponse.apiMessage);
      }
    }
  }, [categoryListApiResponse]);

  // category listing response
  useEffect(() => {
    if (freshnessListResponse && freshnessListResponse.apiStatus) {
      if (freshnessListResponse.apiErrorCode === constants.STATUS_200) {
        if (freshnessListResponse.apiResponse.freshness_info) {
          let freshnessArray = [];
          freshnessListResponse.apiResponse.freshness_info.map((fresh) => {
            freshnessArray.push({
              value: fresh.freshness_id,
              label: fresh.freshness_name,
            });
          });
          setFreshness(freshnessArray);
        } else toast.error(freshnessListResponse.apiMessage);
      }
    }
  }, [freshnessListResponse]);

  //handle category list API response
  useEffect(() => {
    if (listScheduleTimeResponse && listScheduleTimeResponse.apiStatus) {
      if (listScheduleTimeResponse.apiErrorCode === constants.STATUS_200)
        if (listScheduleTimeResponse.apiResponse.time_slots) {
          let timeArray = []
          listScheduleTimeResponse.apiResponse.time_slots.map((time) => {
            timeArray.push({ value: { start: time.timeslot_from, end: time.timeslot_to, meridiem: time.meridiem }, label: `${time.timeslot_from}${time.meridiem} - ${time.timeslot_to}${time.meridiem}` })
          })
          setScheduleTimeList(timeArray)
        }
        else
          toast.error(listScheduleTimeResponse.apiMessage)
    }
  }, [listScheduleTimeResponse])

  // handle add product response
  useEffect(() => {
    if (addProductResponse && addProductResponse.apiStatus) {
      if (addProductResponse.apiErrorCode === constants.STATUS_200) {
        if (addProductResponse.apiResponse &&
          addProductResponse.apiResponse.product_info &&
          addProductResponse.apiResponse.product_info[0]?.product_status !== 0) {
          toast.success(addProductResponse.apiMessage);
          setEnabledSaveProductBtn(false);
          setEnabledListProductBtn(true);
        }
        if (
          addProductResponse.apiResponse &&
          addProductResponse.apiResponse.product_info
        ) {
          setproductId(addProductResponse.apiResponse.product_info[0]?.prod_id);
        }
      } else if (
        (addProductResponse.apiStatus &&
          addProductResponse.apiResponse &&
          addProductResponse.apiResponse.subscription_status &&
          addProductResponse.apiResponse.subscription_status === constants.STATUS_1) ||
        addProductResponse.apiResponse.subscription_status === constants.STATUS_2 ||
        addProductResponse.apiResponse.subscription_status === 3
      ) {
        addProduct({
          category_id: addProductData?.category_id,
          prod_qty: addProductData?.prod_qty,
          prod_type: addProductData?.prod_type,
          subcategory_id: addProductData?.subcategory_id,
          video_link: addProductData?.video_link,
          website_link: addProductData?.website_link,
          prod_description: addProductData?.prod_description,
          prod_name: addProductData?.prod_name,
          posted_date: addProductData?.posted_date,
          valid_till: addProductData?.valid_till,
          prod_price: String(addProductData?.prod_price),
          prod_id: addProductData?.prod_id,
          image_id: addProductData?.image_id,
          prod_availability: addProductData?.prod_availability,
          delivery_type: addProductData?.delivery_type,
          calendly_link: addProductData?.calendly_link,
          type: 3,
          deliveryOption:addProductData?.deliveryOption
        })
        toast.error(addProductResponse.apiMessage);
        setEnabledSaveProductBtn(true);
        setEnabledListProductBtn(false);
      } else {
        toast.error(addProductResponse.apiMessage);
        setEnabledSaveProductBtn(true);
        setEnabledListProductBtn(false);
      }
      resetResponse(dispatch, "addProduct");
    }
  }, [addProductResponse]);

  // handle presigned image response
  useEffect(() => {
    if (
      imagePreSignedUploadResponse &&
      imagePreSignedUploadResponse.apiStatus
    ) {
      if (imagePreSignedUploadResponse.apiErrorCode === constants.STATUS_200) {
        setImageUploadSuccess(true)
        setProductImageChange(!productImageChange);
        fileupload();
        setproductImages(imagePreSignedUploadResponse.apiResponse.prod_images);
        setproductId(imagePreSignedUploadResponse.apiResponse.prod_id);
      } else {
        toast.error(imagePreSignedUploadResponse.apiMessage);
      }
      setProductPicUpload(false);
      resetResponse(dispatch, "productImagePreSigned");
    } else {
      setProductPicUpload(false);
    }
  }, [imagePreSignedUploadResponse]);

  // handle presigned image response
  useEffect(() => {
    if (productId) {
      setAddProductData((addProductData) => ({
        ...addProductData,
        prod_id: productId,
      }));
    }
  }, [productId]);

  // handle presigned image response
  useEffect(() => {
    if (draftproductResponse && draftproductResponse.apiStatus) {
      if (draftproductResponse.apiErrorCode == constants.STATUS_200) {
        if (
          draftproductResponse.apiErrorCode == constants.STATUS_200 &&
          draftproductResponse.apiResponse.product_info[0]
        ) {
          setproductId(
            draftproductResponse.apiResponse.product_info[0].prod_id
          );
          setproductImages(draftproductResponse.apiResponse.prod_images);
          handleDefaultImageId(
            draftproductResponse.apiResponse.prod_images[0]?.image_id
          );
          setProductImageChange(!productImageChange);
          setDraftData(draftproductResponse.apiResponse.product_info[0]);
        }
        fileupload();
      } else if (draftproductResponse.apiErrorCode == constants.STATUS_400) {
        setproductId(0);
        setproductImages([]);
      }
      resetResponse(dispatch, "draftproductsResponse");
    }
  }, [draftproductResponse]);

  // listProductToAppResponse
  useEffect(() => {
    if (listProductToAppResponse && listProductToAppResponse.apiStatus) {
      if (listProductToAppResponse.apiErrorCode === constants.STATUS_200) {
        toast.success(listProductToAppResponse.apiMessage);
        seIsClickdedDoneListMessage(true)
        handleClose();
        navigate("/dashboard");
      } else {
        toast.error(listProductToAppResponse.apiMessage);
      }
      resetResponse(dispatch, "listProductToAppResponse");
    }
  }, [listProductToAppResponse]);
  //handle List your product message list API response
  useEffect(() => {
    if (
      listProductMessageListResponse &&
      listProductMessageListResponse.apiStatus
    ) {
      if (listProductMessageListResponse.apiErrorCode === constants.STATUS_200)
        if (listProductMessageListResponse.apiResponse.messages_info) {
          setMessagesMaster(
            listProductMessageListResponse.apiResponse.messages_info
          );
        }
      resetResponse(dispatch, "listProductMessageListResponse");
    }
  }, [listProductMessageListResponse]);

  // Vendor profile response
  useEffect(() => {
    if (vendorProfileResponse) {
      if (vendorProfileResponse.apiErrorCode === constants.STATUS_200 && vendorProfileResponse.apiResponse && vendorProfileResponse.apiResponse.verification_info) {
        setVerificationInfo(vendorProfileResponse.apiResponse.verification_info)
      }
    }
  }, [vendorProfileResponse]);

  useEffect(() => {
    if (addSubscriptionApiResponse && addSubscriptionApiResponse.apiStatus) {
      if (addSubscriptionApiResponse.apiErrorCode === constants.STATUS_200) {
        setSubscriptionShow(false);
        if (planData.plan_id === constants.STATUS_1) {
          saveAddProduct();
        }
        toast.success(addSubscriptionApiResponse.apiMessage);
      } else {
        toast.error(addSubscriptionApiResponse.apiMessage);
      }
      resetResponse(dispatch, "addSubscription");
    }
  }, [addSubscriptionApiResponse]);

  useEffect(() => {
    if (
      productImages.length !== 0 &&
      !productImages.find((e) => e.image_id === defaultImageId)
    ) {
      handleDefaultImageId(productImages[0].image_id);
    } else if (productImages.length === 0) {
      setAddProductData((addProductData) => ({
        ...addProductData,
        prod_id: 0,
      }));
      handleDefaultImageId(0);
    }
  }, [productImages]);

  useEffect(() => {
    if (productPaymentResponse && productPaymentResponse.apiStatus)
      if (productPaymentResponse.apiErrorCode === constants.STATUS_200) {
        if (
          productPaymentResponse.apiResponse &&
          productPaymentResponse.apiResponse.paymentIntentInfo
        ) {
          navigate("/payment", {
            state: {
              client_secret:
                productPaymentResponse.apiResponse.paymentIntentInfo
                  ?.clientSecret,
              type: "product",
            },
          });
          resetResponse(dispatch, "createProductPayment");
        }
      } else if (productPaymentResponse.apiErrorCode === constants.STATUS_400) {
        toast.error(productPaymentResponse.apiMessage);
        setEnabledSaveProductBtn(true);
        setEnabledListProductBtn(false);
        resetResponse(dispatch, "createProductPayment");
      }
  }, [productPaymentResponse]);

  useEffect(() => {
    if (productPaymentStatusResponse && productPaymentStatusResponse.apiStatus)
      if (productPaymentStatusResponse.apiErrorCode === constants.STATUS_200) {
        setPaymentSuccess(true);
        resetResponse(dispatch, "productPaymentStatus");
      } else if (productPaymentStatusResponse.apiErrorCode === constants.STATUS_400) {
        toast.error(productPaymentStatusResponse.apiMessage);
        resetResponse(dispatch, "productPaymentStatus");
      }
  }, [productPaymentStatusResponse]);

  useEffect(() => {
    if (draftData != "") {
      if (draftData.category_id && draftData.category_name) {
        setCategoryValue({
          value: draftData?.category_id,
          label: draftData?.category_name,
        });
      }
      setEnabledSaveProductBtn(true);
      setEnabledListProductBtn(false);
      if (draftData.subcategory_id && draftData.subcategory_name) {
        setSubCategoriesValue({
          value: draftData?.subcategory_id,
          label: draftData?.subcategory_name,
        });
      }
      if (categoryListApiResponse && categoryListApiResponse.apiStatus) {
        if (categoryListApiResponse.apiErrorCode === constants.STATUS_200) {
          if (categoryListApiResponse.apiResponse.subcategory_info) {
            let categoryArray = [];
            categoryListApiResponse.apiResponse.subcategory_info.map(
              (subcategory) => {
                if (subcategory.category_id === draftData?.category_id) {
                  categoryArray.push({
                    value: subcategory.subcategory_id,
                    label: subcategory.subcategory_name,
                  });
                }
              }
            );
            setSubCategories(categoryArray);
          }
        }
      }
      setCategoriesValue(draftData?.category_id);
      setAddProductData({
        category_id: draftData?.category_id,
        prod_qty: draftData?.prod_qty,
        subcategory_id: draftData?.subcategory_id,
        video_link: draftData?.video_link,
        website_link: draftData?.website_link,
        prod_description: draftData?.prod_description,
        prod_name: draftData?.prod_name,
        posted_date: draftData?.posted_date,
        valid_till: draftData?.valid_till,
        prod_price: String(draftData.prod_price),
        prod_id: draftData?.prod_id,
        image_id: addProductData?.image_id,
        prod_availability: draftData?.prod_availability,
        delivery_type: draftData?.delivery_type,
        calendly_link: draftData?.calendly_link,
        is_nation_wide: draftData?.is_nation_wide,
        type: 1,
        deliveryOption:draftData?.deliveryOption,
      });
      setNationalCheckedBox(draftData?.is_nation_wide)
      if(draftData?.deliveryOption==1){
        setInStoreChecked=0
      }else if(draftData?.deliveryOption==2){
        setInStoreChecked=1
      }
      switch (draftData?.prod_type) {
        case 1:
          setProductTypeValue({ value: 1, label: "New Product" });
          break;
        case 2:
          setProductTypeValue({ value: 2, label: "Used Product" });
          break;
        case 3:
          setProductTypeValue({ value: 3, label: "Refurbished Product" });
          break;
      }
      if (draftData.image_id) {
        handleDefaultImageId(draftData.image_id)
      }
      if (draftData.posted_date !== "") {
        handleDateChange(draftData?.posted_date, 1);
      }
      if (draftData.valid_till !== null) {
        handleDateChange(draftData?.valid_till, 2);
      }
      if (draftData.delivery_type) {
        draftData.delivery_type.map((data, key) => {
          const foundObject =
            deliveryTypeList &&
            deliveryTypeList.find((object) => object.deliverytype_id === data);
          if (foundObject) {
            setChecked((checked) => ({
              ...checked,
              [foundObject.deliverytype_name]: true,
            }));
          }
        });
      }
      if (
        location.state &&
        location.state.payment_intent &&
        draftData.category_id === 5
      ) {
        productPaymentStatus({
          paymentIntent: location.state.payment_intent,
          productId: draftData.prod_id,
        });
      }
    }
  }, [draftData]);

  useEffect(() => {
    if (createSubscriptionResponse && createSubscriptionResponse.apiStatus)
      if (createSubscriptionResponse.apiErrorCode === constants.STATUS_200) {
        if (
          createSubscriptionResponse.apiResponse &&
          createSubscriptionResponse.apiResponse.subscriptionInfo
        ) {
          setSubscriptionShow(false);
          if (planData.plan_id === constants.STATUS_1) {
            addSubscription({
              subscription_type: planData.plan_id,
              amount: planData.amount,
              is_upgrade: planData.is_upgrade,
            });
          } else {
            addProduct({
              category_id: addProductData?.category_id,
              prod_qty: addProductData?.prod_qty,
              subcategory_id: addProductData?.subcategory_id,
              video_link: addProductData?.video_link,
              website_link: addProductData?.website_link,
              prod_description: addProductData?.prod_description,
              prod_name: addProductData?.prod_name,
              posted_date: addProductData?.posted_date,
              valid_till: addProductData?.valid_till,
              prod_price: String(addProductData?.prod_price),
              prod_id: addProductData?.prod_id,
              image_id: addProductData?.image_id,
              prod_availability: addProductData?.prod_availability,
              delivery_type: addProductData?.delivery_type,
              calendly_link: addProductData?.calendly_link,
              type: 3,
              deliveryOption:addProductData?.deliveryOption,
            })
            navigate("/payment", {
              state: {
                client_secret:
                  createSubscriptionResponse.apiResponse.subscriptionInfo
                    ?.clientSecret,
                subscriptionId:
                  createSubscriptionResponse.apiResponse.subscriptionInfo
                    ?.subscriptionId,
                amount: planData.amount,
                plan_id: planData.plan_id,
                is_upgrade: planData.is_upgrade,
                type: "product_subscrption",
              },
            });
          }
          resetResponse(dispatch, "createSubscription");
        }
      } else if (createSubscriptionResponse.apiErrorCode === constants.STATUS_400) {
        toast.error(createSubscriptionResponse.apiMessage);
        resetResponse(dispatch, "createSubscription");
      }
  }, [createSubscriptionResponse]);

  // handle show and close of cancel modal
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleSubscriptionClose = () => setSubscriptionShow(false);
  const paymentClose = () => setPaymentShow(false);
  const paymentShow = () => setPaymentShow(true);
  const handleCloseDelete = () => {
    setShowDelete(false)
    setImageDeleteId('')
  }
  const handleShowDelete = (e) => {
    setShowDelete(true)
    setImageDeleteId(e)
  }

  // handle image delete 
  const handleDeleteButton = () => {
    deleteImage(imageDeleteId)
    handleCloseDelete()
  }

  //handle Validation
  const addProductValidation = () => {
    let isValid = true;
    setAddProductError("");
    if (addProductData.category_id === 0) {
      isValid = false;
      setAddProductError((addProductError) => ({
        ...addProductError,
        ["category_idError"]: alerts.ENTER_CATEGORY,
      }));
    }


    if (addProductData.subcategory_id === 0) {
      isValid = false;
      setAddProductError((addProductError) => ({
        ...addProductError,
        ["subcategory_idError"]: alerts.ENTER_SUB_CATEGORY,
      }));
    }
    if (addProductData.is_nation_wide === "") {
      isValid = false;
      setAddProductError((addProductError) => ({
        ...addProductError,
        ["product_displayType"]: alerts.ENTER_PRODUCT_DISPLAY_TYPE,
      }));
    }

    if (addProductData.prod_type === 0 && addProductData.category_id === constants.STATUS_1) {
      isValid = false;
      setAddProductError((addProductError) => ({
        ...addProductError,
        ["prod_typeError"]: alerts.ENTER_PRODUCT_TYPE,
      }));
    }

    if (addProductData.prod_name !== null && addProductData.prod_name.trim() === "") {
      isValid = false;
      setAddProductError((addProductError) => ({
        ...addProductError,
        ["prod_nameError"]:
          addProductData.category_id === 4
            ? alerts.ENTER_EXPERIENCE_NAME
            : alerts.ENTER_PRODUCT_NAME,
      }));
    }

    if (addProductData.prod_description !== null && addProductData.prod_description.trim() === "") {
      isValid = false;
      setAddProductError((addProductError) => ({
        ...addProductError,
        ["prod_descriptionError"]: alerts.ENTER_PRODUCT_DESCRIPTION,
      }));
    }

    if (
      addProductData.prod_price !== null &&
      addProductData.prod_price.trim() === "" &&
      addProductData.category_id !== 5 &&
      addProductData.category_id !== constants.STATUS_6
    ) {
      isValid = false;
      setAddProductError((addProductError) => ({
        ...addProductError,
        ["prod_priceError"]: alerts.ENTER_PRICE,
      }));
    }

    if (
      addProductData.category_id !== 4 &&
      addProductData.category_id !== 5 &&
      addProductData.category_id !== constants.STATUS_6 &&
      addProductData.delivery_type.length === 0
    ) {
      isValid = false;
      setAddProductError((addProductError) => ({
        ...addProductError,
        ["delivery_typeError"]: alerts.ENTER_DELIVERY_TYPE,
      }));
    }
    if (
      addProductData.category_id !== constants.STATUS_4 &&
      addProductData.category_id !== constants.STATUS_5 &&
      addProductData.category_id !== constants.STATUS_6 &&
      addProductData.category_id !== constants.STATUS_3 &&
      addProductData.deliveryOption === 0
    ) {
      isValid = false;
      setAddProductError((addProductError) => ({
        ...addProductError,
        ["delivery_optionError"]: alerts.ENTER_DELIVERY_OPTION,
      }));
    }

    if (addProductData.category_id === 5 && addProductData.website_link.trim() === "") {
      isValid = false;
      setAddProductError((addProductError) => ({
        ...addProductError,
        ["website_linkError"]: alerts.ENTER_WEBSITE_LINK,
      }));
    }
    if (addProductData.category_id === constants.STATUS_4 && addProductData.posted_date === "") {
      isValid = false;
      setAddProductError((addProductError) => ({
        ...addProductError,
        "posted_dateError": alerts.ENTER_DATE,
      }));
    }

    if (
      addProductData.category_id === constants.STATUS_6 &&
      addProductData.couponName.trim() === ""
    ) {
      isValid = false;
      setAddProductError((addProductError) => ({
        ...addProductError,
        ["couponNameError"]: alerts.ENTER_COUPON_VALUE,
      }));
    }

    if (
      addProductData.category_id === constants.STATUS_6 &&
      addProductData.discount.trim() === ""
    ) {
      setAddProductError((addProductError) => ({
        ...addProductError,
        ["discountError"]: alerts.ENTER_VALUE,
      }));
      isValid = false;
    }

    if (productImages.length === 0) {
      isValid = false;
      setProductPicError(alerts.ENTER_IMAGE);
    } else {
      setProductPicError("");
    }

    if (!imageUploadSuccess) {
      isValid = false;
      setProductPicError(alerts.ENTER_IMAGE);
    }
    if (
      addProductData?.category_id === constants.STATUS_6 &&
      addProductData?.discountType === constants.STATUS_2 &&
      addProductData?.minPurchaseAmt.trim() === ""
    ) {
      isValid = false;
      setAddProductError((addProductError) => ({
        ...addProductError,
        ["minPurchaseAmtError"]: alerts.ENTER_VALUE,
      }));
    }
    if (
      addProductData.category_id === constants.STATUS_6 &&
      addProductData.discountType === constants.STATUS_1 &&
      addProductData.discount.trim() !== "" &&
      addProductData.discount.trim() >= 95
    ) {
      isValid = false;
      setAddProductError((addProductError) => ({
        ...addProductError,
        ["discountError"]: alerts.MAXIMUM_DISCOUNT_ALLOWED,
      }));
    }

    if (
      addProductData?.category_id === constants.STATUS_6 &&
      addProductData?.discountType === constants.STATUS_2 &&
      addProductData?.minPurchaseAmt.trim() !== "" &&
      addProductData?.discount.trim() !== "" &&
      addProductData?.minPurchaseAmt - addProductData.discount < 1

    ) {
      isValid = false;
      setAddProductError((addProductError) => ({
        ...addProductError,
        ["minPurchaseAmtError"]: alerts.MINIMUM_AMOUNT_DIFFERENCE,
      }));
    }

    

    return isValid;
  };

  // handle category change
  const categoriesChange = (e) => {
    const value = e.value;
    setCategoriesValue(e.value);
    setNationalCheckedBox("");
    setInStoreChecked("");
    setSubCategoriesValue(null);
    setFreshnessValue(null);
    setChecked({});
    setSelected([]);
    if (value === constants.STATUS_4) {
      setDate((date) => ({
        ...date,
        posted_date: "",
        valid_date: new Date(),
      }));
    } else {
      setDate((date) => ({
        ...date,
        posted_date: new Date(),
        valid_date: new Date(),
      }));
    }
    setAddProductError("");
    switch (value) {
      case 1:
        setAddProductData(intitalValuesProduct);
        break;
      case 2:
        setAddProductData(intitalValuesFood);
        break;
      case 3:
        setAddProductData(intitalValuesServices);
        break;
      case 4:
        setAddProductData(intitalValuesThingsDo);
        break;
      case 5:
        setAddProductData(intitalValuesAdsJob);
        break;
      case 6:
        setAddProductData(intitalValuesCoupons);
        break;
    }

    setAddProductData((addProductData) => ({
      ...addProductData,
      category_id: value,
      prod_id: productId,
      image_id: defaultImageId,
      subcategory_id: value === constants.STATUS_6 ? 42 : 0,
    }));
    setCategoryValue(e);
    if (categoryListApiResponse && categoryListApiResponse.apiStatus) {
      if (categoryListApiResponse.apiErrorCode === constants.STATUS_200) {
        if (categoryListApiResponse.apiResponse.subcategory_info) {
          let categoryArray = [];
          categoryListApiResponse.apiResponse.subcategory_info.map(
            (subcategory) => {
              if (subcategory.category_id === value) {
                categoryArray.push({
                  value: subcategory.subcategory_id,
                  label: subcategory.subcategory_name,
                });
              }
            }
          );
          setSubCategories(categoryArray);
        }
      }
    }
  };

  // handle subcategory change
  const handleSubCategoriesChange = (value) => {
    setSubCategoriesValue(value);
    setAddProductData((addProductData) => ({
      ...addProductData,
      subcategory_id: value.value,
    }));
  };

  // handle freshness change
  const handleFreshnessChange = (value) => {
    setFreshnessValue(value);
    setAddProductData((addProductData) => ({
      ...addProductData,
      freshness_id: value.value,
    }));
  };

  // handle input changes
  const handleChange = (event) => {
    if (event.target.name === "prod_price") {
      if (/^(\d)*(\.)?([0-9]{1,2})?$/.test(event.target.value)) {
        setAddProductData((addProductData) => ({
          ...addProductData,
          [event.target.name]: event.target.value,
        }));
      }
    } else if (event.target.name === "prod_qty") {
      if (!/\D/.test(event.target.value)) {
        setAddProductData((addProductData) => ({
          ...addProductData,
          [event.target.name]: event.target.value,
        }));
      }
    } else if (event.target.name === "discount" ||  event.target.name ==="minPurchaseAmt") {
      if (addProductData.discountType === constants.STATUS_1) {
        if (/^[0-9]*$/.test(event.target.value)) {
          setAddProductData((addProductData) => ({
            ...addProductData,
            [event.target.name]: event.target.value,
          }));
        }
      } else if (/^\d*\.?\d{0,2}$/.test(event.target.value)) {
        setAddProductData((addProductData) => ({
          ...addProductData,
          [event.target.name]: event.target.value,
        }));
      }
    } else {
      setAddProductData((addProductData) => ({
        ...addProductData,
        [event.target.name]: event.target.value,
      }));
    }
  };

  //Image upload  using image canvas
  const handleImageUpload = async (event) => {
    setProductPicError("");
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      let isValid = validateImageTypes(file);
      if (isValid === "") {
        setProductPicUpload(true);
        const img = document.createElement("img");
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        img.onload = () => {
          let width = img.width;
          let height = img.height;
          canvas.width = width;
          canvas.height = height;

          ctx.drawImage(img, 0, 0, width, height);
          const dataUrl = canvas.toDataURL(file.type, 0.8);
          if (productId) {
            // in file file upload of a product, product id will not be there
            imagePreSignedUpload({ fileName: dataUrl, prod_id: productId });
            setImageUploadSuccess(false);
          } else {
            imagePreSignedUpload({ fileName: dataUrl });
            setImageUploadSuccess(false);
          }
        };
        img.src = URL.createObjectURL(file);
      } else {
        setProductPicError(isValid);
        setProductPicUpload(false);
      }
    }
  };

  // handle delivery checkbox
  const deliveryTypeProduct = (value) => {
    setAddProductData((addProductData) => ({
      ...addProductData,
      delivery_type: value,
    }));
  };

  //handle save and call addproduct api
  const saveAddProduct = () => {
    let isValid = addProductValidation();
    if (isValid) {
      addProduct(addProductData);
      setIsSaveClicked(true);
    }
  };

  // handle Qunatity Plus or Minus
  const handleQuantity = (type) => {
    if (
      type === constants.STATUS_1 &&
      addProductData.prod_qty !== constants.STATUS_1
    ) {
      setAddProductData((addProductData) => ({
        ...addProductData,
        prod_qty: addProductData.prod_qty - 1,
      }));
    } else if (type === constants.STATUS_2) {
      setAddProductData((addProductData) => ({
        ...addProductData,
        prod_qty: addProductData.prod_qty + 1,
      }));
    }
  };

  //handle Product type selection
  const prodTypeChange = (e) => {
    setProductTypeValue(e);
    setAddProductData((addProductData) => ({
      ...addProductData,
      prod_type: e.value,
    }));
  };

  const handleDateChange = (e, type) => {
    if (type === constants.STATUS_1) {
      setAddProductData((addProductData) => ({
        ...addProductData,
        posted_date: e,
      }));
      setDate((date) => ({
        ...date,
        posted_date: new Date(e),
      }));
      if (
        categoriesValue !== "" &&
        categoriesValue === constants.STATUS_6 &&
        e > date.valid_date
      ) {
        setAddProductData((addProductData) => ({
          ...addProductData,
          valid_to: e,
        }));
        setDate((date) => ({
          ...date,
          valid_date: new Date(e),
        }));
      }
    } else {
      setDate((date) => ({
        ...date,
        valid_date: new Date(e),
      }));
      if (categoriesValue !== "" && categoriesValue === constants.STATUS_6) {
        setAddProductData((addProductData) => ({
          ...addProductData,
          valid_to: e,
        }));
      } else {
        setAddProductData((addProductData) => ({
          ...addProductData,
          valid_till: e,
        }));
      }
    }
  };

  const submitListProduct = (messageId) => {
    let params = {
      product_array: [productId],
      messageId: messageId,
      proxy_type: proxyAddress.type?.value,
      zip_codes: proxyAddress.zip_codes,
    };
    listProductToApp(params);
  };

  const deleteImage = (imageid) => {
    let params = {
      prod_id: productId,
      image_id: imageid,
    };
    removeProduct(params);
    let currentImages =
      productImages &&
      productImages.filter((data, key) => {
        return data.image_id !== imageid;
      });
    setProductImageChange(productImageChange);
    setproductImages(currentImages);
    fileupload();
  };

  const handleDefaultImageId = (e) => {
    setdefaultImageId(e);
    setAddProductData((addProductData) => ({
      ...addProductData,
      image_id: e,
    }));
  };
  const fileupload = () => {
    return (
      <FileUpload
        defaultImageId={defaultImageId}
        handleDefaultId={handleDefaultImageId}
        loading={productPicUpload}
        productImages={productImages}
        uploadFile={(e) => handleImageUpload(e)}
        deleteImage={handleShowDelete}
      />
    );
  };

  const showListProducts = () => {
    Setlistproductshow(true);
  };
  const listproducHide = () => {
    Setlistproductshow(false);
    navigate("/dashboard");
  };

  const handleCancel = () => {
    if (productId) {
      deleteProducts({
        prod_id: productId,
      });
    }
    navigate("/dashboard");
  };

  const handleListProduct = () => {
    if (verificationInfo && !verificationInfo.bank_verified) {
      setShowVerificationModal(true);
    } else {
      showListProducts();
    }
  };

  const handleConfirmModal = () => {
    setShowVerificationModal(false);
    navigate("/profile", {
      state: {
        current_tab: "bankinfo",
      },
    });
  };
  const handleLocalCheckBoxChange = (e) => {
    if (e.target.value === "on") {
      setLocalCheckedBox(0);
      setNationalCheckedBox(0);
      setAddProductData((addProductData) => ({
        ...addProductData,
        is_nation_wide: 0,
      }));
    }
  };

  const handleNationWideCheckBoxChange = (e) => {
    if (e.target.value === "on") {
      setLocalCheckedBox(1);
      setNationalCheckedBox(1);
      setAddProductData((addProductData) => ({
        ...addProductData,
        is_nation_wide: 1,
      }));
    }
  };
 

  // const handleDeliveryOptionChange = (e) => {
  //   const { name,checked } = e.target;
  //   if (name === 'In-Store Pickup' && checked) {
  //     setInStoreChecked(true);
  //     setShipToAddressChecked(false); // Uncheck other option
  //     setAddProductData((addProductData) => ({
  //       ...addProductData,
  //       deliveryOption: 1,
  //     }));
  //   } else if (name === 'Ship To My Address' && checked ) {
  //     setShipToAddressChecked(true);
  //     setInStoreChecked(false); // Uncheck other option
  //     setAddProductData((addProductData) => ({
  //       ...addProductData,
  //       deliveryOption: 2,
  //     }));
  //   } else{
  //     setShipToAddressChecked(false);
  //     setInStoreChecked(false);
  //     setAddProductData((addProductData) => ({
  //       ...addProductData,
  //       deliveryOption: 0,
  //     }));
  //   }
  // };

  const handleInStoreCheckBoxChange = (e) => {
    if (e.target.value === "on") {
      setInStoreChecked(0);
      setShipToAddressChecked(0);
      setAddProductData((addProductData) => ({
        ...addProductData,
        deliveryOption: 1,
      }));
    }
  };

  const handleShipAddressCheckBoxChange = (e) => {
    if (e.target.value === "on") {
      setInStoreChecked(1);
      setShipToAddressChecked(1);
      setAddProductData((addProductData) => ({
        ...addProductData,
        deliveryOption: 2,
      }));
    }
  };

  const handleWeekChange = (val) => {
    setScheduledata((scheduledata) => ({
      ...scheduledata,
      value: WeekChange(val, scheduledata),
    }));
  };

  const submitScheduleList = (messageId) => {
    const data = {
      product_array: [productId],
      messageId: messageId,
      type: 1,
      isSchedule: 1,
      proxy_type: proxyAddress.type?.value,
      startdate: scheduledata.start_date,
      enddate: scheduledata.end_date,
      date: scheduledata.date,
      timeslot: scheduledata?.time,
      startTime: scheduledata?.startTime,
      endTime: scheduledata?.endTime,
      timeZone: scheduledata?.timeZone,
      proxy_details: proxyAddress?.proxy_details,
      zip_codes: proxyAddress.zip_codes,
    };
    listProductToApp(data);
  };

  const handleDiscountTypeSelect = (e) => {
    setSelectedDiscountType(e);
    setAddProductData((addProductData) => ({
      ...addProductData,
      discountType: e.value,
      discount: "",
      minPurchaseAmt:""
    }));
    setAddProductError((addProductError) => ({
      ...addProductError,
      minPurchaseAmtError:"",
      discountError: ""
    }));
  };

  return (
    <InnerLayout>
      <Col className="action-wrap">
        <Row>
          <Col lg="3">
            <h1>{Strings.AddProduct.Title}</h1>
            <Breadcrumb>
              <Breadcrumb.Item href="/dashboard">
                {Strings.AddProduct.Title}
              </Breadcrumb.Item>
              <Breadcrumb.Item active>
                {Strings.AddProduct.Heading}
              </Breadcrumb.Item>
            </Breadcrumb>
          </Col>
          <Col lg="9" className="text-lg-end">
            <Button
              variant="outline-primary"
              disabled={!enabledSaveProductBtn}
              onClick={handleShow}
            >
              {Strings.AddProduct.Btn}
            </Button>
            <Button
              variant="primary"
              disabled={!enabledSaveProductBtn}
              onClick={saveAddProduct}
            >
              {Strings.AddProduct.Btn1}
            </Button>
            <Button
              variant="primary"
              disabled={enabledListProductBtn ? false : true}
              onClick={handleListProduct}
            >
              {Strings.AddProduct.Btn2}
            </Button>
          </Col>
        </Row>
      </Col>
      <Col className="inner-main new-product">
        <Row>
          <Col lg="5" xl="4">
            <CustSelectLabel
              label={Strings.AddProduct.Label1}
              options={categories}
              value={categoryValue}
              placeholder={Strings.Admin.SubCategory.Modal.Placeholder1}
              onChange={(e) => categoriesChange(e)}
              error={addProductError.category_idError}
            />
          </Col>
          {addProductData.category_id !== constants.STATUS_6 && (
            <Col lg="5" xl="4">
              <CustSelectLabel
                label={Strings.AddProduct.Label2}
                value={subCategoriesValue}
                options={subCategories}
                onChange={handleSubCategoriesChange}
                placeholder={Strings.Admin.SubCategory.Modal.Placeholder4}
                addProductError={addProductError}
                error={addProductError.subcategory_idError}
              />
            </Col>
          )}
        </Row>

        <h5>{Strings.AddProduct.Heading1}</h5>
        <p>
          {Strings.AddProduct.Label4}
          <br></br>
          {Strings.AddProduct.Label8}
        </p>
        <p>
          {productPicError && productPicError !== "" ? (
            <Form.Text className="red">{productPicError}</Form.Text>
          ) : (
            ""
          )}
        </p>

        {fileupload()}
        <h5>Offer Audience *</h5>
        <div className="delivery-type mt-3">
          <Form.Check
            inline
            label="Local"
            name="Local"
            onChange={handleLocalCheckBoxChange}
            checked={nationalCheckedBox === 0 ? true : false}
          />
          <Form.Check
            inline
            label="Nationwide"
            name="Nation_Wide"
            onChange={handleNationWideCheckBoxChange}
            checked={nationalCheckedBox === 1 ? true : false}
          />
          <p>
            {addProductError.product_displayType &&
            addProductError.product_displayType != "" ? (
              <Form.Text className="red">
                {addProductError.product_displayType}
              </Form.Text>
            ) : (
              ""
            )}
          </p>
        </div>
        {categoriesValue !== "" && categoriesValue === constants.STATUS_1 && (
          <Products
            handleChange={handleChange}
            deliveryTypeProduct={deliveryTypeProduct}
            addProductData={addProductData}
            addProductError={addProductError}
            productTypeValue={productTypeValue}
            prodTypeChange={prodTypeChange}
            productTypes={productTypes}
            checked={checked}
            handleQuantity={handleQuantity}
            deliveryTypeList={deliveryTypeList}
            setSelected={setSelected}
            selected={selected}
            handleInStoreCheckBoxChange={handleInStoreCheckBoxChange}
            handleShipAddressCheckBoxChange={handleShipAddressCheckBoxChange}
            inStoreChecked={inStoreChecked}
            shipToAddressChecked={shipToAddressChecked}
          />
        )}
        {categoriesValue !== "" && categoriesValue === constants.STATUS_2 && (
          <Food
            handleChange={handleChange}
            deliveryTypeProduct={deliveryTypeProduct}
            addProductData={addProductData}
            addProductError={addProductError}
            prodTypeChange={prodTypeChange}
            productTypes={productTypes}
            checked={checked}
            handleQuantity={handleQuantity}
            deliveryTypeList={deliveryTypeList}
            freshnessList={freshness}
            freshnessValue={freshnessValue}
            handleFreshnessChange={handleFreshnessChange}
            setSelected={setSelected}
            selected={selected}
            handleInStoreCheckBoxChange={handleInStoreCheckBoxChange}
            handleShipAddressCheckBoxChange={handleShipAddressCheckBoxChange}
            inStoreChecked={inStoreChecked}
            shipToAddressChecked={shipToAddressChecked}
          />
        )}
        {categoriesValue !== "" && categoriesValue === 3 && (
          <Services
            handleChange={handleChange}
            deliveryTypeProduct={deliveryTypeProduct}
            addProductData={addProductData}
            addProductError={addProductError}
            prodTypeChange={prodTypeChange}
            productTypes={productTypes}
            checked={checked}
            handleQuantity={handleQuantity}
            deliveryTypeList={deliveryTypeList}
            setSelected={setSelected}
            selected={selected}
          />
        )}
        {categoriesValue !== "" && categoriesValue === 4 && (
          <ThingsDo
            date={date}
            handleChange={handleChange}
            handleDateChange={handleDateChange}
            addProductData={addProductData}
            addProductError={addProductError}
            handleQuantity={handleQuantity}
          />
        )}
        {categoriesValue !== "" && categoriesValue === 5 && (
          <AdsJobs
            date={date}
            handleChange={handleChange}
            handleDateChange={handleDateChange}
            addProductData={addProductData}
            addProductError={addProductError}
            paymentSuccess={paymentSuccess}
          />
        )}
        {categoriesValue !== "" && categoriesValue === constants.STATUS_6 && (
          <Coupon
            date={date}
            discountType={DiscountType}
            selectedDiscountType={selectedDiscountType}
            handleDiscountTypeSelect={handleDiscountTypeSelect}
            handleChange={handleChange}
            handleDateChange={handleDateChange}
            addProductData={addProductData}
            addProductError={addProductError}
            paymentSuccess={paymentSuccess}
          />
        )}
      </Col>
      <Modal
        show={show}
        onHide={handleClose}
        size="l"
        centered
        backdrop="static"
      >
        <Modal.Body>
          <h5 className="text-center">{alerts.CANCEL_ADDING_PRODUCT}</h5>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <Button variant="outline-primary" onClick={handleClose}>
            {Strings.AddProduct.Btn}
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              handleCancel();
            }}
          >
            {Strings.AddProduct.Btn3}
          </Button>
        </Modal.Footer>
      </Modal>
      {/* One Time Payment Popup */}
      <Modal
        show={Paymentshow}
        onHide={paymentClose}
        centered
        backdrop="static"
      >
        <Modal.Body>
          <PlanOneTime />
        </Modal.Body>
      </Modal>
      {/* List Product Popup */}
      <ListProductPopUp
        messagesMaster={messagesMaster}
        listproductshow={listproductshow}
        submitListProduct={submitListProduct}
        listproducHide={listproducHide}
        noOfProducts={"1"}
        scheduledata={scheduledata}
        setScheduledata={setScheduledata}
        submitScheduleList={submitScheduleList}
        handleWeekChange={handleWeekChange}
        showSchedule={showSchedule}
        setShowSchedule={setShowSchedule}
        proxyAddress={proxyAddress}
        setProxyAddress={setProxyAddress}
        timeList={scheduleTimeList}
        isClickdedDoneListMessage={isClickdedDoneListMessage}
        seIsClickdedDoneListMessage={seIsClickdedDoneListMessage}
      />
      <Modal
        show={showVerificationModal}
        onHide={() => setShowVerificationModal(false)}
        size="l"
        centered
        backdrop="static"
      >
        <Modal.Body>
          <h5 className="text-center">{alerts.PLEASE_VERIFY_BANK_ADD}</h5>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <Button
            variant="outline-primary"
            onClick={() => setShowVerificationModal(false)}
          >
            {Strings.Profile.PaymentMethod.Btn}
          </Button>
          <Button variant="primary" onClick={handleConfirmModal}>
            {Strings.Profile.PaymentMethod.Btn3}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={productPicConfirmation}
        onHide={() => setProductPicConfirmation(false)}
        size="l"
        centered
        backdrop="static"
      >
        <Modal.Body>
          <h5 className="text-center">Please add atleast one image</h5>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <Button
            variant="primary"
            onClick={() => {
              setProductPicConfirmation(false);
              setProductPicConfirmedStatus(1);
            }}
          >
            {Strings.Profile.PaymentMethod.Btn3}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showDelete}
        onHide={handleCloseDelete}
        size="l"
        centered
        backdrop="static"
      >
        <Modal.Body>
          <h5 className="text-center"> {alerts.DELETE_IMAGE_CONFIRMATION} </h5>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <Button variant="outline-primary" onClick={handleCloseDelete}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleDeleteButton}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    </InnerLayout>
  );
};
export default AddProduct;
