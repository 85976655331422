/**
 * @file   src\reducers\VendorReducer.js
 * @brief  This file is responsible for vendor responses.
 * @date   November, 2022
 * @author ZCO Engineer
 * @copyright (c) 2022, ZCO
 */

const INITIAL_STATE_VENDOR = {
    // vendor list response 
    vendorRegisterResponse: {
        apiStatus: false,
        apiErrorCode: 0,
        apiResponse: {},
        apiMessage: ""
    },
    vendorOtpSendResponse: {
        apiStatus: false,
        apiErrorCode: 0,
        apiResponse: {},
        apiMessage: ""
    },
    vendorProfileResponse: {
        apiStatus: false,
        apiErrorCode: 0,
        apiResponse: {},
        apiMessage: ""
    },
    vendorEditBasicInfoResponse: {
        apiStatus: false,
        apiErrorCode: 0,
        apiResponse: {},
        apiMessage: ""
    },
    vendorEditBankInfoResponse: {
        apiStatus: false,
        apiErrorCode: 0,
        apiResponse: {},
        apiMessage: ""
    },
    vendorEditBusinessInfoResponse: {
        apiStatus: false,
        apiErrorCode: 0,
        apiResponse: {},
        apiMessage: ""
    },
    vendorImagePreSignedResponse: {
        apiStatus: false,
        apiErrorCode: 0,
        apiResponse: {},
        apiMessage: ""
    },
    vendorBusinessImageRemoveResponse: {
        apiStatus: false,
        apiErrorCode: 0,
        apiResponse: {},
        apiMessage: ""
    },
    vendorDashboardResponse: {
        apiStatus: false,
        apiErrorCode: 0,
        apiResponse: {},
        apiMessage: ""
    },
    vendorCategoryListResponse: {
        apiStatus: false,
        apiErrorCode: 0,
        apiResponse: {},
        apiMessage: ""
    },

    addProductImageResponse: {
        apiStatus: false,
        apiErrorCode: 0,
        apiResponse: {},
        apiMessage: ""
    },
    listProductMessageListResponse: {
        apiStatus: false,
        apiErrorCode: 0,
        apiResponse: {},
        apiMessage: ""
    },
    pendingVendorRegistrationResponse: {
        apiStatus: false,
        apiErrorCode: 0,
        apiResponse: {},
        apiMessage: ""
    },
    addSubscriptionResponse: {
        apiStatus: false,
        apiErrorCode: 0,
        apiResponse: {},
        apiMessage: ""
    },
    productMessageResponse: {
        apiStatus: false,
        apiErrorCode: 0,
        apiResponse: {},
        apiMessage: ""
    },
    purchaseHistoryResponse: {
        apiStatus: false,
        apiErrorCode: 0,
        apiResponse: {},
        apiMessage: ""
    },
    sendProductMessageResponse: {
        apiStatus: false,
        apiErrorCode: 0,
        apiResponse: {},
        apiMessage: ""
    },
    vendorNotificationResponse: {
        apiStatus: false,
        apiErrorCode: 0,
        apiResponse: {},
        apiMessage: ""
    },
    vendorViewNotificationResponse: {
        apiStatus: false,
        apiErrorCode: 0,
        apiResponse: {},
        apiMessage: ""
    },
    vendorNotificationAvailableResponse: {
        apiStatus: false,
        apiErrorCode: 0,
        apiResponse: {},
        apiMessage: ""
    },
    vendorExportPurchaseHistoryResponse: {
        apiStatus: false,
        apiErrorCode: 0,
        apiResponse: {},
        apiMessage: ""
    },
    vendorForgotPasswordResponse: {
        apiStatus: false,
        apiErrorCode: 0,
        apiResponse: {},
        apiMessage: ""
    },
    vendorResetPasswordResponse: {
        apiStatus: false,
        apiErrorCode: 0,
        apiResponse: {},
        apiMessage: ""
    },
    vendorStripeVerifyResponse: {
        apiStatus: false,
        apiErrorCode: 0,
        apiResponse: {},
        apiMessage: ""
    },
    vendorCreateSubscriptionResponse: {
        apiStatus: false,
        apiErrorCode: 0,
        apiResponse: {},
        apiMessage: ""
    },
    vendorCancelSubscriptionResponse: {
        apiStatus: false,
        apiErrorCode: 0,
        apiResponse: {},
        apiMessage: ""
    },
    vendorSavePaymentCardResponse: {
        apiStatus: false,
        apiErrorCode: 0,
        apiResponse: {},
        apiMessage: ""
    },
    vendorSubscriptionDetailsResponse: {
        apiStatus: false,
        apiErrorCode: 0,
        apiResponse: {},
        apiMessage: ""
    },
    vendorListPaymentCardResponse: {
        apiStatus: false,
        apiErrorCode: 0,
        apiResponse: {},
        apiMessage: ""
    },
    vendorSubscriptionHistoryResponse: {
        apiStatus: false,
        apiErrorCode: 0,
        apiResponse: {},
        apiMessage: ""
    },
    vendorSubscriptionUpgradeResponse: {
        apiStatus: false,
        apiErrorCode: 0,
        apiResponse: {},
        apiMessage: ""
    },
    vendorDeletePaymentCardResponse: {
        apiStatus: false,
        apiErrorCode: 0,
        apiResponse: {},
        apiMessage: ""
    },
    vendorBankDetailsResponse: {
        apiStatus: false,
        apiErrorCode: 0,
        apiResponse: {},
        apiMessage: ""
    },
    vendorListScheduleResponse: {
        apiStatus: false,
        apiErrorCode: 0,
        apiResponse: {},
        apiMessage: ""
    },
    vendorCancelScheduleResponse: {
        apiStatus: false,
        apiErrorCode: 0,
        apiResponse: {},
        apiMessage: ""
    },
    updatePasswordApiResponse: {
        apiStatus: false,
        apiErrorCode: 0,
        apiResponse: {},
        apiMessage: ""
    },
    addProxyApiResponse: {
        apiStatus: false,
        apiErrorCode: 0,
        apiResponse: {},
        apiMessage: ""
    },
    viewProxyApiResponse: {
        apiStatus: false,
        apiErrorCode: 0,
        apiResponse: {},
        apiMessage: ""
    },
    listProxyApiResponse: {
        apiStatus: false,
        apiErrorCode: 0,
        apiResponse: {},
        apiMessage: ""
    },
    businessInfoApiResponse: {
        apiStatus: false,
        apiErrorCode: 0,
        apiResponse: {},
        apiMessage: ""
    },
    bankInfoApiResponse: {
        apiStatus: false,
        apiErrorCode: 0,
        apiResponse: {},
        apiMessage: ""
    },
    subscriptionInfoApiResponse: {
        apiStatus: false,
        apiErrorCode: 0,
        apiResponse: {},
        apiMessage: ""
    },
    downgradeSubscriptionApiResponse: {
        apiStatus: false,
        apiErrorCode: 0,
        apiResponse: {},
        apiMessage: ""
    },
    expireSubscriptionApiResponse: {
        apiStatus: false,
        apiErrorCode: 0,
        apiResponse: {},
        apiMessage: ""
    },
    vendorRegisterStep2Response: {
        apiStatus: false,
        apiErrorCode: 0,
        apiResponse: {},
        apiMessage: ""
    },
    vendorRegisterStep3Response: {
        apiStatus: false,
        apiErrorCode: 0,
        apiResponse: {},
        apiMessage: ""
    },
    vendorSendMobileOTPResponse: {
        apiStatus: false,
        apiErrorCode: 0,
        apiResponse: {},
        apiMessage: ""
    },
    deleteBankinfoResponse: {
        apiStatus: false,
        apiErrorCode: 0,
        apiResponse: {},
        apiMessage: ""
    },
    heatMapinfo: {
        apiStatus: false,
        apiErrorCode: 0,
        apiResponse: {},
        apiMessage: ""
    },
    removeSubscriptionResponse: {
        apiStatus: false,
        apiErrorCode: 0,
        apiResponse: {},
        apiMessage: ""
    },
    promotionalCitiesResponse: {
        apiStatus: false,
        apiErrorCode: 0,
        apiResponse: {},
        apiMessage: ""
    },
    vendorForgotEmail: {
        apiStatus: false,
        apiErrorCode: 0,
        apiResponse: {},
        apiMessage: ""
    },
    zctaData: {
        apiStatus: false,
        apiErrorCode: 0,
        apiResponse: {},
        apiMessage: ""
    },
    vendorCouponListResponse: {
        apiStatus: false,
        apiErrorCode: 0,
        apiResponse: {},
        apiMessage: ""
    },
    zctaBroadcastData: {
        apiStatus: false,
        apiErrorCode: 0,
        apiResponse: {},
        apiMessage: ""
    },
    vendorAddCouponResponse: {
        apiStatus: false,
        apiErrorCode: 0,
        apiResponse: {},
        apiMessage: ""
    },
    vendorDeleteCouponResponse: {
        apiStatus: false,
        apiErrorCode: 0,
        apiResponse: {},
        apiMessage: ""
    },
    vendorFeedBackResponse: {
        apiStatus: false,
        apiErrorCode: 0,
        apiResponse: {},
        apiMessage: ""
    },
    SalesVsoffersResponse: {
        apiStatus: false,
        apiErrorCode: 0,
        apiResponse: {},
        apiMessage: ""
    },
    timevsWeekDaySalesResponse: {
        apiStatus: false,
        apiErrorCode: 0,
        apiResponse: {},
        apiMessage: ""
    },
    listVendorAddressResponse: {
        apiStatus: false,
        apiErrorCode: 0,
        apiResponse: {},
        apiMessage: ""
    },
    listShipmentCarriersResponse: {
        apiStatus: false,
        apiErrorCode: 0,
        apiResponse: {},
        apiMessage: ""
    },
    listShipmentRatesResponse: {
        apiStatus: false,
        apiErrorCode: 0,
        apiResponse: {},
        apiMessage: ""
    },
    generateShipmentLabelResponse: {
        apiStatus: false,
        apiErrorCode: 0,
        apiResponse: {},
        apiMessage: ""
    },
    shipmentDetailsResponse: {
        apiStatus: false,
        apiErrorCode: 0,
        apiResponse: {},
        apiMessage: ""
    },
    cancelShipmentLabelResponse: {
        apiStatus: false,
        apiErrorCode: 0,
        apiResponse: {},
        apiMessage: ""
    }
}

const VendorActionTypes = {
    vendorRegister: 'vendorRegister',
    vendorOtpVerification: 'vendorOtpVerification',
    vendorProfile: 'vendorProfile',
    vendorEditBasicInfo: 'vendorEditBasicInfo',
    vendorEditBankInfo: 'vendorEditBankInfo',
    vendorEditBusinessInfo: 'vendorEditBusinessInfo',
    vendorSendOtp: 'vendorSendOtp',
    vendorImagePreSigned: 'vendorImagePreSigned',
    vendorBusinessImageUpload: 'vendorBusinessImageUpload',
    removeBusinessImage: 'removeBusinessImage',
    vendorDashboard: 'vendorDashboard',
    vendorCategoryList: 'vendorCategoryList',
    addProductImage: 'addProductImage',
    listProductMessageList: 'listProductMessageList',
    listProducts: 'listProducts',
    pendingVendorRegistrationResponse: 'pendingVendorRegistrationResponse',
    addSubscription: 'addSubscription',
    productMessages: 'productMessages',
    sendProductMessage: 'sendProductMessage',
    purchaseHistory: 'purchaseHistory',
    vendorNotifications: 'vendorNotifications',
    vendorViewNotification: 'vendorViewNotification',
    notificationAvailable: 'notificationAvailable',
    exportPurchaseHistory: 'exportPurchaseHistory',
    forgotPassword: 'forgotPassword',
    resetPassword: 'resetPassword',
    stripeVerify: 'stripeVerify',
    createSubscription: 'createSubscription',
    cancelSubscription: 'cancelSubscription',
    subscriptionDetails: 'subscriptionDetails',
    subscriptionHistory: 'subscriptionHistory',
    subscriptionUpgrade: 'subscriptionUpgrade',
    savePaymentCard: 'savePaymentCard',
    listPaymentCard: 'listPaymentCard',
    deletePaymentCard: 'deletePaymentCard',
    bankDetails: 'bankDetails',
    listSchedule: 'listSchedule',
    cancelSchedule: 'cancelSchedule',
    updatePassword: 'updatePassword',
    addProxy: 'addProxy',
    viewProxy: 'viewProxy',
    listProxy: 'listProxy',
    businessInfo: 'businessInfo',
    bankInfo: 'bankInfo',
    subscriptionInfo: 'subscriptionInfo',
    downgradeSubscription: 'downgradeSubscription',
    expireSubscription: 'expireSubscription',
    vendorRegisterStep2: 'vendorRegisterStep2',
    vendorRegisterStep3: 'vendorRegisterStep3',
    vendorSendMobileOtp: 'vendorSendMobileOtp',
    refundInfo: 'refundInfo',
    approveRefund: 'approveRefund',
    rejectRefund: 'rejectRefund',
    deleteBankinfo: 'deleteBankinfo',
    heatMapInfo: 'heatMapInfo',
    removeSubscription: 'removeSubscription',
    promotionalCities: 'promotionalCities',
    vendorForgotEmail: 'vendorForgotEmail',
    zctaData: 'zctaData',
    zctaBroadcastData: 'zctaBroadcastData',
    vendorCouponList: 'vendorCouponList',
    vendorAddCoupon: 'vendorAddCoupon',
    vendorDeleteCoupon: 'vendorDeleteCoupon',
    vendorFeedBack: 'vendorFeedBack',
    SalesVsoffers: 'SalesVsoffers',
    timevsWeekDaySales: 'timevsWeekDaySales',
    listVendorAddress:'listVendorAddress',
    listShipmentCarriers:'listShipmentCarriers',
    listShipmentRates:'listShipmentRates',
    generateShipmentLabel:"generateShipmentLabel",
    shipmentDetails:"shipmentDetails",
    cancelShipmentLabel:"cancelShipmentLabel"
};


const VendorReducer = (state = INITIAL_STATE_VENDOR, action = {}) => {
    switch (action.type) {
        case VendorActionTypes.vendorRegister:
            return {
                ...state,
                vendorRegisterResponse: {
                    ...state.vendorRegisterResponse, apiStatus: action.apiStatus, apiErrorCode: action.apiErrorCode, apiResponse: action.apiResponse, apiMessage: action.apiMessage
                }
            };
        case VendorActionTypes.vendorSendOtp:
            return {
                ...state,
                vendorOtpSendResponse: {
                    ...state.vendorOtpSendResponse, apiStatus: action.apiStatus, apiErrorCode: action.apiErrorCode, apiResponse: action.apiResponse, apiMessage: action.apiMessage
                }
            };

        case VendorActionTypes.vendorProfile:
            return {
                ...state,
                vendorProfileResponse: {
                    ...state.vendorProfileResponse, apiStatus: action.apiStatus, apiErrorCode: action.apiErrorCode, apiResponse: action.apiResponse, apiMessage: action.apiMessage
                }
            };

        case VendorActionTypes.vendorEditBasicInfo:
            return {
                ...state,
                vendorEditBasicInfoResponse: {
                    ...state.vendorEditBasicInfoResponse, apiStatus: action.apiStatus, apiErrorCode: action.apiErrorCode, apiResponse: action.apiResponse, apiMessage: action.apiMessage
                }
            };

        case VendorActionTypes.vendorEditBankInfo:
            return {
                ...state,
                vendorEditBankInfoResponse: {
                    ...state.vendorEditBankInfoResponse, apiStatus: action.apiStatus, apiErrorCode: action.apiErrorCode, apiResponse: action.apiResponse, apiMessage: action.apiMessage
                }
            };

        case VendorActionTypes.vendorEditBusinessInfo:
            return {
                ...state,
                vendorEditBusinessInfoResponse: {
                    ...state.vendorEditBusinessInfoResponse, apiStatus: action.apiStatus, apiErrorCode: action.apiErrorCode, apiResponse: action.apiResponse, apiMessage: action.apiMessage
                }
            };

        case VendorActionTypes.vendorImagePreSigned:
            return {
                ...state,
                vendorImagePreSignedResponse: {
                    ...state.vendorImagePreSignedResponse, apiStatus: action.apiStatus, apiErrorCode: action.apiErrorCode, apiResponse: action.apiResponse, apiMessage: action.apiMessage
                }
            };

        case VendorActionTypes.removeBusinessImage:
            return {
                ...state,
                vendorBusinessImageRemoveResponse: {
                    ...state.vendorBusinessImageRemoveResponse, apiStatus: action.apiStatus, apiErrorCode: action.apiErrorCode, apiResponse: action.apiResponse, apiMessage: action.apiMessage
                }
            };

        case VendorActionTypes.vendorDashboard:
            return {
                ...state,
                vendorDashboardResponse: {
                    ...state.vendorDashboardResponse, apiStatus: action.apiStatus, apiErrorCode: action.apiErrorCode, apiResponse: action.apiResponse, apiMessage: action.apiMessage
                }
            };

        case VendorActionTypes.vendorCategoryList:
            return {
                ...state,
                vendorCategoryListResponse: {
                    ...state.vendorCategoryListResponse, apiStatus: action.apiStatus, apiErrorCode: action.apiErrorCode, apiResponse: action.apiResponse, apiMessage: action.apiMessage
                }
            };

        case VendorActionTypes.deleteProduct:
            return {
                ...state,
                deleteProductResponse: {
                    ...state.deleteProductResponse, apiStatus: action.apiStatus, apiErrorCode: action.apiErrorCode, apiResponse: action.apiResponse, apiMessage: action.apiMessage
                }
            };

        case VendorActionTypes.addProduct:
            return {
                ...state,
                addProductResponse: {
                    ...state.addProductResponse, apiStatus: action.apiStatus, apiErrorCode: action.apiErrorCode, apiResponse: action.apiResponse, apiMessage: action.apiMessage
                }
            };

        case VendorActionTypes.addProductImage:
            return {
                ...state,
                addProductImageResponse: {
                    ...state.addProductImageResponse, apiStatus: action.apiStatus, apiErrorCode: action.apiErrorCode, apiResponse: action.apiResponse, apiMessage: action.apiMessage
                }
            };
        case VendorActionTypes.listProductMessageList:
            return {
                ...state,
                listProductMessageListResponse: {
                    ...state.listProductMessageListResponse, apiStatus: action.apiStatus, apiErrorCode: action.apiErrorCode, apiResponse: action.apiResponse, apiMessage: action.apiMessage
                }
            };
        case VendorActionTypes.pendingVendorRegistrationResponse:
            return {
                ...state,
                pendingVendorRegistrationResponse: {
                    ...state.pendingVendorRegistrationResponse, apiStatus: action.apiStatus, apiErrorCode: action.apiErrorCode, apiResponse: action.apiResponse, apiMessage: action.apiMessage
                }
            };
        case VendorActionTypes.addSubscription:
            return {
                ...state,
                addSubscriptionResponse: {
                    ...state.addSubscriptionResponse, apiStatus: action.apiStatus, apiErrorCode: action.apiErrorCode, apiResponse: action.apiResponse, apiMessage: action.apiMessage
                }
            };
        case VendorActionTypes.productMessages:
            return {
                ...state,
                productMessageResponse: {
                    ...state.productMessageResponse, apiStatus: action.apiStatus, apiErrorCode: action.apiErrorCode, apiResponse: action.apiResponse, apiMessage: action.apiMessage
                }
            };
        case VendorActionTypes.sendProductMessage:
            return {
                ...state,
                sendProductMessageResponse: {
                    ...state.sendProductMessageResponse, apiStatus: action.apiStatus, apiErrorCode: action.apiErrorCode, apiResponse: action.apiResponse, apiMessage: action.apiMessage
                }
            };
        case VendorActionTypes.purchaseHistory:
            return {
                ...state,
                purchaseHistoryResponse: {
                    ...state.purchaseHistoryResponse, apiStatus: action.apiStatus, apiErrorCode: action.apiErrorCode, apiResponse: action.apiResponse, apiMessage: action.apiMessage
                }
            };
        case VendorActionTypes.vendorNotifications:
            return {
                ...state,
                vendorNotificationResponse: {
                    ...state.vendorNotificationResponse, apiStatus: action.apiStatus, apiErrorCode: action.apiErrorCode, apiResponse: action.apiResponse, apiMessage: action.apiMessage
                }
            };
        case VendorActionTypes.vendorViewNotification:
            return {
                ...state,
                vendorViewNotificationResponse: {
                    ...state.vendorViewNotificationResponse, apiStatus: action.apiStatus, apiErrorCode: action.apiErrorCode, apiResponse: action.apiResponse, apiMessage: action.apiMessage
                }
            };
        case VendorActionTypes.notificationAvailable:
            return {
                ...state,
                vendorNotificationAvailableResponse: {
                    ...state.vendorNotificationAvailableResponse, apiStatus: action.apiStatus, apiErrorCode: action.apiErrorCode, apiResponse: action.apiResponse, apiMessage: action.apiMessage
                }
            };
        case VendorActionTypes.exportPurchaseHistory:
            return {
                ...state,
                vendorExportPurchaseHistoryResponse: {
                    ...state.vendorExportPurchaseHistoryResponse, apiStatus: action.apiStatus, apiErrorCode: action.apiErrorCode, apiResponse: action.apiResponse, apiMessage: action.apiMessage
                }
            };
        case VendorActionTypes.forgotPassword:
            return {
                ...state,
                vendorForgotPasswordResponse: {
                    ...state.vendorForgotPasswordResponse, apiStatus: action.apiStatus, apiErrorCode: action.apiErrorCode, apiResponse: action.apiResponse, apiMessage: action.apiMessage
                }
            };
        case VendorActionTypes.resetPassword:
            return {
                ...state,
                vendorResetPasswordResponse: {
                    ...state.vendorResetPasswordResponse, apiStatus: action.apiStatus, apiErrorCode: action.apiErrorCode, apiResponse: action.apiResponse, apiMessage: action.apiMessage
                }
            };
        case VendorActionTypes.stripeVerify:
            return {
                ...state,
                vendorStripeVerifyResponse: {
                    ...state.vendorStripeVerifyResponse, apiStatus: action.apiStatus, apiErrorCode: action.apiErrorCode, apiResponse: action.apiResponse, apiMessage: action.apiMessage
                }
            };
        case VendorActionTypes.createSubscription:
            return {
                ...state,
                vendorCreateSubscriptionResponse: {
                    ...state.vendorCreateSubscriptionResponse, apiStatus: action.apiStatus, apiErrorCode: action.apiErrorCode, apiResponse: action.apiResponse, apiMessage: action.apiMessage
                }
            };
        case VendorActionTypes.cancelSubscription:
            return {
                ...state,
                vendorCancelSubscriptionResponse: {
                    ...state.vendorCancelSubscriptionResponse, apiStatus: action.apiStatus, apiErrorCode: action.apiErrorCode, apiResponse: action.apiResponse, apiMessage: action.apiMessage
                }
            };
        case VendorActionTypes.subscriptionDetails:
            return {
                ...state,
                vendorSubscriptionDetailsResponse: {
                    ...state.vendorSubscriptionDetailsResponse, apiStatus: action.apiStatus, apiErrorCode: action.apiErrorCode, apiResponse: action.apiResponse, apiMessage: action.apiMessage
                }
            };
        case VendorActionTypes.subscriptionHistory:
            return {
                ...state,
                vendorSubscriptionHistoryResponse: {
                    ...state.vendorSubscriptionHistoryResponse, apiStatus: action.apiStatus, apiErrorCode: action.apiErrorCode, apiResponse: action.apiResponse, apiMessage: action.apiMessage
                }
            };
        case VendorActionTypes.subscriptionUpgrade:
            return {
                ...state,
                vendorSubscriptionUpgradeResponse: {
                    ...state.vendorSubscriptionUpgradeResponse, apiStatus: action.apiStatus, apiErrorCode: action.apiErrorCode, apiResponse: action.apiResponse, apiMessage: action.apiMessage
                }
            };
        case VendorActionTypes.savePaymentCard:
            return {
                ...state,
                vendorSavePaymentCardResponse: {
                    ...state.vendorSavePaymentCardResponse, apiStatus: action.apiStatus, apiErrorCode: action.apiErrorCode, apiResponse: action.apiResponse, apiMessage: action.apiMessage
                }
            };
        case VendorActionTypes.listPaymentCard:
            return {
                ...state,
                vendorListPaymentCardResponse: {
                    ...state.vendorListPaymentCardResponse, apiStatus: action.apiStatus, apiErrorCode: action.apiErrorCode, apiResponse: action.apiResponse, apiMessage: action.apiMessage
                }
            };
        case VendorActionTypes.deletePaymentCard:
            return {
                ...state,
                vendorDeletePaymentCardResponse: {
                    ...state.vendorDeletePaymentCardResponse, apiStatus: action.apiStatus, apiErrorCode: action.apiErrorCode, apiResponse: action.apiResponse, apiMessage: action.apiMessage
                }
            };
        case VendorActionTypes.bankDetails:
            return {
                ...state,
                vendorBankDetailsResponse: {
                    ...state.vendorBankDetailsResponse, apiStatus: action.apiStatus, apiErrorCode: action.apiErrorCode, apiResponse: action.apiResponse, apiMessage: action.apiMessage
                }
            };
        case VendorActionTypes.listSchedule:
            return {
                ...state,
                vendorListScheduleResponse: {
                    ...state.vendorListScheduleResponse, apiStatus: action.apiStatus, apiErrorCode: action.apiErrorCode, apiResponse: action.apiResponse, apiMessage: action.apiMessage
                }
            };
        case VendorActionTypes.cancelSchedule:
            return {
                ...state,
                vendorCancelScheduleResponse: {
                    ...state.vendorCancelScheduleResponse, apiStatus: action.apiStatus, apiErrorCode: action.apiErrorCode, apiResponse: action.apiResponse, apiMessage: action.apiMessage
                }
            };
        case VendorActionTypes.updatePassword:
            return {
                ...state,
                updatePasswordApiResponse: {
                    ...state.updatePasswordApiResponse, apiStatus: action.apiStatus, apiErrorCode: action.apiErrorCode, apiResponse: action.apiResponse, apiMessage: action.apiMessage
                }
            };
        case VendorActionTypes.addProxy:
            return {
                ...state,
                addProxyApiResponse: {
                    ...state.addProxyApiResponse, apiStatus: action.apiStatus, apiErrorCode: action.apiErrorCode, apiResponse: action.apiResponse, apiMessage: action.apiMessage
                }
            };
        case VendorActionTypes.viewProxy:
            return {
                ...state,
                viewProxyApiResponse: {
                    ...state.viewProxyApiResponse, apiStatus: action.apiStatus, apiErrorCode: action.apiErrorCode, apiResponse: action.apiResponse, apiMessage: action.apiMessage
                }
            };
        case VendorActionTypes.listProxy:
            return {
                ...state,
                listProxyApiResponse: {
                    ...state.listProxyApiResponse, apiStatus: action.apiStatus, apiErrorCode: action.apiErrorCode, apiResponse: action.apiResponse, apiMessage: action.apiMessage
                }
            };
        case VendorActionTypes.businessInfo:
            return {
                ...state,
                businessInfoApiResponse: {
                    ...state.businessInfoApiResponse, apiStatus: action.apiStatus, apiErrorCode: action.apiErrorCode, apiResponse: action.apiResponse, apiMessage: action.apiMessage
                }
            };
        case VendorActionTypes.bankInfo:
            return {
                ...state,
                bankInfoApiResponse: {
                    ...state.bankInfoApiResponse, apiStatus: action.apiStatus, apiErrorCode: action.apiErrorCode, apiResponse: action.apiResponse, apiMessage: action.apiMessage
                }
            };
        case VendorActionTypes.subscriptionInfo:
            return {
                ...state,
                subscriptionInfoApiResponse: {
                    ...state.subscriptionInfoApiResponse, apiStatus: action.apiStatus, apiErrorCode: action.apiErrorCode, apiResponse: action.apiResponse, apiMessage: action.apiMessage
                }
            };
        case VendorActionTypes.downgradeSubscription:
            return {
                ...state,
                downgradeSubscriptionApiResponse: {
                    ...state.downgradeSubscriptionApiResponse, apiStatus: action.apiStatus, apiErrorCode: action.apiErrorCode, apiResponse: action.apiResponse, apiMessage: action.apiMessage
                }
            };
        case VendorActionTypes.expireSubscription:
            return {
                ...state,
                expireSubscriptionApiResponse: {
                    ...state.expireSubscriptionApiResponse, apiStatus: action.apiStatus, apiErrorCode: action.apiErrorCode, apiResponse: action.apiResponse, apiMessage: action.apiMessage
                }
            };
        case VendorActionTypes.vendorRegisterStep2:
            return {
                ...state,
                vendorRegisterStep2Response: {
                    ...state.vendorRegisterStep2Response, apiStatus: action.apiStatus, apiErrorCode: action.apiErrorCode, apiResponse: action.apiResponse, apiMessage: action.apiMessage
                }
            };
        case VendorActionTypes.vendorRegisterStep3:
            return {
                ...state,
                vendorRegisterStep3Response: {
                    ...state.vendorRegisterStep3Response, apiStatus: action.apiStatus, apiErrorCode: action.apiErrorCode, apiResponse: action.apiResponse, apiMessage: action.apiMessage
                }
            };
        case VendorActionTypes.vendorSendMobileOtp:
            return {
                ...state,
                vendorSendMobileOTPResponse: {
                    ...state.vendorSendMobileOTPResponse, apiStatus: action.apiStatus, apiErrorCode: action.apiErrorCode, apiResponse: action.apiResponse, apiMessage: action.apiMessage
                }
            };
        case VendorActionTypes.refundInfo:
            return {
                ...state,
                refundInfoResponse: {
                    ...state.refundInfoResponse, apiStatus: action.apiStatus, apiErrorCode: action.apiErrorCode, apiResponse: action.apiResponse, apiMessage: action.apiMessage
                }
            };
        case VendorActionTypes.approveRefund:
            return {
                ...state,
                approveRefundResponse: {
                    ...state.approveRefundResponse, apiStatus: action.apiStatus, apiErrorCode: action.apiErrorCode, apiResponse: action.apiResponse, apiMessage: action.apiMessage
                }
            };
        case VendorActionTypes.rejectRefund:
            return {
                ...state,
                rejectRefundResponse: {
                    ...state.rejectRefundResponse, apiStatus: action.apiStatus, apiErrorCode: action.apiErrorCode, apiResponse: action.apiResponse, apiMessage: action.apiMessage
                }
            };
        case VendorActionTypes.deleteBankinfo:
            return {
                ...state,
                deleteBankinfoResponse: {
                    ...state.deleteBankinfoResponse, apiStatus: action.apiStatus, apiErrorCode: action.apiErrorCode, apiResponse: action.apiResponse, apiMessage: action.apiMessage
                }
            };
        case VendorActionTypes.heatMapInfo:
            return {
                ...state,
                heatMapinfo: {
                    ...state.heatMapinfo, apiStatus: action.apiStatus, apiErrorCode: action.apiErrorCode, apiResponse: action.apiResponse, apiMessage: action.apiMessage
                }
            };
        case VendorActionTypes.removeSubscription:
            return {
                ...state,
                removeSubscriptionResponse: {
                    ...state.removeSubscriptionResponse, apiStatus: action.apiStatus, apiErrorCode: action.apiErrorCode, apiResponse: action.apiResponse, apiMessage: action.apiMessage
                }
            };
        case VendorActionTypes.promotionalCities:
            return {
                ...state,
                promotionalCitiesResponse: {
                    ...state.promotionalCitiesResponse, apiStatus: action.apiStatus, apiErrorCode: action.apiErrorCode, apiResponse: action.apiResponse, apiMessage: action.apiMessage
                }
            };
        case VendorActionTypes.vendorForgotEmail:
            return {
                ...state,
                vendorForgotEmail: {
                    ...state.vendorForgotEmail, apiStatus: action.apiStatus, apiErrorCode: action.apiErrorCode, apiResponse: action.apiResponse, apiMessage: action.apiMessage
                }
            };
        case VendorActionTypes.zctaData:
            return {
                ...state,
                zctaData: {
                    ...state.zctaData, apiStatus: action.apiStatus, apiErrorCode: action.apiErrorCode, apiResponse: action.apiResponse, apiMessage: action.apiMessage
                }
            };
        case VendorActionTypes.zctaBroadcastData:
            return {
                ...state,
                zctaBroadcastData: {
                    ...state.zctaBroadcastData, apiStatus: action.apiStatus, apiErrorCode: action.apiErrorCode, apiResponse: action.apiResponse, apiMessage: action.apiMessage
                }
            };
        case VendorActionTypes.vendorCouponList:
            return {
                ...state,
                vendorCouponListResponse: {
                    ...state.vendorCouponListResponse, apiStatus: action.apiStatus, apiErrorCode: action.apiErrorCode, apiResponse: action.apiResponse, apiMessage: action.apiMessage
                }
            };
        case VendorActionTypes.vendorAddCoupon:
            return {
                ...state,
                vendorAddCouponResponse: {
                    ...state.vendorAddCouponResponse, apiStatus: action.apiStatus, apiErrorCode: action.apiErrorCode, apiResponse: action.apiResponse, apiMessage: action.apiMessage
                }
            };
        case VendorActionTypes.vendorDeleteCoupon:
            return {
                ...state,
                vendorDeleteCouponResponse: {
                    ...state.vendorDeleteCouponResponse, apiStatus: action.apiStatus, apiErrorCode: action.apiErrorCode, apiResponse: action.apiResponse, apiMessage: action.apiMessage
                }
            };
        case VendorActionTypes.vendorFeedBack:
            return {
                ...state,
                vendorFeedBackResponse: {
                    ...state.vendorFeedBackResponse, apiStatus: action.apiStatus, apiErrorCode: action.apiErrorCode, apiResponse: action.apiResponse, apiMessage: action.apiMessage
                }
            };
        case VendorActionTypes.SalesVsoffers:
            return {
                ...state,
                SalesVsoffersResponse: {
                    ...state.SalesVsoffersResponse, apiStatus: action.apiStatus, apiErrorCode: action.apiErrorCode, apiResponse: action.apiResponse, apiMessage: action.apiMessage
                }
            };
        case VendorActionTypes.timevsWeekDaySales:
            return {
                ...state,
                timevsWeekDaySalesResponse: {
                    ...state.timevsWeekDaySalesResponse, apiStatus: action.apiStatus, apiErrorCode: action.apiErrorCode, apiResponse: action.apiResponse, apiMessage: action.apiMessage
                }
            };
        case VendorActionTypes.listVendorAddress:
            return {
                ...state,
                listVendorAddressResponse: {
                    ...state.listVendorAddressResponse, apiStatus: action.apiStatus, apiErrorCode: action.apiErrorCode, apiResponse: action.apiResponse, apiMessage: action.apiMessage
                }
            };
        case VendorActionTypes.listShipmentCarriers:
            return {
                ...state,
                listShipmentCarriersResponse: {
                    ...state.listShipmentCarriersResponse, apiStatus: action.apiStatus, apiErrorCode: action.apiErrorCode, apiResponse: action.apiResponse, apiMessage: action.apiMessage
                }
            };
        case VendorActionTypes.listShipmentRates:
            return {
                ...state,
                listShipmentRatesResponse: {
                    ...state.listShipmentRatesResponse, apiStatus: action.apiStatus, apiErrorCode: action.apiErrorCode, apiResponse: action.apiResponse, apiMessage: action.apiMessage
                }
            };
        case VendorActionTypes.generateShipmentLabel:
            return {
                ...state,
                generateShipmentLabelResponse: {
                    ...state.generateShipmentLabelResponse, apiStatus: action.apiStatus, apiErrorCode: action.apiErrorCode, apiResponse: action.apiResponse, apiMessage: action.apiMessage
                }
            };
        case VendorActionTypes.shipmentDetails:
            return {
                ...state,
                shipmentDetailsResponse: {
                    ...state.shipmentDetailsResponse, apiStatus: action.apiStatus, apiErrorCode: action.apiErrorCode, apiResponse: action.apiResponse, apiMessage: action.apiMessage
                }
            };
        case VendorActionTypes.cancelShipmentLabel:
            return {
                ...state,
                cancelShipmentLabelResponse: {
                    ...state.cancelShipmentLabelResponse, apiStatus: action.apiStatus, apiErrorCode: action.apiErrorCode, apiResponse: action.apiResponse, apiMessage: action.apiMessage
                }
            };
        default:
            return state;
    }
}

export default VendorReducer;