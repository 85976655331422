import React from 'react'
import './Loader.scss'

const Loader = (props) => {
  return (
  //   <div className="align-items-center justify-content-center d-flex mt-5">
  //   <div className="align-items-center justify-content-center d-flex mt-5">
  //     <output className="spinner-grow" style={{ width: '3rem', height: '3rem', color: '#F9C901', marginRight: 10 }} />
  //     <output className="spinner-grow" style={{ width: '3rem', height: '3rem', color: '#F9C901', marginRight: 10 }} />
  //     <output className="spinner-grow" style={{ width: '3rem', height: '3rem', color: '#F9C901', marginRight: 10 }} />
  //     <output className="spinner-grow" style={{ width: '3rem', height: '3rem', color: '#F9C901', marginRight: 10 }} />
  //     <output className="spinner-grow" style={{ width: '3rem', height: '3rem', color: '#F9C901', marginRight: 10 }} />
  //   </div>
  // </div>
  <div className="show cd-loader d-flex justify-content-center align-items-center">
  <div className="loader"></div>
</div>
  )
};

export default Loader;

