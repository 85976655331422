import React from "react";
import "./PlanSubscribed.scss";
import Strings from "../assets/strings/Strings.json";
import {
  Button,
  Col,
  Row,
  Table,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { dateToMMDDYYYY, dateToMMMDDYYYYUnix} from "../helpers/validationHelper";
import * as constants from '../utils/constants';
import { SubscriptionPlans } from '../utils/Enum'

const PlanSubscribed = (props) => {
  const subscriptionHistory = props.subscriptionHistory;
  const subscriptionListData = props.subscriptionListData
  const subscription_upcoming = props.upcoming
  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {Strings.Profile.Subscription.Label5}
    </Tooltip>
  );
  return (
    <div className="plan-subscribed">
      <h1>{Strings.SubscriptionInfo.Title}</h1>
      {/* {props.subscriptionStatus !== constants.STATUS_TRAILING &&
        <h5>
          {Strings.SubscriptionInfo.ExpireInfo} {dateToMMDDYYYY(props.endDate)}
        </h5>
      } */}
      <div className={props.planid === SubscriptionPlans.Basic ? "plan-wrap tier1" : props.planid ===SubscriptionPlans.Advanced ? "plan-wrap tier2" 
        : props.planid === SubscriptionPlans.Ultimate ? "plan-wrap tier4" : props.planid === SubscriptionPlans.Monthly ? "plan-wrap tier3" : ""}>
        <Row>
          <Col lg="5" xl="4" className="pr-0">
            <div className="current-plan">
              <div className="title">{Strings.Profile.Subscription.Label1} <span>{props.planName}</span></div>
              <h4>${props.planCost && parseInt(props.planCost)}
                <span>/ 
                  {props?.planid == SubscriptionPlans.Monthly ?  Strings.Profile.Subscription.Label10 : Strings.Profile.Subscription.Label6}
                </span>
              </h4>
              {subscription_upcoming && subscription_upcoming !== 0 ? (
                ""
              ) : (
                <Button variant="secondary" onClick={props.upgradePlan}>
                  {props.subscriptionStatus === constants.STATUS_CANCELED
                    ? Strings.SubscriptionInfo.Btn6
                    : props.subscriptionStatus !== constants.STATUS_EXPIRED
                      ? Strings.SubscriptionInfo.Btn1
                      : Strings.SubscriptionInfo.Btn3}
                </Button>
              )}
            </div>
          </Col>
          <Col lg="7" xl="8">
            <div className="plan-details">
            <div className="plan-cancel-wrap text-end w-100">
                {props.subscriptionStatus !== constants.STATUS_CANCELED &&
                  props.subscriptionStatus !== constants.STATUS_EXPIRED ? (
                  <Button variant="link" onClick={props.cancelSubscription}>
                    {Strings.SubscriptionInfo.Btn2}
                  </Button>
                ) : props.subscriptionStatus === constants.STATUS_CANCELED &&
                  subscription_upcoming &&
                  subscription_upcoming !== 0 ? (
                  <Button variant="link" onClick={props.cancelSubscription}>
                    {Strings.SubscriptionInfo.Btn5}
                  </Button>
                ) : (
                  ""
                )}
              </div>
              <div className="plan-dtl-wrap w-100">              
              <Row className="plan-info">
                <Col md="3" lg="3">
                  <label className="d-block">
                    {Strings.SubscriptionInfo.Label1}
                  </label>
                  <span>{dateToMMDDYYYY(props.validFrom)}</span>
                </Col>
                <Col md="3" lg="3">
                  <label className="d-block">
                    {Strings.SubscriptionInfo.Label2}
                  </label>
                  <span>{dateToMMDDYYYY(props.endDate)}</span>
                </Col>
                <Col md="3" lg="3">
                  <label className="d-block">
                    {Strings.SubscriptionInfo.Label3}
                  </label>
                  <span>{props.planType}</span>
                </Col>
                <Col md="3" lg="3">
                  <label className="d-block">
                    {Strings.SubscriptionInfo.Label4}
                  </label>
                  <span>
                    {props.subscriptionStatus === constants.STATUS_UPCOMING
                      ? Strings.Profile.Subscription.Label7
                      : props.subscriptionStatus === constants.STATUS_TRAILING
                        ? Strings.Profile.Subscription.Label8
                        : props.subscriptionStatus === constants.STATUS_SUCCESS
                          ? Strings.Profile.Subscription.Label3
                          : props.subscriptionStatus === constants.STATUS_CANCELED
                            ? Strings.Profile.Subscription.Label4
                            : props.subscriptionStatus === constants.STATUS_EXPIRED
                              ? Strings.Profile.Subscription.Label9
                              : props.subscriptionStatus}
                  </span>
                </Col>
              </Row>
                {props.subscriptionStatus &&(props.planid !==SubscriptionPlans.Monthly && props.subscriptionStatus === constants.STATUS_TRAILING) ? <p>Your free trial expires on <strong>{dateToMMMDDYYYYUnix(props.endDate)}</strong>. After the expiry, the subscription amount will be charged from your provided default payment method.</p> : ""}
                {props.subscriptionStatus && props.subscriptionStatus === constants.STATUS_CANCELED && subscription_upcoming && subscription_upcoming !== 0 ? <p>This canceled subscription will be active till <strong>{dateToMMMDDYYYYUnix(props.endDate)}</strong>. Only after the canceled plan period is ended the upcoming plan will become active.</p> : ""}
              </div>
            </div>
          </Col>
        </Row>

      </div>
      {/* <Row className="mt-4 mb-5">
        <Col
          lg="4"
          xl="3"
          className="plan-current text-center d-flex flex-column justify-content-center">
          <span className="current">{Strings.Profile.Subscription.Label1}</span>
          <span className="subscription">{props.planName}</span>
          <span className="price">${props.planCost}</span>
          <span className="details">
            {props.planType}
          </span>
        </Col>
        <Col lg="8" xl="9" className="plan-dtls">
          <h2>{props.planName}</h2>
          <Row className="plan-info">
            <Col md="3" lg="3">
              <label className="d-block">
                {Strings.SubscriptionInfo.Label1}
              </label>
              <span>{dateToMMDDYYYY(props.validFrom)}</span>
            </Col>
            <Col md="3" lg="3">
              <label className="d-block">
                {Strings.SubscriptionInfo.Label2}
              </label>
              <span>{dateToMMDDYYYY(props.endDate)}</span>
            </Col>
            <Col md="3" lg="3">
              <label className="d-block">
                {Strings.SubscriptionInfo.Label3}
              </label>
              <span>{props.planType}</span>
            </Col>
            <Col md="3" lg="3">
              <label className="d-block">
                {Strings.SubscriptionInfo.Label4}
              </label>
              <span>
                {props.subscriptionStatus === constants.STATUS_UPCOMING
                  ? Strings.Profile.Subscription.Label7
                  : props.subscriptionStatus === constants.STATUS_TRAILING
                  ? Strings.Profile.Subscription.Label8
                  : props.subscriptionStatus === constants.STATUS_SUCCESS
                  ? Strings.Profile.Subscription.Label3
                  : props.subscriptionStatus === constants.STATUS_CANCELED
                  ? Strings.Profile.Subscription.Label4
                  : props.subscriptionStatus === constants.STATUS_EXPIRED
                  ? Strings.Profile.Subscription.Label9
                  : props.subscriptionStatus}
              </span>
            </Col>
          </Row>
          <div className="btn-wrap">
            {subscription_upcoming && subscription_upcoming !== 0 ? (
              ""
            ) : (
              <Button variant="secondary" onClick={props.upgradePlan}>
                {props.subscriptionStatus === constants.STATUS_CANCELED
                  ? Strings.SubscriptionInfo.Btn6
                  : props.subscriptionStatus !== constants.STATUS_EXPIRED
                  ? Strings.SubscriptionInfo.Btn1
                  : Strings.SubscriptionInfo.Btn3}
              </Button>
            )}
            {props.subscriptionStatus !== constants.STATUS_CANCELED &&
            props.subscriptionStatus !== constants.STATUS_EXPIRED ? (
              <Button variant="link" onClick={props.cancelSubscription}>
                {Strings.SubscriptionInfo.Btn2}
              </Button>
            ) : props.subscriptionStatus === constants.STATUS_CANCELED &&
              subscription_upcoming &&
              subscription_upcoming !== 0 ? (
              <Button variant="link" onClick={props.cancelSubscription}>
                {Strings.SubscriptionInfo.Btn5}
              </Button>
            ) : (
              ""
            )}
          </div>
          {props.subscriptionStatus === constants.STATUS_TRAILING&&
            <p> Free trial ends on "{dateToMMDDYYYY(props.endDate)}", the payment for the subscription will be automatically collected from your card after the trial period.</p>
          }
        </Col>
      </Row> */}
      <h1 className="mb-3">{Strings.SubscriptionInfo.Title3}</h1>
      <Table responsive>
        <thead>
          <tr>
            <th>{Strings.SubscriptionInfo.Th1}</th>
            <th>{Strings.SubscriptionInfo.Th2}</th>
            <th>{Strings.SubscriptionInfo.Th3}</th>
            <th>{Strings.SubscriptionInfo.Th4}</th>
            <th>{Strings.SubscriptionInfo.Th5}</th>
            <th>{Strings.SubscriptionInfo.Th6}</th>
          </tr>
        </thead>
        <tbody>
          {subscriptionHistory && subscriptionHistory.length
            ? subscriptionHistory.map((items, index) => (
              <tr>
                <td>{dateToMMDDYYYY(items.current_period_start)}</td>
                <td>{dateToMMDDYYYY(items.current_period_end)}</td>
                <td>{items.plan_name}</td>
                {items.status === constants.STATUS_UPCOMING ? (
                  <td>-</td>
                ) : (
                  <td>${items.amount}</td>
                )}
                {items.status && (
                  <td className="sub-status">
                    <span>
                      {items.status === constants.STATUS_UPCOMING
                        ? Strings.Profile.Subscription.Label7
                        : items.status === constants.STATUS_TRAILING
                          ? Strings.Profile.Subscription.Label8
                          : items.status === constants.STATUS_SUCCESS
                            ? Strings.Profile.Subscription.Label3
                            : items.status === constants.STATUS_CANCELED
                              ? Strings.Profile.Subscription.Label4
                              : items.status === constants.STATUS_EXPIRED
                                ? Strings.Profile.Subscription.Label9
                                : items.status}
                    </span>
                  </td>
                )}
                <td>
                  {(items.status === constants.STATUS_UPCOMING) || (items.receipt_url === null)? (
                    " - "
                  ) : (
                    <form
                      method="get"
                      action={items.receipt_url}
                      target="_blank"
                    >
                      <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltip}
                      >
                        <button className="download" type="submit"></button>
                      </OverlayTrigger>
                    </form>
                  )}
                </td>
              </tr>
            ))
            : ""}
        </tbody>
      </Table>
      <p className="mb-0">{Strings.SubscriptionInfo.Note}</p>
    </div>
  );
};

export default PlanSubscribed;
