/**
 * @file   src\
 * @brief  This file is responsible for
 * @date   Oct, 2022
 * @author ZCO Engineer
 * @copyright (c) 2022, ZCO
 */
import React, { useState, useEffect } from "react";
import { Button, Col, Row, Tabs, Tab, Form, Modal } from "react-bootstrap";
import EditProduct from "../assets/img/icons/EditProduct";
import Input from "../components/Input";
import InnerLayout from "../components/InnerLayout";
import CustSelectLabel from "../components/SelectLabel";
import Products from "../components/Products";
import Food from "../components/Food";
import ThingsDo from "../components/ThingsDo";
import Services from "../components/Services";
import AdsJobs from "../components/AdsJobs";
import Plan from "../components/Plan";
import Strings from "../assets/strings/Strings.json";
import { toast } from "react-toastify";
import FileUpload from "../components/InputFile";
import ListProductPopUp from "../components/ListProductPopUp";
import NoUserImage from "../assets/img/avatar.svg";
import { WeekChange } from '../helpers/validationHelper'
import { useNavigate, useLocation, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  _vendorCategoryList,
  _vendorProfile,
  resetResponse,
  _vendorListProductMessageList,
  _productMessages,
  _sendProductMessage,
  _vendorDashboard,
} from "../actions/VendorAction";
import {
  _productsDetailsId,
  _removeProduct,
  _listProductToApp,
  _productImagePreSignedURL,
  _addProduct,
  _productFreshnessList,
  _scheduleTimeList,
  _deleteAddress,
  _resetResponse
} from "../actions/ProductAction";
import { validateImageTypes } from "../helpers/authHelper";
import * as alerts from "../utils/Messages";
import Messages from "../components/Messages";
import * as constants from '../utils/constants';
import LocationZipcode from "../components/LocationZipcode";
import Coupon from "../components/Coupon";
const ProductDetails = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
 
  // category list
  const categoryList = () => dispatch(_vendorCategoryList());
  const categoryListApiResponse = useSelector(
    (RootReducer) => RootReducer.VendorReducer.vendorCategoryListResponse
  );
  // vendor dashboard start
  const vendorDashboard = (dashboard) => dispatch(_vendorDashboard(dashboard));
  const vendorDashboardApiResponse = useSelector((RootReducer) => RootReducer.VendorReducer.vendorDashboardResponse);
  // vendor dashboard ends
  // product details by id
  const productDetails = (productId) => dispatch(_productsDetailsId(productId));
  const productDetailsResponse = useSelector(
    (RootReducer) => RootReducer.ProductReducer.productDetailsIdResponse
  );


  // add product
  const addProduct = (details) => dispatch(_addProduct(details));
  const addProductResponse = useSelector(
    (RootReducer) => RootReducer.ProductReducer.addProductResponse
  );

  //  product freshness list start
  const freshnessList = () => dispatch(_productFreshnessList());
  const freshnessListResponse = useSelector(
    (RootReducer) => RootReducer.ProductReducer.productFreshnessListResponse
  );
  // product freshness list ends

  // product image upload
  const imagePreSignedUpload = (imageName) =>
    dispatch(_productImagePreSignedURL(imageName));
  const imagePreSignedUploadResponse = useSelector(
    (RootReducer) => RootReducer.ProductReducer.productImagePreSignedResponse
  );

  //   remove image
  const removeProduct = (params) => dispatch(_removeProduct(params));
  const removeProductResponse = useSelector(
    (RootReducer) => RootReducer.ProductReducer.removeProductResponse
  );

  // vendor profile start
  const vendorProfile = () => dispatch(_vendorProfile());
  const vendorProfileResponse = useSelector(
    (RootReducer) => RootReducer.VendorReducer.vendorProfileResponse
  );

  const addSubscriptionApiResponse = useSelector(
    (RootReducer) => RootReducer.VendorReducer.addSubscriptionResponse
  );

  // Message list start
  const listProductMessageList = () =>
    dispatch(_vendorListProductMessageList());
  const listProductMessageListResponse = useSelector(
    (RootReducer) => RootReducer.VendorReducer.listProductMessageListResponse
  );

  // list Product To App
  const listProductToApp = (params) => dispatch(_listProductToApp(params));
  const listProductToAppResponse = useSelector(
    (RootReducer) => RootReducer.ProductReducer.listProductToAppResponse
  );

  // messages of product
  const productMessages = (params) => dispatch(_productMessages(params));
  const productMessageResponse = useSelector(
    (RootReducer) => RootReducer.VendorReducer.productMessageResponse
  );

  // Send Product message
  const sendProductMessage = (params) => dispatch(_sendProductMessage(params));
  const sendProductMessageResponse = useSelector(
    (RootReducer) => RootReducer.VendorReducer.sendProductMessageResponse
  );

  // Product list start
  const listScheduleTime = (details) => dispatch(_scheduleTimeList(details));
  const listScheduleTimeResponse = useSelector((RootReducer) => RootReducer.ProductReducer.scheduleTimeListResponse);
  // Product list ends

  // delete address
  const deleteAddress = (details) => dispatch(_deleteAddress(details));
  const deleteaddressResponse = useSelector((RootReducer) => RootReducer.ProductReducer.deleteaddressResponse);

  const productTypes = [
    { value: 1, label: "New Product" },
    { value: 2, label: "Used Product" },
    { value: 3, label: "Refurbished Product" },
  ];
  const [date, setDate] = useState({
    posted_date: new Date(),
    valid_date: new Date(),
  });
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [categoriesValue, setCategoriesValue] = useState([]);
  const [productTypeValue, setProductTypeValue] = useState("");
  const [subCategoriesValue, setSubCategoriesValue] = useState("");
  const [productPicError, setProductPicError] = useState("");
  const [productPicUpload, setProductPicUpload] = useState(false);
  const [productData, setProductData] = useState([]);
  const [messagesMaster, setMessagesMaster] = useState([]);
  const [editProductError, setEditProductError] = useState([]);
  const [subscriptionInfoData, setSubscriptionInfoData] = useState({});
  const [subscriptionListData, setSubscriptionListData] = useState([]);
  const [checked, setChecked] = useState({});
  const [subscriptionShow, setSubscriptionShow] = useState(false);
  const [deleteAddressConfirmation, setDeleteAddressConfirmation] = useState(false);
  const [productImages, setproductImages] = useState([]);
  const [productAddress, setproductAddress] = useState([]);
  const [enableEdit, setenableEdit] = useState(false);
  const [listproductshow, Setlistproductshow] = useState(false);
  const [enabledListProductBtn, setEnabledListProductBtn] = useState(false);
  const [enabledSaveProductBtn, setEnabledSaveProductBtn] = useState(false);
  const [defaultImageId, setdefaultImageId] = useState("");
  const [productMessagesArray, setProductMessagesArray] = useState([]);
  const [selectedMessage, setSelectedMessage] = useState(false);
  const [tabType, setTabType] = useState("upcoming");
  const [sendEnable, setSendEnable] = useState(0);
  const [deliveryTypeList, setDeliveryTypeList] = useState([]);
  const [verificationInfo, setVerificationInfo] = useState([])
  const [showVerificationModal, setShowVerificationModal] = useState(false)
  const [modalMessage, setModalMessage] = useState("")
  const [verifyType, setVerifyType] = useState("")
  const [nationalCheckBox, setNationalCheckBox] = useState("")
  const [localCheckBox, setLocalCheckBox] = useState("")
  const [freshness, setFreshness] = useState([])
  const [freshnessValue, setFreshnessValue] = useState("");
  const [showSchedule, setShowSchedule] = useState(false)
  const [isClickdedDoneListMessage, seIsClickdedDoneListMessage] = useState(false)
  const [scheduleTimeList, setScheduleTimeList] = useState([])
  const [deleteAddressId, setDeleteAddressId] = useState('')
  const [deleteAddressType, setDeleteAddressType] = useState('')
  const [inStoreChecked, setInStoreChecked] = useState('');
  const [shipToAddressChecked, setShipToAddressChecked] = useState(false);
  const [proxyAddress, setProxyAddress] = useState({
    type: { value: 1, label: "Default Address" },
    zip_codes: [],
    proxy_details: []
  })
  const [scheduledata, setScheduledata] = useState({
    start_date: new Date(),
    end_date: new Date(),
    occurrence: 0,
    value: [],
    date: 0,
    time: "",
    startTime: "",
    endTime: "",
    timeZone: ""
  })
  const [selected,setSelected] = useState([])
  const [showDelete,setShowDelete] = useState(false)
  const [imageDeleteId,setImageDeleteId] = useState('')
  const [couponCode,setCouponCode] = useState('')
  const DiscountType = [
    { label: "Percentage Discount", value: constants.STATUS_1 },
    { label: "Discount Amount", value: constants.STATUS_2 },
  ];
  const [selectedDiscountType, setSelectedDiscountType] = useState({ label: "Percentage Discount", value: constants.STATUS_1 })

  const intitalVendorDashboardParams = {
    filter_by: 0,
    page: 1,
    search_key: "",
    category_id: 0,
    prod_type: 1,
    type: 1
  };
  const [vendorDashboardParams, setVendorDashboardParams] = useState(intitalVendorDashboardParams)

  useEffect(() => {
    setTabType(location.state.tab);
    listScheduleTime()
    categoryList();
    freshnessList()
    vendorProfile();
    listProductMessageList();
    productMessages({
      prod_id: location.state.id,
    });
  }, []);

  useEffect(() => {
    productDetails({
      prod_id: location.state.id,
    });
  }, [deleteaddressResponse]);
  // category listing response
  useEffect(() => {
    if (categoryListApiResponse && categoryListApiResponse.apiStatus) {
      if (categoryListApiResponse.apiErrorCode === constants.STATUS_200) {
        if (categoryListApiResponse.apiResponse.delivery_typelist) {
          let deliveryArray = [];
          categoryListApiResponse.apiResponse.delivery_typelist.map((delivery) => {
            deliveryArray.push({
              value: delivery.deliverytype_id,
              label: delivery.deliverytype_name,
            });
          });
          setDeliveryTypeList(deliveryArray);
          // setDeliveryTypeList(
          //   categoryListApiResponse.apiResponse.delivery_typelist
          // );
        }
        if (categoryListApiResponse.apiResponse.category_info) {
          let categoryArray = [];
          categoryListApiResponse.apiResponse.category_info.map((category) => {
            categoryArray.push({
              value: category.category_id,
              label: category.category_name,
            });
          });
          setCategories(categoryArray);
        } else toast.error(categoryListApiResponse.apiMessage);
      }
    }
  }, [categoryListApiResponse]);

  // category listing response
  useEffect(() => {
    if (freshnessListResponse && freshnessListResponse.apiStatus) {
      if (freshnessListResponse.apiErrorCode === constants.STATUS_200) {
        if (freshnessListResponse.apiResponse.freshness_info) {
          let freshnessArray = [];
          freshnessListResponse.apiResponse.freshness_info.map((fresh) => {
            freshnessArray.push({
              value: fresh.freshness_id,
              label: fresh.freshness_name,
            });
          });
          setFreshness(freshnessArray);
        } else toast.error(freshnessListResponse.apiMessage);
      }
    }
  }, [freshnessListResponse]);

  // product Details Response
  useEffect(() => {
    if (productDetailsResponse && productDetailsResponse.apiStatus) {
      if (productDetailsResponse.apiErrorCode === constants.STATUS_200) {
        if (productDetailsResponse.apiResponse.product_info) {
          handleProductDetails();
          setproductImages(productDetailsResponse.apiResponse.prod_images);
          setdefaultImageId(
            productDetailsResponse.apiResponse.product_info[0]?.image_id
          );
        }
        if (productDetailsResponse.apiResponse.prod_address) {
          setproductAddress(productDetailsResponse.apiResponse.prod_address)
        }
        else toast.error(productDetailsResponse.apiMessage);
      }
      resetResponse(dispatch, "addProduct");
    }
  }, [productDetailsResponse]);

  // handle presigned image response
  useEffect(() => {
    if (
      imagePreSignedUploadResponse &&
      imagePreSignedUploadResponse.apiStatus
    ) {
      if (imagePreSignedUploadResponse.apiErrorCode == constants.STATUS_200) {
        fileupload();
        setproductImages(imagePreSignedUploadResponse.apiResponse.prod_images);
      } else {
        toast.error(imagePreSignedUploadResponse.apiMessage);
      }
      setProductPicUpload(false);
      resetResponse(dispatch, "productImagePreSigned");
    } else {
      setProductPicUpload(false);
    }
  }, [imagePreSignedUploadResponse]);

  // removeProductResponse
  useEffect(() => {
    if (removeProductResponse && removeProductResponse.apiStatus) {
      if (removeProductResponse.apiErrorCode === constants.STATUS_200) {
        toast.success(removeProductResponse.apiMessage);
      } else toast.error(removeProductResponse.apiMessage);
      resetResponse(dispatch, "removeProductResponse");
    }
  }, [removeProductResponse]);

  // handle add product response
  useEffect(() => {
    if (addProductResponse && addProductResponse.apiStatus) {
      if (addProductResponse.apiErrorCode === constants.STATUS_200) {
        toast.success(addProductResponse.apiMessage);
        vendorDashboard(vendorDashboardParams);
        if (addProductResponse.apiResponse.product_info[0].is_listed === 0) {
          setenableEdit(false);
          setEnabledSaveProductBtn(false);
          setEnabledListProductBtn(true);
        } else {
          setenableEdit(false);
          setEnabledSaveProductBtn(false);
          setEnabledListProductBtn(false);
        }
      } else if (
        (addProductResponse.apiStatus &&
          addProductResponse.apiResponse &&
          addProductResponse.apiResponse.subscription_status &&
          addProductResponse.apiResponse.subscription_status === constants.STATUS_1) ||
        addProductResponse.apiResponse.subscription_status === constants.STATUS_2 ||
        addProductResponse.apiResponse.subscription_status === 3
      ) {
        toast.error(addProductResponse.apiMessage);
      } else {
        toast.error(addProductResponse.apiMessage);
        setEnabledSaveProductBtn(true);
        setEnabledListProductBtn(false);
      }
      resetResponse(dispatch, "addProduct");
    }
  }, [addProductResponse]);

  // Vendor profile response
  useEffect(() => {
    if (vendorProfileResponse) {
      if (vendorProfileResponse.apiErrorCode == constants.STATUS_200 && vendorProfileResponse.apiResponse && vendorProfileResponse.apiResponse.verification_info) {
        setVerificationInfo(vendorProfileResponse.apiResponse.verification_info)
      }
    }
  }, [vendorProfileResponse]);

  // handle Subscription
  useEffect(() => {
    if (addSubscriptionApiResponse && addSubscriptionApiResponse.apiStatus) {
      if (addSubscriptionApiResponse.apiErrorCode == constants.STATUS_200) {
        setSubscriptionShow(false);
        handleSave();
        toast.success(addSubscriptionApiResponse.apiMessage);
      } else {
        toast.error(addSubscriptionApiResponse.apiMessage);
      }
      resetResponse(dispatch, "addSubscription");
    }
  }, [addSubscriptionApiResponse]);

  //handle List your product message list API response
  useEffect(() => {
    if (
      listProductMessageListResponse &&
      listProductMessageListResponse.apiStatus
    ) {
      if (listProductMessageListResponse.apiErrorCode === constants.STATUS_200)
        if (listProductMessageListResponse.apiResponse.messages_info) {
          setMessagesMaster(
            listProductMessageListResponse.apiResponse.messages_info
          );
        }
      resetResponse(dispatch, "listProductMessageListResponse");
    }
  }, [listProductMessageListResponse]);

  // listProductToAppResponse
  useEffect(() => {
    if (listProductToAppResponse && listProductToAppResponse.apiStatus) {
      if (listProductToAppResponse.apiErrorCode == constants.STATUS_200) {
        toast.success(listProductToAppResponse.apiMessage);
        seIsClickdedDoneListMessage(true)
        navigate("/dashboard");
      } else {
        toast.error(listProductToAppResponse.apiMessage);
      }
      resetResponse(dispatch, "listProductToAppResponse");
    }
  }, [listProductToAppResponse]);

  //handle category list API response
  useEffect(() => {
    if (listScheduleTimeResponse && listScheduleTimeResponse.apiStatus) {
      if (listScheduleTimeResponse.apiErrorCode === constants.STATUS_200)
        if (listScheduleTimeResponse.apiResponse.time_slots) {
          let timeArray = []
          listScheduleTimeResponse.apiResponse.time_slots.map((time) => {
            timeArray.push({ value: { start: time.timeslot_from, end: time.timeslot_to, meridiem: time.meridiem }, label: `${time.timeslot_from} ${time.meridiem} - ${time.timeslot_to} ${time.meridiem}` })
          })
          setScheduleTimeList(timeArray)
        }
        else
          toast.error(listScheduleTimeResponse.apiMessage)
    }
  }, [listScheduleTimeResponse])

  // handle product change
  useEffect(() => {
    if (
      productImages.length !== 0 &&
      !productImages.find((e) => e.image_id === defaultImageId)
    ) {
      handleDefaultImageId(productImages[0].image_id);
    } else if (productImages.length === 0) {
      handleDefaultImageId(0);
    }
  }, [productImages]);

  // product Message Response
  useEffect(() => {
    if (productMessageResponse && productMessageResponse.apiStatus) {
      if (productMessageResponse.apiErrorCode === constants.STATUS_200) {
        if (productMessageResponse.apiResponse.messages_info) {
          let messageArray = [];
          productMessageResponse.apiResponse.messages_info.map(
            (messageInfo) => {
              messageArray.push({
                selectedMsg: 0,
                replied_answer: messageInfo.replied_answer,
                user_id: messageInfo.user_id,
                user_name: messageInfo.user_name,
                profile_image: messageInfo.profile_image,
                question_id: messageInfo.question_id,
                question_text: messageInfo.question_text,
                answeroption1: messageInfo.answeroption1,
                answeroption2: messageInfo.answeroption2,
                answeroption3: messageInfo.answeroption3,
                answeroption4: messageInfo.answeroption4,
                chat_id: messageInfo.chat_id,
                is_replied: messageInfo.is_replied,
              });
            }
          );
          setProductMessagesArray(messageArray);
        } else toast.error(productMessageResponse.apiMessage);
      }
    }
  }, [productMessageResponse]);

  // Send product Message Response
  useEffect(() => {
    if (sendProductMessageResponse && sendProductMessageResponse.apiStatus) {
      if (sendProductMessageResponse.apiErrorCode === constants.STATUS_200) {
        productMessages({
          prod_id: location.state.id,
        });
        toast.success(sendProductMessageResponse.apiMessage);
      } else {
        toast.error(sendProductMessageResponse.apiMessage);
      }
      resetResponse(dispatch, "sendProductMessage");
    }
  }, [sendProductMessageResponse]);


  useEffect(() => {
    if (deleteaddressResponse && deleteaddressResponse.apiStatus) {
      if (deleteaddressResponse.apiErrorCode === constants.STATUS_200) {
        toast.success(deleteaddressResponse.apiMessage);
        setDeleteAddressId('')
        setDeleteAddressConfirmation(false)
      } else {
        toast.error(deleteaddressResponse.apiMessage);
        // toast.error("Address not deleted");
        setDeleteAddressId('')
        setDeleteAddressConfirmation(false)
      }
      _resetResponse(dispatch, "deleteaddress");
    }
  }, [deleteaddressResponse]);

  const handleSubscriptionClose = () => setSubscriptionShow(false);

  // handle product details response
  const handleProductDetails = () => {
    const data = productDetailsResponse.apiResponse.product_info[0];
    setProductData(data);

    switch (data?.prod_type) {
      case 1:
        setProductTypeValue({ value: 1, label: "New Product" });
        break;
      case 2:
        setProductTypeValue({ value: 2, label: "Used Product" });
        break;
      case 3:
        setProductTypeValue({ value: 3, label: "Refurbished Product" });
        break;
    }
    if (data.delivery_type) {
      let deliveryArray = []
      data.delivery_type.map((data, key) => {
        const foundObject =
          deliveryTypeList &&
          deliveryTypeList.find((object) => object.value === data);
        if (foundObject) {
          deliveryArray.push(foundObject)
        }
      });
      setSelected(deliveryArray)
    }

    if (data.discountType){
      if(data.discountType === constants.STATUS_1){
        setSelectedDiscountType({ label: "Percentage Discount", value: constants.STATUS_1 })
      } else {
        setSelectedDiscountType({ label: "Discount Amount", value: constants.STATUS_2 })
      }
    }

    if (data.freshness_id) {
      setFreshnessValue({
        value: data?.freshness_id,
        label: data?.freshness_name
      })
    }

    if (data.prod_price) {
      setProductData((productData) => ({
        ...productData,
        prod_price: data.prod_price.replace(/,/g, '')
      }));
    }

    if (data.posted_date !== "" && data.posted_date !== null) {
      handleDateChange(data?.posted_date, 1);
    }
    if (data.valid_till !== null) {
      handleDateChange(data?.valid_till, 2);
    }

    if (data.valid_to !== null) {
      handleDateChange(data?.valid_to, 2);
    }

    if(data?.category_id && data?.category_id === constants.STATUS_6){
      setCouponCode(data?.couponName)
    }
    setCategoriesValue({
      value: data?.category_id,
      label: data?.category_name,
    });
    setSubCategoriesValue({
      value: data?.subcategory_id,
      label: data?.subcategory_name,
    });
    setNationalCheckBox(data?.is_nation_wide);

    if(data?.deliveryOption==1){
      setInStoreChecked(0)
    }else if(data?.deliveryOption==2){
      setInStoreChecked(1)
    }
    
    intialSubCategories(data?.category_id);
    setProductData((productData) => ({
      ...productData,
      type: 2,
    }));
     
  

  };


  // handle setting sub categories
  const intialSubCategories = (value) => {
    if (categoryListApiResponse.apiResponse.subcategory_info) {
      let categoryArray = [];
      categoryListApiResponse.apiResponse.subcategory_info.map(
        (subcategory) => {
          if (subcategory.category_id === value) {
            categoryArray.push({
              value: subcategory.subcategory_id,
              label: subcategory.subcategory_name,
            });
          }
        }
      );
      setSubCategories(categoryArray);
    }
  };

  //handle Validation
  const addProductValidation = () => {
    let isValid = true;
    setEditProductError([]);
    setProductPicError("");
    if (productData.category_id === 0) {
      isValid = false;
      setEditProductError((editProductError) => ({
        ...editProductError,
        ["category_idError"]: alerts.ENTER_CATEGORY,
      }));
    }

    if (productData.subcategory_id === 0) {
      isValid = false;
      setEditProductError((editProductError) => ({
        ...editProductError,
        ["subcategory_idError"]: alerts.ENTER_SUB_CATEGORY,
      }));
    }

    if (productData.prod_type === 0 && productData.category_id === constants.STATUS_1) {
      isValid = false;
      setEditProductError((editProductError) => ({
        ...editProductError,
        ["prod_typeError"]: alerts.ENTER_PRODUCT_TYPE,
      }));
    }

    if (productData.prod_name.trim() === "") {
      isValid = false;
      setEditProductError((editProductError) => ({
        ...editProductError,
        ["prod_nameError"]: alerts.ENTER_PRODUCT_NAME,
      }));
    }
    if (productData.prod_description !==null && productData.prod_description.trim() === "") {
      isValid = false;
      setEditProductError((editProductError) => ({
        ...editProductError,
        ["prod_descriptionError"]: alerts.ENTER_PRODUCT_DESCRIPTION,
      }));
    }

    if (productImages.length === 0) {
      isValid = false;
      setProductPicError(alerts.ENTER_IMAGE);
    } else {
      setProductPicError("");
    }

    if (
      productData.category_id !== 5  &&
      productData.category_id !== constants.STATUS_6 &&
      productData.prod_price.toString().trim() === ""
    ) {
      isValid = false;
      setEditProductError((editProductError) => ({
        ...editProductError,
        ["prod_priceError"]: alerts.ENTER_PRICE,
      }));
    }

    if (
      productData.delivery_type.length === 0 &&
      (productData.category_id === constants.STATUS_1 ||
        productData.category_id === constants.STATUS_2 ||
        productData.category_id === 3)
    ) {
      isValid = false;
      setEditProductError((editProductError) => ({
        ...editProductError,
        ["delivery_typeError"]: alerts.ENTER_DELIVERY_TYPE,
      }));
    }
    if (
      productData.category_id !== constants.STATUS_4 &&
      productData.category_id !== constants.STATUS_5 &&
      productData.category_id !== constants.STATUS_6 &&
      productData.category_id !== constants.STATUS_3 &&
      productData.deliveryOption === 0
    ) {
      isValid = false;
      setEditProductError((editProductError) => ({
        ...editProductError,
        ["delivery_optionError"]: alerts.ENTER_DELIVERY_OPTION,
      }));
    }

    if (
      productData.category_id === constants.STATUS_6 &&
      productData.couponName.trim() === ""
    ) {
      isValid = false;
      setEditProductError((editProductError) => ({
        ...editProductError,
        ["couponNameError"]: alerts.ENTER_COUPON_VALUE,
      }));
    }
    if (
      productData.category_id === constants.STATUS_6 &&
      productData.discount.toString().trim() === ""
    ) {
      setEditProductError((editProductError) => ({
        ...editProductError,
        ["discountError"]: alerts.ENTER_VALUE,
      }));
      isValid = false;
    }

    if (
      productData?.category_id === constants.STATUS_6 &&
      productData?.discountType === constants.STATUS_2 &&
      String(productData?.minPurchaseAmt).trim() === ""
    ) {
      isValid = false;
      setEditProductError((editProductError) => ({
        ...editProductError,
        ["minPurchaseAmtError"]: alerts.ENTER_VALUE,
      }));
    }
    if (
      productData.category_id === constants.STATUS_6 &&
      productData.discountType === constants.STATUS_1 &&
      productData.discount.toString().trim() !== "" &&
      productData.discount.toString().trim() >= 95
    ) {
      isValid = false;
      setEditProductError((editProductError) => ({
        ...editProductError,
        ["discountError"]: alerts.MAXIMUM_DISCOUNT_ALLOWED,
      }));
    }

    if (
      productData?.category_id === constants.STATUS_6 &&
      productData?.discountType === constants.STATUS_2 &&
      String(productData?.minPurchaseAmt).trim() === "" &&
      productData?.discount.toString().trim() !== "" &&
      productData?.minPurchaseAmt - productData.discount < 1

    ) {
      isValid = false;
      setEditProductError((editProductError) => ({
        ...editProductError,
        ["minPurchaseAmtError"]: alerts.MINIMUM_AMOUNT_DIFFERENCE,
      }));
    }

    return isValid;
  };

  // handle edit save function
  const handleSave = () => {
    let valid = addProductValidation();
    if (valid) {
      if(categoriesValue.value === constants.STATUS_6 && productData.couponName === couponCode){
        let payload = {
          "prod_id": productData.prod_id,
          "prod_name": productData.prod_name,
          "prod_price": productData.prod_price,
          "category_id": productData.category_id,
          "category_name": productData.category_name,
          "subcategory_id": productData.subcategory_id,
          "subcategory_name": productData.subcategory_name,
          "prod_description": productData.prod_description,
          "prod_qty": productData.prod_qty,
          "video_link": productData.video_link,
          "website_link": productData.website_link,
          "display_text": productData.display_text,
          "prod_availability": productData.prod_availability,
          "product_status": productData.product_status,
          "delivery_terms": productData.delivery_terms,
          "posted_date": productData.posted_date,
          "valid_till": productData.valid_till,
          "commission": productData.commission,
          "is_listed": productData.is_listed,
          "prod_type": productData.prod_type,
          "calendly_link": productData.calendly_link,
          "instorepickup": productData.instorepickup,
          "delivery": productData.delivery,
          "safetradeavailable": productData.safetradeavailable,
          "carryinservice": productData.carryinservice,
          "serviceathome": productData.serviceathome,
          "thumbnail_image": productData.thumbnail_image,
          "image_id": productData.image_id,
          "is_nation_wide": productData.is_nation_wide,
          "freshness_id": productData.freshness_id,
          "freshness_name": productData.freshness_name,
          "whats_great": productData.whats_great,
          "couponName": "",
          "discountType": productData.discountType,
          "discount": productData.discount,
          "valid_to": productData.valid_to,
          "status": productData.status,
          "delivery_type": productData.delivery_type,
          "type": productData.type,
          "minPurchaseAmt": productData.minPurchaseAmt,
          "deliveryOption":productData.deliveryOption,
      }
        addProduct(payload);
       
      } else 
      addProduct(productData);
    }
  };

  // handle category change
  const categoriesChange = (e) => {
    const data = productDetailsResponse.apiResponse.product_info[0];
    if (data.category_id === e.value) {
      handleProductDetails();
      setProductData((productData) => ({
        ...productData,
        delivery_type: data.delivery_type,
      }));
    } else {
      setProductData((productData) => ({
        ...productData,
        delivery_type: [],
      }));
      setChecked({});
      setSelected([])
    }
    const value = e.value;
    setCategoriesValue(e);
    setSubCategoriesValue([]);
    setEditProductError([]);
    setProductData((productData) => ({
      ...productData,
      category_id: value,
      subcategory_id: 0,
    }));
    if (categoryListApiResponse && categoryListApiResponse.apiStatus) {
      if (categoryListApiResponse.apiErrorCode === constants.STATUS_200) {
        intialSubCategories(value);
      }
    }
  };

  // handle subcategory change
  const handleSubCategoriesChange = (value) => {
    setSubCategoriesValue(value);
    setProductData((productData) => ({
      ...productData,
      subcategory_id: value.value,
    }));
  };

  // handle freshness change
  const handleFreshnessChange = (value) => {
    setFreshnessValue(value);
    setProductData((productData) => ({
      ...productData,
      freshness_id: value.value,
    }));
  };

  // handle uplpad product images
  const uploadProductPic = (event) => {
    setProductPicError("");
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      let isValid = validateImageTypes(file);
      if (isValid === "") {
        setProductPicUpload(true);
        const img = document.createElement("img");
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        img.onload = () => {
          let width = img.width;
          let height = img.height;
          canvas.width = width;
          canvas.height = height;
          ctx.drawImage(img, 0, 0, width, height);
          const dataUrl = canvas.toDataURL(file.type, 0.8);
          if (productData.prod_id) {
            // in file file upload of a product, product id will not be there
            imagePreSignedUpload({ fileName: dataUrl, "prod_id": productData.prod_id });
          } else {
            imagePreSignedUpload({ fileName: dataUrl });
          }

        };
        img.src = URL.createObjectURL(file);
      } else {
        setProductPicError(isValid);
        setProductPicUpload(false);
      }
    }
  };

  // handle delete image
  const deleteImage = (imageid) => {
    let params = {
      prod_id: productData.prod_id,
      image_id: imageid,
    };
    removeProduct(params);
    let currentImages =
      productImages &&
      productImages.filter((data, key) => {
        return data.image_id != imageid;
      });
    setproductImages(currentImages);
    fileupload();
  };

  // handle product type change
  const prodTypeChange = (e) => {
    setProductTypeValue(e);
    setProductData((productData) => ({
      ...productData,
      prod_type: e.value,
    }));
  };

  // handle qunatity changes
  const handleQuantity = (type) => {
    if (type === constants.STATUS_1 && productData.prod_qty !== constants.STATUS_1) {
      setProductData((productData) => ({
        ...productData,
        prod_qty: productData.prod_qty - 1,
      }));
    } else if (type === constants.STATUS_2) {
      setProductData((productData) => ({
        ...productData,
        prod_qty: productData.prod_qty + 1,
      }));
    }
  };

  const deliveryTypeProduct = (value) => {
    setProductData((productData) => ({
      ...productData,
      delivery_type: value,
    }));
  };

  // handle input change
  const handleChange = (event) => {
    if (event.target.name === "prod_price") {
      if (/^(\d)*(\.)?([0-9]{1,2})?$/.test(event.target.value)) {
        setProductData((productData) => ({
          ...productData,
          [event.target.name]: event.target.value,
        }));
      }
    } else if (event.target.name === "prod_qty") {
      if (!/\D/.test(event.target.value)) {
        setProductData((productData) => ({
          ...productData,
          [event.target.name]: event.target.value,
        }));
      }
    } else if (event.target.name === "discount" ||  event.target.name ==="minPurchaseAmt" ) {
      if (productData.discountType === constants.STATUS_1) {
        if (/^[0-9]*$/.test(event.target.value)) {
          setProductData((productData) => ({
            ...productData,
            [event.target.name]: event.target.value,
          }));
        }
      } else if (/^\d*\.?\d{0,2}$/.test(event.target.value)) {
        setProductData((productData) => ({
          ...productData,
          [event.target.name]: event.target.value,
        }));
      }
    } else {
      setProductData((productData) => ({
        ...productData,
        [event.target.name]: event.target.value,
      }));
    }
  };

  // handle Date picker changes
  const handleDateChange = (e, type) => {
    if (type === constants.STATUS_1) {
      setProductData((productData) => ({
        ...productData,
        posted_date: e,
      }));
      setDate((date) => ({
        ...date,
        posted_date: new Date(e),
      }));
      if (
        categoriesValue !== "" &&
        categoriesValue.value === constants.STATUS_6 &&
        e > date.valid_date
      ) {
        setProductData((productData) => ({
          ...productData,
          valid_to: e,
        }));
        setDate((date) => ({
          ...date,
          valid_date: new Date(e),
        }));
      }
    } else {
      setDate((date) => ({
        ...date,
        valid_date: new Date(e),
      }));
      if (categoriesValue !== "" && categoriesValue.value === constants.STATUS_6) {
        setProductData((productData) => ({
          ...productData,
          valid_to: e,
        }));
      } else {
        setProductData((productData) => ({
          ...productData,
          valid_till: e,
        }));
      }
    }
  };

  const submitListProduct = (messageId) => {
    let params = {
      product_array: [location.state.id],
      messageId: messageId,
      proxy_type: proxyAddress.type?.value,
      zip_codes: proxyAddress.zip_codes
    };
    listProductToApp(params);
  };

  const showListProducts = () => {
    Setlistproductshow(true);
  };

  const listproducHide = () => {
    Setlistproductshow(false);
    navigate("/dashboard");
  };

  const handleCancel = () => {
    setenableEdit(false);
    setEnabledListProductBtn(false);
    setEnabledSaveProductBtn(false);
    handleProductDetails();
    setEditProductError([]);
    setProductPicError("");
  };

  const handleDefaultImageId = (e) => {
    setdefaultImageId(e);
    setProductData((productData) => ({
      ...productData,
      image_id: e,
    }));
  };

  const fileupload = () => {
    return (
      <FileUpload
        defaultImageId={defaultImageId}
        handleDefaultId={handleDefaultImageId}
        loading={productPicUpload}
        enableEdit={!enableEdit}
        productImages={productImages}
        uploadFile={(e) => uploadProductPic(e)}
        deleteImage={handleShowDelete}
      />
    );
  };

  const clicked = (chatId, questionId, selectedMessage, userId) => {
    sendProductMessage({
      answer_option: selectedMessage,
      chat_id: chatId,
      question_id: questionId,
      user_id: userId,
      prod_id: location.state.id,
    });
  };

  const messageOption1Changes = (index, id) => {
    setSendEnable(index);
    setSelectedMessage(id);
  };

  // change tab
  const handleTabChanage = (key) => {
    setTabType(key);
  };

  const handleListProduct = () => {
    if (verificationInfo && !verificationInfo.bank_verified) {
      setModalMessage(alerts.PLEASE_VERIFY_BANK_ADD)
      setShowVerificationModal(true);
      setVerifyType("bank")
    } else {
      showListProducts();
      setVerifyType("")
    }
  };

  const handleConfirmModal = () => {
    setShowVerificationModal(false);
    if (verifyType === "bank") {
      navigate("/profile", {
        state: {
          current_tab: "bankinfo",
        },
      });
    } else {
      navigate("/profile", {
        state: {
          current_tab: "subscription",
        }
      })
    }

  };

  const editProducts = () => {
    if (verificationInfo && !verificationInfo.subscribed) {
      setModalMessage(alerts.PLEASE_SUBSCRIBE_TO_EDIT)
      setShowVerificationModal(true);
      setVerifyType("Subscription")
    } else {
      setenableEdit(true)
      setEnabledSaveProductBtn(true)
      setVerifyType("")
    }
  };

  const handleLocalCheckBoxChange = (e) => {
    if (e.target.value === "on") {
      setLocalCheckBox(0);
      setNationalCheckBox(0);
      setProductData((addProductData) => ({
        ...addProductData,
        is_nation_wide: 0,
      }));
    }
  };

  const handleNationWideCheckBoxChange = (e) => {
    if (e.target.value === "on") {
      setLocalCheckBox(1);
      setNationalCheckBox(1);
      setProductData((addProductData) => ({
        ...addProductData,
        is_nation_wide: 1,
      }));
    }
  };

  // const handleDeliveryOptionChange = (e) => {
  //   const { name,checked } = e.target;
  //   if (name === 'In-Store Pickup' && checked) {
  //     setInStoreChecked(true);
  //     setShipToAddressChecked(false); // Uncheck other option
  //     setProductData((addProductData) => ({
  //       ...addProductData,
  //       deliveryOption: 1,
  //     }));
  //   } else if (name === 'Ship To My Address' && checked ) {
  //     setShipToAddressChecked(true);
  //     setInStoreChecked(false); // Uncheck other option
  //     setProductData((addProductData) => ({
  //       ...addProductData,
  //       deliveryOption: 2,
  //     }));
  //   } else{
  //     setShipToAddressChecked(false);
  //     setInStoreChecked(false);
  //     setProductData((addProductData) => ({
  //       ...addProductData,
  //       deliveryOption: 0,
  //     }));
  //   }
  // };

  const handleInStoreCheckBoxChange = (e) => {
    if (e.target.value === "on") {
      setInStoreChecked(0);
      setShipToAddressChecked(0);
      setProductData((addProductData) => ({
        ...addProductData,
        deliveryOption: 1,
      }));
    }
  };

  const handleShipAddressCheckBoxChange = (e) => {
    if (e.target.value === "on") {
      setInStoreChecked(1);
      setShipToAddressChecked(1);
      setProductData((addProductData) => ({
        ...addProductData,
        deliveryOption: 2,
      }));
    }
  };

  const handleWeekChange = (val) => {
    setScheduledata((scheduledata) => ({
      ...scheduledata,
      value: WeekChange(val, scheduledata),
    }));
  }

  const submitScheduleList = (messageId) => {
    const data = {
      product_array: [location.state.id],
      messageId: messageId,
      type: 1,
      isSchedule: 1,
      proxy_type: proxyAddress.type?.value,
      startdate: scheduledata.start_date,
      enddate: scheduledata.end_date,
      date: scheduledata.date,
      timeslot: scheduledata?.time,
      startTime: scheduledata?.startTime,
      endTime: scheduledata?.endTime,
      timeZone: scheduledata?.timeZone,
      proxy_details: proxyAddress?.proxy_details,
      zip_codes: proxyAddress.zip_codes
    }
    listProductToApp(data);
  }


  const handleDeleteAddress = () => {
    deleteAddress({
      address_id: deleteAddressId,
      productid: productData?.prod_id,
      address_type: deleteAddressType
    })
  }
  const handleDeleteAddressModal = (adddressId, addressType) => {
    setDeleteAddressConfirmation(true)
    setDeleteAddressId(adddressId)
    setDeleteAddressType(addressType)
  }

  const handleCloseDelete = () => {
    setShowDelete(false)
    setImageDeleteId('')
  }
  const handleShowDelete = (e) => {
    setShowDelete(true)
    setImageDeleteId(e)
  }

  // handle image delete 
  const handleDeleteButton = () => {
    deleteImage(imageDeleteId)
    handleCloseDelete()
  }

  const handleDiscountTypeSelect = (e) => {
    setSelectedDiscountType(e)
    setProductData((productData) => ({
      ...productData,
      discountType:e.value,
      discount: "",
      minPurchaseAmt:""
    }));
    setEditProductError((editProductError) => ({
      ...editProductError,
      minPurchaseAmtError:"",
      discountError: ""
    }));
  }

  return (
    <InnerLayout>
      <Col className="action-wrap">
        <Row>
          <Col lg="3" className="d-lg-flex align-items-lg-center">
            <h1> {Strings.ProductDetail.Title}</h1>
          </Col>
        </Row>
      </Col>
      <Col className="mt-4">
        <Tabs
          defaultActiveKey="current"
          id="uncontrolled-tab-example"
          className="mb-3"
          activeKey={tabType}
          onSelect={handleTabChanage}
        >
          <Tab eventKey="current" title="Offer Details">
            <Row className="product-dtls-head">
              <Col lg="6">
                <h3 className="product-dtls">{productData?.prod_name}</h3>
              </Col>
              <Col lg="6" className="text-lg-end">
                {(enableEdit ||
                  enabledListProductBtn ||
                  enabledSaveProductBtn) && (
                    <>
                      <Button
                        disabled={!enabledSaveProductBtn}
                        variant="outline-primary"
                        onClick={handleCancel}
                      >
                        {Strings.ProductDetail.Btn}
                      </Button>
                      <> </>
                      <Button
                        disabled={!enabledSaveProductBtn}
                        variant="primary"
                        onClick={() => handleSave()}
                      >
                        {Strings.ProductDetail.Btn1}
                      </Button>
                      <> </>
                      <Button
                        variant="primary"
                        disabled={enabledListProductBtn ? false : true}
                        onClick={handleListProduct}
                      >
                        {Strings.ProductDetail.Btn2}
                      </Button>
                    </>
                  )}
                {!enableEdit &&
                  !enabledListProductBtn &&
                  !enabledSaveProductBtn && (
                    <Button
                      variant="primary"
                      onClick={editProducts}
                    >
                      <EditProduct />
                      {Strings.ProductDetail.Btn3}
                    </Button>
                  )}
              </Col>
            </Row>
            <Col className="inner-main new-product">
              <Row>
                <Col lg="5" xl="4">
                  <CustSelectLabel
                    label={Strings.ProductDetail.Label1}
                    options={categories}
                    value={categoriesValue}
                    isDisabled={!enableEdit}
                    placeholder={Strings.Admin.SubCategory.Modal.Placeholder1}
                    onChange={(e) => categoriesChange(e)}
                  />
                </Col>
                {categoriesValue.length !== 0 && categoriesValue.value !== constants.STATUS_6 && (
                <Col lg="5" xl="4">
                  <CustSelectLabel
                    label={Strings.ProductDetail.Label2}
                    value={subCategoriesValue}
                    options={subCategories}
                    isDisabled={!enableEdit}
                    onChange={handleSubCategoriesChange}
                    placeholder={Strings.Admin.SubCategory.Modal.Placeholder1}
                  />
                  {editProductError.subcategory_idError &&
                    editProductError.subcategory_idError != "" ? (
                    <Form.Text className="red">
                      {editProductError.subcategory_idError}
                    </Form.Text>
                  ) : (
                    ""
                  )}
                </Col>
                )}
              </Row>
              <h5>{Strings.ProductDetail.Title1}</h5>
              <p>
                {productPicError && productPicError != "" ? (
                  <Form.Text className="red">{productPicError}</Form.Text>
                ) : (
                  ""
                )}
              </p>

              {fileupload()}
              <h5>Offer Audience</h5>
              <div className="delivery-type mt-3">
                <Form.Check
                  inline
                  label="Local"
                  name="Local"
                  disabled={!enableEdit}
                  onChange={handleLocalCheckBoxChange}
                  checked={nationalCheckBox === 0 ? true : false}
                />
                <Form.Check
                  inline
                  label="Nationwide"
                  name="Nation_Wide"
                  disabled={!enableEdit}
                  onChange={handleNationWideCheckBoxChange}
                  checked={nationalCheckBox === 1 ? true : false}
                />
              </div>
              {categoriesValue.length !== 0 && categoriesValue.value === constants.STATUS_1 && (
                <Products
                  addProductData={productData}
                  addProductError={editProductError}
                  prodTypeChange={prodTypeChange}
                  productTypes={productTypes}
                  productTypeValue={productTypeValue}
                  handleQuantity={handleQuantity}
                  handleChange={handleChange}
                  deliveryTypeProduct={deliveryTypeProduct}
                  checked={checked}
                  enableEdit={!enableEdit}
                  deliveryTypeList={deliveryTypeList}
                  type="productDetails"
                  selected={selected}
                  setSelected={setSelected}
                  handleInStoreCheckBoxChange={handleInStoreCheckBoxChange}
                  handleShipAddressCheckBoxChange={handleShipAddressCheckBoxChange}
                  inStoreChecked={inStoreChecked}
                  shipToAddressChecked={shipToAddressChecked}
                />
              )}
              {categoriesValue.length !== 0 && categoriesValue.value === constants.STATUS_2 && (
                <Food
                  handleChange={handleChange}
                  deliveryTypeProduct={deliveryTypeProduct}
                  addProductData={productData}
                  addProductError={editProductError}
                  prodTypeChange={prodTypeChange}
                  productTypes={productTypes}
                  checked={checked}
                  handleQuantity={handleQuantity}
                  enableEdit={!enableEdit}
                  deliveryTypeList={deliveryTypeList}
                  freshnessList={freshness}
                  freshnessValue={freshnessValue}
                  handleFreshnessChange={handleFreshnessChange}
                  type="productDetails"
                  setSelected={setSelected}
                  selected={selected}
                  handleInStoreCheckBoxChange={handleInStoreCheckBoxChange}
                  handleShipAddressCheckBoxChange={handleShipAddressCheckBoxChange}
                  inStoreChecked={inStoreChecked}
                  shipToAddressChecked={shipToAddressChecked}
                />
              )}
              {categoriesValue !== "" && categoriesValue.value === 3 && (
                <Services
                  handleChange={handleChange}
                  deliveryTypeProduct={deliveryTypeProduct}
                  addProductData={productData}
                  addProductError={editProductError}
                  prodTypeChange={prodTypeChange}
                  productTypes={productTypes}
                  checked={checked}
                  handleQuantity={handleQuantity}
                  enableEdit={!enableEdit}
                  deliveryTypeList={deliveryTypeList}
                  type="productDetails"
                  setSelected={setSelected}
                  selected={selected}
                />
              )}
              {categoriesValue !== "" && categoriesValue.value === 4 && (
                <ThingsDo
                  handleChange={handleChange}
                  date={date}
                  handleDateChange={handleDateChange}
                  addProductData={productData}
                  addProductError={editProductError}
                  handleQuantity={handleQuantity}
                  enableEdit={!enableEdit}
                  type="productDetails"
                />
              )}
              {categoriesValue !== "" && categoriesValue.value === 5 && (
                <AdsJobs
                  date={date}
                  handleChange={handleChange}
                  handleDateChange={handleDateChange}
                  addProductData={productData}
                  addProductError={editProductError}
                  enableEdit={!enableEdit}
                  paymentSuccess={true}
                  type="productDetails"
                />
              )}
              {categoriesValue !== "" && categoriesValue.value === constants.STATUS_6 && (
                <Coupon
                  date={date}
                  discountType={DiscountType}
                  selectedDiscountType={selectedDiscountType}
                  handleDiscountTypeSelect={handleDiscountTypeSelect}
                  handleChange={handleChange}
                  handleDateChange={handleDateChange}
                  addProductData={productData}
                  addProductError={editProductError}
                  paymentSuccess={true}
                  enableEdit={!enableEdit}

                />
              )}
              <div className="find-any d-flex">
              {productAddress.length ? <h5>{Strings.ProductDetail.Label4}</h5> : ""}
              {/* {productAddress.length ? <p>Find any <a href="https://www.unitedstateszipcodes.org/" target="_blank"> U.S. Zip Code</a></p> : ""} */}
              </div>
              <Row>
                <Col lg="7">
                  {productAddress.length ? (
                    productAddress.map((items, index) => (
                      <div className="location-zip-wrap">
                        <LocationZipcode data={items} delete={handleDeleteAddressModal} disabled={!enableEdit} />
                      </div>
                    ))) : ""}
                </Col>
              </Row>

              <Row>
              </Row>
            </Col>
          </Tab>
          <Tab eventKey="upcoming" title="Messages">
            {productMessagesArray.length ? (
              productMessagesArray.map((items, index) => (
                <Messages
                  profileImage={
                    items.profile_image ? items.profile_image : NoUserImage
                  }
                  userName={items.user_name}
                  className={items.is_replied == constants.STATUS_1 ? "replied" : ""}
                  questionId={items.question_id}
                  selectedMsg={
                    items.replied_answer !== 0
                      ? items.replied_answer
                      : selectedMessage
                  }
                  chatId={items.chat_id}
                  questionText={items.question_text}
                  answerOption1={items.answeroption1}
                  answerOption2={items.answeroption2}
                  answerOption3={items.answeroption3}
                  answerOption4={items.answeroption4}
                  sendEnable={
                    items.replied_answer !== 0 ? items.chat_id : sendEnable
                  }
                  isRead={items.is_read}
                  onClickMessageOption1={messageOption1Changes}
                  onClickBtn={() =>
                    clicked(
                      items.chat_id,
                      items.question_id,
                      selectedMessage,
                      items.user_id
                    )
                  }
                />
              ))
            ) : (
              <Col className="text-center mt-5">{Strings.ProductDetail.Message}</Col>
            )}
          </Tab>
        </Tabs>
      </Col>
      {/* List Product Popup */}
      <ListProductPopUp
        messagesMaster={messagesMaster}
        listproductshow={listproductshow}
        submitListProduct={submitListProduct}
        listproducHide={listproducHide}
        noOfProducts={"1"}
        scheduledata={scheduledata}
        setScheduledata={setScheduledata}
        submitScheduleList={submitScheduleList}
        handleWeekChange={handleWeekChange}
        showSchedule={showSchedule}
        setShowSchedule={setShowSchedule}
        proxyAddress={proxyAddress}
        setProxyAddress={setProxyAddress}
        timeList={scheduleTimeList}
        isClickdedDoneListMessage={isClickdedDoneListMessage}
        seIsClickdedDoneListMessage={seIsClickdedDoneListMessage}
      />
      <Modal
        show={showVerificationModal}
        onHide={() => setShowVerificationModal(false)}
        size="l"
        centered
        backdrop="static"
      >
        <Modal.Body>
          <h5 className="text-center">{modalMessage}</h5>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <Button variant="outline-primary" onClick={() => setShowVerificationModal(false)}>
            {Strings.Profile.PaymentMethod.Btn}
          </Button>
          <Button variant="primary" onClick={handleConfirmModal}>
            {Strings.Profile.PaymentMethod.Btn3}
          </Button>
        </Modal.Footer>
      </Modal>


      <Modal
        show={deleteAddressConfirmation}
        onHide={() => setDeleteAddressConfirmation(false)}
        size="l"
        centered
        backdrop="static"
      >
        <Modal.Body>
          <h5 className="text-center">
            {productAddress[0] && productAddress[0].address_type === constants.STATUS_3 ?
              Strings.ProductDetail.Label5 :
              Strings.ProductDetail.Label6
            }
          </h5>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <Button
            variant="outline-primary"
            onClick={() => setDeleteAddressConfirmation(false)}
          >
            {Strings.Profile.PaymentMethod.Btn}
          </Button>
          <Button variant="primary" onClick={handleDeleteAddress}>
            {Strings.Profile.PaymentMethod.Btn3}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showDelete} onHide={handleCloseDelete} size="l" centered backdrop="static">
        <Modal.Body >
        <h5 className="text-center"> {alerts.DELETE_IMAGE_CONFIRMATION} </h5>
        </Modal.Body>
        <Modal.Footer className='justify-content-center' >
          <Button variant="outline-primary" onClick={handleCloseDelete}>Cancel</Button>
          <Button variant="primary" onClick={handleDeleteButton}>OK</Button>
        </Modal.Footer>
      </Modal>
    </InnerLayout>
  );
};
export default ProductDetails;
