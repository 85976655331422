import React from 'react'
import './Plan.scss'
import { Button, Col } from 'react-bootstrap';

const PlanOneTime = (props) => {
  const alertMessage = () => {
    alert("Not Implemented")
  }
  return (
    <Col className={'plan-wrap ' + (props.className)} >
      <div className='plan-header one-time text-center'>
        <span className='plan-name d-block'>One Time Payment</span>
        <span className='price d-block'>$5</span>
        <Button variant='primary' onClick={alertMessage}>Pay</Button>
      </div>
      <div className='plan-body'>
        <ul>
          <li className='active'>Add 5  Products</li>
          <li className='active'>Lorem ipsum dolor sit amet</li>
          <li className='active'>But I must explain to you how all</li>
          <li className='active'>But I must explain to you how all</li>
          <li className='active'>But I must explain to you how all</li>
          <li className='active'>But I must explain to you how all</li>
        </ul>
      </div>
    </Col>
  )
};

export default PlanOneTime;

