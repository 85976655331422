/**
 * @file   src\
 * @brief  This file is responsible for
 * @date   Oct, 2022
 * @author ZCO Engineer
 * @copyright (c) 2022, ZCO
 */
import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import InnerLayout from "../components/InnerLayout";
import Strings from "../assets/strings/Strings.json";
import Notify from "../components/Notification";
import Paginate from "../components/Paginate";
import Userphoto from "../assets/img/user-dummy.png";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
  _vendorNotification,
  resetResponse,
  _vendorViewNotification,
} from "../actions/VendorAction";
import  * as constants from '../utils/constants';

const Notifications = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // notification list start
  const notifications = (params) => dispatch(_vendorNotification(params));
  const notificationsResponse = useSelector(
    (RootReducer) => RootReducer.VendorReducer.vendorNotificationResponse
  );
  // notification list ends

  // notification view start
  const notificationView = (params) =>
    dispatch(_vendorViewNotification(params));
  const notificationViewResponse = useSelector(
    (RootReducer) => RootReducer.VendorReducer.vendorViewNotificationResponse
  );
  // notification view ends

  const intitalVendorNotificationParams = {
    page: 1,
  };

  const [vendorNotificationParams, setVendorNotificationParams] = useState(
    intitalVendorNotificationParams
  );
  const [vendorNotifications, setVendorNotifications] = useState([]);
  const [productId, setProductId] = useState("");

  // Calling Notification api
  useEffect(() => {
    notifications(vendorNotificationParams);
  }, [vendorNotificationParams]);

  //handle notification API response
  useEffect(() => {
    if (notificationsResponse && notificationsResponse.apiStatus) {
      if (notificationsResponse.apiErrorCode === constants.STATUS_200) {
        setVendorNotifications(notificationsResponse);
      } else if (
        notificationsResponse.apiErrorCode === constants.STATUS_400 &&
        notificationsResponse.apiStatus
      ) {
        setVendorNotifications(notificationsResponse);
      } else {
        toast.error(notificationsResponse.apiMessage);
      }

      resetResponse(dispatch, "vendorNotifications");
    }
  }, [notificationsResponse]);

  //handle dashBoard API response
  useEffect(() => {
    if (notificationViewResponse && notificationViewResponse.apiStatus) {
      if (notificationViewResponse.apiErrorCode === constants.STATUS_200) {
        navigate("/productdetails", { state: { id: productId, tab: "upcoming" } });
      } else if (
        notificationViewResponse.apiErrorCode === constants.STATUS_400 &&
        notificationViewResponse.apiStatus
      ) {
        toast.error(notificationViewResponse.apiMessage);
      } else toast.error(notificationViewResponse.apiMessage);

      resetResponse(dispatch, "vendorViewNotification");
    }
  }, [notificationViewResponse]);

  // page change and state change
  const pageChange = (page) => {
    if (page) {
      setVendorNotificationParams((vendorNotificationParams) => ({
        ...vendorNotificationParams,
        page: page,
      }));
    }
  };

  const viewNotification = (notify_id, product_id) => {
    setProductId(product_id);
    notificationView({
      notification_id: notify_id,
    });
  };

  // display Notifications
  const notification = () => {
    let response = "";
    if (vendorNotifications.apiStatus && vendorNotifications.apiResponse) {
      if (vendorNotifications.apiResponse.notifications_info) {
        response = vendorNotifications.apiResponse.notifications_info.map(
          (data, index) => {
            return (
              <Notify
                username={data?.user_name}
                profilephoto={data?.profile_image}
                datetime={data?.created_on}
                view={() =>
                  viewNotification(data?.notification_id, data?.product_id)
                }
              />
            );
          }
        );
      } else if (!vendorNotifications.apiResponse.notifications_info) {
        response = (
          <Col className="text-center mt-5">{Strings.Notifications.NoNotification}</Col>
        );
      }
    }
    return response;
  };

  return (
    <InnerLayout>
      <Col className="action-wrap">
        <Row>
          <Col lg="6">
            <h1>{Strings.Notifications.Title}</h1>
          </Col>
        </Row>
      </Col>
      <Col>
        {notification()}
      </Col>
      {vendorNotifications.apiStatus &&
      vendorNotifications.apiResponse &&
      vendorNotifications.apiResponse.pagination_info &&
      vendorNotifications.apiResponse.pagination_info.total_records ? (
        <Col className="d-flex justify-content-center paginate-wrap">
          <span className="records">
          {Strings.Pagination.Label} &nbsp;
            {(vendorNotificationParams.page - 1) *
              vendorNotifications.apiResponse.pagination_info.per_page +
              1}
            &nbsp; {Strings.Pagination.Labe1} &nbsp;
            {(vendorNotificationParams.page - 1) *
              vendorNotifications.apiResponse.pagination_info.per_page +
              vendorNotifications.apiResponse.notifications_info.length}
            &nbsp; {Strings.Pagination.Label2} &nbsp;
            {vendorNotifications.apiResponse.pagination_info.total_records}
            &nbsp; {Strings.Pagination.Label3}
          </span>

          <Paginate
            totalRecords={
              vendorNotifications.apiResponse.pagination_info.total_records
            }
            currentPage={
              vendorNotifications.apiResponse.pagination_info.current_page
            }
            perPage={vendorNotifications.apiResponse.pagination_info.per_page}
            onClick={pageChange}
          />
        </Col>
      ) : (
        ""
      )}
    </InnerLayout>
  );
};
export default Notifications;
