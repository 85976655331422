/**
 * @file   src\
 * @brief  This file is responsible for Vendor change password
 * @date   Oct, 2022
 * @author ZCO Engineer
 * @copyright (c) 2022, ZCO
 */

import { Breadcrumb, Button, Col, Row, Modal } from 'react-bootstrap'
import React, { useState, useRef, useEffect } from 'react'
import InnerLayout from '../components/InnerLayout'
import Strings from '../assets/strings/Strings.json'
import Input from '../components/Input';
import viewpass from '../assets/img/view-password.svg'
import hidepass from '../assets/img/hide-password.svg'
import { emailValidation, passwordValidation } from '../helpers/authHelper';
import { _UpdatePassword, resetResponse } from "../actions/VendorAction";
import * as constants from '../utils/constants';
import * as alerts from '../utils/Messages';
import { useSelector, useDispatch } from "react-redux";
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
const AdminChangePass = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const updatePassword = (changePasswordData) => dispatch(_UpdatePassword(changePasswordData));
  const updatePasswordResponse = useSelector(
    (RootReducer) => RootReducer.VendorReducer.updatePasswordApiResponse
  );


  const intitalChangePasswordError = {
    oldPasswordError: "",
    newPasswordError: "",
    confirmPasswordError: ""
  }
  const intitalChangePassword = {
    password: "",
    old_Password: "",
    confirmPassword: ""
  }


  const [changePasswordData, setChangePasswordData] = useState(intitalChangePassword)
  const [changePasswordError, setChangePasswordError] = useState(intitalChangePasswordError)
  const [updatePasswordClicked, setUpdatePasswordClicked] = useState(false)
  const [viewOldPassword, setViewOldPassword] = useState(false)
  const [viewPassword, setViewPassword] = useState(false)
  const [viewConfirmPassword, setViewConfirmPassword] = useState(false)
  const [showConfirm, setShowConfirm] = useState(false)
  const refPassword = useRef(null);
  const refOldPassword = useRef(null);


   // ------- useEffect start -------

  useEffect(() => {
    let postData = {
      password: changePasswordData.password,
      old_password: changePasswordData.old_Password,
    }
    if (updatePasswordClicked) {
      updatePassword(postData)
    }
  }, [updatePasswordClicked])


  useEffect(() => {    
    if (updatePasswordResponse && updatePasswordResponse.apiStatus) {
      if (updatePasswordResponse.apiErrorCode === constants.STATUS_200) {
        toast.success(updatePasswordResponse.apiMessage)
        navigate("/profile")
        resetResponse(dispatch, "updatePassword")
      }
      else {
        setUpdatePasswordClicked(false)        
        toast.error(updatePasswordResponse.apiMessage)
      }
    }
  }, [updatePasswordResponse]);

     // ------- useEffect End -------


  const handlePasswordChange = (event) => {
    setChangePasswordData(changePasswordData => ({ ...changePasswordData, [event.target.name]: event.target.value }))
  };

  const validation = () => {

    setChangePasswordError(intitalChangePasswordError);
    let isValid = true;
    let focusSet = false;

    if (changePasswordData.old_Password.trim() === "") {
      isValid = false;
      setChangePasswordError(changePasswordError => ({ ...changePasswordError, ["oldPasswordError"]: alerts.ENTER_PASSWORD }))
      if (!focusSet) {
        refOldPassword.current.focus();
        focusSet = true;
      }
    }

    if (changePasswordData.password.trim() === "") {
      isValid = false;
      setChangePasswordError(changePasswordError => ({ ...changePasswordError, ["newPasswordError"]: alerts.ENTER_PASSWORD }))
      if (!focusSet) {
        refPassword.current.focus();
        focusSet = true;
      }
    }
    let passwordErrorMsg = passwordValidation(changePasswordData.password, '');
    if (passwordErrorMsg !== "") {
      isValid = false;
      setChangePasswordError(changePasswordError => ({ ...changePasswordError, ["newPasswordError"]: passwordErrorMsg }))
      if (!focusSet) {
        refPassword.current.focus();
        focusSet = true;
      }
    }

    if (changePasswordData.confirmPassword === "") {
      isValid = false;
      setChangePasswordError(changePasswordError => ({ ...changePasswordError, ["confirmPasswordError"]: alerts.ENTER_CONFIRM_PASSWORD }))
      if (!focusSet) {
        refPassword.current.focus();
        focusSet = true;
      }
    } else if (changePasswordData.confirmPassword !== changePasswordData.password) {
      isValid = false;
      setChangePasswordError(changePasswordError => ({ ...changePasswordError, ["confirmPasswordError"]: alerts.PASSWORD_DOSENT_MATCH }))
      if (!focusSet) {
        refPassword.current.focus();
        focusSet = true;
      }
    }
    return isValid;
  };

  const update_Password = () => {
    let isValid = validation();
    if (isValid) {
      setUpdatePasswordClicked(true);
    }
  };

  const handleCloseConfirm = () => {
    setShowConfirm(false)
  }
  const handleShowConfirm = () => {
    setShowConfirm(true)
  }
  const handleConfirm = () => {
    update_Password()
    setShowConfirm(false)
  }
  return (
    <InnerLayout>
      <Col className='action-wrap'>
        <Row>
          <Col lg='6'>
            <h1>{Strings.Admin.AdminProfile.ChangePassword.Title}</h1>            
          </Col>
        </Row>
      </Col>
      <Col lg='12' className='inner-head text-center'>
        <h2>{Strings.Admin.AdminProfile.ChangePassword.Title2}</h2>
      </Col>
      <Col className='inner-main profile-admin'>
        <Row>
          <Col xl="4">        
          <Row>
              <Col lg='12' className='position-relative'>
            <Input
              label={Strings.Admin.AdminProfile.ChangePassword.Label2}
              id='oldpassword'
              name="old_Password"
              type={viewOldPassword ? 'text' : 'password'}
              value={changePasswordData.old_Password}
              onChange={handlePasswordChange}
              errorMessage={changePasswordError.oldPasswordError}
              txtRef={refOldPassword}
            />
            <span className='view-password position-absolute'><img src={viewOldPassword && changePasswordData.old_Password !== "" ? viewpass : hidepass} onClick={() => setViewOldPassword(!viewOldPassword)} /></span>            
            </Col>
            </Row>
            <Row>
              <Col lg='12' className='position-relative'>
            <Input
              label={Strings.Admin.AdminProfile.ChangePassword.Label3}
              id='newpassword'
              type={viewPassword ? 'text' : 'password'}
              name='password'
              value={changePasswordData.password}
              onChange={handlePasswordChange}
              errorMessage={changePasswordError.newPasswordError}
              txtRef={refPassword}
            />
            <span className='view-password position-absolute'><img src={viewPassword && changePasswordData.password !== "" ? viewpass : hidepass} onClick={() => setViewPassword(!viewPassword)}/></span>           
            </Col>
            </Row>
            <Row>
              <Col lg='12' className='position-relative'>
            <Input
              label={Strings.Admin.AdminProfile.ChangePassword.Label4}
              id='confirmpassword'
              name='confirmPassword'
              type={viewConfirmPassword ? 'text' : 'password'}
              value={changePasswordData.confirmPassword}
              onChange={handlePasswordChange}
              errorMessage={changePasswordError.confirmPasswordError}
            />
            <span className='view-password position-absolute'><img src={viewConfirmPassword && changePasswordData.confirmPassword !== "" ? viewpass : hidepass} onClick={() => setViewConfirmPassword(!viewConfirmPassword)} /></span>
            </Col>
            </Row>
          </Col>
        </Row>
        <Col className='btn-wrap mt-4'>
          <Button variant='outline-primary'
            onClick={() => navigate("/profile")}
          >
            {Strings.Admin.AdminProfile.ChangePassword.Btn1}
          </Button>
          <Button variant='primary'
            onClick={handleShowConfirm}
          >
            {Strings.Admin.AdminProfile.ChangePassword.Btn2}
          </Button>
        </Col>
      </Col>
      <Modal show={showConfirm} onHide={handleCloseConfirm} size="l" centered backdrop="static">
        <Modal.Body >
        <h5 className="text-center"> {alerts.ACTIVE_BLOCK_CONFIRMATION} </h5>
        </Modal.Body>
        <Modal.Footer className='justify-content-center' >
          <Button variant="outline-primary" onClick={handleCloseConfirm}>Cancel</Button>
          <Button variant="primary" onClick={handleConfirm}>OK</Button>
        </Modal.Footer>
      </Modal>
    </InnerLayout>

  );
}
export default AdminChangePass;
