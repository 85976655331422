import React, { useState } from 'react'
const LocationRemove = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20.193" height="20.193" viewBox="0 0 20.193 20.193">
      <defs>
        <clipPath id="clip-path">
          <rect id="Rectangle_768" data-name="Rectangle 768" width="20.193" height="20.193" />
        </clipPath>
      </defs>
      <g id="Group_1551" data-name="Group 1551" transform="translate(0 0)">
        <g id="Group_1550" data-name="Group 1550" transform="translate(0 0)" clip-path="url(#clip-path)">
          <path id="Path_31803" data-name="Path 31803" d="M1.792,4.778V17.4a.84.84,0,0,0,.841.841H16.095a.841.841,0,0,0,.842-.841V4.778Z" transform="translate(0.732 1.953)" />
          <path id="Path_31804" data-name="Path 31804" d="M.841,5.047H19.352a.841.841,0,0,0,0-1.682H14.3V.841A.84.84,0,0,0,13.462,0H6.731A.84.84,0,0,0,5.89.841V3.365H.841a.841.841,0,0,0,0,1.682M7.572,1.682h5.049V3.365H7.572Z" transform="translate(0 0)" />
        </g>
      </g>
    </svg>
  )
};
export default LocationRemove;

