/**
 * @file   src\
 * @brief  This file is responsible for 
 * @date   Oct, 2022
 * @author ZCO Engineer
 * @copyright (c) 2022, ZCO
 */

import { Col, Row} from 'react-bootstrap'
import InnerLayout from '../components/InnerLayout'
import Strings from '../assets/strings/Strings.json'
const AboutUs = (props) => {
  return (
    <InnerLayout  hideHeader={props.hideHeader}>
      <Col className='action-wrap'>
        <Row>
          <Col lg='6'>
            <h1>{Strings.AboutUs.Title}</h1>
          </Col>
        </Row>
      </Col>
      <Col className='inner-main about'>
        <h2>Sub Title</h2>
        <p>Quisque rutrum, ipsum id suscipit rhoncus, felis ligula pretium sapien, id semper nisl lectus sed eros. Duis congue pretium euismod. Vivamus at enim in orci placerat iaculis sed et purus. Proin feugiat fermentum sem eu finibus. Cras purus odio, tristique in augue sagittis, fermentum cursus massa. Praesent aliquet nec elit sit amet auctor. Etiam lobortis lectus ex. Integer posuere vulputate maximus. Phasellus lectus magna, commodo a interdum nec, porttitor sit amet dui. Pellentesque pharetra ligula vitae risus commodo maximus. Cras dictum nisi justo, nec dapibus lacus interdum sit amet. Suspendisse vitae commodo sapien, scelerisque rutrum risus. Nulla facilisi. Mauris justo ex, porta quis dignissim at, congue et dui. Maecenas aliquet efficitur risus.</p>
        <h2>Sub Title</h2>
        <p>Quisque rutrum, ipsum id suscipit rhoncus, felis ligula pretium sapien, id semper nisl lectus sed eros. Duis congue pretium euismod. Vivamus at enim in orci placerat iaculis sed et purus. Proin feugiat fermentum sem eu finibus. Cras purus odio, tristique in augue sagittis, fermentum cursus massa. Praesent aliquet nec elit sit amet auctor. Etiam lobortis lectus ex. Integer posuere vulputate maximus. Phasellus lectus magna, commodo a interdum nec, porttitor sit amet dui. Pellentesque pharetra ligula vitae risus commodo maximus. Cras dictum nisi justo, nec dapibus lacus interdum sit amet. Suspendisse vitae commodo sapien, scelerisque rutrum risus. Nulla facilisi. Mauris justo ex, porta quis dignissim at, congue et dui. Maecenas aliquet efficitur risus.</p>
      </Col>
    </InnerLayout>
  );
}
export default AboutUs;
