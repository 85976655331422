import React, { useState } from 'react'
const Delete = (props) => {
  return (
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
  <g id="Group_649" data-name="Group 649" transform="translate(-156 -540)">
    <path id="Path_28" data-name="Path 28" d="M176,545v18H160V545" fill="none" stroke-linecap="square" stroke-miterlimit="10" stroke-width="2"/>
    <line id="Line_29" data-name="Line 29" x2="22" transform="translate(157 545)" fill="none" stroke-linecap="square" stroke-miterlimit="10" stroke-width="2"/>
    <line id="Line_30" data-name="Line 30" y2="6" transform="translate(168 551)" fill="none" stroke-linecap="square" stroke-miterlimit="10" stroke-width="2"/>
    <line id="Line_31" data-name="Line 31" y2="6" transform="translate(164 551)" fill="none" stroke-linecap="square" stroke-miterlimit="10" stroke-width="2"/>
    <line id="Line_32" data-name="Line 32" y2="6" transform="translate(172 551)" fill="none" stroke-linecap="square" stroke-miterlimit="10" stroke-width="2"/>
    <path id="Path_29" data-name="Path 29" d="M164,545v-4h8v4" fill="none" stroke-linecap="square" stroke-miterlimit="10" stroke-width="2"/>
  </g>
</svg>
  )
};
export default Delete;

