import React, { useState } from 'react'

const RemoveProxy = (props) => {

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="1" viewBox="0 0 21 1">
      <g id="Group_1480" data-name="Group 1480" transform="translate(-444.5 -1021)">
        <line id="Line_40" data-name="Line 40" x1="21" transform="translate(444.5 1021.5)" fill="none" stroke-width="1" />
      </g>
    </svg>
  )
};

export default RemoveProxy;

