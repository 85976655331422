/**
 * @file   src\
 * @brief  This file is responsible for
 * @date   Nov, 2022
 * @author ZCO Engineer
 * @copyright (c) 2022, ZCO
 */
import { Button, Col, Row, Form } from "react-bootstrap";
import Strings from "../assets/strings/Strings.json";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { _vendorSavePaymentCard } from "../actions/VendorAction";
import "./CardForm.scss";
import {
  useStripe,
  useElements,
  AddressElement,
  CardExpiryElement,
  CardCvcElement,
  CardNumberElement,
} from "@stripe/react-stripe-js";
import { useEffect, useState } from "react";

const CardForm = (props) => {
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();
  const setCardSave = props.setCardSave;
  const cardSave = props.cardSave;

  // vendor profile start
  const savePaymentCard = (params) => dispatch(_vendorSavePaymentCard(params));
  // vendor profile ends

  const [classCardNumber, setClassCardNumber] = useState("");
  const [errorMessages, setErrorMessages] = useState("");

  useEffect(() => {
    setTimeout(() => {
      setClassCardNumber('new-class')
    }, 300);
  }, [])

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!stripe && !elements) {
      return;
    }
    var addressElement = elements.getElement(AddressElement);
    var cardNumberElement = elements.getElement(CardNumberElement);
    var cardExp = elements.getElement(CardExpiryElement);
    var cardCvc = elements.getElement(CardCvcElement);
    const { complete, value } = await addressElement.getValue();
      const { token, error } = await stripe.createToken(cardNumberElement,
        {
        address_city: value.address.city === null ? "" : value.address.city,
        address_country:
          value.address.country === null ? "" : value.address.country,
        address_line1: value.address.line1 === null ? "" : value.address.line1,
        address_line2: value.address.line2 === null ? "" : value.address.line2,
        address_state: value.address.state === null ? "" : value.address.state,
        address_zip: value.address.postal_code === null ? "" : value.address.postal_code,
        name: value.name === null ? "" : value.name,
      });
      if (error) {
        toast.error(error.message);
        setCardSave(false);
        addressElement.clear()
        cardNumberElement.clear()
        cardExp.clear()
        cardCvc.clear()
      } else {
        if(complete){
        if (token?.id) {
          savePaymentCard({ token: token?.id });
        }
        }
      }
  };
  const inputStyle = {
    iconColor: "#c4f0ff",
    fontSmoothing: "antialiased",

    ":-webkit-autofill": {
      color: "#fce883",
    },
    "::placeholder": {
      color: "#9E9E9E",
    },
  };

 

  const cardNumberChange = async (e, type) => {
    if (!e.error) {
      if (type === "number") {
        setErrorMessages((errorMessages) => ({
          ...errorMessages,
          ["numberError"]: "",
        }));
      } else if (type === "expiry") {
        setErrorMessages((errorMessages) => ({
          ...errorMessages,
          ["expiryError"]: "",
        }));
      } else {
        setErrorMessages((errorMessages) => ({
          ...errorMessages,
          ["cvvError"]: "",
        }));
      }
    } else if (e.error.message) {
      if (type === "number") {
        setErrorMessages((errorMessages) => ({
          ...errorMessages,
          ["numberError"]: e.error.message,
        }));
      } else if (type === "expiry") {
        setErrorMessages((errorMessages) => ({
          ...errorMessages,
          ["expiryError"]: e.error.message,
        }));
      } else {
        setErrorMessages((errorMessages) => ({
          ...errorMessages,
          ["cvvError"]: e.error.message,
        }));
      }
    }
  }


  return (
    <form className="card-details">
      <h3 className="mt-0">{Strings.Profile.PaymentMethod.Label1}</h3>
      <Row>
        <Col lg="4">
          <label>{Strings.CardForm.Label1}</label>
          <CardNumberElement
            onChange={(e) => cardNumberChange(e,"number")}
            className={"stripe-control " + classCardNumber}
            options={{ style: { base: inputStyle } }}
          />
          <Form.Text className="error">{errorMessages.numberError}</Form.Text>
        </Col>
        <Col lg="2">
          <label>{Strings.CardForm.Label2}</label>
          <CardExpiryElement
            onChange={(e) => cardNumberChange(e,"expiry")}
            className={"stripe-control " + classCardNumber}
            options={{ style: { base: inputStyle } }}
          />
          <Form.Text className="error">{errorMessages.expiryError}</Form.Text>
        </Col>
        <Col lg="2">
          <label>{Strings.CardForm.Label3}</label>
          <CardCvcElement
            onChange={(e) => cardNumberChange(e)}
            className={"stripe-control " + classCardNumber}
            options={{ style: { base: inputStyle } }}
          />
          <Form.Text className="error">{errorMessages.cvvError}</Form.Text>
        </Col>
      </Row>
      <h3 className="mb-4 mt-5">{Strings.Profile.PaymentMethod.Label2}</h3>
      <Row>
        <Col lg="8">
          <AddressElement
            readOnly={true}
            options={{
              mode: "billing",
              allowedCountries: ["US"],
              autocomplete: {
                mode: "google_maps_api",
                apiKey: process.env.REACT_APP_GOOGLE_MAP_ACCESS_KEY,
              },
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col lg="6" className="text-lg-start mt-4">
          <Button
            variant="primary"
            disabled={!stripe || cardSave}
            onClick={handleSubmit}
          >
            {Strings.Profile.PaymentMethod.Btn2}
          </Button>
        </Col>
      </Row>
    </form>
  );
};
export default CardForm;
