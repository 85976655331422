/**
 * @file   src\
 * @brief  This file is responsible for 
 * @date   Oct, 2022
 * @author ZCO Engineer
 * @copyright (c) 2022, ZCO
 */

import { Button, Col, Row } from 'react-bootstrap'
import InnerLayout from '../components/InnerLayout'
import Strings from '../assets/strings/Strings.json'
import feedbackimg from "../assets/img/feedbackimg.svg"
import Input from '../components/Input'
import { _vendorFeedBack, resetResponse } from "../actions/VendorAction";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import * as constants from "../utils/constants";
import * as alerts from '../utils/Messages'
import { emailValidation } from '../helpers/authHelper';
import CustTextArea from "../components/CustTextArea";
const Feedback = (props) => {

  const dispatch = useDispatch();
  const vendorFeedBackData = (data) => dispatch(_vendorFeedBack(data));
  const vendorFeedBackResponse = useSelector(
    (RootReducer) => RootReducer.VendorReducer.vendorFeedBackResponse
  );

  const [feedBackData, setFeedBackData] = useState({ email: "", comments: "" });
  const [feedBackError, setFeedBackError] = useState({ email: "", comments: "" });


  //handle vendorFeedBack response
  useEffect(() => {
    if (vendorFeedBackResponse && vendorFeedBackResponse.apiStatus) {
      if (vendorFeedBackResponse.apiErrorCode === constants.STATUS_200) {
        toast.success(vendorFeedBackResponse.apiMessage)
        setFeedBackData((feedBackData) => ({
          ...feedBackData,
          email: "",
          comments: "",
        }));
        resetResponse(dispatch, "vendorFeedBack")

      } else {
        toast.error(vendorFeedBackResponse.apiMessage)
        resetResponse(dispatch, "vendorFeedBack")
      }
    }
  }, [vendorFeedBackResponse])


  const onTextFeedBackChange = (event) => {
    setFeedBackData((feedBackData) => ({
      ...feedBackData,
      [event.target.name]: event.target.value,
    }));
  };

  const validateFeedBack = () => {
    let isValid = true;
    setFeedBackError((feedBackError) => ({
      ...feedBackError,
      comments: "",
      email: ""
    }));
    if (feedBackData.email.trim() === "") {
      isValid = false;
      setFeedBackError(feedBackError => ({ ...feedBackError, email: alerts.ENTER_EMAIL }))
    }
    else if (emailValidation(feedBackData.email) === false) {
      isValid = false;
      setFeedBackError(feedBackError => ({ ...feedBackError, email: alerts.ENTER_VALID_EMAIL }))
    }
    if (feedBackData.comments.trim() === "") {
      isValid = false;
      setFeedBackError(feedBackError => ({ ...feedBackError, comments: alerts.ENTER_COMMENTS }))
    }
    return isValid
  }
  const handleFeedBackSubmit = () => {
    const valid = validateFeedBack()
    if (valid) {
      vendorFeedBackData(feedBackData)
    }
  }

  return (
    <InnerLayout hideHeader={props.hideHeader}>
      <Col className='inner-main feedback'>
        <Col lg={12}>

          <Row>
            <Col lg={7} className="d-flex align-items-center justify-content-center">
              <img src={feedbackimg} alt="" className="img-fluid" />
            </Col>
            <Col lg={5}>
              <Input
                label="Email"
                onChange={onTextFeedBackChange}
                id="email"
                name="email"
                errorMessage={feedBackError.email}
                value={feedBackData.email}
              /> 
              <div className="position-relative">
              <small className="character-count">{feedBackData.comments.length}/1000</small>
              <CustTextArea
                label="Comments"
                id="comments"
                name="comments"
                maxLength="1000"
                value={feedBackData.comments}
                errorMessage={feedBackError.comments}
                onChange={onTextFeedBackChange}
              />
              </div>
              <Col className="text-end"><Button onClick={handleFeedBackSubmit}>Submit</Button></Col>
            </Col>
          </Row>

        </Col>
      </Col>
    </InnerLayout>
  );
}
export default Feedback;
