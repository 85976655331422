/**
 * @file   src\utils\Messages.js
 * @brief  javascript file to config messages used
 * @date   July , 2022
 * @author ZCO Engineer
 * @copyright (c) 2022, ZCO
 */

export const CONFIRM_LOGOUT = "Are you sure you want to Sign Out?";

export const ENTER_VALID_EMAIL = "Enter a valid Email address";
export const LOGIN_FAILED = "Invalid Email address or Password";

export const REENTER_NEW_PASSWORD = "Re-Type New Password";
export const ENTER_NEW_PASSWORD = "Enter New Password";
export const ENTER_CURRENT_PASSWORD = "Enter Current Password";
export const ENTER_CONFIRM_PASSWORD = "Enter Confirm Password";
export const RESET_PASSWORD_ENTER_CONFIRM_PASSWORD =
  "Enter Confirm New Password";
export const ENTER_VALID_PASSWORD =
  "Password should contain 6-15 characters, at least one upper case letter and one special character";
export const PASSWORD_MISMATCH =
  "New Password and Confirm New Password entries must be the same";
export const PASSWORD_SHOULD_NOT_BE_SAME =
  "Current Password and New Password should not be the same";

export const ENTER_EMAIL = "Enter Email Address";
export const ENTER_PASSWORD = "Enter Password";
export const ENTER_OLD_PASSWORD = "Enter Old Password";
export const ENTER_FIRST_NAME = "Enter First Name";
export const ENTER_LAST_NAME = "Enter Last Name";
export const ENTER_PHONE_NUMBER = "Enter Phone Number";
export const ENTER_OTP = "Enter verification code";
export const ENTER_BUSINESS_NAME = "Enter Business Name";
export const ENTER_BUSINESS_PHONE_NUMBER = "Enter Business Phone Number";
export const ENTER_ADDRESS = "Enter Address";
export const ENTER_ADDRESS2 = "Enter Address 2";
export const ENTER_CITY = "Enter City";
export const ENTER_STATE = "Enter State";
export const SPECIALCHARACTER_ERROR = "Special Characters are not allowed";
export const NUMBER_ERROR = "Numbers are not allowed";
export const ENTER_VALID_ZIP_CODE = "Enter a valid Zip Code";
export const ENTER_ZIP_CODE = "Enter Zip Code";
export const ENTER_COUNTRY = "Enter Country";
export const ENTER_SELLERPOLICYLINK = "Enter Seller Policy Link";
export const ENTER_SELLERPOLICYTEXT = "Enter Seller Policy Details";
export const ENTER_VALID_PHONE_NUMBER = "Enter a valid Phone Number";
export const CONFIRM_PREVIOUS_PAGE =
  "Are you sure you want to move back to the previous page?";
export const TERMS_AND_CONDITIONS =
  "Quisque rutrum, ipsum id suscipit rhoncus, felis ligula pretium sapien, id semper nisl lectus sed eros. Duis congue pretium euismod. Vivamus at enim in orci placerat iaculis sed et purus. Proin feugiat fermentum sem eu finibus. Cras purus odio, tristique in augue sagittis, fermentum cursus massa. Praesent aliquet nec elit sit amet auctor. Etiam lobortis lectus ex. Integer posuere vulputate maximus. Phasellus lectus magna, commodo a interdum nec, porttitor sit amet dui. Pellentesque pharetra ligula vitae risus commodo maximus. Cras dictum nisi justo, nec dapibus lacus interdum sit amet. Suspendisse vitae commodo sapien, scelerisque rutrum risus. Nulla facilisi. Mauris justo ex, porta quis dignissim at, congue et dui. Maecenas aliquet efficitur risus.";
export const ENTER_VALID_LINK = "Enter a valid link";
export const PROMOTIONAL_CITY_CHANGE_WARNING =
  "If you change the address to a non promotional city, the promotional features will be disabled and you need to subscribe to get the features. Are you sure, you want to proceed?";

export const ENTER_ACCOUNT_NAME = "Enter Name on the Account";
export const ENTER_ACCOUNT_NUMBER = "Enter Account Number";
export const ENTER_ROUTING_NUMBER = "Enter Routing Number";
export const ENTER_RE_ACCOUNT_NUMBER = "Re-enter Account Number";
export const ENTER_ACCOUNT_DOSENT_MATCH =
  "Account Number and Re-entered Account Number does not match";
export const VERIFICATION_PAYMENT_METHOD =
  "Your bank account has not yet been verified. You can use this payment method after successful verification";
export const VERIFICATION_LINK =
  "Verify your banking information using this link. This is an important and necessary step to accept online payments";
export const SAVE_PROFILE = "Are you sure, you want to save updates?";
export const PROXY_ADDRESS_DELETE =
  "Deleting this address will remove all the offers listed or scheduled under this address. Are you sure you want to delete this address?";

export const ENTER_IMAGE = "Add at least one offer image";
export const IMAGE_TYPE = "Only .JPEG and .PNG files are supported";
export const FILE_TYPE = "Only .JPEG ,PDF and .PNG files are supported";
export const IMAGE_SIZE = "Upload an image within 6 MB size";
export const FILE_SIZE = "Upload a file within 6 MB size";
export const DELETE_IMAGE = "Cannot remove all images. One image is mandatory";
export const DISPLAY_IMAGE = "Set this image as the default Offer image";
export const DELETE_IMAGE_CONFIRMATION =
  "Are you sure you want to delete this image?";
export const DELETE_CONFIRMATION = "Are you sure you want to delete?";
export const ACTIVE_BLOCK_CONFIRMATION = "Are you sure you want to proceed?";

export const ENTER_PRODUCT_NAME = "Enter Offer Name";
export const ENTER_EXPERIENCE_NAME = "Enter Offer Name";
export const ENTER_PRODUCT_DESCRIPTION = "Enter Offer Description";
export const ENTER_WEBSITE_LINK = "Enter website link";
export const ENTER_PRICE = "Enter Price";
export const ENTER_CATEGORY = "Select Category";
export const ENTER_SUB_CATEGORY = "Select Sub Category";
export const ENTER_PRODUCT_TYPE = "Select Product Type";
export const ENTER_DELIVERY_TYPE = "Select Additional Info";
export const ENTER_PRODUCT_DISPLAY_TYPE = "Select Offer Display Type";
export const ENTER_DATE = "Select Date and Time";
export const ENTER_DELIVERY_OPTION = "Select Delivery";

export const API_ERROR_RESPONSE =
  "Could not complete the process. Please try again after some time";
export const PASSWORD_DOSENT_MATCH =
  "Password and Confirm Password does not match";
export const RESET_PASSWORD_DOSENT_MATCH =
  "New Password and re-entered New Password does not match";
export const RETYPE_PASSWORD_DOSENT_MATCH =
  "New Password and Re-Typed New Password does not match";

export const UPLOAD_IMAGE = "Upload files with .jpg or .png extension only";
export const NO_DEALS_AVAILABLE = "No Deals available";
export const NO_PURCHASE_HISTORY_AVAILABLE = "No Purchase History available";
export const NO_USERS_AVAILABLE = "No Users found";
export const NO_NOTIFICATIONS_AVAILABLE = "No Notifications found";
export const NO_CATEGORY_SELECTED = "Select Category";
export const ENTER_SUB_CATEGORY_NAME = "Enter Sub Category";

export const CALENDLY_ACCOUNT =
  "If you have a Calendly account, copy and paste your scheduling link";
export const WEBSITE_LINK =
  "Drop a message for your customer about your service. You can also add hyperlink to your website or booking portal";
export const PRODUCT_DELETE = "Are you sure you want to Delete the item?";
export const PRODUCT_DELETE_CURRENT_PRODUCT =
  "Deleting this offer will remove it from all the listings and schedules in the application. Are you sure you want to delete this offer?";

export const CANCEL_SCHEDULE = "Are you sure you want to cancel this schedule?";
export const PAYMENT_NOT_RECIEVED =
  "The payment has not been received. Please try again";
export const CANCEL_ADDING_PRODUCT =
  "Are you sure you want to cancel adding this offer?";
export const PLEASE_SUBSCRIBE_TO_ADD =
  "You have not yet subscribed. Please subscribe to add your offer";
export const PLEASE_VERIFY_BANK =
  "Please complete your banking information before proceeding to list your offer in the app";
export const PLEASE_VERIFY_BANK_BROADCAST =
  "Please verify your banking information before proceeding to broadcast your offers";
export const PLEASE_VERIFY_BANK_ADD =
  "Please complete your banking information before proceeding to list your offers in the app";
export const PLEASE_SUBSCRIBE_LIST =
  "You have not yet subscribed. Please subscribe to list your offers";
export const PLEASE_SUBSCRIBE_LIST_BROADCAST =
  "You have not yet subscribed. Please subscribe to broadcast your offers";
export const PLEASE_SUBSCRIBE_TO_EDIT =
  "You have not yet subscribed. Please subscribe to edit your offer";
export const PLEASE_SUBSCRIBE_LOGIN =
  "Your subscription has expired. Please subscribe to access this feature";
export const PAYMENT_REQUIRED =
  "Add a Payment Method for completing the subscription payment";

export const DELETE_ZIPCODE =
  "Deleting this Zip code will remove all the offers listed or scheduled under this Zip Code. Are you sure you want to delete this Zip code?";
export const SUBSCRIPTION_DOWNGRADE_TO_TIER1 =
  "After the downgrade, only 30 of your latest offers will be shown in the application and the remaining will be removed. Are you sure you want to downgrade?";
export const 
SUBSCRIPTION_DOWNGRADE_TO_TIER2 =
  "After the downgrade, only 60 of your latest offers will be shown in the application and the remaining will be removed. Are you sure you want to downgrade?";
export const SUBSCRIPTION_DOWNGRADE_TO_TIER4 ="Are you sure you want to downgrade from Yearly to Monthly plan?";
  export const DELETE_SUBCATEGORY =
  "Are you sure you want to delete this subcategory?";
export const DELETE_BANKINFO = "Delete Account?";
export const DELETE_BANKINFO_MESSAGE =
  "On deleting the bank account, you will not be able to list offers";

export const ENTER_VALUE = "Enter Value";
export const ENTER_COUPON_VALUE = "Enter Coupon Name";
export const MAXIMUM_DISCOUNT_ALLOWED =
  "You have entered the maximum % discount allowed";
export const MINIMUM_AMOUNT_DIFFERENCE =
  "Please enter a minimum purchase amount with atleast $1 difference";

export const ENTER_COMMENTS = "Enter Comments";
export const NO_ZCTA_AVAILABLE = "No ZCTA data available";

// Rate Input Validation Messages
export const SELECT_CARRIER = "Select a Carrier";
export const ENTER_FRON_COUNTRY = "Enter Country";
export const ENTER_FRON_ZIP = "Enter Postal Code";
export const ENTER_WEIGHT = "Enter Weight";
export const ENTER_LENGTH = "Enter Length";
export const ENTER_HEIGTH = "Enter Height";
export const ENTER_WIDTH = "Enter Width";
export const CONFIRM_VALIDATION = "Select a rate and click Confirm";
export const GENERATE_LABEL_VALIDATION =
  "Select Carrier and Rate before generating the label";
