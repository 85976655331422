import React, { useState, useRef, useEffect } from 'react'
import './Register.scss'
import Strings from "../assets/strings/Strings.json"
import { Button, Col, Row, Form, Modal } from 'react-bootstrap';
import { Stepper, Step } from 'react-form-stepper';
import Input from '../components/Input';
import { Link, useNavigate } from 'react-router-dom';
import OtpForm from './OtpForm';
import OtpMail from '../assets/img/icons/OtpMail';
import OtpPhone from '../assets/img/icons/OtpPhone';
import { useDispatch, useSelector } from 'react-redux';
import { _vendorRegistration, _sendOTP, _pendingVendorRegistration, _vendorRegistrationStep2, _vendorRegistrationStep3, _sendMobileOTP } from '../actions/VendorAction';
import { emailValidation, nameValidation, passwordValidation, phoneNumberValidation, zipCodeValidation } from '../helpers/authHelper';
import { toast } from 'react-toastify'
import * as alerts from '../utils/Messages';
import { useParams } from 'react-router-dom';
import viewpass from '../assets/img/view-password.svg'
import hidepass from '../assets/img/hide-password.svg'
import * as constants from '../utils/constants';
import moment from 'moment/moment';
import TermsConditions from './Terms'
import momentTimezone from 'moment-timezone';
import Autocomplete from "react-google-autocomplete";


const RegisterForms = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let { id, step } = useParams();


  // ------- call API from functions and response from redux start ---------
  const vendorRegister = (signUpFormData) => dispatch(_vendorRegistration(signUpFormData));
  const vendorRegisterResponse = useSelector((RootReducer) => RootReducer.VendorReducer.vendorRegisterResponse);

  // ------- call API from functions and response from redux start ---------
  const vendorRegisterStep2 = (signUpFormData) => dispatch(_vendorRegistrationStep2(signUpFormData));
  const vendorRegisterStep2Response = useSelector((RootReducer) => RootReducer.VendorReducer.vendorRegisterStep2Response);

  // ------- call API from functions and response from redux start ---------
  const vendorRegisterStep3 = (signUpFormData) => dispatch(_vendorRegistrationStep3(signUpFormData));
  const vendorRegisterStep3Response = useSelector((RootReducer) => RootReducer.VendorReducer.vendorRegisterStep3Response);

  // ------- call API from functions and response from redux start ---------
  const vendorRegisterOtp = (otpDetails) => dispatch(_sendOTP(otpDetails));
  const vendorRegisterOtpResponse = useSelector((RootReducer) => RootReducer.VendorReducer.vendorOtpSendResponse);

  // ------- call API from functions and response from redux start ---------
  const vendorRegisterMobileOtp = (otpDetails) => dispatch(_sendMobileOTP(otpDetails));
  const vendorRegisterMobileOtpResponse = useSelector((RootReducer) => RootReducer.VendorReducer.vendorSendMobileOTPResponse);

  // ------- api to get pending Vendor Registration---------
  const pendingVendorRegistration = (id) => dispatch(_pendingVendorRegistration(id));
  const pendingVendorRegistrationResponse = useSelector((RootReducer) => RootReducer.VendorReducer.pendingVendorRegistrationResponse);

  const StepperStyle = {
    activeBgColor: '#94DA0A',
    completedBgColor: "#707070",
    size: "21px",
    circleFontSize: "0"
  }


  // ------- state start -------
  const intitalValuesSignUpStep1 = {
    first_name: "",
    last_name: "",
    phone_number: "",
    email: "",
    pwd: "",
    confirmPwd: "",
    step: 1,
    latitude: "",
    longitude: ""
  }
  const intitalValuesSignUpStep2 = {
    email: "",
    mob_number: "",
    emailOtp: "",
    email_otp: "",
    mobile_otp: "",
    mobileOtp: "",
    step: 2,
  }
  const intitalValuesSignUpStep3 = {
    email: "",
    business_name: "",
    business_number: "",
    business_address: "",
    business_address2: "",
    business_city: "",
    business_state: "",
    zip_code: "",
    business_country: "",
    step: 3,
    vendor_id: 0,
    country: '',
    latitude: "",
    longitude: "",
    timeZone: momentTimezone.tz.guess()
  }
  const intitalFormErrorSignUpStep1 = {
    first_nameError: "",
    last_nameError: "",
    phone_numberError: "",
    emailError: "",
    pwdError: "",
    confirmPwdError: "",
  }
  const intitalFormErrorSignUpStep2 = {
    emailOtpError: "",
    mobileOtpError: ""
  }
  const intitalFormErrorSignUpStep3 = {
    business_nameError: "",
    business_numberError: "",
    business_addressError: "",
    business_address2Error: "",
    business_cityError: "",
    business_stateError: "",
    zip_codeError: "",
    business_countryError: "",
  }
  const intitalOtpDetails = {
    "email": "",
    "mob_number": ""
  }

  const [goSteps, setGoSteps] = useState(-1);
  const [stepNo, setStepNo] = useState(-1)

  const [signupStep1Data, setSignupStep1Data] = useState(intitalValuesSignUpStep1);
  const [signupStep1Error, setSignupStep1Error] = useState(intitalFormErrorSignUpStep1);
  const [signupStep1Clicked, setSignupStep1Clicked] = useState(false);

  const [signupStep2Data, setSignupStep2Data] = useState(intitalValuesSignUpStep2);
  const [signupStep2Error, setSignupStep2Error] = useState(intitalFormErrorSignUpStep2);
  const [signupStep2Clicked, setSignupStep2Clicked] = useState(false);

  const [signupStep3Data, setSignupStep3Data] = useState(intitalValuesSignUpStep3);
  const [signupStep3Error, setSignupStep3Error] = useState(intitalFormErrorSignUpStep3);
  const [signupStep3Clicked, setSignupStep3Clicked] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const [showTermsAndConditionModal, setShowTermsAndConditionModal] = useState(false);
  const [vendorId, setVendorId] = useState(0);
  const [newStep, setNewStep] = useState(-1);
  const [otpLimitTimeEmail, setOtpLimitTimeEmail] = useState('');
  const [otpLimitTimeMobile, setOtpLimitTimeMobile] = useState('');


  const [disableOtpBtnEmail, setDisableOtpBtnEmail] = useState(false);
  const [disableOtpBtnPhone, setDisableOtpBtnPhone] = useState(false);

  const [otpRetriedcoutEmail, setOtpRetriedcoutEmail] = useState('');
  const [otpRetriedcoutPhone, setOtpRetriedcoutPhone] = useState('');


  const [otpDetails, setOtpDetails] = useState(intitalOtpDetails);

  const [sentEmailOtpClicked, setSentEmailOtpClicked] = useState(false);
  const [sentPhoneOtpClicked, setSentPhoneOtpClicked] = useState(false);
  const [sendEmailOtpExpired, setSendEmailOtpExpired] = useState(false);
  const [sendPhoneOtpExpired, setSendPhoneOtpExpired] = useState(false);

  const [termsAndConditionsCheckboxValue, setTermsAndConditionsCheckboxValue] = useState(false);
  const [signUpFormData, setSignUpFormData] = useState([]);
  const [accountVerified, setAccountVerified] = useState(false);
  const [accountVerifiedMobile, setAccountVerifiedMobile] = useState(false);


  const [vendorRegisterStep1ResponseData, setVendorRegisterStep1ResponseData] = useState('');
  const [vendorRegisterStep2ResponseData, setVendorRegisterStep2ResponseData] = useState('');
  const [vendorRegisterStep3ResponseData, setVendorRegisterStep3ResponseData] = useState('');

  const [termsAndConditionsCheckboxError, setTermsAndConditionsCheckboxError] = useState('');

  const [toastSuccessMessage, setToastSuccessMessage] = useState('')
  const [toastErrorMessage, setToastErrorMessage] = useState('')
  const [vendorData, setVendorData] = useState([])
  const [viewPassword, setViewPassword] = useState(false)
  const [viewConfirmPassword, setViewConfirmPassword] = useState(false)

  const [businessInfoPhoneNumber, setBusinessInfoPhoneNumber] = useState("");
  const [basicInfoPhoneNumber, setBasicInfoPhoneNumber] = useState("")

  const [lat, setLat] = useState("")
  const [long, setLong] = useState("")
  const [loadMap, setLoadMap] = useState(false);
  const [place, setPlace] = useState(null);

  const refFirstname = useRef(null);
  const refLastname = useRef(null);
  const refEmail = useRef(null);
  const refPhone = useRef(null);
  const refPassword = useRef(null);
  const refConfirmPassword = useRef(null);

  const refBusinessName = useRef(null);
  const refBusinessPhoneNumber = useRef(null);
  const refAddress = useRef(null);
  const refAddress2 = useRef(null);
  const refCity = useRef(null);
  const ref_State = useRef(null);
  const refZipCode = useRef(null);
  const refCountry = useRef(null);

  // ------- useEffect start -------
  useEffect(() => {
    setGoSteps(props.step ? parseInt(props.step) : 0)
    setStepNo(props.step ? parseInt(props.step) : 0)
  }, [])

  useEffect(() => {
    if (parseInt(id)) {
      pendingVendorRegistration(id);
    }
  }, [id])

  useEffect(() => {
    if (otpLimitTimeEmail) {
      let currentTime = new Date();
      let expireTime = new Date(otpLimitTimeEmail);
      let totaldisabletime = moment(expireTime).add(10, 's').toDate();
      let minutes = (totaldisabletime - currentTime) / (1000 * 60);
      if (minutes && minutes > 0) {
        let disableTime = minutes * 60
        if (otpRetriedcoutEmail % 3 === 0) {
          setDisableOtpBtnEmail(true)
          setTimeout(() => setDisableOtpBtnEmail(false), disableTime * 1000)
        }
      }
    }
  }, [otpLimitTimeEmail])

  useEffect(() => {
    if (otpLimitTimeMobile) {
      let currentTime = new Date();
      let expireTime = new Date(otpLimitTimeMobile);
      let totaldisabletime = moment(expireTime).add(10, 's').toDate();
      let minutes = (totaldisabletime - currentTime) / (1000 * 60);
      if (minutes && minutes > 0) {
        let disableTime = minutes * 60
        if (otpRetriedcoutPhone % 3 === 0) {
          setDisableOtpBtnPhone(true)
          setTimeout(() => setDisableOtpBtnPhone(false), disableTime * 1000)
        }
      }
    }
  }, [otpLimitTimeMobile])

  useEffect(() => {
    let currentStep = step ? step : 0;
    if (parseInt(currentStep) == constants.STATUS_2) {
      setSignupStep2Clicked(false);
      setSentEmailOtpClicked(false)
      setSentPhoneOtpClicked(false)
      setGoSteps(1);
      setStepNo(1);
    } else if (parseInt(currentStep) === 3) {
      setGoSteps(2);
      setStepNo(2);
    } else {
      setGoSteps(0);
      setStepNo(0);
    }

  }, [step])


  useEffect(() => {
    if (vendorRegisterResponse) {
      if (signUpFormData.step === constants.STATUS_1) {
        setVendorRegisterStep1ResponseData(vendorRegisterResponse);
      }
      if (signUpFormData.step === constants.STATUS_2) {
        setVendorRegisterStep2ResponseData(vendorRegisterResponse);
      }
      if (signUpFormData.step === 3) {
        setVendorRegisterStep3ResponseData(vendorRegisterResponse);
      }
    }
  }, [vendorRegisterResponse])

  useEffect(() => {
    if (vendorRegisterStep2Response) {
      if (signUpFormData.step === constants.STATUS_2) {
        setVendorRegisterStep2ResponseData(vendorRegisterStep2Response);
      }
    }
  }, [vendorRegisterStep2Response])

  useEffect(() => {
    if (vendorRegisterStep3Response) {
      if (signUpFormData.step === 3) {
        setVendorRegisterStep3ResponseData(vendorRegisterStep3Response);
      }
    }
  }, [vendorRegisterStep3Response])

  //toast message

  useEffect(() => {
    if (toastSuccessMessage !== '') {
      toast.success(toastSuccessMessage, {
        onClose: () => {
          setToastSuccessMessage('');
          setVendorRegisterStep1ResponseData([])
          setVendorRegisterStep2ResponseData([])
          setVendorRegisterStep3ResponseData([])
        }
      });
    }

  }, [toastSuccessMessage])

  useEffect(() => {
    if (toastErrorMessage !== '') {
      toast.error(toastErrorMessage, {
        onClose: () => {
          setToastErrorMessage('')
          setVendorRegisterStep1ResponseData([])
          setVendorRegisterStep2ResponseData([])
          setVendorRegisterStep3ResponseData([])
        }
      });
    }
  }, [toastErrorMessage])

  //vendorStep1 response
  useEffect(() => {
    if (vendorRegisterStep1ResponseData) {
      if (vendorRegisterStep1ResponseData && vendorRegisterStep1ResponseData.apiErrorCode === constants.STATUS_200) {
        setSignupStep1Error(intitalFormErrorSignUpStep1)
        setSignupStep1Clicked(false);
        setGoSteps(1);

        if (vendorRegisterStep1ResponseData.apiResponse && vendorRegisterStep1ResponseData.apiResponse.user_info && vendorRegisterStep1ResponseData.apiResponse.user_info.vendor_id) {
          pendingVendorRegistration(vendorRegisterStep1ResponseData.apiResponse.user_info.vendor_id);
          navigate("/register/step/2/" + vendorRegisterStep1ResponseData.apiResponse.user_info.vendor_id);
          setSendEmailOtpExpired(true)
          setSendPhoneOtpExpired(true)
        }

      }
      else if (vendorRegisterStep1ResponseData && vendorRegisterStep1ResponseData.apiErrorCode === constants.STATUS_400) {
        setToastErrorMessage(vendorRegisterResponse.apiMessage)
        setSignupStep1Clicked(false);
      }
      setSignupStep1Clicked(false)
    }
  }, [vendorRegisterStep1ResponseData])

  //vendorStep2 response
  useEffect(() => {

    if (vendorRegisterStep2ResponseData.apiStatus) {
      if (vendorRegisterStep2ResponseData && vendorRegisterStep2ResponseData.apiErrorCode && (vendorRegisterStep2ResponseData.apiErrorCode === constants.STATUS_200 || vendorRegisterStep2ResponseData.apiMessage === constants.STATUS_200)) {

        setSignupStep2Error(intitalFormErrorSignUpStep2)
        setSignupStep2Clicked(false);
        setSentEmailOtpClicked(false)
        setSentPhoneOtpClicked(false)
        setSignupStep2Data(intitalValuesSignUpStep2)
        setGoSteps(2);

        if (vendorRegisterStep2ResponseData.apiResponse && vendorRegisterStep2ResponseData.apiResponse.otp_info && vendorRegisterStep2ResponseData.apiResponse.otp_info.verified_status) {
          setAccountVerified(true)
          setAccountVerifiedMobile(true)
          setDisableOtpBtnEmail(true)
          setDisableOtpBtnPhone(true)
          setTimeout(() => {
            navigate("/register/step/3/" + vendorId);
          }, 100)
        }
      }
      else if (vendorRegisterStep2ResponseData && vendorRegisterStep2ResponseData.apiErrorCode === constants.STATUS_400) {
        if (vendorRegisterStep2ResponseData.apiResponse && vendorRegisterStep2ResponseData.apiResponse.otp_info && vendorRegisterStep2ResponseData.apiResponse.otp_info.otp_expired === 1) {
          if (vendorRegisterStep2ResponseData.apiResponse.otp_info.e_address && vendorRegisterStep2ResponseData.apiResponse.otp_info.e_address !== "") {
            setSendEmailOtpExpired(true);
          }
          if (vendorRegisterStep2ResponseData.apiResponse.otp_info.mob_number && vendorRegisterStep2ResponseData.apiResponse.otp_info.mob_number !== "") {
            setSendPhoneOtpExpired(true);
          }
        }
        setToastErrorMessage(vendorRegisterStep2ResponseData.apiMessage)
        setSignupStep2Clicked(false);
      }
    }
  }, [vendorRegisterStep2ResponseData])

  //vendorStep3 response

  useEffect(() => {

    if (vendorRegisterStep3ResponseData) {
      if (vendorRegisterStep3ResponseData && vendorRegisterStep3ResponseData.apiErrorCode === constants.STATUS_200) {
        setSignupStep3Error(intitalFormErrorSignUpStep3)
        setToastSuccessMessage(vendorRegisterStep3ResponseData.apiMessage)
        setSignupStep2Clicked(false);
        setSignupStep1Data([])
        setSignupStep2Data([])
        setSignupStep3Data([])
        props.onPress()
        resetOtpResponse("vendorSendOtp")
      }
      else if (vendorRegisterStep3ResponseData && vendorRegisterStep3ResponseData.apiErrorCode === constants.STATUS_400) {
        setToastErrorMessage(vendorRegisterStep3ResponseData.apiMessage)
        setSignupStep3Clicked(false);
      }
    }
  }, [vendorRegisterStep3ResponseData])


  //calling sendOtp fuction
  useEffect(() => {
    if (sentEmailOtpClicked && otpDetails.mob_number === "") {
      vendorRegisterOtp(otpDetails)
    } else if (sentPhoneOtpClicked && otpDetails.email === "") {
      vendorRegisterMobileOtp(otpDetails)
    }
  }, [otpDetails])

  useEffect(() => {
    //pending vendor response  
    if (pendingVendorRegistrationResponse) {

      if (pendingVendorRegistrationResponse && pendingVendorRegistrationResponse.apiErrorCode === constants.STATUS_200) {
        if (pendingVendorRegistrationResponse.apiResponse.user_info) {
          if (pendingVendorRegistrationResponse.apiResponse.user_info[0]) {
            setOtpRetriedcoutEmail(pendingVendorRegistrationResponse.apiResponse.user_info[0].otp_retried_count_email)
            setOtpRetriedcoutPhone(pendingVendorRegistrationResponse.apiResponse.user_info[0].otp_retried_count_mobile)
            if (pendingVendorRegistrationResponse.apiResponse.user_info[0].updatedon) {
              setOtpLimitTimeEmail(pendingVendorRegistrationResponse.apiResponse.user_info[0].updatedon)
            }
            if (pendingVendorRegistrationResponse.apiResponse.user_info[0].updatedon_mobile) {
              setOtpLimitTimeMobile(pendingVendorRegistrationResponse.apiResponse.user_info[0].updatedon_mobile)
            }
            if (pendingVendorRegistrationResponse.apiResponse.user_info[0]?.is_email_verified === constants.STATUS_1 && pendingVendorRegistrationResponse.apiResponse.user_info[0]?.is_mobile_verified === constants.STATUS_1) {
              setAccountVerified(true)
              setDisableOtpBtnEmail(true)
              setAccountVerifiedMobile(true)
              setDisableOtpBtnPhone(true)
            } else if (pendingVendorRegistrationResponse.apiResponse.user_info[0]?.is_email_verified === constants.STATUS_1 && pendingVendorRegistrationResponse.apiResponse.user_info[0]?.is_mobile_verified !== constants.STATUS_1) {
              setAccountVerified(true)
              setDisableOtpBtnEmail(true)
              setAccountVerifiedMobile(false)
              setDisableOtpBtnPhone(false)
            } else if (pendingVendorRegistrationResponse.apiResponse.user_info[0]?.is_email_verified !== constants.STATUS_1 && pendingVendorRegistrationResponse.apiResponse.user_info[0]?.is_mobile_verified === constants.STATUS_1) {
              setAccountVerified(false)
              setDisableOtpBtnEmail(false)
              setAccountVerifiedMobile(true)
              setDisableOtpBtnPhone(true)
            } else {
              setAccountVerified(false)
              setAccountVerifiedMobile(false)
              setDisableOtpBtnEmail(false)
              setDisableOtpBtnPhone(false)
            }
          }
          let vendor = pendingVendorRegistrationResponse.apiResponse.user_info.filter((row) => {
            return row.vendor_id == id
          })

          //setting params to send otp and  setSignupStep1Data
          if (vendor && vendor[0]) {
            setVendorData(vendor[0])

            setSignupStep1Data(signupStep1Data => ({ ...signupStep1Data, email: vendor[0].e_address, phone_number: vendor[0].mob_number, vendor_id: vendor[0].vendor_id, first_name: vendor[0].f_name, last_name: vendor[0].l_name, step: stepNo ? stepNo : 1 }))

            setVendorId(vendor[0].vendor_id)
          } else {
            if (parseInt(step) == constants.STATUS_2 || parseInt(step) === 3) {
              setGoSteps(0);
              setStepNo(0);
              navigate('/register')
            }
          }
          resetOtpResponse("pendingVendorRegistrationResponse")
        }

      } else if (pendingVendorRegistrationResponse && pendingVendorRegistrationResponse.apiErrorCode !== constants.STATUS_200) {
        setToastErrorMessage(pendingVendorRegistrationResponse.apiMessage)
      }
    }

  }, [pendingVendorRegistrationResponse])

  useEffect(() => {

    if (vendorRegisterOtpResponse && vendorRegisterOtpResponse.apiStatus) {
      if (vendorRegisterOtpResponse && vendorRegisterOtpResponse.apiErrorCode === constants.STATUS_200) {
        setToastSuccessMessage(vendorRegisterOtpResponse.apiMessage)
        if (vendorRegisterOtpResponse.apiResponse.otp_info.is_email_otp === constants.STATUS_1) {
          setSendEmailOtpExpired(true)
        }
        if (vendorRegisterOtpResponse.apiResponse.otp_info.is_mobile_otp === constants.STATUS_1) {
          setSendPhoneOtpExpired(true)
        }
      } else if (vendorRegisterOtpResponse && vendorRegisterOtpResponse.apiErrorCode !== constants.STATUS_200) {
        setToastErrorMessage(vendorRegisterOtpResponse.apiMessage)
        if (vendorRegisterOtpResponse.apiResponse) {
          setOtpRetriedcoutEmail(vendorRegisterOtpResponse.apiResponse.otp_retried_count_email)
          setOtpRetriedcoutPhone(vendorRegisterOtpResponse.apiResponse.otp_retried_count_mobile)
          if (vendorRegisterOtpResponse.apiResponse.updatedOn) {
            setOtpLimitTimeEmail(vendorRegisterOtpResponse.apiResponse.updatedOn)
          }
          if (vendorRegisterOtpResponse.apiResponse.updatedOnMobile) {
            setOtpLimitTimeMobile(vendorRegisterOtpResponse.apiResponse.updatedOnMobile)
          }
        }
      }
      resetOtpResponse('vendorSendOtp')
    }
  }, [vendorRegisterOtpResponse])

  useEffect(() => {

    if (vendorRegisterMobileOtpResponse && vendorRegisterMobileOtpResponse.apiStatus) {
      if (vendorRegisterMobileOtpResponse && vendorRegisterMobileOtpResponse.apiErrorCode === constants.STATUS_200) {
        setToastSuccessMessage(vendorRegisterMobileOtpResponse.apiMessage)
        if (vendorRegisterMobileOtpResponse.apiResponse.otp_info.is_email_otp === constants.STATUS_1) {
          setSendEmailOtpExpired(true)
        }
        if (vendorRegisterMobileOtpResponse.apiResponse.otp_info.is_mobile_otp === constants.STATUS_1) {
          setSendPhoneOtpExpired(true)
        }
      } else if (vendorRegisterMobileOtpResponse && vendorRegisterMobileOtpResponse.apiErrorCode !== constants.STATUS_200) {
        setToastErrorMessage(vendorRegisterMobileOtpResponse.apiMessage)
        if (vendorRegisterMobileOtpResponse.apiResponse) {
          setOtpRetriedcoutEmail(vendorRegisterMobileOtpResponse.apiResponse.otp_retried_count_email)
          setOtpRetriedcoutPhone(vendorRegisterMobileOtpResponse.apiResponse.otp_retried_count_mobile)
          if (vendorRegisterMobileOtpResponse.apiResponse.updatedOn) {
            setOtpLimitTimeEmail(vendorRegisterMobileOtpResponse.apiResponse.updatedOn)
          }
          if (vendorRegisterMobileOtpResponse.apiResponse.updatedOnMobile) {
            setOtpLimitTimeMobile(vendorRegisterMobileOtpResponse.apiResponse.updatedOnMobile)
          }
        }
      }
      resetOtpResponse('vendorSendMobileOtp')
    }
  }, [vendorRegisterMobileOtpResponse])

  //calling step1 registration function
  useEffect(() => {
    if (signupStep1Clicked) {
      vendorRegister(signUpFormData)
    }
  }, [signupStep1Clicked])

  useEffect(() => {
    if (signupStep1Data) {
      setSignUpFormData(signUpFormData => ({ ...signUpFormData, ["email"]: signupStep1Data.email, longitude: long, latitude: lat }))
    }
  }, [signupStep1Data])

  useEffect(() => {
    if (lat && long) {
      setSignUpFormData(signUpFormData => ({ ...signUpFormData, longitude: long, latitude: lat }))
      setSignupStep3Data(signupStep3Data => ({ ...signupStep3Data, longitude: long, latitude: lat }))
    }
  }, [lat, long])


  //calling step2 registration function
  useEffect(() => {
    if (signupStep2Clicked) {
      let postParams = {
        email: accountVerified ? "" : signupStep1Data.email,
        mob_number: signupStep2Data.mobile_otp !== "" ? signupStep1Data.phone_number : "",
        email_otp: signupStep2Data.email_otp,
        mobile_otp: signupStep2Data.mobile_otp,
        vendor_id: signupStep1Data.vendor_id,
        step: 2,
      }
      vendorRegisterStep2(postParams)
    }
  }, [signupStep2Clicked])

  //calling step3 registration function
  useEffect(() => {
    if (signupStep3Clicked) {
      let signUpFormDatas = signUpFormData
      signUpFormDatas.latitude = lat;
      signUpFormDatas.longitude = long;
      signUpFormDatas.vendor_id = vendorId

      vendorRegisterStep3(signUpFormDatas)
    }
  }, [signupStep3Clicked])

  useEffect(() => {
    setSignupStep3Data(signupStep3Data => ({ ...signupStep3Data, ["email"]: signupStep1Data.email }))
  }, [signupStep1Data])

  // useEffect(() => {
  //   if (!loadMap && parseInt(step) === 3) {
  //     loadGoogleMapScript(() => {
  //       setLoadMap(true)
  //     });
  //   }
  // }, [loadMap, step]);

  // useEffect(() => {
  //   if (loadMap) {
  //     initPlaceAPI();
  //   }

  // }, [loadMap]);

  useEffect(() => {
    if (place) {
      let address = place;
      let streetNumber = ''
      let routeInfo = ''
      let plusCode = ''
      let neighborhood = ''
      let premise = ''
      let business_city = ''
      address.forEach((item) => {

        if (item.types.indexOf('street_number') >= 0) {
          streetNumber = item.long_name + ' '
          setSignupStep3Data(signupStep3Data => ({ ...signupStep3Data, business_address: streetNumber }))
        }

        if (item.types.indexOf('route') >= 0) {
          routeInfo = streetNumber + item.long_name + ' '
          setSignupStep3Data(signupStep3Data => ({ ...signupStep3Data, business_address: routeInfo }))
        }
        if (item.types.indexOf('plus_code') >= 0) {
          if (streetNumber === "") {
            plusCode = streetNumber + routeInfo + item.long_name + " ";
          } else {
            plusCode = routeInfo + item.long_name + " ";
          }
          setSignupStep3Data(signupStep3Data => ({ ...signupStep3Data, business_address: plusCode }))
        }

        if (item.types.indexOf('neighborhood') >= 0) {
          if (streetNumber === "") {
            neighborhood = streetNumber + routeInfo + plusCode + item.long_name + " ";
          } else {
            neighborhood = routeInfo + plusCode + item.long_name + " ";
          }
          setSignupStep3Data(signupStep3Data => ({ ...signupStep3Data, business_address: neighborhood }))
        }
        if (item.types.indexOf('premise') >= 0) {
          if (streetNumber === "") {
            premise =
              streetNumber +
              routeInfo +
              plusCode +
              neighborhood +
              item.long_name +
              " ";
          } else {
            premise =
              routeInfo +
              plusCode +
              neighborhood +
              item.long_name +
              " ";
          }
          setSignupStep3Data(signupStep3Data => ({ ...signupStep3Data, business_address: premise }))
        }

        if (item.types.indexOf('administrative_area_level_1') >= 0) {
          setSignupStep3Data(signupStep3Data => ({ ...signupStep3Data, business_state: item.long_name }))
        }

        if (item.types.indexOf('locality') >= 0) {
          business_city = item.long_name
          setSignupStep3Data(signupStep3Data => ({ ...signupStep3Data, business_city: item.long_name }))
        } else if (item.types.indexOf('administrative_area_level_3') >= 0 && business_city === '') {
          setSignupStep3Data(signupStep3Data => ({ ...signupStep3Data, business_city: item.long_name }))
        }

        if (item.types.indexOf('country') >= 0) {
          setSignupStep3Data(signupStep3Data => ({ ...signupStep3Data, country: item.short_name }))
          setSignupStep3Data(signupStep3Data => ({ ...signupStep3Data, business_country: item.long_name }))
        }
        if (item.types.indexOf('postal_code') >= 0) {
          setSignupStep3Data(signupStep3Data => ({ ...signupStep3Data, zip_code: item.long_name }))
        }
      });
    }
  }, [place]);

  // handle basic phone formatting
  useEffect(() => {
    phoneFormat(signupStep1Data.phone_number, 1);
  }, [signupStep1Data.phone_number]);

  // handle business phone formatting
  useEffect(() => {
    phoneFormat(signupStep3Data.business_number, 2);
  }, [signupStep3Data.business_number]);

  // ------- useEffect End -------


  // -------  custom functions start -------

  //validation of step 1 registration


  const step1Validation = () => {

    setSignupStep1Error(intitalFormErrorSignUpStep1);
    let isValid = true;
    let focusSet = false;
    let phoneNumberErrMsg = phoneNumberValidation(signupStep1Data.phone_number);
    let firstNameErrMsg = nameValidation(signupStep1Data.first_name);
    let lastNameErrMsg = nameValidation(signupStep1Data.last_name);

    if (signupStep1Data.first_name.trim() === "") {
      isValid = false;
      setSignupStep1Error(signupStep1Error => ({ ...signupStep1Error, ["first_nameError"]: alerts.ENTER_FIRST_NAME }))
      if (!focusSet) {
        refFirstname.current.focus();
        focusSet = true;
      }
    }
    else if (firstNameErrMsg !== "") {
      isValid = false;
      setSignupStep1Error(signupStep1Error => ({ ...signupStep1Error, ["first_nameError"]: firstNameErrMsg }))
      if (!focusSet) {
        refFirstname.current.focus();
        focusSet = true;
      }
    }

    if (signupStep1Data.last_name.trim() === "") {
      isValid = false;
      setSignupStep1Error(signupStep1Error => ({ ...signupStep1Error, ["last_nameError"]: alerts.ENTER_LAST_NAME }))
      if (!focusSet) {
        refLastname.current.focus();
        focusSet = true;
      }
    } else if (lastNameErrMsg !== "") {
      isValid = false;
      setSignupStep1Error(signupStep1Error => ({ ...signupStep1Error, ["last_nameError"]: lastNameErrMsg }))
      if (!focusSet) {
        refLastname.current.focus();
        focusSet = true;
      }
    }


    if (signupStep1Data.phone_number.trim() === "") {
      isValid = false;
      setSignupStep1Error(signupStep1Error => ({ ...signupStep1Error, ["phone_numberError"]: alerts.ENTER_PHONE_NUMBER }))
      if (!focusSet) {
        refPhone.current.focus();
        focusSet = true;
      }
    }
    else if (phoneNumberErrMsg !== "") {
      isValid = false;
      setSignupStep1Error(signupStep1Error => ({ ...signupStep1Error, ["phone_numberError"]: phoneNumberErrMsg }))
      if (!focusSet) {
        refPhone.current.focus();
        focusSet = true;
      }
    }


    if (signupStep1Data.email.trim() === "") {
      isValid = false;
      setSignupStep1Error(signupStep1Error => ({ ...signupStep1Error, ["emailError"]: alerts.ENTER_EMAIL }))
      if (!focusSet) {
        refEmail.current.focus();
        focusSet = true;
      }
    }
    else if (emailValidation(signupStep1Data.email) === false) {
      isValid = false;
      setSignupStep1Error(signupStep1Error => ({ ...signupStep1Error, ["emailError"]: alerts.ENTER_VALID_EMAIL }))
      if (!focusSet) {
        refEmail.current.focus();
        focusSet = true;
      }
    }


    let passwordErrorMsg = passwordValidation(signupStep1Data.pwd, '');
    if (passwordErrorMsg !== "") {
      isValid = false;
      setSignupStep1Error(signupStep1Error => ({ ...signupStep1Error, ["pwdError"]: passwordErrorMsg }))
      if (!focusSet) {
        refPassword.current.focus();
        focusSet = true;
      }
    }

    if (signupStep1Data.confirmPwd === "") {
      isValid = false;
      setSignupStep1Error(signupStep1Error => ({ ...signupStep1Error, ["confirmPwdError"]: alerts.ENTER_CONFIRM_PASSWORD }))
      if (!focusSet) {
        refConfirmPassword.current.focus();
        focusSet = true;
      }
    } else if (signupStep1Data.confirmPwd !== signupStep1Data.pwd) {
      isValid = false;
      setSignupStep1Error(signupStep1Error => ({ ...signupStep1Error, ["confirmPwdError"]: alerts.PASSWORD_DOSENT_MATCH }))
      if (!focusSet) {
        refConfirmPassword.current.focus();
        focusSet = true;
      }
    }
    return isValid;
  };


  //validation of step 2 registration
  const step2Validation = () => {
    let isValid = true;
    setSignupStep2Error("")
    if (!signupStep2Data.email_otp && !accountVerified) {
      setSignupStep2Error(signupStep2Error => ({ ...signupStep2Error, ["emailOtpError"]: alerts.ENTER_OTP }))
      isValid = false
    }

    return isValid;
  }

  //validation of step 3 registration

  const step3Validation = () => {
    let isValid = true;
    let focusSet = false;
    let phoneNumberErrMsg = (signupStep3Data.business_number !== "") ? phoneNumberValidation(signupStep3Data.business_number) : "";

    setSignupStep3Error(intitalFormErrorSignUpStep3)

    //     business_countryError
    if (signupStep3Data.business_name.trim() === "") {
      isValid = false;
      setSignupStep3Error(signupStep3Error => ({ ...signupStep3Error, ["business_nameError"]: alerts.ENTER_BUSINESS_NAME }))
      if (!focusSet) {
        refBusinessName.current.focus();
        focusSet = true;
      }
    }

    if (phoneNumberErrMsg !== "") {
      isValid = false;
      setSignupStep3Error(signupStep3Error => ({ ...signupStep3Error, ["business_numberError"]: phoneNumberErrMsg }))
      if (!focusSet) {
        refBusinessPhoneNumber.current.focus();
        focusSet = true;
      }
    }


    if (signupStep3Data.business_address.trim() === "") {
      isValid = false;
      setSignupStep3Error(signupStep3Error => ({ ...signupStep3Error, ["business_addressError"]: alerts.ENTER_ADDRESS }))
      if (!focusSet) {
        // refAddress.current.focus();
        focusSet = true;
      }
    }

    if (signupStep3Data.business_city.trim() === "") {
      isValid = false;
      setSignupStep3Error(signupStep3Error => ({ ...signupStep3Error, ["business_cityError"]: alerts.ENTER_CITY }))
      if (!focusSet) {
        refCity.current.focus();
        focusSet = true;
      }
    }

    if (signupStep3Data.business_state.trim() === "") {
      isValid = false;
      setSignupStep3Error(signupStep3Error => ({ ...signupStep3Error, ["business_stateError"]: alerts.ENTER_STATE }))
      if (!focusSet) {
        ref_State.current.focus();
        focusSet = true;
      }
    }

    if (signupStep3Data.business_address.trim() !== "" && signupStep3Data.latitude === "" || signupStep3Data.longitude === "") {
      isValid = false;
      // toast.error("Some error has occurred please try again");
    }
    if (signupStep3Data.business_country.trim() === "") {
      isValid = false;
      setSignupStep3Error(signupStep3Error => ({ ...signupStep3Error, ["business_countryError"]: alerts.ENTER_COUNTRY }))
      if (!focusSet) {
        refCountry.current.focus();
        focusSet = true;
      }
    }
    if (termsAndConditionsCheckboxValue === false) {
      isValid = false;
      setToastErrorMessage("Agree to the Terms and Conditions to continue.")
    }
    return isValid;
  };

  const handleSignupStep1Change = (event) => {

    let inputValue = event.target.value;
    if (event.target.name === 'first_name' || event.target.name === 'last_name') {
      inputValue = inputValue.charAt(0).toUpperCase() + inputValue.slice(1);
      setSignupStep1Data(signupStep1Data => ({ ...signupStep1Data, [event.target.name]: inputValue }))
    } else if (event.target.name === 'phone_number') {
      if ((/^[-\d\(\)\[\]\s]+$/.test(event.target.value))) {
        const numbers = event.target.value.match(/\d+/g);
        const fullNumber = numbers.join("");
        setSignupStep1Data(signupStep1Data => ({ ...signupStep1Data, [event.target.name]: fullNumber }))
      } else if (!(/\D/.test(event.target.value))) {
        setSignupStep1Data(signupStep1Data => ({ ...signupStep1Data, [event.target.name]: event.target.value }))
      }
    }
    else {
      setSignupStep1Data(signupStep1Data => ({ ...signupStep1Data, [event.target.name]: event.target.value }))
    }
  };


  // change in for step 2 signUp

  const handleSignupStep2EmailOtpChange = (newotp) => {
    setSignupStep2Data(signupStep2Data => ({ ...signupStep2Data, ["email_otp"]: newotp }))
  }
  const handleSignupStep2PhoneOtpChange = (newotp) => {
    setSignupStep2Data(signupStep2Data => ({ ...signupStep2Data, ["mobile_otp"]: newotp }))
  }


  //change in for step 3 signUp
  const handleSignupStep3Change = (event) => {
    if (event.target.name === 'zip_code') {
      if (!(/\D/.test(event.target.value))) {
        setSignupStep3Data(signupStep3Data => ({ ...signupStep3Data, [event.target.name]: event.target.value }))
      }

    } else if (event.target.name === 'business_number') {
      if ((/^[-\d\(\)\[\]\s]+$/.test(event.target.value))) {
        const numbers = event.target.value.match(/\d+/g);
        const fullNumber = numbers.join("");
        setSignupStep3Data(signupStep3Data => ({ ...signupStep3Data, [event.target.name]: fullNumber }))
      } else if (!(/\D/.test(event.target.value))) {
        setSignupStep3Data(signupStep3Data => ({ ...signupStep3Data, [event.target.name]: event.target.value }))
      }
    }
    else {
      setSignupStep3Data(signupStep3Data => ({ ...signupStep3Data, [event.target.name]: event.target.value }))
      setSignupStep3Data(signupStep3Data => ({ ...signupStep3Data, ["email"]: signupStep1Data.email }))
    }
  };

  const handleTermsAndConditionsCheckbox = (e) => {
    setTermsAndConditionsCheckboxError("")
    setTermsAndConditionsCheckboxValue(e.target.checked);
  };

  //Mask Email address
  const maskEmail = (email) => {
    let split = email.split('@')
    return email.substr(0, 1) + '*******' + email.substr(split[0].length - 1, 1) + "@" + split[1]
  }
  //Mask Phone Number 
  const maskPhoneNumber = (phone) => {
    let subNum = phone.toString().substring(10, 7)
    subNum = '*******' + subNum
    return subNum
  }

  //Step 1 Submit
  const step1Form = () => {
    let isValid = step1Validation();
    if (isValid) {
      setSignupStep1Clicked(true);
      setSignUpFormData({
        first_name: signupStep1Data?.first_name,
        last_name: signupStep1Data?.last_name,
        phone_number: signupStep1Data?.phone_number,
        email: signupStep1Data?.email,
        pwd: signupStep1Data?.pwd,
        confirmPwd: signupStep1Data?.confirmPwd,
        step: 1,
        latitude: "",
        longitude: ""
      })
    }
  }


  //Step 2 Submit
  const step2Form = () => {
    let isValid = step2Validation();
    if (accountVerified && signupStep2Data.mobile_otp === "") {
      setTimeout(() => {
        navigate("/register/step/3/" + vendorId);
      }, 100)
    } else if (isValid) {
      setSignupStep2Clicked(true);
      setSignUpFormData(signupStep2Data)
    }
  }

  //Step 3 Submit
  const step3Form = () => {
    let isValid = step3Validation();

    if (isValid) {
      setSignupStep3Clicked(true);
      setSignUpFormData(signupStep3Data)
    }
  }

  //send OTP 
  const sendOtp = (entryOtpTypeValue) => {
    if (entryOtpTypeValue === 'email') {
      setSignupStep2Data(signupStep2Data => ({ ...signupStep2Data, ["email_otp"]: "" }))
      setOtpDetails({ "email": signupStep1Data.email, "mob_number": "" })
      setSentEmailOtpClicked(true)
    }
    else if (entryOtpTypeValue === 'phone') {
      setOtpDetails({ "email": "", "mob_number": signupStep1Data.phone_number })
      setSignupStep2Data(signupStep2Data => ({ ...signupStep2Data, ["mobile_otp"]: "" }))
      setSentPhoneOtpClicked(true)
    }
  }

  const resetOtpResponse = (resetType) => {
    if (resetType)
      dispatch({ type: resetType, apiStatus: false, apiErrorCode: 0, apiResponse: {}, apiMessage: "" });
  }

  const onSubmit = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault()
    }
  }

  const redirectToPreviousStep = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault()
    }
    // setGoSteps(stepNo)
    setLoadMap(false)
    handleClose()
    setSentEmailOtpClicked(false)
    setSentPhoneOtpClicked(false)
    setSendEmailOtpExpired(false)
    setSendPhoneOtpExpired(false)
    if (newStep === 0)
      navigate("/register/step/1" + '/' + vendorId);
    else if (newStep > 0) {
      if (newStep === constants.STATUS_1) {
        setSignupStep2Data(intitalValuesSignUpStep2)
        setAccountVerified(false)
        setDisableOtpBtnEmail(false)
        setAccountVerifiedMobile(false)
        setDisableOtpBtnPhone(false)
      }
      navigate("/register/step/" + (newStep) + '/' + vendorId);
    }

  }
  const redirectRegisterStep = (newStep) => {
    setShowModal(true)
    setNewStep(parseInt(newStep))
  }

  // load google map script
  // const loadGoogleMapScript = (callback) => {

  //   if (typeof window.google === 'object' && typeof window.google.maps === 'object') {
  //     callback();

  //   } else {
  //     const googleMapScript = document.createElement("script");
  //     googleMapScript.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAP_ACCESS_KEY}&callback=Function.prototype&libraries=places`;
  //     window.document.body.appendChild(googleMapScript);
  //     googleMapScript.addEventListener("load", callback);
  //     setLoadMap(true)
  //   }
  // }

  const phoneFormat = (value, type) => {
    let formattedPhoneNumber = value;
    if (value.length <= 3) {
      formattedPhoneNumber = value;
    } else if (value.length > 3 && value.length <= 6) {
      formattedPhoneNumber = `(${value.slice(0, 3)}) ${value.slice(3, 6)}`;
    } else if (value.length > 6) {
      formattedPhoneNumber = `(${value.slice(0, 3)}) ${value.slice(
        3,
        6
      )}-${value.slice(6, 10)}`;
    }
    if (type === constants.STATUS_1) {
      setBasicInfoPhoneNumber(formattedPhoneNumber)
    } else
      setBusinessInfoPhoneNumber(formattedPhoneNumber);
  };


  const handleClose = () => setShowModal(false);
  const handleTermsAndConditionClose = () => setShowTermsAndConditionModal(false);

  const resetAddressBoxes = () => {
    setPlace('')
    setSignupStep3Data(signupStep3Data => ({ ...signupStep3Data, business_address: "", business_address2: "", business_city: "", business_state: "", zip_code: "", country: "" }))
  }

  const handleBackStep3 = () => {
    redirectRegisterStep(2)
    pendingVendorRegistration(vendorId)
  }

  const handleDiplayAddress = (event) => {
    setSignupStep3Data(signupStep3Data => ({ ...signupStep3Data, ['business_address']: event }))
  }
  return (
    <div className='register-wrap'>
      <Stepper activeStep={goSteps} styleConfig={StepperStyle} hideConnectors={true} className='stepper-wrap'>
        <Step onClick={() => redirectRegisterStep(0)} label={Strings.Register.BasicInfo.Step} />
        <Step onClick={() => redirectRegisterStep(2)} label={Strings.Register.Verification.Step} />
        <Step onClick={() => redirectRegisterStep(3)} label={Strings.Register.BusinessInfo.Step} />
      </Stepper>

      {goSteps === 0 && (
        <div className='form-wrap'>

          <Row>
            <Col lg='6'>
              <Input
                label={Strings.Register.BasicInfo.Label1}
                type='text' id='firstname'
                name='first_name'
                maxLength='25'
                value={signupStep1Data.first_name}
                onChange={handleSignupStep1Change}
                txtRef={refFirstname}
                errorMessage={signupStep1Error.first_nameError}
                onKeyDown={onSubmit}
              />
            </Col>
            <Col lg='6'>
              <Input label={Strings.Register.BasicInfo.Label2}
                type='text'
                id='lastname'
                name='last_name'
                maxLength='25'
                value={signupStep1Data.last_name}
                onChange={handleSignupStep1Change}
                txtRef={refLastname}
                errorMessage={signupStep1Error.last_nameError}
                onKeyDown={onSubmit}
              />
            </Col>
            <Col lg='6'>
              <Input
                label={Strings.Register.BasicInfo.Label3}
                type='text'
                id='phone'
                name='phone_number'
                maxLength='14'
                value={basicInfoPhoneNumber}
                onChange={handleSignupStep1Change}
                onBlur={handleSignupStep1Change}
                txtRef={refPhone}
                errorMessage={signupStep1Error.phone_numberError}
                onKeyDown={onSubmit}
              />
            </Col>
            <Col lg='6'>
              <Input
                label={Strings.Register.BasicInfo.Label4}
                type='email'
                id='email'
                name='email'
                maxLength={50}
                value={signupStep1Data.email}
                onChange={handleSignupStep1Change}
                txtRef={refEmail}
                errorMessage={signupStep1Error.emailError}
                onKeyDown={onSubmit}
              />
            </Col>
            <Col lg='6' className='position-relative'>
              <Input
                label={Strings.Register.BasicInfo.Label5}
                type={viewPassword ? 'text' : 'password'}
                id='password'
                name='pwd'
                maxLength={15}
                value={signupStep1Data.pwd}
                onChange={handleSignupStep1Change}
                txtRef={refPassword}
                errorMessage={signupStep1Error.pwdError}
                onKeyDown={onSubmit}
              />
              <span className='view-password position-absolute'><img src={signupStep1Data.pwd !== "" ? viewpass : hidepass} onClick={() => setViewPassword(!viewPassword)} /></span>
            </Col>
            <Col lg='6' className='position-relative'>
              <Input
                label={Strings.Register.BasicInfo.Label6}
                type={viewConfirmPassword ? 'text' : 'password'}
                id='password'
                name='confirmPwd'
                maxLength={15}
                value={signupStep1Data.confirmPwd}
                onChange={handleSignupStep1Change}
                txtRef={refConfirmPassword}
                errorMessage={signupStep1Error.confirmPwdError}
                onKeyDown={onSubmit}
              />
              <span className='view-password position-absolute'><img src={signupStep1Data.confirmPwd !== "" ? viewpass : hidepass} onClick={() => setViewConfirmPassword(!viewConfirmPassword)} /></span>
            </Col>
          </Row>
          <Row className='btn-wrap'>
            <Col md='6'>
              <Button variant='primary' disabled={signupStep1Clicked} onClick={step1Form}>{signupStep1Clicked ? <div class="spinner-border" style={{ width: '1.5rem', height: '1.5rem' }} role="status" /> : Strings.Register.BasicInfo.Btn}</Button>
            </Col>
            <Col md='6' className='align-items-md-center justify-content-md-end d-flex login-info'>
              {Strings.Register.BasicInfo.Info1}&nbsp;<Link to="/">{Strings.Register.BasicInfo.Info2}</Link>
            </Col>
          </Row>
        </div>
      )}
      {goSteps === constants.STATUS_1 && (

        <div className='form-wrap'>


          <p>{Strings.Register.Verification.Ppart1}<br />

          </p>
          <OtpForm
            icon={<OtpMail />}
            label={Strings.Register.Verification.Label1}
            radiolabel={maskEmail(signupStep1Data.email)}
            button={!sendEmailOtpExpired ? Strings.Register.Verification.Btn1 : Strings.Register.Verification.Btn4}
            buttonClick={() => sendOtp("email")}
            name="email_otp"
            otpField="emailOtp"
            onOtpValueChange={handleSignupStep2EmailOtpChange}
            otpValue={signupStep2Data.email_otp}
            maxLength={4}
            autocomplete='off'
            status={accountVerified}
            buttonDisable={disableOtpBtnEmail}
            errorMessage={signupStep2Error.emailOtpError}
            readOnly={!sentEmailOtpClicked}
            inputType="number"
            autoFocus="true"
          />
          <OtpForm
            icon={<OtpPhone />}
            label={Strings.Register.Verification.Label2}
            radiolabel={maskPhoneNumber(signupStep1Data.phone_number)}
            button={!sendPhoneOtpExpired ? Strings.Register.Verification.Btn1 : Strings.Register.Verification.Btn4}
            buttonClick={() => sendOtp("phone")}
            name="mobile_otp"
            otpField="mobileOtp"
            otpValue={signupStep2Data.mobile_otp}
            maxLength={4}
            autocomplete='off'
            onOtpValueChange={handleSignupStep2PhoneOtpChange}
            errorMessage={signupStep2Error.mobileOtpError}
            readOnly={!sentPhoneOtpClicked}
            status={accountVerifiedMobile}
            buttonDisable={disableOtpBtnPhone}
            inputType="number"
          />
          <Row className='btn-wrap'>
            <Col md='12'>
              <Button variant='outline-primary' onClick={() => redirectRegisterStep(1)}>{Strings.Register.Verification.Btn2}</Button>
              <Button variant='primary' onClick={step2Form}>{Strings.Register.Verification.Btn3}</Button>
            </Col>
          </Row>
        </div>
      )}
      {goSteps === constants.STATUS_2 && (
        <div className='form-wrap'>
          <Row>
            <Col lg='6'>
              <Input
                label={Strings.Register.BusinessInfo.Label1}
                type='text'
                id='businessname'
                name='business_name'
                maxLength={200}
                value={signupStep3Data.business_name}
                errorMessage={signupStep3Error.business_nameError}
                txtRef={refBusinessName}
                onChange={handleSignupStep3Change}
                onKeyDown={onSubmit}
              />
            </Col>
            <Col lg='6'>
              <Input
                label={Strings.Register.BusinessInfo.Label2}
                type='text'
                id='businessphone'
                name='business_number'
                maxLength="14"
                value={businessInfoPhoneNumber}
                errorMessage={signupStep3Error.business_numberError}
                txtRef={refBusinessPhoneNumber}
                onBlur={handleSignupStep3Change}
                onChange={handleSignupStep3Change}
                onKeyDown={onSubmit}
              />
            </Col>
            <Col lg='6' className='reg-address'>
              <label>{Strings.Register.BusinessInfo.Label3}</label>
              <Autocomplete
                apiKey={process.env.REACT_APP_GOOGLE_MAP_ACCESS_KEY}
                style={{ width: "100%"}}
                onPlaceSelected={(place) => {
                  setSignupStep3Data(signupStep3Data => ({ ...signupStep3Data, business_address: "", business_address2: "", business_city: "", business_state: "", zip_code: "", country: "" }))
                  setPlace(place.address_components)
                  setLat(place.geometry.location.lat())
                  setLong(place.geometry.location.lng())

                }}
                onChange={(e) => handleDiplayAddress(e.target.value)}
                value={signupStep3Data.business_address}
                options={{
                  fields: ['formatted_address', 'address_components', 'geometry', 'name'],
                  strictBounds: false,
                  types: ['geocode', 'establishment'],
                  componentRestrictions: { country: 'us' }
                }}
              />
              {signupStep3Data.business_address && <button type="button" className="btn-close position-absolute address-clear" aria-label="Close" onClick={resetAddressBoxes} />}
              {signupStep3Error.business_addressError && signupStep3Error.business_addressError !== '' ? <Form.Text style={{color:"red"}}>
                {signupStep3Error.business_addressError}
              </Form.Text> : ""}
            </Col>
            <Col lg='6'>
              <Input
                label={Strings.Register.BusinessInfo.Label4}
                type='text'
                id='address2'
                name='business_address2'
                value={signupStep3Data.business_address2}
                errorMessage={signupStep3Error.business_address2Error[1]}
                txtRef={refAddress2}
                // onChange={handleSignupStep3Change}
                onKeyDown={onSubmit}
              />
            </Col>
            <Col lg='6'>
              <Input
                label={Strings.Register.BusinessInfo.Label5}
                type='text'
                id='city'
                name='business_city'
                maxLength={100}
                value={signupStep3Data.business_city}
                errorMessage={signupStep3Error.business_cityError}
                txtRef={refCity}
                // onChange={handleSignupStep3Change}
                onKeyDown={onSubmit}
              />
            </Col>

            <Col lg='6'>
              <Input
                label={Strings.Register.BusinessInfo.Label6}
                type='text'
                id='state'
                name='business_state'
                maxLength={200}
                value={signupStep3Data.business_state}
                errorMessage={signupStep3Error.business_stateError}
                txtRef={ref_State}
                // onChange={handleSignupStep3Change}
                onKeyDown={onSubmit}
              />
            </Col>
            <Col lg='6'>
              <Input
                label={Strings.Register.BusinessInfo.Label7}
                type='text'
                id='zip'
                name='zip_code'
                maxLength={constants.STATUS_5}
                value={signupStep3Data.zip_code}
                errorMessage={signupStep3Error.zip_codeError}
                txtRef={refZipCode}
                onBlur={handleSignupStep3Change}
                // onChange={handleSignupStep3Change}
                onKeyDown={onSubmit}
              />
            </Col>
            <Col lg='6'>
              <Input
                label={Strings.Register.BusinessInfo.Label8}
                type='text'
                id='country'
                name='business_country'
                value={signupStep3Data.business_country}
                errorMessage={signupStep3Error.business_countryError}
                // status={true}
                txtRef={refCountry}
              //onChange={handleSignupStep3Change}
              />
            </Col>
          </Row>
          <Row>
            <Col lg='12 agree'>
              <div className='d-flex'>
                <Form.Check
                  aria-label="option 1"
                  onChange={handleTermsAndConditionsCheckbox}
                />

                <span>{Strings.Register.BusinessInfo.Agree1}&nbsp;<Link to="" onClick={() => setShowTermsAndConditionModal(true)}>{Strings.Register.BusinessInfo.Agree2}</Link>*</span>
              </div>
              {termsAndConditionsCheckboxError && <span className='error d-block'>{termsAndConditionsCheckboxError}</span>}
            </Col>
          </Row>
          <Row className='btn-wrap'>
            <Col md='12'>
              <Button variant='outline-primary' onClick={handleBackStep3}>{Strings.Register.BusinessInfo.Btn1}</Button>
              <Button variant='primary' onClick={step3Form}>{Strings.Register.BusinessInfo.Btn2}</Button>
            </Col>
          </Row>
        </div>
      )}


      <Modal show={showModal} onHide={handleClose} size="l" centered backdrop="static">
        <Modal.Body >
          <h5 className="text-center">{alerts.CONFIRM_PREVIOUS_PAGE}</h5>
        </Modal.Body>
        <Modal.Footer className='justify-content-center'>
          <Button variant="outline-primary" onClick={handleClose}>{Strings.Register.Modal.Btn1}</Button>
          <Button variant="primary"
            onClick={redirectToPreviousStep}>{Strings.Register.Modal.Btn2}</Button>
        </Modal.Footer>
      </Modal>


      <Modal show={showTermsAndConditionModal} onHide={handleTermsAndConditionClose} size="xl" centered>
        <Modal.Header closeButton>
          <Modal.Title>{Strings.Terms.Title}</Modal.Title>
        </Modal.Header>
        <Modal.Body className='terms'>
          <TermsConditions />
        </Modal.Body>
      </Modal>
    </div>
  )
};

export default RegisterForms;