import React from 'react'
import './Messages.scss'
import { Button, Col } from 'react-bootstrap';
import Strings from "../assets/strings/Strings.json";

const Messages = (props) => {
  
  const MessageOption = props.onClickMessageOption1
  
  return (
    <Col className={'message-wrap ' + props.className}>
      <div className='profile-dtls d-flex align-items-center'>
        <div className='prof-avatar'>
          <img src={props.profileImage} alt="" />
        </div>
        <span>{props.userName}</span>
      </div>
      <div className='message-inner'>
        <p>{props.questionText}</p>
        <div className='message-selector clearfix'>
          <span className={props.selectedMsg === 1 && props.chatId === props.sendEnable ? 'active' : ''} onClick={() => MessageOption(props.chatId,1)}>{props.answerOption1}</span>
          <span className={props.selectedMsg === 2 && props.chatId === props.sendEnable? 'active' : ''} onClick={() => MessageOption(props.chatId,2)}>{props.answerOption2}</span>
          {(props.answerOption3 || props.answerOption4) &&
          <>
          <br></br>
          <br></br>
          {props.answerOption3 &&
          <span className={props.selectedMsg === 3 && props.chatId === props.sendEnable ? 'active' : ''} onClick={() => MessageOption(props.chatId,3)}>{props.answerOption3}</span>
          }
          {props.answerOption4 &&
          <span className={props.selectedMsg === 4 && props.chatId === props.sendEnable? 'active' : ''} onClick={() => MessageOption(props.chatId,4)}>{props.answerOption4}</span>
          }
          </>
          }
          <Button disabled={props.chatId !== props.sendEnable} variant='primary' onClick={props.onClickBtn}>{Strings.Messages.Btn}</Button>
        </div>
      </div>
    </Col>
  )
};

export default Messages;

