import React from "react";
import { Col, Row } from "react-bootstrap";
import Input from "./Input";
import CustTextArea from "./CustTextArea";
import Strings from "../assets/strings/Strings.json";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import WebLink from "./WebLink";
import * as alerts from "../utils/Messages";

const AdsJobs = (props) => {
  const handleChange = props.handleChange;
  const addProductData = props.addProductData;
  const addProductError = props.addProductError;
  const handleDateChange = props.handleDateChange;
  const edit = props.enableEdit;
  const date = props.date;
  return (
    <>
      <Row>
        <Col lg="10" xl="8">
          <Input
            label={Strings.AddProduct.Classifieds.Label}
            id="prod_name"
            name="prod_name"
            maxLength={100}
            value={addProductData.prod_name}
            errorMessage={addProductError.prod_nameError}
            onChange={handleChange}
            readOnly={edit}
          />
        </Col>
      </Row>
      <Row>
        <Col lg="5" xl="4">
          <div className="date-picker mb-20">
            <label>{Strings.AddProduct.Classifieds.Label1}</label>
            <DatePicker
              minDate={new Date(2000, 0, 1)}
              disabled={edit}
              onKeyDown={(e) => {
                e.preventDefault();
              }}
              maxDate={date.valid_date}
              selected={date.posted_date}
              onChange={(e) => {
                handleDateChange(e, 1);
              }}
            />
          </div>
        </Col>
        <Col lg="5" xl="4">
          <div className="date-picker mb-20">
            <label>{Strings.AddProduct.Classifieds.Label2}</label>
            <DatePicker
              minDate={new Date()}
              disabled={edit}
              onKeyDown={(e) => {
                e.preventDefault();
              }}
              selected={date.valid_date}
              onChange={(e) => {
                handleDateChange(e, 2);
              }}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg="5" xl="4">
          <CustTextArea
            label={Strings.AddProduct.Classifieds.Label3}
            id="prod_description"
            name="prod_description"
            maxLength={500}
            value={addProductData.prod_description}
            errorMessage={addProductError.prod_descriptionError}
            onChange={handleChange}
            readOnly={edit}
            subText={Strings.AddProduct.DescriptionSub}
          />

        </Col>
        <Col lg="5" xl="4">
          <CustTextArea
            label={Strings.AddProduct.Classifieds.Label5}
            id="whats_great"
            name="whats_great"
            maxLength={500}
            value={addProductData.whats_great}
            //errorMessage={addProductError.prod_descriptionError}
            onChange={handleChange}
            readOnly={edit}
            subText={Strings.AddProduct.WhatsGreatSub}
          />
        </Col>
      </Row>
      <Row>
        <Col lg="10" xl="8">
          <h5>{Strings.AddProduct.Classifieds.WebsiteTitle}</h5>
          <p className="mb-0">{alerts.WEBSITE_LINK}</p>
          <WebLink
            label={Strings.AddProduct.Classifieds.Label4}
            id="website_link"
            maxLength={100}
            name="website_link"
            value={
              addProductData.website_link === null
                ? ""
                : addProductData.website_link
            }
            errorMessage={addProductError.website_linkError}
            onChange={handleChange}
            readOnly={edit}
          />
        </Col>
      </Row>
      <Row>
        <Col lg="10" xl="8">
          {props.type === "productDetails" ? (
            <h5>{Strings.ProductDetail.Title2}</h5>
          ) : (
            <>
              <h5>{Strings.AddProduct.Heading2}</h5>
              <p>{Strings.AddProduct.Label5}</p>
            </>
          )}
          <Input
            label={Strings.AddProduct.Label3}
            id="video_link"
            type="text"
            name="video_link"
            maxLength={100}
            value={addProductData.video_link}
            onChange={handleChange}
            readOnly={edit}
          />
        </Col>
      </Row>
    </>
  );
};

export default AdsJobs;
