import React, { useState, useEffect } from 'react'
import './Product.scss'
import Delete from '../assets/img/icons/Delete';
import { Col, Form, Modal, Button } from 'react-bootstrap';
import View from '../assets/img/icons/View';
import { useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { resetResponse } from '../actions/VendorAction';
import { _deleteProduct } from '../actions/ProductAction'
import * as alerts from '../utils/Messages'
import { OverlayTrigger } from 'react-bootstrap';
import Tooltip from 'react-bootstrap/Tooltip';
import * as constants from '../utils/constants';
import * as enums from '../utils/Enum';

const Product = (props) => {
  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      View / Edit Offer Details
    </Tooltip>
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // ------- call API from functions and response from redux start ---------
  // delete product start
  const deleteProducts = (productId) => dispatch(_deleteProduct(productId));
  // delete product ends

  const [show, setShow] = useState(false);
  const [productPrice, setProductPrice] = useState(0.00);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    setProductPrice(props && props.price ? (parseFloat(props.price)).toFixed(2) : '0.00')
  }, [props])

  // calling delete product api function
  const handleSaveButton = () => {
    handleClose()
    deleteProducts({
      "prod_id": props.id,
    })
  }
  const handleView = () => {
    resetResponse(dispatch, "productDetailsId")
    navigate('/productdetails', { state: { id: props.id } })
    // window.location.reload()
  }
  const [expiryClass, setExpiryClass] = useState(null);
  const [expiryDate, setExpiryDate] = useState(false);

  useEffect(() => {
    let expiryClassCheck = false;
    const currentDate = new Date().toISOString().split("T")[0];
    const getDateOnly = (date) => {
      return date ? new Date(date).toISOString().split("T")[0] : null;
    };
    const postedDateOnly = getDateOnly(props?.posted_date);
    const validTillOnly = getDateOnly(props?.validTill);
    const validToOnly = getDateOnly(props?.valid_to);

    if (postedDateOnly && postedDateOnly < currentDate && props?.categoryId === enums.CategoryId.OutAndAbout) {
      setExpiryDate(true);
      expiryClassCheck = true;
    }
    if (validTillOnly && validTillOnly < currentDate && props?.categoryId === enums.CategoryId.Classifieds) {
      setExpiryDate(true);
      expiryClassCheck = true;
    }
    if (validToOnly && validToOnly < currentDate && props?.categoryId === enums.CategoryId.Coupons) {
      setExpiryDate(true);
      expiryClassCheck = true;
    }
    if (expiryClassCheck === constants.STATUS_TRUE) {
      setExpiryClass({ classname: "product position-relative expired" });
    } else if (expiryClassCheck === constants.STATUS_TRUE || props?.quantity <= constants.STATUS_0) {
      setExpiryClass({ classname: "product position-relative expired no-qty" });
    } else {
      setExpiryClass({ classname: "product position-relative" });
    }

  }, [props]);



  return (
    <Col md='6' lg='4' xl='3' className='product-wrap'>
      <div className={expiryClass?.classname}>

        {/* <div className='product position-relative expired no-qty'> */}
        <Form.Check aria-label="option 1" className='check-box position-absolute'
          hidden={((expiryDate == constants.STATUS_TRUE) || (props?.quantity <= constants.STATUS_0)) ? true : false} value={props?.id} onChange={props?.handleCheckBoxChange} />

        {props.isNationWide ? <div className='nation-wide position-absolute'>Nationwide</div> : ""}
        {expiryDate == constants.STATUS_TRUE ? <div className='expired-badge position-absolute'>Expired</div> : ""}
        <div className='img-wrap position-relative'>
          <img src={props.img} />
          <div className='position-absolute btn-wrap'>
            <OverlayTrigger
              placement="bottom"
              delay={{ show: 250, hide: 400 }}
              overlay={renderTooltip}
            >
              <a onClick={handleView}><View /></a>
            </OverlayTrigger>
            <OverlayTrigger
              placement="bottom"
              delay={{ show: 250, hide: 400 }}
              overlay={<Tooltip id="button-tooltip" {...props}>
                Delete Offer
              </Tooltip>}
            >
              <a onClick={handleShow}><Delete /></a>
            </OverlayTrigger>
          </div>
        </div>
        <span className='title d-block'>
          {props.title}
        </span>
        <div className='bottom-wrap d-flex justify-content-between align-items-center'>
          <span className='price text-center'>${productPrice}</span>
          <span className='qty'>Qty:{props.quantity}</span>
        </div>
        <div className='overlay'></div>
      </div>

      <Modal show={show} onHide={handleClose} size="l" centered backdrop="static">
        <Modal.Body >
          <h5 className="text-center">{props.productType && props.productType == 2 ? alerts.PRODUCT_DELETE : alerts.PRODUCT_DELETE_CURRENT_PRODUCT}</h5>
        </Modal.Body>
        <Modal.Footer className='justify-content-center' >
          <Button variant="outline-primary" onClick={handleClose}>Cancel</Button>
          <Button variant="primary" onClick={handleSaveButton}>OK</Button>
        </Modal.Footer>
      </Modal>
    </Col>
  )
};

export default Product;

