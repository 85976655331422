import React, { useState, useEffect } from 'react'
import { Button, Form } from 'react-bootstrap';
import Search from '../assets/img/icons/Search';
import Select from 'react-select';
import './SearchSelect.scss'
import ClearSearch from '../assets/img/icons/Close';

const SearchSelect = (props) => {
  const colourStyles = {
    option: (styles, { isSelected }) => ({
      ...styles,
      background: isSelected
        ? '#89CB08'
        : '#ffffff',
      zIndex: 1,
    }),
    menu: base => ({
      ...base,
      zIndex: 100,
    }),
  }

  const [dropdownValue, setDropdownValue] = useState(props.options[0])

  const [prevListType, setPrevListType] = useState("")
  const [searchValues, setSearchValues] = useState(
    {
      searchText: "",
      filterValue: ""
    }
  )
  const [searchFlag, setSearchFlag] = useState(false)


  const searchTextChange = (event) => {
    setSearchFlag(true)
    setSearchValues(searchValues => ({ ...searchValues, searchText: event.target.value }))
  }

  const clearSearchData = (event) => {
    setSearchFlag(false)
    setSearchValues(searchValues => ({ ...searchValues, searchText: "", filterValue: 0 }))
    props.onClick("")
  }

  useEffect(() => {
    if (props.listType) {
      if (props.listType !== prevListType) {
        setDropdownValue(props.options[0])
        setSearchValues(searchValues => ({ ...searchValues, searchText: "", filterValue: 0 }))
        setPrevListType(props.listType)
      }
    }
  }, [props.listType, props.vendorDashboardParams])

  useEffect(() => {
    if (searchFlag && searchValues.searchText === "") {
      setSearchValues(searchValues => ({ ...searchValues, searchText: "" }))
      props.onClick("")
    }
  }, [searchValues.searchText])

  return (
    <div className='search-group d-flex position-relative'>
      <Select
        classNamePrefix='filter'
        placeholder={props.placeholder}
        styles={colourStyles}
        value={dropdownValue}
        options={props.options}
        onChange={(e) => (setDropdownValue(e), setSearchValues(searchValues => ({ ...searchValues, filterValue: e.value })))} />

      <Form.Control
        type="search"
        id="search"
        placeholder='Search'
        value={searchValues.searchText}
        onChange={searchTextChange}
      />
      {searchValues.searchText !== '' ? <button className='clear-search position-absolute' onClick={clearSearchData}><ClearSearch /></button> : ""}
      <Button variant='light' className='search position-absolute d-flex align-items-center justify-content-center' onClick={() => props.onClick(searchValues.searchText, searchValues.filterValue)} ><Search /></Button>
    </div>
  )
};


export default SearchSelect;

