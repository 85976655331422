import React, { useState } from 'react'
const EditProduct = (props) => {
  return (
<svg xmlns="http://www.w3.org/2000/svg" width="22.311" height="22.121" viewBox="0 0 22.311 22.121">
  <g id="edit" transform="translate(-1 -0.879)">
    <path id="Path_31482" data-name="Path 31482" d="M11,4H4A2,2,0,0,0,2,6V20a2,2,0,0,0,2,2H18a2,2,0,0,0,2-2V13" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
    <path id="Path_31483" data-name="Path 31483" d="M18.5,2.5a2.121,2.121,0,0,1,3,3L12,15,8,16l1-4Z" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
  </g>
</svg>
  )
};
export default EditProduct;

