import React, { useState } from 'react'

const OtpMail = (props) => {
  return (
<svg xmlns="http://www.w3.org/2000/svg" width="22" height="18" viewBox="0 0 22 18">
  <g id="mail" transform="translate(-1 -3)">
    <path id="Path_35" data-name="Path 35" d="M4,4H20a2.006,2.006,0,0,1,2,2V18a2.006,2.006,0,0,1-2,2H4a2.006,2.006,0,0,1-2-2V6A2.006,2.006,0,0,1,4,4Z" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
    <path id="Path_36" data-name="Path 36" d="M16.286,6,9.143,11,2,6" transform="translate(2.857 1)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
  </g>
</svg>

  )
};
export default OtpMail;

