import React from "react";
import { Col, Form } from "react-bootstrap";
import Plus from "../assets/img/icons/Plus";
import "./InputFile.scss";
import Delete from "../assets/img/icons/Delete";
import NoImage from "../assets/img/no-image.svg";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import * as alerts from "../utils/Messages";
import PdfImage from '../assets/img/pdf-image.svg'
import DownloadIc from '../assets/img/icons/Download'
import  * as constants from '../utils/constants';

const FileUpload = (props) => {
  let productImages = props.productImages;
  const edit = props.enableEdit;
  const handleDefaultId = props.handleDefaultId;
  const defaultImageId = props.defaultImageId;
  const profile = props.profile;

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {alerts.DISPLAY_IMAGE}
    </Tooltip>
  );

    // handle download image.
    const downloadPdf = (image) => {
      const filename = image.split('.').pop();
      const a = document.createElement('a');
      a.href = image;
      a.target= "_blank"
      a.download = `download.${filename}`; // Set the desired filename
      a.style.display = 'none';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    };
  return (
    <Col className="d-flex upload-wrap">
      {productImages.length < 4 && !edit ? (
        <div className="custom-file-upload position-relative">
          <input
            type="file"
            name="file"
            onChange={(e) => props.uploadFile(e)}
          />
          <Plus />
        </div>
      ) : (
        ""
      )}
      {productImages.length === 0 && !props.loading ? (
        <div className="image-view position-relative">
          <img src={NoImage} alt="" />
        </div>
      ) : (
        ""
      )}
      {productImages &&
        productImages.map((data, key) => {
          return (
            <div className="image-view position-relative">
              <img 
              style={{
                cursor: data.file_type === constants.STATUS_3 ? 'pointer' : 'default', // Add cursor style for better user interaction
              }}
              src={data.file_type && data.file_type === constants.STATUS_3 ? PdfImage : data.image_path} alt="" 
              onClick={() =>  {
                if (data.file_type === constants.STATUS_3) {
                  downloadPdf(data.image_path);
                }
              }}
              />
              {data.image_id && !edit ? (
                <>
                  <span
                    className="delete position-absolute d-flex"
                    onClick={() => props.deleteImage(data.image_id)}
                  >
                    <Delete />
                  </span>                  
                </>
              ) : (
                ""
              )}
              {!profile && (
                <div className="check-wrap position-absolute">
                  <OverlayTrigger
                    placement="bottom"
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderTooltip}
                  >
                    <Form.Check
                      id="defaultImage"
                      disabled={edit}
                      checked={data.image_id === defaultImageId}
                      onChange={() => handleDefaultId(data.image_id)}
                      name="defaultImage"
                    />
                  </OverlayTrigger>
                </div>
              )}
            </div>
          );
        })}
      {props.loading && (
        <div className="image-view d-flex align-items-center justify-content-center">
          <span class="spinner-border" style={{color:"#94DA0A"}}></span>
        </div>
      )}
    </Col>
  );
};

export default FileUpload;
