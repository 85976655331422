import React from "react";
import { Col, Row, Form } from "react-bootstrap";
import Input from "./Input";
import CustTextArea from "./CustTextArea";
import Quantity from "./Quantity";
import Strings from "../assets/strings/Strings.json";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const ThingsDo = (props) => {
  const handleChange = props.handleChange;
  const addProductData = props.addProductData;
  const addProductError = props.addProductError;
  const handleQuantity = props.handleQuantity;
  const handleDateChange = props.handleDateChange;
  const edit = props.enableEdit;
  const date = props.date;
  return (
    <>
      <Row>
        <Col lg="5" xl="4">
          <Input
            label={Strings.AddProduct.ThingToDo.Label1}
            id="prod_name"
            name="prod_name"
            type="text"
            maxLength={100}
            value={addProductData.prod_name}
            errorMessage={addProductError.prod_nameError}
            onChange={handleChange}
            readOnly={edit}
          />
          <div className="date-picker mb-20">
            <label>{Strings.AddProduct.ThingToDo.Label5}</label>
            <DatePicker
              disabled={edit}
              timeInputLabel="Time:"
              showTimeInput
              minDate={new Date(2000, 0, 1)}
              dateFormat="MM/dd/yyyy  hh:mm a"
              selected={date.posted_date}
              onChange={(e) => {
                handleDateChange(e, 1);
              }}
              onKeyDown={(e) => {
                e.preventDefault();
              }}
            />
            {addProductError.posted_dateError && addProductError.posted_dateError !== '' ? <Form.Text style={{color:'red'}}>
              {addProductError.posted_dateError}
            </Form.Text> : ""}
          </div>
          <Col lg="12" className="price-icon">
            <Input
              label={Strings.AddProduct.ThingToDo.Label2}
              id="prod_price"
              name="prod_price"
              type="text"
              maxLength={10}
              value={addProductData.prod_price}
              onBlur={handleChange}
              errorMessage={addProductError.prod_priceError}
              onChange={handleChange}
              readOnly={edit}
              subText={Strings.AddProduct.PriceSub}
            />
          </Col>

          <Col lg="12">
            <Quantity
              onMinus={() => {
                handleQuantity(1);
              }}
              onPlus={() => {
                handleQuantity(2);
              }}
              label={Strings.AddProduct.ThingToDo.Label4}
              type="text"
              id="prod_qty"
              name="prod_qty"
              value={addProductData.prod_qty}
              errorMessage={addProductError.prod_qtyError}
              onChange={handleChange}
              maxLength={"6"}
              readOnly={edit}
              subText={Strings.AddProduct.QuantitySub}
            />
          </Col>
        </Col>
        <Col lg="5" xl="4" className="description">
          <CustTextArea
            label={Strings.AddProduct.ThingToDo.Label3}
            id="prod_description"
            name="prod_description"
            maxLength={500}
            value={addProductData.prod_description}
            errorMessage={addProductError.prod_descriptionError}
            onChange={handleChange}
            readOnly={edit}
            subText={Strings.AddProduct.DescriptionSub}
          />
          <CustTextArea
            label={Strings.AddProduct.ThingToDo.Label6}
            id="whats_great"
            name="whats_great"
            maxLength={500}
            value={addProductData.whats_great}
            // errorMessage={addProductError.prod_descriptionError}
            onChange={handleChange}
            readOnly={edit}
            subText={Strings.AddProduct.WhatsGreatSub}
          />
        </Col>
      </Row>
      {props.type === "productDetails" ? (
        <h5>{Strings.ProductDetail.Title2}</h5>
      ) : (
        <>
          <h5>{Strings.AddProduct.Heading2}</h5>
          <p>{Strings.AddProduct.Label5}</p>
        </>
      )}
      <Row>
        <Col lg="10" xl="8">
          <Input
            label={Strings.AddProduct.Label3}
            id="video_link"
            type="text"
            name="video_link"
            maxLength={100}
            value={addProductData.video_link}
            onChange={handleChange}
            readOnly={edit}
          />
        </Col>
      </Row>
    </>
  );
};

export default ThingsDo;
