/**
 * @file   src\actions\VendorAction.js
 * @brief  This file is responsible for vendor actions.
 * @date   November, 2022
 * @author ZCO Engineer
 * @copyright (c) 2022, ZCO
 */

import { VendorApiList, ApiUrls } from "../library/Apis";
// import { apiPost, apiPut, apiDelete, apiGet, apiEncryptedPost, apiEncryptedGet, apiEncryptedDelete, apiEncryptedPut, apiDecryptPost } from "../library/Requests";
import axios from '../library/Requests';
import  * as constants from '../utils/constants'
import { decryptData, encryptData } from "../library/Encryption";

const VendorActionType = {
    vendorRegister: 'vendorRegister',
    vendorProfile: 'vendorProfile',
    vendorEditBasicInfo: 'vendorEditBasicInfo',
    vendorEditBankInfo: 'vendorEditBankInfo',
    vendorEditBusinessInfo: 'vendorEditBusinessInfo',
    vendorSendOtp: 'vendorSendOtp',
    vendorImagePreSigned: 'vendorImagePreSigned',
    vendorBusinessImageUpload: 'vendorBusinessImageUpload',
    vendorDashboard: 'vendorDashboard',
    vendorCategoryList: 'vendorCategoryList',
    addProductImage: 'addProductImage',
    listProductMessageList: 'listProductMessageList',
    listProducts: 'listProducts',
    pendingVendorRegistration: 'pendingVendorRegistrationResponse',
    addSubscription: 'addSubscription',
    productMessages: 'productMessages',
    sendProductMessage: 'sendProductMessage',
    purchaseHistory: 'purchaseHistory',
    removeBusinessImage: 'removeBusinessImage',
    vendorNotifications: 'vendorNotifications',
    vendorViewNotification: 'vendorViewNotification',
    notificationAvailable: 'notificationAvailable',
    exportPurchaseHistory: 'exportPurchaseHistory',
    forgotPassword: 'forgotPassword',
    resetPassword: 'resetPassword',
    stripeVerify: 'stripeVerify',
    createSubscription: 'createSubscription',
    cancelSubscription: 'cancelSubscription',
    subscriptionDetails: 'subscriptionDetails',
    subscriptionHistory: 'subscriptionHistory',
    subscriptionUpgrade: 'subscriptionUpgrade',
    savePaymentCard: 'savePaymentCard',
    listPaymentCard: 'listPaymentCard',
    deletePaymentCard: 'deletePaymentCard',
    bankDetails: 'bankDetails',
    listSchedule: 'listSchedule',
    cancelSchedule: 'cancelSchedule',
    updatePassword: 'updatePassword',
    addProxy: 'addProxy',
    viewProxy: 'viewProxy',
    listProxy: 'listProxy',
    businessInfo: 'businessInfo',
    bankInfo: 'bankInfo',
    subscriptionInfo: 'subscriptionInfo',
    downgradeSubscription: 'downgradeSubscription',
    expireSubscription: 'expireSubscription',
    vendorRegisterStep2: 'vendorRegisterStep2',
    vendorRegisterStep3: 'vendorRegisterStep3',
    vendorSendMobileOtp: 'vendorSendMobileOtp',
    refundInfo: 'refundInfo',
    approveRefund: 'approveRefund',
    rejectRefund:'rejectRefund',
    deleteBankinfo:'deleteBankinfo',
    heatMapInfo:'heatMapInfo',
    removeSubscription: 'removeSubscription',
    promotionalCities: 'promotionalCities',
    vendorForgotEmail:'vendorForgotEmail',
    zctaData : 'zctaData',
    zctaBroadcastData : 'zctaBroadcastData',
    vendorCouponList:'vendorCouponList',
    vendorAddCoupon:'vendorAddCoupon',
    vendorDeleteCoupon:'vendorDeleteCoupon',
    vendorFeedBack: 'vendorFeedBack',
    SalesVsoffers:'SalesVsoffers',
    timevsWeekDaySales:'timevsWeekDaySales',
    listVendorAddress:'listVendorAddress',
    listShipmentCarriers:'listShipmentCarriers',
    listShipmentRates:'listShipmentRates',
    generateShipmentLabel:"generateShipmentLabel",
    shipmentDetails:"shipmentDetails",
    cancelShipmentLabel:"cancelShipmentLabel"
};


/**
* response
* @param {string} actionType 
* @param {string} apiStatus 
* @param {number} apiErrorCode 
* @param {json} apiResponse 
* @param {string} apiMessage 
* @returns json
*/

export function vendorResponse(actionType, apiStatus, apiErrorCode, apiResponse, apiMessage) {
    return {
        type: actionType, apiStatus, apiErrorCode, apiResponse, apiMessage
    };
}

/**
 * Register vendor
 * @param {json} params 
 * @returns json
 */

const _vendorRegistration = (params) => {
    return async (dispatch) => {
        await axios.post(ApiUrls.vendor + VendorApiList.registrationURL, params)
            .then((response) => {
                if (response) {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.vendorRegister,
                            true, response.error_code, response.data, response.response_code
                        ));
                } else {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.vendorRegister,
                            false, 100, [], 300
                        ));
                }
            })

    }
}
/**
 * vendor registration send otp for verification
 * @param {json} params 
 * @returns json
 */
const _sendOTP = (params) => {
    return async (dispatch) => {
        await axios.post(ApiUrls.vendor + VendorApiList.sendOTP, params)
            .then((response) => {
                if (response) {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.vendorSendOtp,
                            true, response.error_code, response.data, response.response_code
                        ));
                } else {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.vendorSendOtp,
                            false, 100, [], 300
                        ));
                }
            }) 

    }
}

/**
 * Profile vendor
 * @param {json} params 
 * @returns json
 */
//apiEncryptedGet
const _vendorProfile = () => {
    return async (dispatch) => {
        await axios.get(ApiUrls.vendor + VendorApiList.profileURL)
            .then((response) => {
                if (response) {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.vendorProfile,
                            true, response.error_code, response.data, response.response_code
                        ));
                } else {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.vendorProfile,
                            false, 100, [], 300
                        ));
                }
            })

    }
}

/**
 * Profile Basic vendor Edit
 * @param {json} params 
 * @returns json
 */

const _vendorProfileBasicEdit = (params) => {
    return async (dispatch) => {
        await axios.post(ApiUrls.vendor + VendorApiList.basicEditInfoURL, params)
            .then((response) => {
                if (response) {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.vendorEditBasicInfo,
                            true, response.error_code, response.data, response.response_code
                        ));
                } else {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.vendorEditBasicInfo,
                            false, 100, [], 300
                        ));
                }
            })

    }
}


/**
 * Profile Bank vendor Edit
 * @param {json} params 
 * @returns json
 */

const _vendorProfileBankEdit = (params) => {
    return async (dispatch) => {
        await axios.post(ApiUrls.vendor + VendorApiList.editBankInfoURL, params)
            .then((response) => {
                if (response) {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.vendorEditBankInfo,
                            true, response.error_code, response.data, response.response_code
                        ));
                } else {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.vendorEditBankInfo,
                            false, 100, [], 300
                        ));
                }
            })

    }
}


/**
 * Profile Business vendor Edit
 * @param {json} params 
 * @returns json
 */


const _vendorProfileBusinessEdit = (params) => {
    return async (dispatch) => {
        await axios.post(ApiUrls.vendor + VendorApiList.editBusinessInfoURL, params)
            .then((response) => {
                if (response) {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.vendorEditBusinessInfo,
                            true, response.error_code, response.data, response.response_code
                        ));
                } else {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.vendorEditBusinessInfo,
                            false, 100, [], 300
                        ));
                }
            })

    }
}


/**
 * Image PreSigned URL 
 * @param {json} params 
 * @returns json
 */

const _vendorImagePreSignedURL = (params) => {
    return async (dispatch) => {
        await axios.post(ApiUrls.vendor + VendorApiList.imagePreSignedURL, params)
            .then((response) => {
                if (response) {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.vendorImagePreSigned,
                            true, response.error_code, response.data, response.response_code
                        ));
                } else {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.vendorImagePreSigned,
                            false, 100, [], 300
                        ));
                }
            })

    }
}

/**
 * remove Business image 
 * @param {json} params 
 * @returns json
 */

const _removeBusinessImage = (params = {}) => {
    return async (dispatch) => {
        await axios.delete(ApiUrls.vendor + VendorApiList.removeBusinessImage, {data:params})
            .then((response) => {
                if (response) {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.removeBusinessImage,
                            true, response.error_code, response.data, response.response_code
                        ));
                } else {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.removeBusinessImage,
                            false, 100, [], 300
                        ));
                }
            })

    }
}


/**
 * Image PreSigned URL 
 * @param {json} params 
 * @returns json
 */

const _vendorBusinessImageUpload = (params) => {
    return async (dispatch) => {
        await axios.post(ApiUrls.vendor + VendorApiList.businessImageUploadURL, params)
            .then((response) => {
                if (response) {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.vendorBusinessImageUpload,
                            true, response.error_code, response.data, response.response_code
                        ));
                } else {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.vendorBusinessImageUpload,
                            false, 100, [], 300
                        ));
                }
            })

    }
}


/**
 * Vendor DashBoard  
 * @param {json} params 
 * @returns json
 */

const _vendorDashboard = (params) => {
    return async (dispatch) => {
        await axios.post(ApiUrls.vendor + VendorApiList.dashboardURL, params)
            .then((response) => {
                if (response) {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.vendorDashboard,
                            true, response.error_code, response.data, response.response_code
                        ));
                } else if (response && response.error_code  && response.error_code === constants.STATUS_400) {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.vendorDashboard,
                            true, response.error_code, [], response.response_code
                        ));
                } else {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.vendorDashboard,
                            false, 100, [], 300
                        ));
                }
            })

    }
}

/**
 * Vendor category list  
 * @param {json} params 
 * @returns json
 */

const _vendorCategoryList = () => {
    return async (dispatch) => {
        await axios.post(ApiUrls.vendor + VendorApiList.categoryListURL,{})
            .then((response) => {
                if (response) {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.vendorCategoryList,
                            true, response.error_code, response.data, response.response_code
                        ));
                } else if (response && response.error_code  && response.error_code === constants.STATUS_400) {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.vendorCategoryList,
                            true, response.error_code, [], response.response_code
                        ));
                } else {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.vendorCategoryList,
                            false, 100, [], 300
                        ));
                }
            })

    }
}


/**
 * Add product image
 * @param {json} params 
 * @returns json
 */

const _addProductImage = (params) => {
    return async (dispatch) => {
        await axios.delete(ApiUrls.product + VendorApiList.addProductImageURL,{data:params})
            .then((response) => {
                if (response) {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.addProductImage,
                            true, response.error_code, response.data, response.response_code
                        ));
                }
                else {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.deleteProduct,
                            false, 100, [], 300
                        ));
                }
            })

    }
}

/**
 * Reset Response 
 */

const resetResponse = (dispatch, action) => {
    dispatch(
        {
            type: action, apiStatus: false, apiErrorCode: 0, apiResponse: {}, apiMessage: ""
        }
    );
}

/**
* Vendor List Products Message List  
* @param {json} params 
* @returns json
*/

const _vendorListProductMessageList = () => {
    return async (dispatch) => {
        await axios.get(ApiUrls.vendor + VendorApiList.listProductMessageList)
            .then((response) => {
                if (response) {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.listProductMessageList,
                            true, response.error_code, response.data, response.response_code
                        ));
                } else if (response && response.error_code  && response.error_code === constants.STATUS_400) {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.listProductMessageList,
                            true, response.error_code, [], response.response_code
                        ));
                } else {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.listProductMessageList,
                            false, 100, [], 300
                        ));
                }
            })

    }
}
/**
*  pending Vendor Registration
* @param {json} params 
* @returns json
*/

const _pendingVendorRegistration = (id) => {
    return async (dispatch) => {
        await axios.post(ApiUrls.vendor + VendorApiList.pendingVendorRegistration, { vendorId: id })
            .then((response) => {
                if (response) {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.pendingVendorRegistration,
                            true, response.error_code, response.data, response.response_code
                        ));
                } else if (response && response.error_code  && response.error_code === constants.STATUS_400) {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.pendingVendorRegistration,
                            true, response.error_code, [], response.response_code
                        ));
                } else {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.pendingVendorRegistration,
                            false, 100, [], 300
                        ));
                }
            })

    }
}

/**
* Add Subscription 
* @param {json} params 
* @returns json
*/

const _addSubscription = (params) => {
    return async (dispatch) => {
        await axios.post(ApiUrls.vendor + VendorApiList.addSubscriptionURL, params)
            .then((response) => {
                if (response) {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.addSubscription,
                            true, response.error_code, response.data, response.response_code
                        ));
                } else if (response && response.error_code  && response.error_code === constants.STATUS_400) {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.addSubscription,
                            true, response.error_code, [], response.response_code
                        ));
                } else {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.addSubscription,
                            false, 100, [], 300
                        ));
                }
            })

    }
}

/**
* Purchase History 
* @param {json} params 
* @returns json
*/
const _purchaseHistory = (params) => {
    return async (dispatch) => {
        await axios.post(ApiUrls.vendor + VendorApiList.purchaseHistoryURL, params)
            .then((response) => {
                if (response) {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.purchaseHistory,
                            true, response.error_code, response.data, response.response_code
                        ));
                } else if (response && response.error_code  && response.error_code === constants.STATUS_400) {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.purchaseHistory,
                            true, response.error_code, [], response.response_code
                        ));
                } else {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.purchaseHistory,
                            false, 100, [], 300
                        ));
                }
            })

    }
}

/**
* Vendor Notifications 
* @param {json} params 
* @returns json
*/


const _productMessages = (params) => {
    return async (dispatch) => {
        await axios.post(ApiUrls.vendor + VendorApiList.productmessagesURL, params)
            .then((response) => {
                if (response) {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.productMessages,
                            true, response.error_code, response.data, response.response_code
                        ));
                } else if (response && response.error_code  &&response.error_code === constants.STATUS_400) {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.productMessages,
                            true, response.error_code, [], response.response_code
                        ));
                } else {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.productMessages,
                            false, 100, [], 300
                        ));
                }
            })

    }
}

const _vendorNotification = (params) => {
    return async (dispatch) => {
        await axios.post(ApiUrls.vendor + VendorApiList.notificationURL, params)
            .then((response) => {
                if (response) {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.vendorNotifications,
                            true, response.error_code, response.data, response.response_code
                        ));
                } else if (response && response.error_code  &&response.error_code === constants.STATUS_400) {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.vendorNotifications,
                            true, response.error_code, [], response.response_code
                        ));
                } else {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.vendorNotifications,
                            false, 100, [], 300
                        ));
                }
            })

    }
}


/**
* Send Product  Messages 
* @param {json} params 
* @returns json
*/

const _sendProductMessage = (params) => {
    return async (dispatch) => {
        await axios.post(ApiUrls.vendor + VendorApiList.sendProductMessageURL, params)
            .then((response) => {
                if (response) {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.sendProductMessage,
                            true, response.error_code, response.data, response.response_code
                        ));
                } else if (response && response.error_code  &&response.error_code === constants.STATUS_400) {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.sendProductMessage,
                            true, response.error_code, [], response.response_code
                        ));
                } else {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.sendProductMessage,
                            false, 100, [], 300
                        ));
                }
            })

    }
}


/**
* Vendor Notifications View
* @param {json} params 
* @returns json
*/
const _vendorViewNotification = (params) => {
    return async (dispatch) => {
        await axios.post(ApiUrls.vendor + VendorApiList.notificationViewURL, params)
            .then((response) => {
                if (response) {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.vendorViewNotification,
                            true, response.error_code, response.data, response.response_code
                        ));
                } else if (response && response.error_code  && response.error_code === constants.STATUS_400) {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.vendorViewNotification,
                            true, response.error_code, [], response.response_code
                        ));
                } else {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.vendorViewNotification,
                            false, 100, [], 300
                        ));
                }
            })

    }
}



/**
* Vendor Notifications View
* @param {json} params 
* @returns json
*/
const _vendorNotificationAvailable = () => {
    return async (dispatch) => {
        await axios.get(ApiUrls.vendor + VendorApiList.notificationCheckURL)
            .then((response) => {
                if (response) {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.notificationAvailable,
                            true, response.error_code, response.data, response.response_code
                        ));
                } else if (response && response.error_code  && response.error_code === constants.STATUS_400) {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.notificationAvailable,
                            true, response.error_code, [], response.response_code
                        ));
                } else {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.notificationAvailable,
                            false, 100, [], 300
                        ));
                }
            })

    }
}

/**
* Vendor Export Purchase History
* @param {json} params 
* @returns json
*/
const _vendorExportPurchaseHistory = (params) => {
    return async (dispatch) => {
        await axios.post(ApiUrls.vendor + VendorApiList.exportPurchaseHistoryURL, params)
            .then((response) => {
                if (response) {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.exportPurchaseHistory,
                            true, response.error_code, response.data, response.response_code
                        ));
                } else if (response && response.error_code  &&response.error_code === constants.STATUS_400) {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.exportPurchaseHistory,
                            true, response.error_code, [], response.response_code
                        ));
                } else {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.exportPurchaseHistory,
                            false, 100, [], 300
                        ));
                }
            })

    }
}

/**
* Vendor Forgot Password
* @param {json} params 
* @returns json
*/
const _vendorForgotPassword = (params) => {
    return async (dispatch) => {
        await axios.post(ApiUrls.vendor + VendorApiList.forgotPasswordURL, params)
            .then((response) => {
                if (response) {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.forgotPassword,
                            true, response.error_code, response.data, response.response_code
                        ));
                } else if (response && response.error_code  &&response.error_code === constants.STATUS_400) {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.forgotPassword,
                            true, response.error_code, [], response.response_code
                        ));
                } else {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.forgotPassword,
                            false, 100, [], 300
                        ));
                }
            })

    }
}

/**
* Vendor Reset Password
* @param {json} params 
* @returns json
*/
const _vendorResetPassword = (params) => {
    return async (dispatch) => {
        await axios.post(ApiUrls.vendor + VendorApiList.resetPasswordURL, params)
            .then((response) => {
                if (response) {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.resetPassword,
                            true, response.error_code, response.data, response.response_code
                        ));
                } else if (response && response.error_code  && response.error_code === constants.STATUS_400) {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.resetPassword,
                            true, response.error_code, [], response.response_code
                        ));
                } else {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.resetPassword,
                            false, 100, [], 300
                        ));
                }
            })

    }
}

/**
* Vendor strpe verify
* @param {json} params 
* @returns json
*/
const _vendorStripeVerify = () => {
    return async (dispatch) => {
        await axios.post(ApiUrls.vendor + VendorApiList.stripeVerifyURL, {})
            .then((response) => {
                if (response) {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.stripeVerify,
                            true, response.error_code, response.data, response.response_code
                        ));
                } else if (response && response.error_code  &&response.error_code === constants.STATUS_400) {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.stripeVerify,
                            true, response.error_code, [], response.response_code
                        ));
                } else {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.stripeVerify,
                            false, 100, [], 300
                        ));
                }
            })

    }
}

/**
* Vendor create subscription Password
* @param {json} params 
* @returns json
*/
//apiDecryptPost
const _vendorCreateSubscription = (params) => {
    return async (dispatch) => {
        await axios.post(ApiUrls.vendor + VendorApiList.createSubscriptionURL, params)
            .then(async (response) => {
                if (response) {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.createSubscription,
                            true, response.error_code, response.data, response.response_code
                        ));
                } else if (response && response.error_code && response.error_code === constants.STATUS_400) {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.createSubscription,
                            true, response.error_code, [], response.response_code
                        ));
                } else {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.createSubscription,
                            false, 100, [], 300
                        ));
                }
            })

    }
}

/**
* Vendor save payment card
* @param {json} params 
* @returns json
*/
const _vendorSavePaymentCard = (params) => {
    return async (dispatch) => {
        await axios.post(ApiUrls.vendor + VendorApiList.savePaymentCardURL, params)
            .then((response) => {
                if (response) {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.savePaymentCard,
                            true, response.error_code, response.data, response.response_code
                        ));
                } else if (response.error_code === constants.STATUS_400) {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.savePaymentCard,
                            true, response.error_code, [], response.response_code
                        ));
                } else {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.savePaymentCard,
                            false, 100, [], 300
                        ));
                }
            })

    }
}
/**
* Vendor list payment card
* @param {json} params 
* @returns json
*/
const _vendorListPaymentCard = (params = {}) => {
    return async (dispatch) => {
        await axios.get(ApiUrls.vendor + VendorApiList.listPaymentCardURL, params)
            .then((response) => {
                if (response) {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.listPaymentCard,
                            true, response.error_code, response.data, response.response_code
                        ));
                } else if (response.error_code === constants.STATUS_400) {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.listPaymentCard,
                            true, response.error_code, [], response.response_code
                        ));
                } else {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.listPaymentCard,
                            false, 100, [], 300
                        ));
                }
            })

    }
}

/**
* Vendor delete payment card
* @param {json} params 
* @returns json
*/
//apiEncryptedDelete
const _vendorDeletePaymentCard = (params ) => {
    return async (dispatch) => {
        await  axios.delete(ApiUrls.vendor + VendorApiList.deletePaymentCardURL, {data:params})
            .then((response) => {
                if (response) {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.deletePaymentCard,
                            true, response.error_code, response.data, response.response_code
                        ));
                } else if (response.error_code === constants.STATUS_400) {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.deletePaymentCard,
                            true, response.error_code, [], response.response_code
                        ));
                } else {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.deletePaymentCard,
                            false, 100, [], 300
                        ));
                }
            })

    }
}
/**
* Vendor Cancel subscription 
* @param {json} params 
* @returns json
*/
const _vendorCancelSubscription = () => {
    return async (dispatch) => {
        await axios.get(ApiUrls.vendor + VendorApiList.cancelSubscriptionURL)
            .then((response) => {
                if (response) {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.cancelSubscription,
                            true, response.error_code, response.data, response.response_code
                        ));
                } else if (response && response.error_code  && response.error_code === constants.STATUS_400) {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.cancelSubscription,
                            true, response.error_code, [], response.response_code
                        ));
                } else {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.cancelSubscription,
                            false, 100, [], 300
                        ));
                }
            })

    }
}
/**
* Vendor  subscription details
* @param {json} params 
* @returns json
*/
const _vendorSubscriptionDetails = () => {
    return async (dispatch) => {
        await axios.get(ApiUrls.vendor + VendorApiList.getSubscriptionDetailsURL)
            .then((response) => {
                if (response) {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.subscriptionDetails,
                            true, response.error_code, response.data, response.response_code
                        ));
                } else if (response && response.error_code  && response.error_code === constants.STATUS_400) {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.subscriptionDetails,
                            true, response.error_code, [], response.response_code
                        ));
                } else {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.subscriptionDetails,
                            false, 100, [], 300
                        ));
                }
            })

    }
}
/**
* Vendor Subscription history
* @param {json} params 
* @returns json
*/
const _vendorSubscriptionHistory = () => {
    return async (dispatch) => {
        await axios.get(ApiUrls.vendor + VendorApiList.subscriptionHistoryURL)
            .then((response) => {
                if (response) {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.subscriptionHistory,
                            true, response.error_code, response.data, response.response_code
                        ));
                } else if (response && response.error_code  && response.error_code === constants.STATUS_400) {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.subscriptionHistory,
                            true, response.error_code, [], response.response_code
                        ));
                } else {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.subscriptionHistory,
                            false, 100, [], 300
                        ));
                }
            })

    }
}

/**
* Vendor Subscription upgrade
* @param {json} params 
* @returns json
*/
//apiDecryptPost
const _vendorUpgradeSubscription = (params) => {
    return async (dispatch) => {
        await axios.post(ApiUrls.vendor + VendorApiList.subscriptionUpgradeURL, params)
            .then((response) => {
                if (response) {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.subscriptionUpgrade,
                            true, response.error_code, response.data, response.response_code
                        ));
                } else if (response && response.error_code  && response.error_code === constants.STATUS_400) {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.subscriptionUpgrade,
                            true, response.error_code, [], response.response_code
                        ));
                } else {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.subscriptionUpgrade,
                            false, 100, [], 300
                        ));
                }
            })

    }
}
/**
* Vendor Bank Details
* @param {json} params 
* @returns json
*/
const _vendorBankDetails = () => {
    return async (dispatch) => {
        await axios.post(ApiUrls.vendor + VendorApiList.bankDetailsURL, {})
            .then((response) => {
                if (response) {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.bankDetails,
                            true, response.error_code, response.data, response.response_code
                        ));
                } else if (response && response.error_code  && response.error_code === constants.STATUS_400) {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.bankDetails,
                            true, response.error_code, [], response.response_code
                        ));
                } else {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.bankDetails,
                            false, 100, [], 300
                        ));
                }
            })

    }
}

/**
* Vendor List Schedule
* @param {json} params 
* @returns json
*/
const _listSchedule = () => {
    return async (dispatch) => {
        await axios.post(ApiUrls.vendor + VendorApiList.listScheduleURL, {})
            .then((response) => {
                if (response) {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.listSchedule,
                            true, response.error_code, response.data, response.response_code
                        ));
                } else if (response && response.error_code  && response.error_code === constants.STATUS_400) {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.listSchedule,
                            true, response.error_code, [], response.response_code
                        ));
                } else {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.listSchedule,
                            false, 100, [], 300
                        ));
                }
            })

    }
}

/**
* Vendor cancel Schedule
* @param {json} params 
* @returns json
*/
const _cancelSchedule = (params) => {
    return async (dispatch) => {
        await axios.post(ApiUrls.vendor + VendorApiList.cancelScheduleURL, params)
            .then((response) => {
                if (response) {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.cancelSchedule,
                            true, response.error_code, response.data, response.response_code
                        ));
                } else if (response && response.error_code  && response.error_code === constants.STATUS_400) {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.cancelSchedule,
                            true, response.error_code, [], response.response_code
                        ));
                } else {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.cancelSchedule,
                            false, 100, [], 300
                        ));
                }
            })

    }

}
/**
* Vendor Change password
* @param {json} params 
* @returns json
*/
const _UpdatePassword = (params) => {
    return async (dispatch) => {
        await axios.post(ApiUrls.vendor + VendorApiList.updatePassword, params)
            .then((response) => {
                if (response) {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.updatePassword,
                            true, response.error_code, response.data, response.response_code
                        ));
                } else if (response && response.error_code  && response.error_code === constants.STATUS_400) {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.updatePassword,
                            true, response.error_code, [], response.response_code
                        ));
                } else {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.updatePassword,
                            false, 100, [], 300
                        ));
                }
            })

    }
}
/**
* Vendor add proxy address and zipcode
* @param {json} params 
* @returns json
*/
const _addProxyAddress = (params) => {
    return async (dispatch) => {
        await axios.post(ApiUrls.vendor + VendorApiList.addProxyURL, params)
            .then((response) => {
                if (response) {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.addProxy,
                            true, response.error_code, response.data, response.response_code
                        ));
                } else if (response && response.error_code  && response.error_code === constants.STATUS_400) {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.addProxy,
                            true, response.error_code, [], response.response_code
                        ));
                } else {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.addProxy,
                            false, 100, [], 300
                        ));
                }
            })

    }
}
/**
* Vendor view proxy address and zipcode
* @param {json} params 
* @returns json
*/
const _viewProxyAddress = (params = {}) => {
    return async (dispatch) => {
        await axios.get(ApiUrls.vendor + VendorApiList.viewProxyURL, params)
            .then((response) => {
                if (response) {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.viewProxy,
                            true, response.error_code, response.data, response.response_code
                        ));
                } else if (response && response.error_code  && response.error_code === constants.STATUS_400) {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.viewProxy,
                            true, response.error_code, [], response.response_code
                        ));
                } else {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.viewProxy,
                            false, 100, [], 300
                        ));
                }
            })

    }
}
/**
* Vendor list proxy address and zipcode
* @param {json} params 
* @returns json
*/
const _listProxyAddress = (params) => {
    return async (dispatch) => {
        await axios.post(ApiUrls.vendor + VendorApiList.listProxyURL, params)
            .then((response) => {
                if (response) {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.listProxy,
                            true, response.error_code, response.data, response.response_code
                        ));
                } else if (response && response.error_code  && response.error_code === constants.STATUS_400) {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.listProxy,
                            true, response.error_code, [], response.response_code
                        ));
                } else {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.listProxy,
                            false, 100, [], 300
                        ));
                }
            })

    }
}

/**
* Vendor view profile business info 
* @param {json} params 
* @returns json
*/
const _profileBusinessInfo = (params = {}) => {
    return async (dispatch) => {
        await axios.get(ApiUrls.vendor + VendorApiList.businessInfoURL, params)
            .then((response) => {
                if (response) {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.businessInfo,
                            true, response.error_code, response.data, response.response_code
                        ));
                } else if (response && response.error_code  && response.error_code === constants.STATUS_400) {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.businessInfo,
                            true, response.error_code, [], response.response_code
                        ));
                } else {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.businessInfo,
                            false, 100, [], 300
                        ));
                }
            })

    }
}


/**
* Vendor view profile bank info 
* @param {json} params 
* @returns json
*/
const _profileBankInfo = (params = {}) => {
    return async (dispatch) => {
        await axios.get(ApiUrls.vendor + VendorApiList.bankInfoURL, params)
            .then((response) => {
                if (response) {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.bankInfo,
                            true, response.error_code, response.data, response.response_code
                        ));
                } else if (response && response.error_code  && response.error_code === constants.STATUS_400) {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.bankInfo,
                            true, response.error_code, [], response.response_code
                        ));
                } else {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.bankInfo,
                            false, 100, [], 300
                        ));
                }
            })

    }
}

/**
* Vendor view profile subscription info 
* @param {json} params 
* @returns json
*/
const _profileSubscriptionInfo = (params = {}) => {
    return async (dispatch) => {
        await axios.get(ApiUrls.vendor + VendorApiList.subscriptionInfoURL, params)
            .then((response) => {
                if (response) {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.subscriptionInfo,
                            true, response.error_code, response.data, response.response_code
                        ));
                } else if (response && response.error_code  && response.error_code === constants.STATUS_400) {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.subscriptionInfo,
                            true, response.error_code, [], response.response_code
                        ));
                } else {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.subscriptionInfo,
                            false, 100, [], 300
                        ));
                }
            })

    }
}

/**
* Vendor downgrade subscription 
* @param {json} params 
* @returns json
*/
//apiDecryptPost
const _vendorDowngradeSubscription = (params) => {
    return async (dispatch) => {
        await axios.post(ApiUrls.vendor + VendorApiList.downgradeSubscriptionURL, params)
            .then(async (response) => {
                if (response) {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.downgradeSubscription,
                            true, response.error_code, response.data, response.response_code
                        ));
                } else if (response && response.error_code && response.error_code === constants.STATUS_400) {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.downgradeSubscription,
                            true, response.error_code, [], response.response_code
                        ));
                } else {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.downgradeSubscription,
                            false, 100, [], 300
                        ));
                }
            })

    }
}

/**
* Vendor expire subscription 
* @param {json} params 
* @returns json
*/
//apiDecryptPost
const _vendorExpireSubscription = (params) => {
    return async (dispatch) => {
        await axios.post(ApiUrls.vendor + VendorApiList.expireSubscriptionURL, params)
            .then(async (response) => {
                if (response) {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.expireSubscription,
                            true, response.error_code, response.data, response.response_code
                        ));
                } else if (response && response.error_code && response.error_code === constants.STATUS_400) {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.expireSubscription,
                            true, response.error_code, [], response.response_code
                        ));
                } else {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.expireSubscription,
                            false, 100, [], 300
                        ));
                }
            })

    }
}

/**
 * Register Step2 vendor
 * @param {json} params 
 * @returns json
 */

const _vendorRegistrationStep2 = (params) => {
    return async (dispatch) => {
        await axios.post(ApiUrls.vendor + VendorApiList.registrationStep2URL, params)
            .then((response) => {
                if (response) {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.vendorRegisterStep2,
                            true, response.error_code, response.data, response.response_code
                        ));
                } else {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.vendorRegisterStep2,
                            false, 100, [], 300
                        ));
                }
            })

    }
}
/**
 * Register Step2 vendor
 * @param {json} params 
 * @returns json
 */

const _vendorRegistrationStep3 = (params) => {
    return async (dispatch) => {
        await axios.post(ApiUrls.vendor + VendorApiList.registrationStep3URL, params)
            .then((response) => {
                if (response) {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.vendorRegisterStep3,
                            true, response.error_code, response.data, response.response_code
                        ));
                } else {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.vendorRegisterStep3,
                            false, 100, [], 300
                        ));
                }
            })

    }
}

/**
 * vendor registration send mobile otp for verification
 * @param {json} params 
 * @returns json
 */
const _sendMobileOTP = (params) => {
    return async (dispatch) => {
        await axios.post(ApiUrls.vendor + VendorApiList.sendMobileOTP, params)
            .then((response) => {
                if (response) {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.vendorSendMobileOtp,
                            true, response.error_code, response.data, response.response_code
                        ));
                } else {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.vendorSendMobileOtp,
                            false, 100, [], 300
                        ));
                }
            })

    }
}
/**
 * Refund information in purchase history
 * @param {json} params 
 * @returns json
 */
const _refundInfo = (params) => {
    return async (dispatch) => {
        await axios.post(ApiUrls.vendor + VendorApiList.refundInfo, params)
            .then((response) => {
                if (response) {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.refundInfo,
                            true, response.error_code, response.data, response.response_code
                        ));
                } else {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.refundInfo,
                            false, 100, [], 300
                        ));
                }
            })

    }
}
/**
 * Refund approve in purchase history
 * @param {json} params 
 * @returns json
 */
const _approveRefund = (params) => {
    return async (dispatch) => {
        await axios.post(ApiUrls.vendor + VendorApiList.approveRefund, params)
            .then((response) => {
                if (response) {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.approveRefund,
                            true, response.error_code, response.data, response.response_code
                        ));
                } else {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.approveRefund,
                            false, 100, [], 300
                        ));
                }
            })

    }
}
/**
 * Refund reject in purchase history
 * @param {json} params 
 * @returns json
 */
const _rejectRefund = (params) => {
    return async (dispatch) => {
        await axios.post(ApiUrls.vendor + VendorApiList.rejectRefund, params)
            .then((response) => {
                if (response) {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.rejectRefund,
                            true, response.error_code, response.data, response.response_code
                        ));
                } else {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.rejectRefund,
                            false, 100, [], 300
                        ));
                }
            })

    }
}
/**
 * Delete Bank info
 * @param {json} params 
 * @returns json
 */
const _DeleteBankInfo = (params = {}) => {
    return async (dispatch) => {
        await axios.post(ApiUrls.vendor + VendorApiList.deleteBankinfo, params)
            .then((response) => {
                if (response) {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.deleteBankinfo,
                            true, response.error_code, response.data, response.response_code
                        ));
                } else {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.deleteBankinfo,
                            false, 100, [], 300
                        ));
                }
            })

    }
}

/**
 * Delete Bank info
 * @param {json} params 
 * @returns json
 */
const _heatMap = (params = {}) => {
    return async (dispatch) => {
        await axios.get(ApiUrls.vendor + VendorApiList.heatMapInfo, params)
            .then((response) => {
                if (response) {
                    return dispatch( 
                        vendorResponse(
                            VendorActionType.heatMapInfo,
                            true, response.error_code, response.data, response.response_code
                        ));
                } else {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.heatMapInfo,
                            false, 100, [], 300
                        ));
                }
            })

    }
}

/**
 * Remove subscription.
 * @param {json} params 
 * @returns json
 */
const _removeSubscription = (params) => {
    return async (dispatch) => {
        await axios.delete(ApiUrls.vendor + VendorApiList.removeSubscription, {data:params})
            .then((response) => {
                if (response) {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.removeSubscription,
                            true, response.error_code, response.data, response.response_code
                        ));
                } else {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.removeSubscription,
                            false, 100, [], 300
                        ));
                }
            })

    }
}
/**
* Promotional cities list  
* @param {json} params 
* @returns json
*/

const _vendorPromotionalCitiesList = () => {
    return async (dispatch) => {
        await axios.get(ApiUrls.vendor + VendorApiList.promotionalCitiesURL)
            .then((response) => {
                if (response) {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.promotionalCities,
                            true, response.error_code, response.data, response.response_code
                        ));
                } else if (response && response.error_code  && response.error_code === constants.STATUS_400) {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.promotionalCities,
                            true, response.error_code, [], response.response_code
                        ));
                } else {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.promotionalCities,
                            false, 100, [], 300
                        ));
                }
            })

    }
}

const _vendorForgotEmail = (params) => {
    return async (dispatch) => {
        await axios.post(ApiUrls.vendor + VendorApiList.forgotEmailURL,params)
            .then((response) => {
                if (response) {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.vendorForgotEmail,
                            true, response.error_code, response.data, response.response_code
                        ));
                } else if (response && response.error_code  && response.error_code === constants.STATUS_400) {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.vendorForgotEmail,
                            true, response.error_code, [], response.response_code
                        ));
                } else {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.vendorForgotEmail,
                            false, 100, [], 300
                        ));
                }
            })

    }
}

const _vendorZctaData = (params) => {
    return async (dispatch) => {
        await axios.post(ApiUrls.vendor + VendorApiList.zctaDataURL,params)
            .then((response) => {
                if (response) {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.zctaData,
                            true, response.error_code, response.data, response.response_code
                        ));
                } else if (response && response.error_code  && response.error_code === constants.STATUS_400) {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.zctaData,
                            true, response.error_code, [], response.response_code
                        ));
                } else {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.zctaData,
                            false, 100, [], 300
                        ));
                }
            })

    }
}
const _vendorZctaBroadcastData = (params) => {
    return async (dispatch) => {
        await axios.post(ApiUrls.vendor + VendorApiList.zctaDataBroadcastURL,params)
        .then((response) => {
            if (response) {
                return dispatch(
                    vendorResponse(
                        VendorActionType.zctaBroadcastData,
                        true, response.error_code, response.data, response.response_code
                        ));
            } else if (response && response.error_code  && response.error_code === constants.STATUS_400) {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.zctaBroadcastData,
                            true, response.error_code, [], response.response_code
                        ));
            } else {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.zctaBroadcastData,
                            false, 100, [], 300
                        ));
                }
            })

    }
}


/**
* vendor coupon list  
* @param {json} params 
* @returns json
*/

const _vendorCouponList = () => {
    return async (dispatch) => {
        await axios.get(ApiUrls.vendor + VendorApiList.couponListURL)
            .then((response) => {
                if (response) {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.vendorCouponList,
                            true, response.error_code, response.data, response.response_code
                        ));
                } else if (response && response.error_code  && response.error_code === constants.STATUS_400) {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.vendorCouponList,
                            true, response.error_code, [], response.response_code
                        ));
                } else {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.vendorCouponList,
                            false, 100, [], 300
                        ));
                }
            })

    }
}


/**
* vendor add coupon   
* @param {json} params 
* @returns json
*/
const _vendorAddCoupon = (params) => {
    return async (dispatch) => {
        await axios.post(ApiUrls.vendor + VendorApiList.addCouponURL,params)
            .then((response) => {
                if (response) {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.vendorAddCoupon,
                            true, response.error_code, response.data, response.response_code
                        ));
                } else if (response && response.error_code  && response.error_code === constants.STATUS_400) {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.vendorAddCoupon,
                            true, response.error_code, [], response.response_code
                        ));
                } else {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.vendorAddCoupon,
                            false, 100, [], 300
                        ));
                }
            })

    }
}

/**
* Vendor delete coupon
* @param {json} params 
* @returns json
*/
//apiEncryptedDelete
const _vendorDeleteCoupon = (params ) => {
    return async (dispatch) => {
        await  axios.delete(ApiUrls.vendor + VendorApiList.deleteCouponURL, {data:params})
            .then((response) => {
                if (response) {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.vendorDeleteCoupon,
                            true, response.error_code, response.data, response.response_code
                        ));
                } else if (response.error_code === constants.STATUS_400) {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.vendorDeleteCoupon,
                            true, response.error_code, [], response.response_code
                        ));
                } else {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.vendorDeleteCoupon,
                            false, 100, [], 300
                        ));
                }
            })

    }
}

/**
* vendor feed back   
* @param {json} params 
* @returns json
*/
const _vendorFeedBack = (params) => {
    return async (dispatch) => {
        await axios.post(ApiUrls.vendor + VendorApiList.feedBackURL,params)
            .then((response) => {
                if (response) {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.vendorFeedBack,
                            true, response.error_code, response.data, response.response_code
                        ));
                } else if (response && response.error_code  && response.error_code === constants.STATUS_400) {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.vendorFeedBack,
                            true, response.error_code, [], response.response_code
                        ));
                } else {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.vendorFeedBack,
                            false, 100, [], 300
                        ));
                }
            })

    }
}


/**
* Sales VS offers chart api   
* @param {json} params 
* @returns json
*/
const _SalesVsoffers = (params) => {
    return async (dispatch) => {
        await axios.post(ApiUrls.vendor + VendorApiList.SalesVsoffers,params)
            .then((response) => {
                if (response) {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.SalesVsoffers,
                            true, response.error_code, response.data, response.response_code
                        ));
                } else if (response && response.error_code  && response.error_code === constants.STATUS_400) {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.SalesVsoffers,
                            true, response.error_code, [], response.response_code
                        ));
                } else {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.SalesVsoffers,
                            false, 100, [], 300
                        ));
                }
            })

    }
}



/**
* Time vs WeekDay Sales api   
* @param {json} params 
* @returns json
*/
const _timevsWeekDaySales = (params) => {
    return async (dispatch) => {
        await axios.post(ApiUrls.vendor + VendorApiList.timevsWeekDaySales,params)
            .then((response) => {
                if (response) {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.timevsWeekDaySales,
                            true, response.error_code, response.data, response.response_code
                        ));
                } else if (response && response.error_code  && response.error_code === constants.STATUS_400) {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.timevsWeekDaySales,
                            true, response.error_code, [], response.response_code
                        ));
                } else {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.timevsWeekDaySales,
                            false, 100, [], 300
                        ));
                }
            })

    }
}

/**
* Get the vendor address info for setting up the shipment 
* @param {json} params 
* @returns json
*/
const _vendorAddressInfo = (params) => {
    return async (dispatch) => {
        await axios.get(ApiUrls.vendor + VendorApiList.listVendorAddress+"?purchaseId="+params.purchaseId)
            .then((response) => {
                if (response) {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.listVendorAddress,
                            true, response.error_code, response.data, response.response_code
                        ));
                } else if (response && response.error_code  && response.error_code === constants.STATUS_400) {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.listVendorAddress,
                            true, response.error_code, [], response.response_code
                        ));
                } else {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.listVendorAddress,
                            false, 100, [], 300
                        ));
                }
            })

    }
}


/**
* Get the list of shipment Carriers for setting up the shipment
* @param {json} params 
* @returns json
*/
const _shipmentCarriers = () => {
    return async (dispatch) => {
        await axios.get(ApiUrls.vendor + VendorApiList.listShipmentCarriers)
            .then((response) => {
                if (response) {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.listShipmentCarriers,
                            true, response.error_code, response.data, response.response_code
                        ));
                } else if (response && response.error_code  && response.error_code === constants.STATUS_400) {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.listShipmentCarriers,
                            true, response.error_code, [], response.response_code
                        ));
                } else {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.listShipmentCarriers,
                            false, 100, [], 300
                        ));
                }
            })

    }
}

/**
* Get the rates for the selected carrier for setting up the shipment 
* @param {json} params 
* @returns json
*/
const _shipmentRateInfo = (params) => {
    return async (dispatch) => {
        await axios.post(ApiUrls.vendor + VendorApiList.listShipmentRates, params)
            .then((response) => {
                if (response) {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.listShipmentRates,
                            true, response.error_code, response.data, response.response_code
                        ));
                } else if (response && response.error_code  && response.error_code === constants.STATUS_400) {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.listShipmentRates,
                            true, response.error_code, [], response.response_code
                        ));
                } else {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.listShipmentRates,
                            false, 100, [], 300
                        ));
                }
            })

    }
}

/**
* To generate the shipment label 
* @param {json} params 
* @returns json
*/
const _generateShipmentLabel = (params) => {
    return async (dispatch) => {
        await axios.post(ApiUrls.vendor + VendorApiList.generateShipmentLabel, params)
            .then((response) => {
                if (response) {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.generateShipmentLabel,
                            true, response.error_code, response.data, response.response_code
                        ));
                } else if (response && response.error_code  && response.error_code === constants.STATUS_400) {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.generateShipmentLabel,
                            true, response.error_code, [], response.response_code
                        ));
                } else {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.generateShipmentLabel,
                            false, 100, [], 300
                        ));
                }
            })

    }
}

/**
* Get the shipment info for the selected purchase
* @param {json} params 
* @returns json
*/
const _shipmentDetails = (params) => {
    return async (dispatch) => {
        await axios.get(ApiUrls.vendor + VendorApiList.shipmentDetails+"?purchaseId="+params.purchaseId)
            .then((response) => {
                if (response) {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.shipmentDetails,
                            true, response.error_code, response.data, response.response_code
                        ));
                } else if (response && response.error_code  && response.error_code === constants.STATUS_400) {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.shipmentDetails,
                            true, response.error_code, [], response.response_code
                        ));
                } else {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.shipmentDetails,
                            false, 100, [], 300
                        ));
                }
            })

    }
}

/**
* To cancel the shipment label 
* @param {json} params 
* @returns json
*/
const _cancelShipmentLabel = (params) => {
    return async (dispatch) => {
        await axios.put(ApiUrls.vendor + VendorApiList.cancelShipmentLabel, params)
            .then((response) => {
                if (response) {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.cancelShipmentLabel,
                            true, response.error_code, response.data, response.response_code
                        ));
                } else if (response && response.error_code  && response.error_code === constants.STATUS_400) {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.cancelShipmentLabel,
                            true, response.error_code, [], response.response_code
                        ));
                } else {
                    return dispatch(
                        vendorResponse(
                            VendorActionType.cancelShipmentLabel,
                            false, 100, [], 300
                        ));
                }
            })

    }
}


export { _vendorRegistration, _sendOTP, _vendorProfile, _vendorProfileBasicEdit, _vendorProfileBankEdit, 
        _vendorProfileBusinessEdit, _vendorImagePreSignedURL, _vendorBusinessImageUpload, _vendorDashboard, 
        _vendorCategoryList, resetResponse, _addProductImage, _vendorListProductMessageList, _pendingVendorRegistration, 
        _addSubscription, _removeBusinessImage, _vendorNotification, _vendorViewNotification, _productMessages, 
        _sendProductMessage, _purchaseHistory, _vendorNotificationAvailable, _vendorExportPurchaseHistory, 
        _vendorForgotPassword, _vendorResetPassword, _vendorStripeVerify, _vendorCreateSubscription, 
        _vendorSavePaymentCard, _vendorListPaymentCard, _vendorDeletePaymentCard, _vendorCancelSubscription, 
        _vendorSubscriptionDetails, _vendorSubscriptionHistory, _vendorUpgradeSubscription, _vendorBankDetails, 
        _listSchedule, _cancelSchedule, _UpdatePassword, _addProxyAddress, _viewProxyAddress, _listProxyAddress, 
        _profileBusinessInfo, _profileBankInfo, _profileSubscriptionInfo, _vendorDowngradeSubscription, 
        _vendorExpireSubscription, _vendorRegistrationStep2, _vendorRegistrationStep3, _sendMobileOTP, _refundInfo, 
        _approveRefund, _rejectRefund,_DeleteBankInfo, _heatMap, _removeSubscription, _vendorPromotionalCitiesList, 
        _vendorForgotEmail, _vendorCouponList, _vendorAddCoupon, _vendorDeleteCoupon,_vendorZctaData, 
        _vendorZctaBroadcastData, _vendorFeedBack, _SalesVsoffers, _timevsWeekDaySales, _vendorAddressInfo, 
        _shipmentCarriers, _shipmentRateInfo, _generateShipmentLabel, _shipmentDetails, _cancelShipmentLabel};



