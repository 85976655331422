import React, { useState } from 'react'
import { OverlayTrigger } from 'react-bootstrap';
import Tooltip from 'react-bootstrap/Tooltip';

const ICShipmentInfo = (props) => {
  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Label Canceled
    </Tooltip>
  );
  return (
    <OverlayTrigger
      placement="top"
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip}
    >
      <svg xmlns="http://www.w3.org/2000/svg" width="15.167" height="15.167" viewBox="0 0 15.167 15.167">
        <path id="Path_32055" data-name="Path 32055" d="M7.583,0a7.583,7.583,0,1,0,7.583,7.583A7.592,7.592,0,0,0,7.583,0m0,11.375a.632.632,0,1,1,.632-.632.632.632,0,0,1-.632.632m.632-3.16a.632.632,0,0,1-1.264,0V4.424a.632.632,0,1,1,1.264,0Z" fill="#f65e5f" />
      </svg>

    </OverlayTrigger>
  )
};

export default ICShipmentInfo;

