/**
 * @file   src\
 * @brief  This file is responsible for 
 * @date   Oct, 2022
 * @author ZCO Engineer
 * @copyright (c) 2022, ZCO
 */

import { Col, Row} from 'react-bootstrap'
import InnerLayout from '../components/InnerLayout'
import Strings from '../assets/strings/Strings.json'
import PrivacyPolicy from '../components/Privacy'

const Privacy = (props) => {
  return (
    <InnerLayout  hideHeader={props.hideHeader}>
      <Col className='action-wrap'>
        <Row>
          <Col lg='6'>
            <h1>{Strings.Privacy.Title}</h1>
          </Col>
        </Row>
      </Col>
      <Col className='inner-main about'>
       <PrivacyPolicy/>
      </Col>
    </InnerLayout>
  );
}
export default Privacy;
