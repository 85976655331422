import React, { useState } from 'react'

const Plus = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="37.717" height="37.717" viewBox="0 0 37.717 37.717">
      <g id="Group_635" data-name="Group 635" transform="translate(18.917 2) rotate(45)">
        <path id="chevron-left" d="M11.962,23.924,4.486,16.447,0,11.962,4.984,6.978,11.962,0" transform="translate(11.797 0)" fill="none" stroke="#94da0a" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
        <path id="chevron-left-2" data-name="chevron-left" d="M0,23.924l7.476-7.476,4.486-4.486L6.978,6.978,0,0" transform="translate(0 0)" fill="none" stroke="#94da0a" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
      </g>
    </svg>
  )
};

export default Plus;

