/**
 * @file   src\library\Apis.js
 * @brief  This file is responsible for handling api url list.
 * @date   November, 2022
 * @author ZCO Engineer
 * @copyright (c) 2022, ZCO
 */

export const ApiUrls = {
    vendor: process.env.REACT_APP_VENDOR_API_URL,
    users: process.env.REACT_APP_USERS_API_URL,
    admin: process.env.REACT_APP_ADMIN_API_URL,
    product: process.env.REACT_APP_PRODUCT_API_URL,
}

export const VendorApiList = {
    loginURL: 'V1/login',
    signOutURL: 'V1/logout',
    registrationURL: 'V1/signup',
    sendOTP: 'V1/sendotp',
    profileURL: 'V1/profile',
    basicEditInfoURL: 'V1/basicinfo',
    editBankInfoURL: 'V1/editbankinfo',
    editBusinessInfoURL: 'V1/editbusinessinfo',
    imagePreSignedURL: 'V1/base64imgurl',
    businessImageUploadURL: 'V1/uploadBusinessImage',
    dashboardURL: 'V1/dashboard',
    categoryListURL: 'V1/categorieslistvendor',
    addProductImageURL: 'V1/uploadproductimages',
    listProductMessageList: 'V1/predefinedmessage',
    listProducts: 'V1/listproducts',
    pendingVendorRegistration: 'V1/pendinginfo',
    addSubscriptionURL: 'V1/addsubscription',
    productmessagesURL: 'V1/productmessages',
    sendProductMessageURL: 'V1/sendmessage',
    purchaseHistoryURL: 'V1/payment/history',
    removeBusinessImage: 'V1/removeimage',
    notificationURL: 'V1/notificationlist',
    notificationViewURL: 'V1/viewnotification',
    notificationCheckURL: 'V1/notificationcheck',
    exportPurchaseHistoryURL: 'V1/report/export',
    forgotPasswordURL: 'V1/forgotpassword',
    resetPasswordURL: 'V1/resetpassword',
    stripeVerifyURL: 'V1/payment/stripeverifyurl',
    createSubscriptionURL: 'V1/payment/createsubscription',
    cancelSubscriptionURL: 'V1/payment/cancelsubscription',
    getSubscriptionDetailsURL: 'V1/payment/subscriptiondetails',
    subscriptionHistoryURL: 'V1/payment/subscriptionhistory',
    subscriptionUpgradeURL: 'V1/payment/upgradesubscription',
    savePaymentCardURL: 'V1/payment/savecard',
    listPaymentCardURL: 'V1/payment/cardlist',
    deletePaymentCardURL: 'V1/payment/deletecard',
    bankDetailsURL: 'V1/payment/bankdetails',
    refreshTokenURL: 'V1/auth/refreshtoken',
    listScheduleURL: 'V1/schedules/list',
    cancelScheduleURL: 'V1/schedules/cancel',   
    updatePassword: 'V1/updatepassword',
    addProxyURL: 'V1/proxy/addEdit',
    viewProxyURL: 'V1/proxy/view',
    listProxyURL: 'V1/proxy/list',
    businessInfoURL: 'V1/businessinfo',
    bankInfoURL: 'V1/bankinfo',
    subscriptionInfoURL: 'V1/subscriptioninfo',
    downgradeSubscriptionURL: 'V1/payment/downgradesubscription',
    expireSubscriptionURL: 'V1/payment/expiresubscription',
    registrationStep2URL: 'V1/signupstep2',
    registrationStep3URL: 'V1/signupstep3',
    sendMobileOTP: 'V1/sendmobileotp',
    refundInfo: 'V1/refund/info',
    approveRefund:'V1/refund/approve',
    rejectRefund:'V1/refund/reject',
    deleteBankinfo:'V1/deleteaccntinfo',
    heatMapInfo:'V1/heatmap',
    removeSubscription: 'V1/payment/removeSubscription',
    promotionalCitiesURL: 'V1/payment/promotionalCities',
    forgotEmailURL: 'V1/forgotemail',
    zctaDataURL:'V1/zctaData',
    zctaDataBroadcastURL:'V1/zctaDataBroadcast',
    couponListURL: 'V1/coupon/list',
    addCouponURL: 'V1/coupon/generateCoupon',
    deleteCouponURL: 'V1/coupon/deleteCoupon',
    feedBackURL:'V1/feedback',
    SalesVsoffers :'V1/salesvsofferstrend',
    timevsWeekDaySales :'V1/timevsWeekDaySales',
    listVendorAddress: "V1/shipment/listAddress",
    listShipmentCarriers: "V1/shipment/listCarriers",
    listShipmentRates:"V1/shipment/viewRates",
    generateShipmentLabel:"V1/shipment/generateLabel",
    shipmentDetails:"V1/shipment/details",
    cancelShipmentLabel:"V1/shipment/cancelLabel"
};

export const UserApiList1 = {
    userList: 'user/getUserList',
    forgotPasswordURL: 'V1/forgotpassword',
    resetPasswordURL: 'V1/resetpassword'
}
/*
export const UserApiList = {
    userList: 'user/getUserList'
}*/

export const AdminApiList = {
    vendorList: 'V1/vendors',
    vendorApprovalList: 'V1/vendors/approvals',
    vendorAcceptOrReject: 'V1/vendors/verify',
    vendorBlockOrActivate: 'V1/vendors/changestatus',
    vendorBusinessinfo: 'V1/vendors/businessinfo',
    adminProfile: 'V1/details',
    vendorDeals: 'V1/vendors/deals',
    userListing: 'V1/users',
    userPurchaseHistory: 'V1/users/orders',
    deleteUser: 'V1/users/delete',
    changeUserStatus: 'V1/users/changestatus',
    categoryListing: 'V1/catgories/list',
    subcategoryListing: 'V1/catgories/dashboard',
    createSubCategory: 'V1/catgories/addoredit',
    forgotPasswordURL: 'V1/password/forgot',
    resetPasswordURL: 'V1/password/reset',
    listSubscriptions: 'V1/vendors/plans',
    updateProfile: 'V1/profile/update',
    listSchedule: 'V1/schedules/list',
    cancelSchedule: 'V1/schedules/cancel',
    updatePassword: 'V1/password/update',
    uploadProfileImage: 'V1/getpresignedurl',
    removeProfileImage: 'V1/profile/deleteimage',
    signOutURL: 'V1/logout',
    deleteSubcategory: 'V1/catgories/delete',
    refreshTokenURL: 'V1/auth/refreshtoken',
}

export const ProductsApiList = {
    draftproducts: 'V1/draftproductlist',
    deleteProductURL: 'V1/deleteproduct',
    addProductURL: 'V1/addproduct',
    imagePreSignedURL: 'V1/getpresignedurl',
    listProductToApp: 'V1/listproducts',
    removeimage: 'V1/removeimage',
    productDetailsById: 'V1/productbyid',
    createProductPaymentURL: 'V1/payment/intent',
    productPaymentStatusURL: 'V1/payment/productstatus',
    productFreshnessListURL: 'V1/freshnessList',
    scheduleTimeListURL: 'V1/timeslot',
    deleteaddress:'V1/deleteaddress',
}


