import React from "react";
import { Col, Row, Form } from "react-bootstrap";
import Input from "./Input";
import CustTextArea from "./CustTextArea";
import CustSelectLabel from "./SelectLabel";
import Quantity from "./Quantity";
import Strings from "../assets/strings/Strings.json";
import { MultiSelect } from "react-multi-select-component";

const Food = (props) => {
  const handleChange = props.handleChange;
  const addProductData = props.addProductData;
  const addProductError = props.addProductError;
  const deliveryTypeProduct = props.deliveryTypeProduct;
  const edit = props.enableEdit;
  const deliveryTypeList = props.deliveryTypeList;
  const handleQuantity = props.handleQuantity;
  const freshnessList = props.freshnessList;
  const selected = props.selected
  const setSelected = props.setSelected;
  const inStoreChecked=props.inStoreChecked;
  const shipToAddressChecked=props.shipToAddressChecked;

  const handledelivery = (e) => {
    setSelected(e)
    let result = e.map(({ value }) => value)
    deliveryTypeProduct(result)
  }

  const valueRenderer = (selected) => {
    return selected.length === 1 ? (
      <span className="selected-service" >{selected[0].label}</span>
    ) : (
      selected.map(({ label }) => <span className="selected-service" key={label}> {label}</span>)
    );
  };

  const DefaultItemRenderer = (e) => (
    (<><div className={`item-renderer ${e.disabled ? "disabled" : ""}`}>
      <Form.Check
        inline
        onChange={e.onClick}
        checked={e.checked}
        tabIndex={-1}
        disabled={e.disabled}
      />
      <span>{e.option.label}</span>
    </div>
    </>)
  );

  const CustomClearIcon = () => <div></div>;
  const ArrowRenderer = ({ expanded }) => <></>;

  return (
    <>
      <Row>
        <Col lg="5" xl="4">
          <Row>
            <Col lg="12">
              <Input
                label={Strings.AddProduct.Food.Label1}
                id="prod_name"
                type="text"
                name="prod_name"
                maxLength={100}
                value={addProductData.prod_name}
                errorMessage={addProductError.prod_nameError}
                onChange={handleChange}
                readOnly={edit}
              />
            </Col>
            <Col lg="12" className="price-icon">
              <Input
                label={Strings.AddProduct.Food.Label2}
                id="prod_price"
                name="prod_price"
                type="text"
                maxLength={10}
                value={addProductData.prod_price}
                onBlur={handleChange}
                errorMessage={addProductError.prod_priceError}
                onChange={handleChange}
                readOnly={edit}
                subText={Strings.AddProduct.PriceSub}
              />
            </Col>
            <Col lg="12">
              <Quantity
                onMinus={() => {
                  handleQuantity(1);
                }}
                onPlus={() => {
                  handleQuantity(2);
                }}
                label={Strings.AddProduct.Food.Label4}
                type="text"
                id="prod_qty"
                name="prod_qty"
                value={addProductData.prod_qty}
                errorMessage={addProductError.prod_qtyError}
                onChange={handleChange}
                maxLength={"6"}
                readOnly={edit}
                subText={Strings.AddProduct.QuantitySub}
              />
            </Col>
          </Row>
        </Col>
        <Col lg="5" xl="4" className="description">
          <Col lg="12">
            <CustSelectLabel
              label={Strings.AddProduct.Label6}
              options={freshnessList}
              value={props.freshnessValue}
              placeholder={Strings.AddProduct.Label7}
              onChange={(e) => props.handleFreshnessChange(e)}
              error={addProductError.category_idError}
              isDisabled={edit}
            />
          </Col>
          <CustTextArea
            label={Strings.AddProduct.Food.Label8}
            id="whats_great"
            name="whats_great"
            maxLength={500}
            value={addProductData.whats_great}
            //errorMessage={addProductError.prod_descriptionError}
            onChange={handleChange}
            readOnly={edit}
            subText={Strings.AddProduct.WhatsGreatSub}
          />
        </Col>
      </Row>
      <Row>
        <Col xl='8' lg='10'>
          <CustTextArea
            label={Strings.AddProduct.Food.Label3}
            id="prod_description"
            name="prod_description"
            maxLength={500}
            value={addProductData.prod_description}
            errorMessage={addProductError.prod_descriptionError}
            onChange={handleChange}
            readOnly={edit}
            subText={Strings.AddProduct.DescriptionSub}

          />
        </Col>
      </Row>
      <Row>
      <Col lg="10" xl="8">
      <h5>{Strings.AddProduct.Product.Title}</h5>
        <div className="delivery-type mt-3">
        <Form.Check
            inline
            label={Strings.AddProduct.Product.Label8}
            name="In-Store Pickup" 
            onChange={props.handleInStoreCheckBoxChange} 
            disabled={edit}
            checked={inStoreChecked === 0 ? true : false}
           
          />
          <Form.Check
            inline
            label={Strings.AddProduct.Product.Label9}
            name="Ship To My Address" 
            onChange={props.handleShipAddressCheckBoxChange} 
            disabled={edit}
            checked={inStoreChecked === 1 ? true : false }
          
          />

        </div>
       </Col>
       {addProductError.delivery_optionError &&
          addProductError.delivery_optionError != "" ? (
          <Form.Text className="red">
            {addProductError.delivery_optionError}
          </Form.Text>
        ) : (
          ""
        )}
        </Row>
      {props.type === "productDetails" ? (
        <h5>{Strings.ProductDetail.Title2}</h5>
      ) : (
        <>
          <h5>{Strings.AddProduct.Heading2}</h5>
          <p>{Strings.AddProduct.Label5}</p>
        </>
      )}
      <Row>
        <Col lg="10" xl="8">
          <Input
            label={Strings.AddProduct.Label3}
            id="video_link"
            type="text"
            name="video_link"
            maxLength={100}
            value={addProductData.video_link}
            onChange={handleChange}
            readOnly={edit}
          />
        </Col>
      </Row>
      <Row>
        <Col lg="10 multi-select-additional" xl="8" className="delivery-type ">
          <label className="d-block">{Strings.AddProduct.Food.Label5}</label>
          <MultiSelect
            className="additional-info"
            options={deliveryTypeList}
            value={selected}
            onChange={handledelivery}
            hasSelectAll
            disableSearch
            disabled={edit}
            valueRenderer={valueRenderer}
            ItemRenderer={DefaultItemRenderer}
            ClearSelectedIcon={edit && <CustomClearIcon />}
            ArrowRenderer={edit && ArrowRenderer}
          />
          {addProductError.delivery_typeError &&
            addProductError.delivery_typeError !== "" ? (
            <Form.Text className="red">
              {addProductError.delivery_typeError}
            </Form.Text>
          ) : (
            ""
          )}
          <h6>{Strings.AddProduct.Food.Label6}</h6>
          <div className="availability">
            <Input
              label={Strings.AddProduct.Food.Label7}
              id="prod_availability"
              maxLength={100}
              name="prod_availability"
              value={addProductData.prod_availability}
              errorMessage={addProductError.availabilityError}
              onChange={handleChange}
              readOnly={edit}
            />
          </div>
        </Col>
      </Row>
    </>
  );
};

export default Food;
