import React from "react";
import { Form, InputGroup, OverlayTrigger, Tooltip } from "react-bootstrap";
import QuantityAdd from "../assets/img/icons/QuantityAdd";
import QuantityLess from "../assets/img/icons/QuantityLess";
import "./Quantity.scss";
import Strings from "../assets/strings/Strings.json";
import Info from '../assets/img/icons/InfoTooltip'

const Quantity = (props) => {
  return (
    <Form className="quantity-group">
      <Form.Group>
        <Form.Label>{props.label}
        <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{Strings.AddProduct.QuantitySub}</Tooltip>}>
            <span className='tooltip-wrap'><Info /></span>
          </OverlayTrigger>
        </Form.Label>
        <InputGroup className="mb-3">
          {!props.readOnly && (
            <button
              type="button"
              disabled={props.readOnly}
              onClick={props.onMinus}
            >
              <QuantityLess />
            </button>
          )}
          <Form.Control
            id={props.id}
            name={props.name}
            type={props.type}
            placeholder={props.placeholder}
            onChange={props.onChange}
            value={props.value}
            ref={props.txtRef}
            disabled={props.status}
            readOnly={props.readOnly}
            className={
              props.errorMessage && props.errorMessage !== "" ? "error" : ""
            }
            onKeyDown={props.onKeyDown}
            maxLength={props.maxLength}
            autoCapitalize={props.autoCapitalize}
            onBlur={props.onBlur}
            onSubmit={props.onSubmit}
          />
          {props.errorMessage && props.errorMessage !== "" ? (
            <Form.Text className="error">{props.errorMessage}</Form.Text>
          ) : (
            ""
          )}
          {!props.readOnly && (
            <button
              type="button"
              disabled={props.readOnly}
              onClick={props.onPlus}
            >
              <QuantityAdd />
            </button>
          )}
        </InputGroup>
      </Form.Group>
    </Form>
  );
};

export default Quantity;
