import React, { useState, useEffect } from "react";
import { Button, Col, Row, Modal } from "react-bootstrap";
import Strings from "../assets/strings/Strings.json";
import CustSelectLabel from "./SelectLabel";
import DatePicker from "react-datepicker";
import CustTextArea from "./CustTextArea";
import Input from "./Input";
import * as constants from '../utils/constants'
import * as Messages from '../utils/Messages'
import { _vendorAddCoupon, _vendorCouponList, resetResponse } from '../actions/VendorAction';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from "react-toastify";
const CouponAddPopup = (props) => {

    const dispatch = useDispatch()
    // Add Coupon response
    const addCoupon = (params) => dispatch(_vendorAddCoupon(params));
    const addCouponApiResponse = useSelector(
        (RootReducer) => RootReducer.VendorReducer.vendorAddCouponResponse
    );
    const couponList = () => dispatch(_vendorCouponList());

    const DiscountType = [
      { label: "Percentage Discount", value: constants.STATUS_1 },
      { label: "Discount Amount", value: constants.STATUS_2 },
    ];
    const [selectedDiscountType, setSelectedDiscountType] = useState({ label: "Percentage Discount", value: constants.STATUS_1 })
    const [addCouponLoad, setAddCouponLoad] = useState(false)

    const intitalValue = {
        couponName: "",
        discountType: 1,
        discount: "",
        validFrom: new Date(),
        validTo: new Date(),
        description: "",
    }

    const intialErrorValue = {
        couponNameError: "",
        discountError: "",
        validFrom: new Date(),
        validTo: new Date(),
    } 

  const [addCouponData, setAddCouponData] = useState(intitalValue);

  const [addCouponError, setAddCouponError] = useState(intialErrorValue);
  
  //handle add coupon API response
  useEffect(() => {
    if (addCouponApiResponse && addCouponApiResponse.apiStatus) {
      if (addCouponApiResponse.apiErrorCode === constants.STATUS_200) {
        toast.success(addCouponApiResponse.apiMessage)
        couponList()
        resetResponse(dispatch,"vendorAddCoupon")
        props.setCouponListLoad(true);
        handleClose()
      } else {
        toast.error(addCouponApiResponse.apiMessage)
        resetResponse(dispatch,"vendorAddCoupon")
      }
      setAddCouponLoad(false)
    }
  }, [addCouponApiResponse])

  const handleClose = () => {
    props.setShow(false);
    setAddCouponData(intitalValue)
    setAddCouponError(intialErrorValue)
    setSelectedDiscountType({ label: "Percentage Discount", value: constants.STATUS_1 })
  };

  const handleDiscountTypeSelect = (e) => {
    setSelectedDiscountType(e)
    setAddCouponData((addCouponData) => ({...addCouponData,discountType:e.value,discount: "" }));
  }
  
  const handleOnTextChange = (event) => {
    if (event.target.name === "discount") {
      if (addCouponData.discountType === constants.STATUS_1) {
        if (/^[0-9]*$/.test(event.target.value)) {
          setAddCouponData((addCouponData) => ({
            ...addCouponData,
            [event.target.name]: event.target.value,
          }));
        }
      } else if (/^\d*\.?\d{0,2}$/.test(event.target.value)) {
        setAddCouponData((addCouponData) => ({
          ...addCouponData,
          [event.target.name]: event.target.value,
        }));
      }
    } else
      setAddCouponData((addCouponData) => ({
        ...addCouponData,
        [event.target.name]: event.target.value,
      }));
  }

    const handleDate = (e,type) => {
        if( type === constants.STATUS_1){
        setAddCouponData((addCouponData) => ({
            ...addCouponData,
            validFrom: e,
          }));
          if (e > addCouponData.validTo) {
            setAddCouponData((addCouponData) => ({
                ...addCouponData,
                validTo: e,
              }));
          }
        }else setAddCouponData((addCouponData) => ({
            ...addCouponData,
            validTo: e,
          }));
        
      }

      const Validate = () => {
        let isValid = true;
        setAddCouponError(intialErrorValue)
        const couponName = addCouponData.couponName.trim()
        const discount = addCouponData.discount.trim()
        if(couponName === ""){
            setAddCouponError((addCouponError) => ({
                ...addCouponError,
                couponNameError: Messages.ENTER_COUPON_VALUE,
              }));
              isValid= false;
        }
        if(discount === ""){
            setAddCouponError((addCouponError) => ({
                ...addCouponError,
                discountError: Messages.ENTER_VALUE,
              }));
              isValid= false;
        }
        return isValid;
      }

      const handleAddCoupon = () => {
        console.log("Data",addCouponData);
        console.log("Validate",Validate());
        const isValid = Validate();
        if(isValid){
            setAddCouponLoad(true)
            addCoupon(addCouponData)
        }

      }
  return (
    <>
      <Modal show={props.show} size="lg">
        <Modal.Header>
          <Modal.Title>Add New Coupon</Modal.Title>
        </Modal.Header>
        <Modal.Body className="pb-0">
          <Row>
            <Col xl={6} lang={6}>
              <Input
                label="Coupon Name"
                id="couponName"
                name="couponName"
                type="text"
                value={addCouponData?.couponName}
                maxLength={10}
                onChange={handleOnTextChange}
                errorMessage={addCouponError.couponNameError}
              />
            </Col>
            <Col xl={6} lang={6}>
              <Row className="pe-2">
                <Col className="pe-0 border-right-radius-none">
                  <CustSelectLabel
                    options={DiscountType}
                    label="Discount Type"
                    onChange={(e) => handleDiscountTypeSelect(e)}
                    name="message"
                    value={selectedDiscountType}
                  />
                </Col>
                <Col sm={4} className="select-child no-margin ps-0 position-relative profix-input">
                  <span className="input-prefix">{addCouponData.discountType === constants.STATUS_1 ? "% ":"$ "}</span>
                  <Input
                    id="discount"
                    name="discount"
                    type="text"
                    value={addCouponData?.discount}
                    onChange={handleOnTextChange}
                    errorMessage={addCouponError.discountError}
                    maxLength={addCouponData.discountType === constants.STATUS_1 ? 2 : 4}
                  />
                </Col>
              </Row>
            </Col>
            <Col xl={6} lg={6}>
              <div className="date-picker">
                <label>Valid From</label>
                <DatePicker minDate={new Date()}
                 onChange={(date) => handleDate(date,constants.STATUS_1)}
                selected={addCouponData?.validFrom}
                dateFormat="MM/dd/yyyy  hh:mm a"
                timeInputLabel="Time:"
                onKeyDown={(e) => {
                  e.preventDefault();
                }}
                showTimeInput
                />
              </div>
            </Col>
            <Col xl={6} lg={6}>
              <div className="date-picker">
                <label>Valid To</label>
                <DatePicker minDate={addCouponData?.validFrom}
                onChange={(date) => handleDate(date)}
                 selected={addCouponData?.validTo}
                 dateFormat="MM/dd/yyyy  hh:mm a"
                 timeInputLabel="Time:"
                 onKeyDown={(e) => {
                  e.preventDefault();
                }}
                 showTimeInput
                 />
              </div>
            </Col>
            <Col className="mt-3 position-relative">
              <small className="character-count">{addCouponData.description.length}/1000</small>
              <CustTextArea
                label={Strings.Profile.BusinessInfo.Label3}
                id="description"
                name="description"
                maxLength="1000"
                value={addCouponData?.description}
                onChange={handleOnTextChange}
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose} disabled={addCouponLoad}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleAddCoupon} disabled={addCouponLoad}>
            {addCouponLoad ? <div class="spinner-border" style={{ width: '1.5rem', height: '1.5rem' }} role="status" /> : "Generate Coupon" }
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default CouponAddPopup;
