import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import Input from "./Input";
import CustTextArea from "./CustTextArea";
import Strings from "../assets/strings/Strings.json";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import WebLink from "./WebLink";
import * as alerts from "../utils/Messages";
import * as constants from '../utils/constants'
import CustSelectLabel from "./SelectLabel";

const Coupon = (props) => {
  const handleChange = props.handleChange;
  const addProductData = props.addProductData;
  const addProductError = props.addProductError;
  const handleDateChange = props.handleDateChange;
  const DiscountType = props.discountType
  const selectedDiscountType = props.selectedDiscountType
  const handleDiscountTypeSelect = props.handleDiscountTypeSelect
  const edit = props.enableEdit;
  const date = props.date;
  return (
    <>
      <Row>
        <Col lg="5" xl="4">
          <Input
            label={Strings.AddProduct.Coupons.Label1}
            id="prod_name"
            name="prod_name"
            maxLength={100}
            value={addProductData.prod_name}
            errorMessage={addProductError.prod_nameError}
            onChange={handleChange}
            readOnly={edit}
          />
        </Col>
        <Col lg="5" xl="4">
          <Input
            label={Strings.AddProduct.Coupons.Label2}
            id="couponName"
            name="couponName"
            maxLength={constants.STATUS_10}
            value={addProductData.couponName}
            errorMessage={addProductError.couponNameError}
            onChange={handleChange}
            readOnly={edit}
          />
        </Col>
      </Row>
      <Row>
        <Col lg="5" xl="4">
          <Row className="pe-2">
            <Col className="pe-0 border-right-radius-none">
              <CustSelectLabel
                label={Strings.AddProduct.Coupons.Label3}
                options={DiscountType}
                onChange={(e) => handleDiscountTypeSelect(e)}
                name="message"
                value={selectedDiscountType}
                isDisabled={edit}
              />
            </Col>
            <Col sm={4} className="select-child no-margin ps-0 pe-0 position-relative profix-input">
              <span className="input-prefix">{addProductData.discountType === constants.STATUS_1 ? '%' : '$'}</span>
              <Input
                id="discount"
                name="discount"
                type="text"
                value={addProductData.discount}
                errorMessage={addProductError.discountError}
                onChange={handleChange}
                readOnly={edit}
                maxLength={addProductData.discountType === constants.STATUS_1 ? 2 : 5}
              />
            </Col>
          </Row>
        </Col>
        <Col lg="5" xl="4" className="price-icon">
          <Input
            label={Strings.AddProduct.Coupons.Label11}
            id="minPurchaseAmt"
            name="minPurchaseAmt"
            maxLength={constants.STATUS_5}
            value={addProductData.minPurchaseAmt}
            errorMessage={addProductError.minPurchaseAmtError}
            onChange={handleChange}
            readOnly={edit || addProductData.discountType === constants.STATUS_1 ? true : false}
            status={addProductData.discountType === constants.STATUS_1 ? true : false}
          />
        </Col>
      </Row>

      <Row>
        <Col lg="5" xl="4">
          <div className="date-picker mb-20">
            <label>{Strings.AddProduct.Coupons.Label4}</label>
            <DatePicker
              timeInputLabel="Time:"
              showTimeInput
              dateFormat="MM/dd/yyyy  hh:mm a"
              minDate={new Date()}
              disabled={edit}
              onKeyDown={(e) => {
                e.preventDefault();
              }}
              selected={date.posted_date}
              onChange={(e) => {
                handleDateChange(e, 1);
              }}
            />
          </div>
        </Col>
        <Col lg="5" xl="4">
          <div className="date-picker mb-20">
            <label>{Strings.AddProduct.Coupons.Label5}</label>
            <DatePicker
              timeInputLabel="Time:"
              showTimeInput
              dateFormat="MM/dd/yyyy  hh:mm a"
              minDate={date.posted_date}
              disabled={edit}
              onKeyDown={(e) => {
                e.preventDefault();
              }}
              selected={date.valid_date}
              onChange={(e) => {
                handleDateChange(e, 2);
              }}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg="10" xl="8">
          <h5>{Strings.AddProduct.Coupons.WebsiteTitle}</h5>
          <p className="mb-0">{alerts.WEBSITE_LINK}</p>
          <WebLink
            label={Strings.AddProduct.Coupons.Label6}
            id="website_link"
            maxLength={100}
            name="website_link"
            value={
              addProductData.website_link === null
                ? ""
                : addProductData.website_link
            }
            errorMessage={addProductError.website_linkError}
            onChange={handleChange}
            readOnly={edit}
          />
        </Col>
      </Row>
      <Row>
        <Col lg="5" xl="4">
          <CustTextArea
            label={Strings.AddProduct.Coupons.Label7}
            id="prod_description"
            name="prod_description"
            maxLength={500}
            value={addProductData.prod_description}
            errorMessage={addProductError.prod_descriptionError}
            onChange={handleChange}
            readOnly={edit}
            subText={Strings.AddProduct.DescriptionSub}
          />

        </Col>
        <Col lg="5" xl="4">
          <CustTextArea
            label={Strings.AddProduct.Coupons.Label8}
            id="whats_great"
            name="whats_great"
            maxLength={500}
            value={addProductData.whats_great}
            //errorMessage={addProductError.prod_descriptionError}
            onChange={handleChange}
            readOnly={edit}
            subText={Strings.AddProduct.WhatsGreatSub}
          />
        </Col>
      </Row>
      <Row>
        <Col lg="10" xl="8">
          {props.type === "productDetails" ? (
            <h5>{Strings.ProductDetail.Title2}</h5>
          ) : (
            <>
              <h5>{Strings.AddProduct.Coupons.Title}</h5>
              <p>{Strings.AddProduct.Coupons.Label9}</p>
            </>
          )}
          <Input
            label={Strings.AddProduct.Coupons.Label10}
            id="video_link"
            type="text"
            name="video_link"
            maxLength={100}
            value={addProductData.video_link}
            onChange={handleChange}
            readOnly={edit}
          />
        </Col>
      </Row>
      {/* <Button variant="primary" className="mt-3">{Strings.AddProduct.Coupons.Btn}</Button> */}
    </>
  );
};

export default Coupon;
