import React, { useEffect, useRef, useState, useCallback } from "react";
import './Register.scss';
import Strings from "../assets/strings/Strings.json";
import { Button, Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import '../assets/styles/HeatMap.scss';
import { useDispatch, useSelector } from 'react-redux';
import { _heatMap } from '../actions/VendorAction';
import * as constants from '../utils/constants';

const RegisterSuccess = (props) => {
  const navigate = useNavigate();
  const mapRef = useRef(null);
  const map = useRef(null);
  const heatmap = useRef(null);
  const [heatmapData, setHeatmapData] = useState([]);

  const dispatch = useDispatch();
  const heatMap = () => dispatch(_heatMap());
  const heatMapResponse = useSelector(
    (RootReducer) => RootReducer.VendorReducer.heatMapinfo
  );

  const successButton = () => {
    props.onPress();
    navigate('/');
  };

  // Function to compute a value for color coding
  const computeValue = useCallback((item) => {
    return 500;
  }, []);

  const gradient = [
    "rgba(0, 255, 255, 0)",
    "rgba(0, 255, 255, 1)",
    "rgba(0, 191, 255, 1)",
    "rgba(0, 127, 255, 1)",
    "rgba(0, 63, 255, 1)",
    "rgba(0, 0, 255, 1)",
    "rgba(0, 0, 223, 1)",
    "rgba(0, 0, 191, 1)",
    "rgba(0, 0, 159, 1)",
    "rgba(0, 0, 127, 1)",
    "rgba(63, 0, 91, 1)",
    "rgba(127, 0, 63, 1)",
    "rgba(191, 0, 31, 1)",
    "rgba(255, 0, 0, 1)",
  ];

  //heatmap API call
  useEffect(() => {
    heatMap();
  }, []);

  // Function to get a color based on the computed value
  const getColorForValue = useCallback((value) => {
    if (value >= 1000) {
      return 'green'; // Red
    } else if (value >= 100) {
      return 'green'; // Yellow
    } else {
      return 'green'; // Green
    }
  }, []);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAP_ACCESS_KEY}&callback=initMap&libraries=visualization&v=weekly`;
    script.async = true;
    window.initMap = initMap;
    script.onload = () => {
    };
    script.onerror = (error) => {
      console.error('Error loading Google Maps API script:', error);
    };
    document.head.appendChild(script);
    return () => {
      document.head.removeChild(script);
    };
  }, []);

  const initMap = () => {
    if (!mapRef.current || !heatmapData.length || !window.google.maps.visualization) {
      return; // Map container not available, no data for heatmap, or visualization library not loaded
    }
  
    map.current = new window.google.maps.Map(mapRef.current, {
      center: { lat: 37.775, lng: -122.434 },
      zoom:2,
      scaleControl: true,
      mapTypeControl: true,
      minZoom:+2
    });
  
    // Create a heatmap layer
    heatmap.current = new window.google.maps.visualization.HeatmapLayer({
      data: heatmapData,
      map: map.current,
      opacity:1,
      gradient:gradient
    });
  
    // createLegend();
  };

  //heatmap API response
  useEffect(() => {
    if (heatMapResponse && heatMapResponse.apiStatus) {
      if (heatMapResponse.apiErrorCode === constants.STATUS_200) {
        let AD = []
        let DA = []
        heatMapResponse.apiResponse.map((item) => {
          // Use the memoized functions
          const value = computeValue(item);
          const color = getColorForValue(value);
          if (
            item.location &&
            item.location.coordinates &&
            Array.isArray(item.location.coordinates) &&
            item.location.coordinates.length >= 2
          ) {           
            if (AD.length === 0) {
              AD.push({ lat: item.location.coordinates[1], long: item.location.coordinates[0] })
              DA.push(new window.google.maps.LatLng(
                item.location.coordinates[1],
                item.location.coordinates[0]
              ));
            } else {
              const result = AD.find(({ lat }) => lat === item.location.coordinates[1]);
              if (!result) {
                AD.push({ lat: item.location.coordinates[1], long: item.location.coordinates[0] })
                DA.push(new window.google.maps.LatLng(
                  item.location.coordinates[1],
                  item.location.coordinates[0]
                ));
              }
            }
          } else {
            // Handle missing or incorrect data
            console.error('Invalid data structure:', item);
            // return null;
          }
        });
        setHeatmapData(DA);
      }
    }
  }, [heatMapResponse]);

  useEffect(() => {
    if (window.google && window.google.maps) {
      initMap();
    } else {
      console.error('Google Maps API is not available');
    }
  }, [heatmapData]);

  return (
    <Col className='register-wrap success-heatmap'>
      <Row>
        <Col lg='8'>
          {window.google && window.google.maps ? (
            <>
            <div ref={mapRef} className="heatmap-map register"></div>
            <div className='map-legend d-flex pt-3 justify-content-end'>
          <div className="legend-item">
            <div className="legend-color" style={{ backgroundColor: 'red' }}></div>
            <div className="legend-label">High</div>
          </div>
          <div className="legend-item">
            <div className="legend-color" style={{ backgroundColor: '#0002d0' }}></div>
            <div className="legend-label">Mid</div>
          </div>
          <div className="legend-item">
            <div className="legend-color" style={{ backgroundColor: '#00a4ff' }}></div>
            <div className="legend-label">Low</div>
          </div>
        </div>
            </>
          ) : (
            <p>Loading or error message for Google Maps API...</p>
          )}
        </Col>
        <Col lg='4' className="text-center d-flex flex-column justify-content-center align-items-center p-4">
          <h1>{Strings.Register.Success.Title}</h1>
          <p className='m-0'>{Strings.Register.Success.Ppart1}<span> {Strings.Register.Success.Ppart2}</span> {Strings.Register.Success.Ppart3}</p>
          <Button variant='primary' onClick={successButton}>{Strings.Register.Success.Btn}</Button>
        </Col>
      </Row>
    </Col>
  );
};

export default RegisterSuccess;
