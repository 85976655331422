import React, { useState } from 'react'
import { OverlayTrigger } from 'react-bootstrap';
import Tooltip from 'react-bootstrap/Tooltip';

const Notificaion = (props) => {
  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Notifications
    </Tooltip>
  );
  return (
    <OverlayTrigger
      placement="bottom"
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip}
    >
      <svg xmlns="http://www.w3.org/2000/svg" width="19.35" height="21.135" viewBox="0 0 19.35 21.135">
        <g id="Group_824" data-name="Group 824" transform="translate(-1215.898 -42.25)">
          <g id="icn_notification" transform="translate(1154.648 -114)">
            <path id="Path_13" data-name="Path 13" d="M69,175.9v.089a2.675,2.675,0,1,0,5.35,0V175.9" transform="translate(-0.75 -2.029)" fill="none" stroke-miterlimit="10" stroke-width="1.5" />
            <path id="Path_14" data-name="Path 14" d="M77.172,165.926v-2.678a6.247,6.247,0,1,0-12.495,0v2.678c0,2.946-2.677,3.66-2.677,5.356,0,1.517,3.481,2.678,8.925,2.678s8.925-1.16,8.925-2.678C79.85,169.586,77.172,168.872,77.172,165.926Z" transform="translate(0 0)" fill="none" stroke-linecap="square" stroke-miterlimit="10" stroke-width="1.5" />
          </g>
        </g>
      </svg>
    </OverlayTrigger>
  )
};

export default Notificaion;

