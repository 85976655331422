import React from 'react'
import Select from 'react-select';
import './Select.scss'

const CustSelect = (props) => {
  const defaultValue = props.defaultValue
  const colourStyles = {
    option: (styles, { isSelected }) => ({
      ...styles,
      background: isSelected
        ? '#89CB08'
        : '#ffffff',
          zIndex: 1,
    }),
    menu: base => ({
      ...base,
      zIndex: 100,
    }),
  }
  return (
    <div className='cut-select'>
      <Select 
        classNamePrefix='filter'
        placeholder={props.placeholder}
        styles={colourStyles}
        onChange={props.onChange}
        noOptionsMessage={props.noOptionsMessage}
        options={props.options}
        defaultValue={defaultValue}
        value={props.value}
         />
    </div>
  )
};

export default CustSelect;

