/**
 * @file   src\
 * @brief  This file is responsible for 
 * @date   Oct, 2022
 * @author ZCO Engineer
 * @copyright (c) 2022, ZCO
 */

import { Button, Col, Row } from 'react-bootstrap'
import InnerLayout from '../components/InnerLayout'
import Strings from '../assets/strings/Strings.json'
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { _refundInfo, _approveRefund, _rejectRefund, resetResponse } from '../actions/VendorAction';
import { useDispatch, useSelector } from 'react-redux';
import * as constants from '../utils/constants';
import { toast } from 'react-toastify';
import { dateToMMDDYYYdate } from '../helpers/validationHelper';

const Refund = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const id = location.state.id


  const [refundInfoData, setRefundInfoData] = useState("");

  const refundInfo = (refundInfoParams) => dispatch(_refundInfo(refundInfoParams));
  const refundInfoResponse = useSelector((RootReducer) => RootReducer.VendorReducer.refundInfoResponse)

  const approveRefund = (refundInfoParams) => dispatch(_approveRefund(refundInfoParams));
  const approveRefundResponse = useSelector((RootReducer) => RootReducer.VendorReducer.approveRefundResponse)

  const rejectRefund = (refundInfoParams) => dispatch(_rejectRefund(refundInfoParams));
  const rejectRefundResponse = useSelector((RootReducer) => RootReducer.VendorReducer.rejectRefundResponse)


  useEffect(() => {
    if (id) {
      refundInfo({ "purchaseId": id })
    }
  }, [])

  useEffect(() => {
    if (refundInfoResponse && refundInfoResponse.apiStatus) {
      if (refundInfoResponse.apiErrorCode == constants.STATUS_200 && refundInfoResponse.apiResponse) {
        setRefundInfoData(refundInfoResponse.apiResponse.order_info)
      }
    }
  }, [refundInfoResponse])

  const handleApproveRefund = (purchaseId) => {
    if (purchaseId) {
      approveRefund(({ "purchaseId": id }))
    }

  }

  const handleRejectRefund = (purchaseId) => {
    if (purchaseId) {
      rejectRefund(({ "purchaseId": id }))
    }
  }


  useEffect(() => {
    if (approveRefundResponse && approveRefundResponse.apiStatus) {
      if (approveRefundResponse.apiErrorCode == constants.STATUS_200) {
        navigate("/purchasehistory")
        toast.success(approveRefundResponse.apiMessage)
      }
      else {
        toast.error(approveRefundResponse.apiMessage)
      }
      resetResponse(dispatch, "approveRefund");
    }
  }, [approveRefundResponse])


  useEffect(() => {
    if (rejectRefundResponse && rejectRefundResponse.apiStatus) {
      if (rejectRefundResponse.apiErrorCode == constants.STATUS_200) {
        navigate("/purchasehistory")
        toast.success(rejectRefundResponse.apiMessage)
      }
      else {
        toast.error(rejectRefundResponse.apiMessage)
      }
      resetResponse(dispatch, "rejectRefund");
    }
  }, [rejectRefundResponse])

  return (
    <InnerLayout>
      <Col className='action-wrap'>
        <Row>
          <Col lg='6' className='refund'>
            <h1 className='mb-2'>{Strings.Refund.Title}</h1>
            <span>{Strings.Refund.Subtitle}</span>
          </Col>
        </Row>
      </Col>
      <Col className="inner-main refund">
        <Row>
          <Col lg="6" className='status'>
            <Row>
              <Col lg="4">
                <label className='d-block'>{Strings.Refund.Label1}</label>
                <span>{refundInfoData.order_id}</span>
              </Col>
              <Col lg="4">
                <label className='d-block'>{Strings.Refund.Label2}</label>
                <span>{refundInfoData.product_name}</span>
              </Col>
              <Col lg="4">
                <label className='d-block'>{Strings.Refund.Label3}</label>
                <span>{dateToMMDDYYYdate(refundInfoData.purchase_date)}</span>
              </Col>
              <Col lg="12">
                <h3>{Strings.Refund.Subtitle1}</h3>
                <p>{refundInfoData.refund_reason}</p>
              </Col>
              <Col lg="12">
                <h3 className='mt-4 mb-4'>{Strings.Refund.Subtitle2}</h3>
                <div className='button-wrap'>
                  <Button variant='outline-primary' onClick={() => handleRejectRefund(id)}>{Strings.Refund.Btn1}</Button>
                  <Button variant='primary' onClick={() => handleApproveRefund(id)}>{Strings.Refund.Btn2}</Button>
                </div>
                <p className='note'>{Strings.Refund.Note}</p>
              </Col>
            </Row>
          </Col>
          <Col lg="6" className="breakdown">
            <h3>{Strings.Refund.Subtitle3}</h3>
            <div className='break-details'>
              <ul>
                <li><label>{Strings.Refund.Label4}</label><span>${refundInfoData.amount_paid}</span></li>
                <li><label>{Strings.Refund.Label10}</label><span>${refundInfoData.discountAmount}</span></li>
                <li><label>{Strings.Refund.Label6}</label><span>${refundInfoData.processing_fee}</span></li>                
                <li><label>{Strings.Refund.Label5}</label><span>${refundInfoData.nearbuys_commission}</span></li>
                <li><label>{Strings.Refund.Label7}</label><span>${refundInfoData.item_price}</span></li>
                <li className='total'><label>{Strings.Refund.Label8}</label><span>${refundInfoData.item_price}</span></li>
              </ul>
            </div>
          </Col>
        </Row>
      </Col>
    </InnerLayout>
  );
}
export default Refund;
