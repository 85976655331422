/**
 * @file   src\utils\ResponseCode.js
 * @brief  javascript file to config messages used
 * @date   July , 2022
 * @author ZCO Engineer
 * @copyright (c) 2022, ZCO
 */
const RESPONSE_CODE = {
  300 : "Could not complete the process. Please try again after some time.",
  1001: "You have been signed in successfully.",
  1002: "You have been signed up successfully.",
  1003: "An verification code has been sent to your email address. Please use it to verify the same and sign up.",
  1004: "An verification code has been sent to your phone number. Please use it to verify the same and sign up.",
  1005: "NearBuys: verification code to Sign Up",
  1006: "NearBuys: Reset Password",
  1007: "A link to reset your password has been mailed to your registered email address.",
  1008: "Step 1 of registration has been completed successfully.",
  1009: "Verification code is verified. Step 2 of registration has been completed successfully.",
  1010: "Step 2 of registration has been completed successfully.",
  1011: "An access token has been generated successfully.",
  1012: "You have signed out successfully.",
  1013: "The password has been updated successfully.",
  1014: "The list of categories has been retrieved successfully.",
  1015: "The list of vendors has been successfully retrieved.",
  1016: "Your account has been deleted.",
  1017: "The bank info has been updated successfully.",
  1018: "Your subscription has been added successfully.",
  1019: "Your subscription has been updated successfully.",
  1020: "The business info has been updated successfully.",
  1021: "The basic info has been updated successfully.",
  1022: "The S3 pre-signed URL has been generated successfully.",
  1023: "The image has been uploaded successfully.",
  1024: "The profile information has been retrieved successfully.",
  1025: "The subscription list has been retrieved successfully.",
  1026: "The deals have been retrieved successfully.",
  1027: "The vendors with deals have been retrieved successfully.",
  1028: "The products list has been retrieved successfully.",
  1029: "The purchase history has been retrieved successfully.",
  1030: "NearBuys: Account Signup",
  1031: "The predefined messages have been listed successfully.",
  1032: "The details of the pending vendors have been listed successfully.",
  1033: "The image has been removed.",
  1034: "The product messages have been retrieved successfully.",
  1035: "The message has been sent successfully.",
  1036: "The report has been exported as PDF successfully.",
  1037: "The message has been viewed successfully.",
  1038: "A new notification has been received.",
  1039: "No new notification to display.",
  1040: "Thank you! Your payment has been received successfully.",
  1041: "Your password has been changed successfully.",
  1042: "The notifications have been marked as read.",
  1043: "The Stripe verify url has been genearted successfully.",
  1044: "Your stripe identity has been verified successfully.",
  1045: "The subscription has been successfully created.",
  1046: `Invalid Security Key.`,
  1047: `The access token has expired. Please sign in again.`,
  1048: "Enter all mandatory fields.",
  1049: "Enter a valid Email Address and Password.",
  1050: "This email address has already been used. Please use another.",
  1051: "Could not process the request. Please try again later.",
  1052: "User not found. Please sign up to continue.",
  1053: "Incorrect verification code. Enter a valid verification code.",
  1054: "This phone number has already been used. Please use another.",
  1055: "User not found. Please sign up to continue.",
  1056: "The specified Email Address does not exist.",
  1057: "Invalid Token.",
  1058: "You do not have access to this account. Please contact the application administrator.",
  1059: "New Password and Confirm New Password entries must be the same.",
  1060: "Your account has been blocked. Please contact the application administrator.",
  1061: "Your account has been deleted. Please sign up to continue.",
  1062: "User not found. Please sign up to continue.",
  1063: "You have already been subscribed to the application.",
  1064: "Subscribe to upgrade the application.",
  1065: "A maximum of 4 file(s) can only be uploaded.",
  1066: "No Deals found!",
  1067: "Enter a valid Phone Number.",
  1068: "Enter a valid Email Address.",
  1069: "Email and Phone Number cannot be edited.",
  1070: "No Products found.",
  1071: "Your account has not yet been approved to use this application.",
  1072: "No deals found within the 7 miles radius of your location.",
  1073: "Enter letters only.",
  1074: "A maximum of 200 characters can only be entered.",
  1075: "A maximum of 25 characters can only be entered.",
  1076: "Enter a valid Zip code.",
  1077: "Special characters are not allowed.",
  1078: "Your account has not been approved yet.",
  1079: "The verification code sent to your email has expired. Please use Resend code and try again.",
  1080: "Please enter the required input.",
  1081: "A maximum of 4 file(s) can only be uploaded.",
  1082: "No purchase history found.",
  1083: "The Phone number or the email id entered is already used, Please update to continue.",
  1084: "The password reset link has expired.",
  1085: "Your email has not yet been verified. You can sign in after successful email verification.",
  1086: "Password and Confirm Password entries must be the same.",
  1087: "Please complete the Sign Up process to continue to the application.",
  1088: "No vendors found.",
  1089: "You have exceeded the maximum limit for resending the verification code. Please retry again after sometime.",
  1090: "No notifications found.",
  1091: "Could not delete the image from the server. Please try again later.",
  1092: "No images found.",
  1093: "Could not fetch the location details. Please try again later.",
  1094: "Enter Vendor ID to view the Pending list.",
  1095: "The verification code sent to your phone number has expired. Please use Resend code and try again.",
  1096: "The email id entered is already used, Please update to continue.",
  1097: "The phone number entered is already used, Please update to continue.",
  1098: "Something went wrong. Please try again.",
  1099: "Please select profile image to upload.",
  1100: "The address cannot be empty.",
  1101: "The business phone number cannot be empty.",
  1102: "Select a picture to upload.",
  1103: "Upload an image file of size below 6MB.",
  1104: "Could not process your payment. Please try again later.",
  1105: "Invalid Password.",
  1106: "Something went wrong. Please try again later.",
  1107: "An error occurred .Please try again later.",
  1108: `The access token has expired. Please sign in again.`,
  1109: "Please verify your bank information.",
  1110: "Invalid credentials.",
  1111: "A maximum of 15 characters can only be entered.",
  1112: "A maximum of 4 numbers can only be entered.",
  1113: "Please try again after 10 minutes.",
  1114: "An error occurred while trying to find subscription history.",
  1115: "We were unable to find your subscription details.",
  1116: "Thank you! We have received your payment.",
  1117: "The product has been moved to Upcoming List.",
  1118: "The subscription has been canceled.",
  1119: "The subscription details have been retrieved successfully.",
  1120: "The subscription history has been retrieved successfully.",
  1121: "The details of the card have been retrieved successfully.",
  1122: "The details of the card have been saved successfully.",
  1123: "The card has been deleted.",
  1124: "The account details have been successfully retrieved.",
  1125: "The PDF has been removed successfully.",
  1126: "The product has been saved and moved to upcoming product list.",
  1127: "The product has been deleted.",
  1128: "The details of the products have been retrieved successfully.",
  1129: "The details of the product have been updated successfully.",
  1130: "The S3 pre-signed URL has been generated successfully.",
  1131: "The image has been uploaded successfully.",
  1132: "The selected products have been listed successfully.",
  1133: "The image has been removed.",
  1134: "The product has been reported successfully.",
  1135: "The list of reasons has been retrieved successfully.",
  1136: "The selected products have been broadcast successfully.",
  1137: "Invalid Security Key.",
  1138: `The access token has expired. Please sign in again.`,
  1139: "Could not process the request. Please try again later.",
  1140: "Please subscribe to add a product.",
  1141: "You have added maximum products. Please upgrade your subscription plan if you wish to add more products.",
  1142: "Please enter the required input.",
  1143: "Product not found.",
  1144: "A maximum of 4 file(s) can only be uploaded.",
  1145: "User not found. Please sign up to continue.",
  1146: "Your subscription is expired. Please subscribe to continue.",
  1147: "No items found.",
  1148: "Please update your location and try again.",
  1149: "No NearBuys users found in your location.",
  1150: "No image found.",
  1151: "Could not delete the image from the server. Please try again later.",
  1152: "You are allowed to list the products only once in a day.",
  1153: "Upload a file of size less than or equal to 6MB.",
  1154: "You are not allowed to remove all the images. At least one image must be available.",
  1155: "No messages to display.",
  1156: "No reasons to display.",
  1157: "Please verify your bank account details to access List Products.",
  1158: "Your account has been blocked. Please contact the application administrator.",
  1159: "You have signed in successfully.",
  1160: "Your password has been changed successfully.",
  1161: "The list of vendors have been retrieved successfully.",
  1162: "The vendor has been deleted.",
  1163: "The status of the selected vendor has been changed successfully.",
  1164: "The details of the selected vendor have been retrieved successfully.",
  1165: "You have signed out successfully.",
  1166: "The business details of the selected vendor have been retrieved successfully.",
  1167: "The details of the admin have been retrieved successfully.",
  1168: "Your password has been changed successfully.",
  1169: "The user has been deleted.",
  1170: "The user list has been successfully retrieved.",
  1171: "The user orders has been successfully retrieved.",
  1172: "A link to reset your password has been mailed to your registered email address.",
  1173: "The status of the selected user has been changed successfully.",
  1174: "The vendor deals has been successfully retrieved.",
  1175: "The vendor has been blocked.",
  1176: "The vendor has been activated.",
  1177: "The vendor has been approved.",
  1178: "The vendor has been rejected.",
  1179: "Your profile details has been updated successfully.",
  1180: "The pre-signed URL has been generated successfully.",
  1181: "The profile image has been updated successfully.",
  1182: "The profile image has been removed.",
  1183: "The vendor has been approved successfully.",
  1184: "The user has been blocked.",
  1185: "The user has been activated.",
  1186: "The user details retrieved successfully.",
  1187: "The categories have been retrieved successfully.",
  1188: "The Sub categories have been retrieved successfully.",
  1189: "The subcategory has been added successfully.",
  1190: "The subcategory details have been updated successfully.",
  1191: "The password has been updated successfully.",
  1192: "The subscription list has been retrieved successfully.",
  1193: `Invalid Security Key.`,
  1194: `The access token has expired. Please sign in again.`,
  1195: "Enter all mandatory fields.",
  1196: "Could not process the request. Please try again later.",
  1197: "User not found.",
  1198: "Invalid token.",
  1199: "You do not have access to this account. Please contact the application administrator.",
  1200: "New Password and Confirm New Password entries must be the same.",
  1201: "Invalid Password.",
  1202: "Invalid Email Address.",
  1203: "Invalid credentials.",
  1204: "Vendors not found.",
  1205: "No vendor details is found.",
  1206: "No deals available.",
  1207: "Business details not available for the selected vendor.",
  1208: "No users found.",
  1209: "No user information found.",
  1210: "Could not delete the image from the server. Please try again later.",
  1211: "Please provide proper input.",
  1212: "No categories to display.",
  1213: "This subcategory already exists. Please add another subcategory.",
  1214: "The password reset token is either invalid or has expired.",
  1215: "Password and Confirm Password entries must be the same.",
  1216: "The specified Email Address does not exist.",
  1217: "A maximum of 200 characters can only be entered.",
  1218: "A maximum of 15 characters can only be entered.",
  1219: "No Sub categories to display.",
  1220: "An access token has been generated successfully.",
  1221:	"An error occured .Please try again later",
  1222:	"The reported products list has been retrieved successfully.",
  1223:	"No Products found.",
  1224:	"The schedules list has been retrieved successfully.",
  1225:	"There are no new schedules.",
  1226:	"The schedules were successfully canceled.",
  1227: "Unsupported file type.",
  1228: "The notifications of the selected products have been scheduled and listed successfully.",
  1229: "Invalid Old password.",
  1230: "Profile photo updated.",
  1231: "You have exceeded the maximum limit for resending the verification code for the day. Please retry again tomorrow.",
  1232:	"You have exceeded the maximum limit of Resend verification code attempts. Please try again later.",
  1233:	"You have reached the maximum limit per month for listing Nationwide products.",
  1234:	"You have reached the maximum limit of offerings allowed per month.",
  1235: "Your proxy address/Zip codes have been updated successfully.",
  1300:	"Invalid Origin header.",
  1301:	"Invalid Referer header.",
  1302:	"Email is verified",
  1303:	"Phone number is verified.",
  1304:	"You are not allowed to add Proxy address. Please upgrade your subscription plan to access this feature.",
  1305:	"You have already added maximum Zip codes allowed.",
  1306:	"Your proxy address/zip codes have been listed successfully.",
  1307:	"No proxy address/Zip codes found.",
  1308:	"The items selected is more than the offerings allowed per scheduled broadcast.",
  1309:	"The items selected is more than the offerings allowed per broadcast.",
  1310:	"No default address found.",
  1311:	"No proxy zip code found, You can add proxy zip code from your profile.",
  1312:	"No proxy address found, You can add proxy address from your profile.",
  1313:	"You have reached the maximum limit of scheduled broadcast allowed per month.",
  1314:	"The timeslots have been retrieved successfully.",
  1315:	"No timeslots available.",
  1316:	"Invalid input format.",
  1318:	"Invalid input. Enter letters only.",
  1320:	"Upload an image file of size below 6MB.",
  1321:	"Invalid Allow Access Control Origin in header.",
  1322:	"Invalid Origin header.",
  1323:	"Invalid Referer header.",
  1324:	"No pending vendor info found.",
  1325:	"Invalid Allow Access Control Origin in header.",
  1326:	"Invalid input. Enter letters only.",
  1327:	"This mobile number does not exist. Please check the entered mobile number.",
  1328:	"You have reached the maximum limit of manual broadcast allowed per month.",
  1329:	"The selected items are more than the maximum offerings allowed per month. Please reduce the number of items and try again.",
  1330:	"The selected items are more than the maximum nationwide listings allowed per month. Please reduce the number of items and try again.",
  1331:	"Please subscribe to add proxy address or zip code.",
  1332:	"Incorrect Email OTP. Enter a valid OTP.",
  1333:	"Incorrect Mobile OTP. Enter a valid OTP.",
  1334: "The user has been blocked. Please try again later.",
  1335:	"No such Purchase Order found!",
  1336:	"Order details has been retrieved successfully.",
  1337:	" The refund request has been rejected successfully.",
  1338:	"Refund initiated successfully. It may take a few days for the money to reach the customer's bank account.",
  1339:	"The refund has already been processed. You can't reject the refund request once the refund has been initiated.",
  1340:	" Address deleted. The product has been disassociated from the address.",
  1341: "No subcategories found.",
  1342: "The subcategory has been deleted successfully.",
  1343: "We cannot delete this subcategory since products already exist.",
};

export default RESPONSE_CODE;

