import React, { useState } from 'react'

const ArrowAccordion = (props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="7" viewBox="0 0 12 7">
            <path id="chevron-left" d="M0,11a1,1,0,0,1-.707-.293,1,1,0,0,1,0-1.414L3.586,5-.707.707a1,1,0,0,1,0-1.414,1,1,0,0,1,1.414,0l5,5a1,1,0,0,1,0,1.414l-5,5A1,1,0,0,1,0,11Z" transform="translate(11 1) rotate(90)" />
        </svg>
    )
};

export default ArrowAccordion;

