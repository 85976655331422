import React from "react";
import "./Notification.scss";
import { Button, Col } from "react-bootstrap";
import Strings from "../assets/strings/Strings.json";
import NoImage from "../assets/img/avatar.svg";
import moment from "moment";

const Notify = (props) => {
  return (
    <Col className="notify-wrap d-flex justify-content-between">
      <div className="notify-left">
        <span className="message">{Strings.Notifications.Message}</span>
        <div className="user-dtls d-flex align-items-center">
          <div className="user-photo">
            <img
              src={props.profilephoto ? props.profilephoto : NoImage}
              alt=""
            />
          </div>
          <div>
            <span className="user-name">{props.username}</span>
            <div>
              <span className="user-date">
                 {moment(props.datetime).format("MM/DD/YYYY, h:mm a")}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex align-items-center">
        <Button onClick={props.view}>{Strings.Notifications.Btn}</Button>
      </div>
    </Col>
  );
};

export default Notify;
