import React from 'react'

const OtpPhone = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="23.93" height="23.93" viewBox="0 0 23.93 23.93">
      <path id="ios-call" d="M25.854,21.728a18.478,18.478,0,0,0-3.86-2.583c-1.157-.556-1.581-.544-2.4.046-.682.493-1.123.951-1.907.779a11.377,11.377,0,0,1-3.831-2.835A11.3,11.3,0,0,1,11.021,13.3c-.166-.79.292-1.226.779-1.907.59-.819.607-1.243.046-2.4a18.113,18.113,0,0,0-2.583-3.86c-.842-.842-1.031-.659-1.495-.493A8.506,8.506,0,0,0,6.4,5.371,4.129,4.129,0,0,0,4.756,7.106c-.326.7-.7,2.016,1.22,5.441a30.359,30.359,0,0,0,5.338,7.119h0l.006.006.006.006h0a30.477,30.477,0,0,0,7.119,5.338c3.425,1.924,4.736,1.546,5.441,1.22a4.059,4.059,0,0,0,1.735-1.644,8.506,8.506,0,0,0,.727-1.369C26.513,22.758,26.7,22.569,25.854,21.728Z" transform="translate(-3.523 -3.539)" fill="none" stroke-width="2" />
    </svg>

  )
};
export default OtpPhone;

