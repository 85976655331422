/**
 * @file   src\
 * @brief  This file is responsible for 
 * @date   Oct, 2022
 * @author ZCO Engineer
 * @copyright (c) 2022, ZCO
 */
import React, { useState } from 'react'
import '../assets/styles/register.scss'
import Strings from '../assets/strings/Strings.json';
import InnerLayout from '../components/InnerLayout';
import RegisterSuccess from '../components/RegisterSuccess';
import RegisterForms from '../components/RegisterForms';

const Register = (props) => {

  const [switchCase, setSwitchCase] = useState(false)

  const registerCompleteShow = () => {
    setSwitchCase(true)
  }
  const registerCompleteHide = () => {
    setSwitchCase(false)
  }
  return (
    <InnerLayout hideHeader={props.hideHeader}>
      <h1>{Strings.Register.Title}</h1>
      {switchCase ?
        <RegisterSuccess onPress={registerCompleteHide} /> :
        <RegisterForms onPress={registerCompleteShow} step={props.step} props={props} />
      }
    </InnerLayout>
  );
}
export default Register;
