import React from 'react'
import './PlanCancel.scss'
import Strings from "../assets/strings/Strings.json";

const PlanCancel = (props) => {

  return (
    <div className={props.className}>
      <span className='current'>{props.isUpComing===false ? Strings.Profile.Subscription.Label1:Strings.Profile.Subscription.Label11}<span className='subscription'> {props.planName}</span></span>
      <span className='price'>${props.planCost}<span className='details'>/{props.planType}</span></span>
    </div>
  )
};

export default PlanCancel;

