import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from "react-router-dom";
import {  Col, Table,OverlayTrigger, Popover  } from 'react-bootstrap'
import * as constants from "../utils/constants";
import { useDispatch, useSelector } from "react-redux";
import { _vendorZctaBroadcastData ,resetResponse } from "../actions/VendorAction";
import { currencyFormat } from '../helpers/validationHelper';

const ZctaAnalysis = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate()
  // vendor Zcta Data start
  const vendorZctaBroadcast = (data) => dispatch(_vendorZctaBroadcastData(data));
  const vendorZctaBroadcastDataResponse = useSelector(
    (RootReducer) => RootReducer.VendorReducer.zctaBroadcastData
  );
  // vendor Zcta Data ends

  const [zctaData, setZctaData] = useState({
    zipCodeInfo:[],
    medianHouseHoldIncomeByAge:[],
    houseHoldIncomeDistribution:[],
    zipCode: []
  });
  const [zctaLoad, setZctaLoad] = useState(false);

  useEffect(() => {
    if(props.listproductshow && props.zctaZipCode){
      setZctaLoad(true)
      setZctaData((zctaData) => ({
        ...zctaData,
        zipCodeInfo:[],
        houseHoldIncomeDistribution:[],
        medianHouseHoldIncomeByAge:[]
      }));
      vendorZctaBroadcast({
        zipCodes: props.zctaZipCode
      })
    } else {
        setZctaData((zctaData) => ({
            ...zctaData,
            zipCodeInfo:[],
            houseHoldIncomeDistribution:[],
            medianHouseHoldIncomeByAge:[]
          }));
    }
  }, [props.zctaZipCode, props.listproductshow])

  useEffect(() => {
    if (vendorZctaBroadcastDataResponse && vendorZctaBroadcastDataResponse.apiStatus) {
      if (vendorZctaBroadcastDataResponse.apiErrorCode === constants.STATUS_200 && vendorZctaBroadcastDataResponse.apiResponse) {
        if (vendorZctaBroadcastDataResponse.apiResponse.zctaData && vendorZctaBroadcastDataResponse.apiResponse.zctaData.length > constants.STATUS_1) {
          if(vendorZctaBroadcastDataResponse.apiResponse.zctaData.length === constants.STATUS_2){
            setZctaData((zctaData) => ({
              ...zctaData,
              zipCodeInfo:vendorZctaBroadcastDataResponse.apiResponse.zctaData[constants.STATUS_0].cityInfo,
              medianHouseHoldIncomeByAge:vendorZctaBroadcastDataResponse.apiResponse.zctaData[constants.STATUS_0].medianHouseHoldIncomeByAge,
              houseHoldIncomeDistribution: vendorZctaBroadcastDataResponse.apiResponse.zctaData[constants.STATUS_0].houseHoldIncomeDistribution,
              zipCode: vendorZctaBroadcastDataResponse.apiResponse.zctaData[constants.STATUS_1].zipCodes
            }));
          } else {
            let data = vendorZctaBroadcastDataResponse.apiResponse.zctaData
            let medianArray= [];
            let houseArray = [];
            let cityArray = [];
            let lengthArray = [];
            data.map((item)=> (
                item.zipCodes? setZctaData((zctaData) => ({...zctaData,zipCode:item.zipCodes})) :
                item.cityInfo.length !== constants.STATUS_0 &&
                (
                medianArray.push(item.medianHouseHoldIncomeByAge[constants.STATUS_0]),
                houseArray.push(item.houseHoldIncomeDistribution[constants.STATUS_0]),
                cityArray.push(item.cityInfo[constants.STATUS_0]),
                lengthArray.push(item.cityInfo.length)
                )
            ))
            const allEqual = arr => arr.every(val => val === arr[0]);
            if(allEqual(lengthArray) && lengthArray && lengthArray[0] !== constants.STATUS_1){
              setZctaData((zctaData) => ({
                ...zctaData,
                zipCodeInfo:[],
                
              }));
            } else
            setZctaData((zctaData) => ({
              ...zctaData,
              zipCodeInfo:cityArray,
              medianHouseHoldIncomeByAge:medianArray,
              houseHoldIncomeDistribution: houseArray
            }));
          }
        } else {
          setZctaData((zctaData) => ({
            ...zctaData,
            zipCodeInfo:[],
            medianHouseHoldIncomeByAge:[],
            houseHoldIncomeDistribution:[]
          }));
        }
      } 
      setZctaLoad(false);
      resetResponse(dispatch, "zctaBroadcastData")
    }
  }, [vendorZctaBroadcastDataResponse]);

  const median = (data) => (
    <Popover id="popover-basic" className='analysis-popover'>
      <Popover.Body>
        <ul>
          <li>Householder Under 25 Years: <span>{data?.mhhi_hhldr_u25}</span></li>
          <li>Householder 25 to 44: <span>{data?.mhhi_hhldr_25_44}</span></li>
          <li>Householder 45 to 64: <span>{data?.mhhi_hhldr_45_64}</span></li>
          <li>Householder 65+ Years: <span>{data?.mhhi_hhldr_65_plus}</span></li>
        </ul>
      </Popover.Body>
    </Popover>
  );  
      const incomeDistribution= (data) => (
        <Popover id="popover-basic" className='analysis-popover'>
          <Popover.Body>
            <ul>
              <li>Less than $25,000:<span>{data?.hhi_hh_w_lt_25k}</span></li>
              <li>$25,000 to $49,999:<span>{data?.hhi_hh_w_25k_49k}</span></li>
              <li>$50,000 to $74,999:<span>{data?.hhi_hh_w_50k_74k}</span></li>
              <li>$75,000 to $99,999:<span>{data?.hhi_hh_w_75k_99k}</span></li>
              <li>$100,000 to $149,999:<span>{data?.hhi_hh_w_100k_149k}</span></li>
              <li>$150,000 to $199,999:<span>{data?.hhi_hh_w_150k_199k}</span></li>
              <li>Greater than $200,000:<span>{data?.hhi_hh_w_200k_plus}</span></li>
            </ul>
          </Popover.Body>
        </Popover>
      );

      
    return (
        <>
        {zctaLoad ? <Col className="text-center mt-5"><div className="image-view d-flex align-items-center justify-content-center">
            <span class="spinner-border" style={{color:"#94DA0A"}}></span>
            </div> </Col> : zctaData.zipCodeInfo.length === constants.STATUS_0 ? <Col className="text-center mt-5">No Data Available. </Col> :
          <>
          {/* <div className='d-flex justify-content-between border p-3 rounded'>
            <div className='analysis-data'>City: <span>{zctaData?.zipCodeInfo[0].city}</span></div>
            <div className='analysis-data'>County: <span>{zctaData?.zipCodeInfo[0].country}</span></div>
            <div className='analysis-data'>State: <span>{zctaData?.zipCodeInfo[0].state}</span></div>
          </div> */}
          <Table responsive bordered>
            <thead>
              <tr>
                <th>Zip Code</th>
                <th>Median<br></br>Household (Age)</th>
                <th>Per Capita<br></br>Income ($)</th>
                <th>Total<br></br>Population</th>
                <th>Population<br></br>Density</th>
                <th>Households</th>
                <th>Household <br></br>
                  Income <br></br>
                  Distribution</th>
                <th>Family Poverty</th>
                <th>Unemployment</th>
              </tr>
            </thead>
            <tbody>
            {zctaData.zipCodeInfo.length > 0 && zctaData.zipCodeInfo.map((items, index) => (
              <tr>
                <td onClick={() => {items?.name && navigate("/heatmap",{state:{ zipCode:items?.name}})}}><Link variant="success" to="#">{items?.name}</Link></td>
                <td>
                  <OverlayTrigger
                    trigger="focus"
                    placement="top-start"

                    overlay={median(zctaData?.medianHouseHoldIncomeByAge[index])}
                  >
                    <Link variant="success" tabIndex={0}>View Details</Link>
                  </OverlayTrigger>
                </td>
                <td>{items && items.pc_income ? `$${currencyFormat(items.pc_income)}` : "-"}</td>
                <td>{items && items.totalpopulation ? items.totalpopulation : "-"}</td>
                <td>{items && items.populationdensity ? items.populationdensity : "-"}</td>
                <td>{items && items.medianhouseholdincome ? items?.medianhouseholdincome : "-"}</td>
                <td><OverlayTrigger trigger="focus" placement="top-start" overlay={incomeDistribution(zctaData?.houseHoldIncomeDistribution[index])}>
                    <Link variant="success" tabIndex={0}>View Details</Link>
                  </OverlayTrigger></td>
                <td>{items && items.familyproverty ? `${items.familyproverty}%` : "-"}</td>
                <td>{items && items.unemployment ? `${items.unemployment}%` : "-"}</td>
              </tr>
            ))}
            </tbody>
          </Table>
          <div className='text-end fst-italic'>* For Analyzing other Zip Codes, navigate to <Link to="/heatmap" className='text-black text-decoration-underline'>Insights</Link></div>
          </>
          }
          </>
    )
};


export default ZctaAnalysis;