/**
 * @file   src\
 * @brief  This file is responsible for 
 * @date   Oct, 2022
 * @author ZCO Engineer
 * @copyright (c) 2022, ZCO
 */

import { Col, Row } from 'react-bootstrap'
import InnerLayout from '../components/InnerLayout'
import Strings from '../assets/strings/Strings.json'
import TermsConditions from '../components/Terms'
const Terms = (props) => {
  return (
    <InnerLayout hideHeader={props.hideHeader}>
      <Col className='action-wrap'>
        <Row>
          <Col lg='6'>
            <h1>{Strings.Terms.Title}</h1>
          </Col>
        </Row>
      </Col>
      <Col className='inner-main about'>
        <TermsConditions/>
      </Col>
    </InnerLayout>
  );
}
export default Terms;
