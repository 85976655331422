import React from 'react'
import './WebLink.scss'
import { Form, Col } from 'react-bootstrap';
import Link from '../assets/img/icons/Link'

const WebLink = (props) => {
  return (
    <Col className='web-link position-relative'>
      <div className='link-box position-absolute d-flex justify-content-center align-items-center'>
        <Link />
      </div>
      <Form className='input-group'>
        <Form.Group>
          <Form.Label>{props.label}</Form.Label>
          <Form.Control
            id={props.id}
            name={props.name}
            type={props.type}
            placeholder={props.placeholder}
            onChange={props.onChange}
            value={props.value}
            readOnly={props.readOnly}
          />
          {props.errorMessage && props.errorMessage !== '' ? <Form.Text className='error'>
            {props.errorMessage}
          </Form.Text> : ""}
        </Form.Group>
      </Form>
    </Col>
  )
};

export default WebLink;

