/**
 * @file   src\
 * @brief  This file is responsible for
 * @date   Nov, 2022
 * @author ZCO Engineer
 * @copyright (c) 2022, ZCO
 */
import { Button } from "react-bootstrap";
import InnerLayout from "../components/InnerLayout";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Strings from "../assets/strings/Strings.json";
import {
  useStripe,
  useElements,
  PaymentElement,
  
} from "@stripe/react-stripe-js";

const Payments = (props) => {
  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();
  const type = props.type;


  // handle Payment submit
  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe && !elements) {
      return;
    }

    const { error, paymentIntent } = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: `${process.env.REACT_APP_VENDOR_API_URL}/profile`,
      },
      redirect: "if_required",
    });

    if (error) {
      toast.error(error.message);
      if (type === "product" || type === "product_subscrption") {
        navigate("/addproduct");
      }  else {
        navigate("/profile");
      }
    } else if (paymentIntent && paymentIntent.status === "succeeded") {
      if (type === "product") {
        toast.success(paymentIntent?.status);
        navigate("/addproduct", {
          state: {
            payment_intent: paymentIntent.id,
          },
        });
      } else if (type === "product_subscrption"){
        navigate("/addproduct");
      } else {
        navigate("/profile", {
          state: {
            current_tab: "subscription",
          }})
      }
    } else {
      toast.error(paymentIntent.status);
      if (type === "product" || type === "product_subscrption") {
        navigate("/addproduct");
      } else {
        navigate("/profile");
      }
    }
  };

  return (
    <InnerLayout hideHeader={props.hideHeader}>
      <form>
        <PaymentElement />
        <br></br>
        <Button variant="primary" disabled={!stripe} onClick={handleSubmit}>
          {Strings.Profile.PaymentMethod.Btn4}
        </Button>
      </form>
    </InnerLayout>
  );
};
export default Payments;
