import React from 'react'
import './OtpForm.scss'
import { Row, Col, Button } from 'react-bootstrap';
import Verified from '../assets/img/icons/Verified';
import OTPInput, { ResendOTP } from "otp-input-react";
import Strings from "../assets/strings/Strings.json"
const OtpForm = (props) => {

  return (
    <Col className='otp-wrap'>
      <Row>
        <Col lg='5' className='d-flex selector-wrap'>
          <div className='icon-wrap d-flex justify-content-center align-items-center'>
            {props.icon}
          </div>
          <div className='radio-wrap'>
            <span>{props.label} {props.name === 'email_otp' ? <label>*
            </label>: <label className='optional'>{Strings.Register.Verification.Optional}</label> } {props.status && <Verified />}</span>
            <span>
              {props.radiolabel}
            </span>
          </div>
        </Col>
        <Col lg='4' className='otp-input'>
          <OTPInput         
            value={props.otpValue}
            onChange={props.onOtpValueChange}
            OTPLength={4}
            disabled={props.buttonDisable}
            otpType={props.inputType}
            secure={true}
            autoFocus={props.autoFocus}
          />
          <small className='error'>{props.errorMessage}</small>
        </Col>
        <Col lg='3'><Button onClick={props.buttonClick} disabled={props.buttonDisable} variant='primary'>{props.button}</Button>
        </Col>
      </Row>
    </Col>
  )
};

export default OtpForm;

