import React, { useState } from 'react'
const View = (props) => {
  return (
<svg id="eye" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
  <rect id="Rectangle_706" data-name="Rectangle 706" width="24" height="24" fill="none" stroke='none'/>
  <path id="Path_769" data-name="Path 769" d="M1,12S5,4,12,4s11,8,11,8-4,8-11,8S1,12,1,12Z" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
  <circle id="Ellipse_27" data-name="Ellipse 27" cx="3" cy="3" r="3" transform="translate(9 9)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
</svg>


  )
};
export default View;

