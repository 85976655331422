import React, { useState, useEffect } from "react";
import { Button, Col, Row, Modal } from "react-bootstrap";
import Strings from "../assets/strings/Strings.json";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CustSelectLabel from "./SelectLabel";
import Form from "react-bootstrap/Form";
import * as constants from "../utils/constants";
import RESPONSE_CODE from '../utils/ResponseCode'
import { useDispatch, useSelector } from "react-redux";
import { _listProxyAddress } from "../actions/VendorAction";
import ZctaAnalysis from './ZctaAnalysis';

const ListProductPopUp = (props) => {

  const dispatch = useDispatch();
  let messagesMaster = props.messagesMaster;
  let scheduledata = props.scheduledata;
  let setScheduledata = props.setScheduledata;
  let submitScheduleList = props.submitScheduleList;
  let showSchedule = props.showSchedule;
  let setShowSchedule = props.setShowSchedule;
  let proxyAddress = props.proxyAddress;
  let setProxyAddress = props.setProxyAddress;
  let timeList = props.timeList
  let isClickdedDoneListMessage= props.isClickdedDoneListMessage
  let seIsClickdedDoneListMessage= props.seIsClickdedDoneListMessage
  let currentPlan = props.currentPlan

  // vendor list proxy and zipcodes start
  const listProxyAddress = (params) => dispatch(_listProxyAddress(params));
  const listProxyAddressResponse = useSelector(
    (RootReducer) => RootReducer.VendorReducer.listProxyApiResponse
  );
  // vendor list proxy and zipcodes ends

  const [messages, setMessages] = useState([]);
  const [messageDescription, setMessageDescription] = useState("");
  const [messageId, setMessageId] = useState(0);
  const [DayOptions, setDayOptions] = useState([]);
  const [zipcodeList, setZipcodeList] = useState([]);
  const [checked, setChecked] = useState({});
  const [addressValue, setAddressValue] = useState({
    message: "",
    errorMessage:""
  });
  const [date, setDate] = useState({
    startDay: 1,
    endDay: 30
  });
  const [optionsAddress, setOptionsAddress] = useState([
    { value: 1, label: "Default Address" },
    { value: 2, label: "Proxy Zipcode" },
    { value: 3, label: "Proxy Address" },
  ])
  const [zctaZipCode, setZctaZipCode] = useState("");

  useEffect(() => {
    if (listProxyAddressResponse && listProxyAddressResponse.apiStatus) {
      setProxyAddress((proxyAddress) => ({
        ...proxyAddress,
        zip_codes: [],
      }));
      setAddressValue((addressValue) => ({
        ...addressValue,
        message: "",
        errorMessage:""
      }));
      setZipcodeList([]);
      setZctaZipCode("")
      if (
        listProxyAddressResponse.apiErrorCode == constants.STATUS_200 &&
        listProxyAddressResponse.apiResponse &&
        listProxyAddressResponse.apiResponse.proxy_info
      ) {
        
        if (
          proxyAddress.type.value === constants.STATUS_1 &&
          listProxyAddressResponse.apiResponse.proxy_info[0]
        ) {
          let address =
          `${listProxyAddressResponse.apiResponse.proxy_info[0]?.address1 || ""}
          ${listProxyAddressResponse.apiResponse.proxy_info[0]?.city ? ", " +
           listProxyAddressResponse.apiResponse.proxy_info[0]?.city : ""}
           ${listProxyAddressResponse.apiResponse.proxy_info[0]?.statename ? ", " + 
           listProxyAddressResponse.apiResponse.proxy_info[0]?.statename : ""}
           ${listProxyAddressResponse.apiResponse.proxy_info[0]?.zip ? ", " + 
           listProxyAddressResponse.apiResponse.proxy_info[0]?.zip : ""}
           ${listProxyAddressResponse.apiResponse.proxy_info[0]?.countryname ? ", " + 
           listProxyAddressResponse.apiResponse.proxy_info[0]?.countryname : ""}`;
          setAddressValue((addressValue) => ({
            ...addressValue,
            message: address,
          }));
          setProxyAddress((proxyAddress) => ({
            ...proxyAddress,
            zip_codes: listProxyAddressResponse.apiResponse.proxy_info[0]?.zip
          }));
          setZctaZipCode([listProxyAddressResponse.apiResponse.proxy_info[0]?.zip])
        } else if (
          proxyAddress.type.value === 3 &&
          listProxyAddressResponse.apiResponse.proxy_info[0]
        ) {
          let address = ""
          if(listProxyAddressResponse.apiResponse.proxy_info[0].address === ""){
            address = ""
            setAddressValue((addressValue) => ({
              ...addressValue,
              errorMessage: RESPONSE_CODE[1312],
            }));
          }else{
           address =
            listProxyAddressResponse.apiResponse.proxy_info[0]?.address +
            ", " +
            listProxyAddressResponse.apiResponse.proxy_info[0]?.city +
            ", " +
            listProxyAddressResponse.apiResponse.proxy_info[0]?.state +
            " " +
            listProxyAddressResponse.apiResponse.proxy_info[0]?.zip +
            ", " +
            listProxyAddressResponse.apiResponse.proxy_info[0]?.country_name;
          }
          setAddressValue((addressValue) => ({
            ...addressValue,
            message: address,
          }));
          setProxyAddress((proxyAddress) => ({
            ...proxyAddress,
            zip_codes: listProxyAddressResponse.apiResponse.proxy_info[0]?.zip
          }));
          setZctaZipCode([listProxyAddressResponse.apiResponse.proxy_info[0]?.zip.trim()])
        } else if (
          proxyAddress.type.value === constants.STATUS_2 &&
          listProxyAddressResponse.apiResponse.proxy_info
        ) {
          let data = listProxyAddressResponse.apiResponse.proxy_info;
          let zipArray = [];
          let zipcode= []
          data.map((data) => {
            zipArray.push({
              value: data.zip_id,
              label: data.zip_code,
            });
            zipcode.push(data.zip_code)
          });
          setZipcodeList(zipArray);
          setZctaZipCode(zipcode)
        }
      } else if(listProxyAddressResponse.apiErrorCode === constants.STATUS_400 ){
        setAddressValue((addressValue) => ({
          ...addressValue,
          errorMessage:listProxyAddressResponse.apiMessage
        }));
      }
    }
  }, [listProxyAddressResponse]);

  useEffect(() => {
    if (messagesMaster) {
      var messageArray = [];
      messagesMaster.map((messages) => {
        messageArray.push({
          value: messages.message_id,
          label: messages.message_title,
        });
      });
      setMessages(messageArray);
    }
  }, [props.messagesMaster]);

  useEffect(() => {
    if (isClickdedDoneListMessage) {
      seIsClickdedDoneListMessage(false);
      setMessageDescription("");
      setMessageId(0);
    }
  }, [isClickdedDoneListMessage]);

  useEffect(() => {
    listProxyAddress({
      proxy_type: proxyAddress.type.value,
    });
  }, [proxyAddress.type]);

  useEffect(() => {
    if (currentPlan === constants.STATUS_1) {
      setOptionsAddress([
        { value: 1, label: "Default Address" },
        { value: 2, label: "Proxy Zipcode" },
      ])
    } else {
      setOptionsAddress([
        { value: 1, label: "Default Address" },
        { value: 2, label: "Proxy Zipcode" },
        { value: 3, label: "Proxy Address" },
      ])
    }
   
  }, [currentPlan]);

  useEffect(() => {
    const today = new Date();
    const nextMonth = new Date(today.getFullYear(), today.getMonth() + 2, 1);
    const lastDayOfMonth = new Date(nextMonth - 1);
    setScheduledata((scheduledata) => ({
      ...scheduledata,
      end_date: lastDayOfMonth
    }));
  }, [showSchedule]);

  useEffect(() => {
    const list = []
    for (let i = date.startDay; i <= date.endDay; i++) {
      const day = { value: i, label: i }
      list.push(day)   
    }    
    setDayOptions(list)
  }, [date])

  useEffect(() => {
    getSmallestDayInMonths(scheduledata.start_date,scheduledata.end_date)     
  }, [scheduledata.start_date,scheduledata.end_date])

  const getSmallestDayInMonths = (month1, month2) => {
   
    const startMonth = month1.getMonth();
    const endMonth = month2.getMonth();
    const startYear = month1.getFullYear();
    const endYear = month2.getFullYear();
    const startdayNumber = month1.getDate();
    const enddayNumber = month2.getDate();
    const diffInMillis = Math.abs(Date.parse(month1) - Date.parse(month2));
    const diffInDays = Math.floor(diffInMillis / (1000 * 60 * 60 * 24));
    const lastDayOfMonth = new Date(month2.getFullYear(), month2.getMonth() + 1, 0)
    if(startMonth === endMonth && startYear === endYear){
      setDate((date) => ({
        ...date,
        startDay: startdayNumber,
        endDay: enddayNumber
      }))
    } else if(endMonth === (startMonth === 11 ? 0 : startMonth + 1) && diffInDays < 30){
      const date = new Date(startYear, startMonth, 1);
      date.setDate(0); 
      const list = []
      for (let i = 1; i <= enddayNumber; i++) {
        const day = { value: i, label: i }
        list.push(day)   
      } 
      for (let i = startdayNumber; i <= date.getDate(); i++) {
        const day = { value: i, label: i }
        list.push(day)   
      }        
      setDayOptions(list)
    } else {
    let months = "";
    for (let year = startYear; year <= endYear; year++) {
      const start = year === startYear ? startMonth : 0;
      const end = year === endYear ? endMonth : 11;
      for (let month = start; month <= end; month++) {
        const daysInMonth = new Date(year, month + 1, 0).getDate();
        if((month === end && enddayNumber === lastDayOfMonth.getDate())|| (month !== end && enddayNumber !== lastDayOfMonth.getDate()) || (month === 0 && end === 0 && enddayNumber !== lastDayOfMonth.getDate()) ){
          if(months === "" || daysInMonth <= months){
            months = daysInMonth
            setDate((date) => ({
              ...date,
              startDay: 1,
              endDay: daysInMonth,
            }))
          }
        }
      }
    }
  }
  }

  const handleTimeRange = (e) => {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    setScheduledata((scheduledata) => ({
      ...scheduledata,
      time: e.label,
      startTime : getTime(e.value.start,e.value.meridiem),
      endTime : getTime(e.value.end,e.value.meridiem),
      timeZone : timeZone
    }));
  }

  const getTime = (time,type) =>{
    const [hour, minute] = time.split('.');
    let newTime = new Date();
    newTime.setHours(parseInt(hour, 10) + (hour!== '12' && type === 'PM' ? 12 : 0));
    newTime.setMinutes(parseInt(minute, 10));
    newTime.setSeconds(0);
    newTime.setMilliseconds(0);
    return newTime.toLocaleTimeString([], { hour12: false })
  }

  const selectedMessage = (event) => {
    let messageIds = event.value;
    setMessageId(messageIds);
    messagesMaster.forEach((messages) => {
      if (messages.message_id === messageIds) {
        setMessageDescription(messages.description);
      }
    });
  };

  const selectAddress = (event) => {
    setProxyAddress((proxyAddress) => ({
      ...proxyAddress,
      type: event,
    }));
  };

  const doneListProduct = () => {
    if (messageId) {
      props.submitListProduct(messageId);
    }
  };
  const closePopUp = () => {
    props.listproducHide();
    setMessageId(0);
    setMessageDescription("");
  };

  const handleScheduleBtn = () => {
    setShowSchedule(true);
    setScheduledata((scheduledata) => ({
      ...scheduledata,
      occurrence: 3,
      time: ""
    }));
  };

  const handleCancelSchedule = () => {
    const today = new Date();
    const nextMonth = new Date(today.getFullYear(), today.getMonth() + 2, 1);
    const lastDayOfMonth = new Date(nextMonth - 1);
    setShowSchedule(false);
    seIsClickdedDoneListMessage(false);
    setScheduledata((scheduledata) => ({
      ...scheduledata,
      value: [],
      occurrence: 0,
      start_date: new Date(),
      end_date: lastDayOfMonth,
      date: 0,
      time : "",
      startTime : "",
      endTime : "",
      timeZone : ""
    }));
    setProxyAddress((proxyAddress) => ({
      ...proxyAddress,
      type : { value: 1, label: "Default Address" },
      zip_codes: [],
      proxy_details:[]
    }));
    setZipcodeList([]);
  };

  const handleCancel = () =>{
    closePopUp()
    setProxyAddress((proxyAddress) => ({
      ...proxyAddress,
      type : { value: 1, label: "Default Address" },
      zip_codes: [],
      proxy_details:[]
    }));
    setZipcodeList([]);
    setAddressValue((addressValue) => ({
      ...addressValue,
      errorMessage:""
    }));
  }


  const handleSheduleSubmit = () => {
    if (messageId) {
      submitScheduleList(messageId);
    }
  };

  const handleDate = (e, type) => {
    if (type === 1) {
      setScheduledata((scheduledata) => ({
        ...scheduledata,
        start_date: e,
      }));
      if (e > scheduledata.end_date) {
        setScheduledata((scheduledata) => ({
          ...scheduledata,
          end_date: e,
        }));
      }
    } else {
      setScheduledata((scheduledata) => ({
        ...scheduledata,
        end_date: e,
      }));
    }
  };

  // handle proxy checkbox
  const handleZipcodeChange = (value, zip_name) => {
    var zipcode_to_timezone = require( 'zipcode-to-timezone' );
    var timeZone = zipcode_to_timezone.lookup( zip_name );
    if (checked.hasOwnProperty(zip_name)) {
      setChecked((checked) => ({
        ...checked,
        [zip_name]: !checked[zip_name],
      }));
    } else {
      setChecked({ ...checked, zip_name: true });
    }
    if (proxyAddress.zip_codes.includes(zip_name)) {
      let array = [...proxyAddress.zip_codes];
      let array2 =[...proxyAddress.proxy_details]
      let index = array.indexOf(zip_name);
      let index2 = array2.findIndex(item => item.zipcode === zip_name);
      if (index !== -1) {
        array.splice(index, 1);
        array2.splice(index2, 1)
        setProxyAddress((proxyAddress) => ({
          ...proxyAddress,
          zip_codes: array,
          proxy_details: array2
        }));
      }
    } else {
      let array = [...proxyAddress.zip_codes, zip_name];
      let array2 =[...proxyAddress.proxy_details, {"zipcode":zip_name,"timeZone":timeZone=== null ?"Asia/Kolkata":timeZone}]
      setProxyAddress((proxyAddress) => ({
        ...proxyAddress,
        zip_codes: array,
        proxy_details: array2
      }));
    }
  };

  return (
    <Modal show={props.listproductshow} centered backdrop="static" size="xl">
      <Modal.Body className="list-product-pop">
        <h3>{Strings.ListProduct.Title}</h3>
        <p>
          {Strings.ListProduct.Description1}
          <span style={{marginLeft:5}}>
            {" "}
            {props.noOfProducts ? props.noOfProducts : ""}{" "}
            {props.noOfProducts > 1 ? "Offers." : "Offer."}{" "}
          </span>
        </p>
        <p>
          {Strings.ListProduct.Des2P1}{" "}
          <span>{Strings.ListProduct.Des2P2} </span>
          {Strings.ListProduct.Des2P3}
        </p>
        <Row>
          <Col lg="5" xl="5">
            <CustSelectLabel
              options={messages}
              onChange={selectedMessage}
              name="message"
              placeholder={Strings.Admin.SubCategory.Modal.Placeholder3}
            />
          </Col>
          <Col lg="7" xl="7">
            {messageDescription ? (
              <div className="info-wrap mt-1">{messageDescription} </div>
            ) : (
              ""
            )}
          </Col>
        </Row>
        <Row>
          <Col lg="5" xl="5">
            <CustSelectLabel
              options={optionsAddress}
              onChange={selectAddress}
              value={proxyAddress.type}
              name="message"
              placeholder={Strings.Admin.SubCategory.Modal.Placeholder3}
            />
          </Col>
          {addressValue.errorMessage && addressValue.errorMessage !== '' ? <Form.Text className='text-danger'>
          {addressValue.errorMessage}
        </Form.Text> : ""}
          <Col lg="7" xl="7">
            {addressValue.message ? (
              <div className="info-wrap mt-1">{addressValue.message}</div>
            ) : (
              ""
            )}
          </Col>
        </Row>
        <Row className="proxy-pin mt-3">
          {zipcodeList &&
            zipcodeList.map((data, key) => {
              return (
                <Col lg="3">
                  <Form.Check
                    key={[key]}
                    inline
                    label={data?.label}
                    className="check-box"
                    onChange={() =>
                      handleZipcodeChange(data?.value, data?.label)
                    }
                    checked={checked[data?.deliverytype_name]}
                  />
                </Col>
              );
            })}
        </Row>
        <Row className="mt-4">
          <Col lg="12" xl="5" className="btn-wrap">
            <Button
              variant="outline-primary"
              disabled={showSchedule}
              onClick={handleCancel}
            >
              {Strings.ListProduct.Btn1}
            </Button>
            <Button
              variant="primary"
              onClick={doneListProduct}
              disabled={showSchedule || (messageId ? false : true) || (proxyAddress.type.value ===constants.STATUS_2 && proxyAddress.zip_codes.length === 0) || addressValue.errorMessage !== ""}
            >
              {Strings.ListProduct.Btn2}
            </Button>
          </Col>
          <Col
            lg="12"
            xl="7"
            className="d-flex align-items-center justify-content-between schedule-info"
          >
            <p className="mb-0">
              {Strings.ListProduct.or}
              <span>{Strings.ListProduct.ScheduleInfo}</span>
            </p>
            <Button
              variant="primary"
              disabled={showSchedule || (messageId ? false : true) || (proxyAddress.type.value === constants.STATUS_2 && proxyAddress.zip_codes.length === 0) || addressValue.errorMessage !== ""}
              onClick={handleScheduleBtn}
            >
              {Strings.ListProduct.Btn3}
            </Button>
          </Col>
        </Row>
        <br></br>
        {showSchedule && (
          <div className="schedule-wrap">
            <h3>{Strings.ListProduct.Schedule.Title}</h3>
            <Row>
              
              <Col lg="3">
                <div className="date-picker">
                <label>{Strings.ListProduct.Schedule.Label2}</label>
                  <DatePicker
                    onKeyDown={(e) => {
                      e.preventDefault();
                    }}
                    minDate={new Date()}
                    selected={scheduledata.start_date}
                    onChange={(e) => {
                      handleDate(e, 1);
                    }}
                  />
                </div>
              </Col>
              <Col lg="3">
                <div className="date-picker">
                <label>{Strings.ListProduct.Schedule.Label3}</label>
                  <DatePicker
                    onKeyDown={(e) => {
                      e.preventDefault();
                    }}
                    minDate={scheduledata.start_date}
                    selected={scheduledata.end_date}
                    onChange={(e) => {
                      handleDate(e);
                    }}
                  />
                </div>
              </Col>
              <Col lg="3">
                <CustSelectLabel
                  label={"Notification Schedule Date"}
                  options={DayOptions}
                  onChange={(e) => {
                    setScheduledata((scheduledata) => ({
                      ...scheduledata,
                      date: e.value,
                    }));
                  }}
                  name="message"
                  placeholder={Strings.ListProduct.Schedule.placeholder1}
                />
              </Col>
              <Col lg="3">
                <CustSelectLabel
                  label={Strings.ListProduct.Schedule.Label1}
                  options={timeList}
                  onChange={(e) => {
                    handleTimeRange(e)
                  }}
                  name="message"
                  placeholder={Strings.ListProduct.Schedule.placeholder}
                />
              </Col>
            </Row>
              
            <div className="btn-wrap second-row">
              <Button onClick={handleCancelSchedule} variant="outline-primary">
                {Strings.ListProduct.Schedule.Btn1}
              </Button>
              <Button
                onClick={handleSheduleSubmit}
                disabled={
                  scheduledata.occurrence === 0 ||
                  (scheduledata.occurrence === 2 &&
                    scheduledata.value.length === 0) ||
                  (scheduledata.occurrence === 3 && scheduledata.date === 0) ||
                  (messageId ? false : true)  || scheduledata.time === ""
                }
                variant="primary"
              >
                {Strings.ListProduct.Schedule.Btn2}
              </Button>
              {scheduledata.occurrence === 1 && (
                <p>{Strings.ListProduct.Schedule.Info}</p>
              )}
              {scheduledata.occurrence === 2 && (
                <p>{Strings.ListProduct.Schedule.Info1}</p>
              )}
              {scheduledata.occurrence === 3 && (
                <p>{Strings.ListProduct.Schedule.Info2}</p>
              )}
            </div>
          </div>
        )}
        <div className='zcta-data-analysis mt-4'>
          <h3>ZCTA DATA Analysis</h3>
          <ZctaAnalysis zctaZipCode={zctaZipCode} listproductshow={props.listproductshow}/>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ListProductPopUp;
