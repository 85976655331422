import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import * as constants from "../utils/constants";

const HeatmapChart = (props) => {

  const [sortedWeekDays, setSortedWeekDays] = useState({});
  const hours = [
    "8 AM", "9 AM", "10 AM", "11 AM", "12 PM",
    "1 PM", "2 PM", "3 PM", "4 PM", "5 PM",
    "6 PM", "7 PM", "8 PM", "9 PM", "10 PM"
  ];
  useEffect(() => {
    const dayNameMapping = {
      Monday: 'Mon',
      Tuesday: 'Tue',
      Wednesday: 'Wed',
      Thursday: 'Thu',
      Friday: 'Fri',
      Saturday: 'Sat',
      Sunday: 'Sun'
    };

    const daysOfWeek = Object.keys(dayNameMapping);
    const shortDaysOfWeek = Object.values(dayNameMapping);
    const todayIndex = props.weekTimeStartDate.getDay();
    const adjustedTodayIndex = (todayIndex === 0 ? 6 : todayIndex - 1);

    const sortedDays = {};
    for (let i = 0; i < daysOfWeek.length; i++) {
      const index = (adjustedTodayIndex + i) % daysOfWeek.length;
      sortedDays[daysOfWeek[index]] = shortDaysOfWeek[index];
    }
    setSortedWeekDays(sortedDays)
  }, [props.data])

  const seriesData = hours.map(hour => {
    const hourData = Object.keys(sortedWeekDays).map(day => {
      const dayData = props.data?.find(d => d.name === sortedWeekDays[day])?.data.find(d => d.x === hour);
      return { x: sortedWeekDays[day], y: dayData ? dayData.y : 0 };
    });
    return {
      name: hour,
      data: hourData
    };
  });

  const options = {
    chart: {
      height: 550,
      type: 'heatmap',
      toolbar: {
        tools: {
          download: false, // Hides the download button
        },
      },
      inverted: true, // Inverts the axes
    },
    plotOptions: {
      heatmap: {
        shadeIntensity: 0.5,
        colorScale: {
          ranges: [
            { from: constants.STATUS_0, to: props.minValue ? props.minValue : constants.STATUS_1, color: '#f29849' },
            { from: props.minValue ? props.minValue + constants.STATUS_0_01 : constants.STATUS_2, to: props.avgValue ? props.avgValue : constants.STATUS_3, color: '#f56e20' },
            { from: props.avgValue ? props.avgValue + constants.STATUS_0_01 : constants.STATUS_3, to: props.maxValue ? props.maxValue : constants.STATUS_4, color: '#fc550d' },
          ],
        },
      },
    },
    dataLabels: {
      enabled: true,
      style: {
        colors: ['#000'],
        fontWeight: 'normal'
      },
    },
    xaxis: {
      type: 'category',
      categories: Object.values(sortedWeekDays),
      labels: {
        style: {
          fontWeight: 'bold'
        }
      }
    },
    yaxis: {
      type: 'category',
      categories: hours,
      labels: {
        style: {
          fontWeight: 'bold'
        }
      }
    },
    legend: {
      show: false // Hides the legend
    },
  };

  return (
    <div id="chart">
      <ReactApexChart options={options} series={seriesData} type="heatmap" height={550} />
    </div>
  );
};

export default HeatmapChart;
