import React, { useState, useEffect } from "react";
import "./ForgotPasswordForm.scss";
import { Button, Col } from "react-bootstrap";
import Strings from "../assets/strings/Strings.json";
import Input from "../components/Input";
import { useDispatch, useSelector } from "react-redux";
import { _vendorForgotEmail, resetResponse } from "../actions/VendorAction";
import { phoneNumberValidation } from "../helpers/authHelper";
import { toast } from "react-toastify";
import * as alerts from "../utils/Messages";
import * as constants from '../utils/constants';
import { Link } from "react-router-dom";

const ForgotPasswordForm = (props) => {
  const dispatch = useDispatch();

  // ------- call API from functions and response from redux start ---------
  // vendor forgot password
  const vendorForgotEmail = (params) =>
    dispatch(_vendorForgotEmail(params));
  const vendorForgotEmailResponse = useSelector(
    (RootReducer) => RootReducer.VendorReducer.vendorForgotEmail
  );

  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [phoneDisplayNumber, setDisplayPhoneNumber] = useState("");
  const [submitDisable, setSubmitDisable] = useState(false);
  const [textSendSuccess, setTextSendSuccess] = useState(false);

  useEffect(() => {
    phoneFormat(phoneNumber);
  }, [phoneNumber]);

  //handle vendor forgot password response
  useEffect(() => {
    if (vendorForgotEmailResponse && vendorForgotEmailResponse.apiStatus) {
      setSubmitDisable(true);
      if (vendorForgotEmailResponse && vendorForgotEmailResponse.apiErrorCode === constants.STATUS_200) {        
        setTextSendSuccess(true)
        toast.success(vendorForgotEmailResponse.apiMessage);
      } else if (
        vendorForgotEmailResponse &&
        vendorForgotEmailResponse.apiErrorCode !== constants.STATUS_200
      ) {
        toast.error(vendorForgotEmailResponse.apiMessage);
      }
      resetResponse(dispatch, "vendorForgotEmail");
    }
  }, [vendorForgotEmailResponse]);


  const phoneFormat = (value, type) => {
    let formattedPhoneNumber = value;
    if (value.length <= 3) {
      formattedPhoneNumber = value;
    } else if (value.length > 3 && value.length <= 6) {
      formattedPhoneNumber = `(${value.slice(0, 3)}) ${value.slice(3, 6)}`;
    } else if (value.length > 6) {
      formattedPhoneNumber = `(${value.slice(0, 3)}) ${value.slice(
        3,
        6
      )}-${value.slice(6, 10)}`;
    }
    setDisplayPhoneNumber(formattedPhoneNumber)
  };


  // handle input change
  const handleChange = (event) => {
    setSubmitDisable(false)
    if ((/^[-\d\(\)\[\]\s]+$/.test(event.target.value))) {
      const numbers = event.target.value.match(/\d+/g);
      const fullNumber = numbers.join("");
      setPhoneNumber(fullNumber)
    } else if (!/\D/.test(event.target.value)) {
      setPhoneNumber(event.target.value)
    }
  };

  // handle validation
  const validation = () => {
    let isValid = true;
    setPhoneNumberError("");
    if (phoneNumber.trim() === "") {
      isValid = false;
      setPhoneNumberError(alerts.ENTER_PHONE_NUMBER);
    } else if (phoneNumberValidation(phoneNumber) === false) {
      isValid = false;
      setPhoneNumberError(alerts.ENTER_VALID_EMAIL);
    }
    return isValid;
  };

  //handle submit
  const submit = () => {
    let isValid = validation();
    if (isValid) {
      vendorForgotEmail({
        "phone_no": phoneNumber
      })
    }
  };


  const submitFormOnKeyDown = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      submit();
    }
  };

  return (
    <div className="forgot-pass-wrap">
      <h1>
        {/* {process.env.REACT_APP_USER_TYPE === "admin" ? "Admin - " : ""}{" "} */}
        {Strings.ForgotEmail.Title}      </h1>
      <p className="info">{Strings.ForgotEmail.Info1}<br />{Strings.ForgotEmail.Info2}</p>

      <Input
        id="phone"
        label={Strings.ForgotEmail.Label1}
        name="phone"
        type="text"
        autoComplete="off"
        maxLength='14'
        value={phoneDisplayNumber}
        onChange={handleChange}
        errorMessage={phoneNumberError}
        onKeyDown={submitFormOnKeyDown}
      />
      <Col className="d-block text-end forgot-pass">
        <Link to="/">{Strings.Register.BasicInfo.Info2}</Link>
      </Col>
      <Button
        onClick={submit}
        disabled={submitDisable || phoneNumber === ""}
        className="mt-2"
      >
        {Strings.ForgotEmail.Btn}
      </Button>

      <p className="pass-alert">
        {submitDisable && textSendSuccess ? Strings.ForgotEmail.Alert : ""}
      </p>
    </div>
  );
};

export default ForgotPasswordForm;
