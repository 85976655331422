/**
 * @file   src\
 * @brief  This file is responsible for 
 * @date   Aug, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

import { Button, Col, Row, Table, Modal, Card, Form } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation } from "react-router-dom"
import InnerLayout from '../components/InnerLayout'
import Strings from '../assets/strings/Strings.json'
import * as alerts from "../utils/Messages";
import CustSelectLabel from '../components/SelectLabel'
import React, { useState, useEffect } from 'react'
import InputSelect from '../components/InputSelect'
import Input from "../components/Input"
import Loader from '../components/Loader'
import Autocomplete from "react-google-autocomplete"
import { DECIMAL_REGEX, MAX_DECIMAL_LENGTH, MAX_PHONE_LENGTH, MAX_BUSINESSNAME_LENGTH, MAX_ADDRESS_LENGTH } from '../utils/constants'
import { ShipmentLabel } from '../utils/Enum'
import { formatPhoneNumber } from "../helpers/validationHelper"
import { phoneNumberValidation } from "../helpers/authHelper";
import { toast } from 'react-toastify'
import * as constants from '../utils/constants'
import {
  _vendorAddressInfo,
  _shipmentCarriers,
  _shipmentRateInfo,
  _generateShipmentLabel,
  _shipmentDetails,
  _cancelShipmentLabel,
  resetResponse
} from '../actions/VendorAction'

const SetupShipment = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const OTHER_ADDRESS_SELECTED = 0;

  const purchaseId = location.state?.id
  const isEdit = location.state?.isEdit;

  const intitalRateValues = {
    carrier_ids: [],
    carrier_code: "",
    from_country_code: "",
    from_postal_code: "",
    to_country_code: "",
    to_postal_code: "",
    to_city_locality: "",
    to_state_province: "",
    weight: {
      value: 0,
      unit: "ounce",
    },
    dimensions: {
      unit: "inch",
      length: 0,
      width: 0,
      height: 0,
    },
    businessname: "",
    phone: "",
    address: "",
    address2: "",
    city: "",
    state: ""
  };

  const initialRateCardValues = {
    carrierName: "",
    carrierCode: "",
    serviceType: "",
    packageType: "",
    packageName: "",
    rate: 0,
    expectedDelivery: 0,
    serviceCode: ""
  };

  const intitalRateValuesError = {
    carrier_idError: "",
    from_CountryError: "",
    from_ZipError: "",
    weight_Error: "",
    length_Error: "",
    width_Error: "",
    height_Error: "",
    businessname_Error: "",
    phone_Error: "",
    address_Error: "",
    city_Error: "",
    state_Error: ""
  };
  const shipmentLabelInitialValues = {
    shipmentLabelId: "",
    shipmentLabel: "",
    shipmentLabelPdf: "",
    shipmentTrackingNo: ""
  }
  const intitalRateTableValues = {
    ratesColHeader: [],
    ratesRowValues: [],
    carrierDetails: ""
  };

  const DimensionOptions = [
    { value: 1, label: 'inch' },
    { value: 2, label: 'centimeter' },
  ]


  const WeightOptions = [
    { value: 1, label: 'ounce' },
    { value: 2, label: 'pound' },
    { value: 3, label: 'gram' },
    { value: 4, label: 'kilogram' },
  ]

  const [RatesModal, setRatesModalShow] = useState(false);
  const [showUserAddress, setUserAddressDisplay] = useState(false);
  const [showRateCard, setRateCardShow] = useState(false);

  const [VoidModal, setVoidModalShow] = useState(false);
  const [voidSuccessModal, setVoidSuccessModal] = useState(false);
  const [showLabel, setShowLabel] = useState(false)
  const [viewLabelBtn, setViewLabelBtn] = useState(false)
  const [labelbtnEnabled, setLabelbtnEnabled] = useState(false)
  const [place, setPlace] = useState(null);

  const [dimensionUnitValue, setDimensionUnitValue] = useState({ value: 1, label: 'inch' });
  const [weightUnitValue, setWeightUnitValue] = useState({ value: 1, label: 'ounce' });
  const [weightValue, setWeightValue] = useState("");
  const [lengthValue, setLengthValue] = useState("")
  const [widthValue, setWidthValue] = useState("")
  const [heightValue, setHeightValue] = useState("")

  const [vendorAddressOptions, setVendorAddressOptions] = useState([]);
  const [vendorAddress, setVendorAddress] = useState();
  const [shipmentCarriers, setShipmentCarriers] = useState([]);
  const [selectedCarrier, setSelectedCarrier] = useState();
  const [load, setLoad] = useState(false)
  const [rateValues, setRateValues] = useState(intitalRateValues);
  const [ratesValuesError, setAddRateValuesError] = useState(intitalRateValuesError);
  const [ratesTableValues, setRatesTableValues] = useState(intitalRateTableValues);
  const [rateCardSelected, setRateCardValue] = useState(initialRateCardValues);
  const [generatedLabel, setLabelInfo] = useState(shipmentLabelInitialValues);

  // API call to get the vendor address
  const listVendorAddress = (vendorInfoParams) => dispatch(_vendorAddressInfo(vendorInfoParams));
  const vendorAddressResponse = useSelector((RootReducer) => RootReducer.VendorReducer.listVendorAddressResponse)

  // API call to get the shipment details
  const shipmentDetails = (requestParams) => dispatch(_shipmentDetails(requestParams));
  const shipmentDetailsResponse = useSelector((RootReducer) => RootReducer.VendorReducer.shipmentDetailsResponse)

  // API call to get the shipment Carriers
  const listShipmentCarriers = () => dispatch(_shipmentCarriers());
  const shipmentCarriersResponse = useSelector((RootReducer) => RootReducer.VendorReducer.listShipmentCarriersResponse)

  // API call to get the shipment rates
  const shipmentRates = (requestParams) => dispatch(_shipmentRateInfo(requestParams));
  const shipmentRatesResponse = useSelector((RootReducer) => RootReducer.VendorReducer.listShipmentRatesResponse)

  const generateShipmentLabel = (requestParams) => dispatch(_generateShipmentLabel(requestParams));
  const shipmentLabelResponse = useSelector((RootReducer) => RootReducer.VendorReducer.generateShipmentLabelResponse)

  // API call to cancel shipment label
  const cancelShipmentLabel = (requestParams) => dispatch(_cancelShipmentLabel(requestParams));
  const cancelShipmentLabelResponse = useSelector((RootReducer) => RootReducer.VendorReducer.cancelShipmentLabelResponse)

  useEffect(() => {
    //alert(purchaseId)
    listShipmentCarriers();
    listVendorAddress({ purchaseId: purchaseId });
    if (isEdit) shipmentDetails({ purchaseId: purchaseId });

    // Handle unload event
    return () => {
      resetResponse(dispatch, "listShipmentRates");
      resetResponse(dispatch, "cancelShipmentLabel");
      resetResponse(dispatch, "generateShipmentLabel");
      resetResponse(dispatch, "shipmentDetails");
    };
  }, []);

  useEffect(() => {
    if (vendorAddressResponse && vendorAddressResponse.apiStatus) {
      if (vendorAddressResponse.apiErrorCode === constants.STATUS_200)
        if (vendorAddressResponse.apiResponse.vendorAddress) {
          let vendAddress = vendorAddressResponse.apiResponse.vendorAddress;
          let userAddress = vendorAddressResponse.apiResponse.userAddress;
          let vendorArray = [];

          vendorArray.push({
            value: vendAddress.vendorid,
            label: vendAddress.fullname + " " + vendAddress.address1,
          });
          vendorArray.push({ value: 0, label: "Other" });
          setVendorAddressOptions(vendorArray);
          setVendorAddress(vendorArray[0]);

          setRateValues((rateValues) => ({
            ...rateValues,
            to_country_code: userAddress.countryname,
            to_postal_code: userAddress.zip,
            to_city_locality: userAddress.city,
            to_state_province: userAddress.statename,
            from_country_code: !isEdit ? vendAddress.countryname : "",
            from_postal_code: !isEdit ? vendAddress.zip : "",
          }));

          //alert(JSON.stringify(vendorAddressResponse.apiResponse))
        } else toast.error(vendorAddressResponse.apiMessage);
    }
  }, [vendorAddressResponse]);

  useEffect(() => {
    if (shipmentDetailsResponse && shipmentDetailsResponse.apiStatus) {
      if (shipmentDetailsResponse.apiErrorCode === constants.STATUS_200)
        //console.log(JSON.stringify(shipmentDetailsResponse.apiResponse));
        fillShipmentDetails(shipmentDetailsResponse.apiResponse);
    }
  }, [shipmentDetailsResponse, shipmentCarriersResponse]);

  useEffect(() => {
    if (shipmentCarriersResponse && shipmentCarriersResponse.apiStatus) {
      if (shipmentCarriersResponse.apiErrorCode === constants.STATUS_200) {
        let carrierArray = [];
        shipmentCarriersResponse.apiResponse.map((Carrier) => {
          carrierArray.push({
            value: Carrier.carrier_code,
            label: Carrier.carrier_name
          });
        });
        setShipmentCarriers(carrierArray);
        if (carrierArray.length > 0) updateSelectedCarrier(carrierArray[0]);
      }
    }
  }, [shipmentCarriersResponse]);

  useEffect(() => {
    if (shipmentRatesResponse && shipmentRatesResponse.apiStatus) {
      if (shipmentRatesResponse.apiErrorCode === constants.STATUS_200) {
        //console.log(JSON.stringify(shipmentRatesResponse.apiResponse))
        fillShipmentRatesValues(shipmentRatesResponse.apiResponse);
        setRatesModalShow(true);
      } else toast.error(shipmentRatesResponse.apiMessage);
      setLoad(false);
    }
  }, [shipmentRatesResponse]);

  useEffect(() => {
    //alert(JSON.stringify(shipmentLabelResponse))
    if (shipmentLabelResponse && shipmentLabelResponse.apiStatus) {
      if (shipmentLabelResponse.apiErrorCode === constants.STATUS_200) {
        const labelInfo = {
          shipmentLabelId: shipmentLabelResponse.apiResponse.labelId,
          shipmentLabel: shipmentLabelResponse.apiResponse.label,
          shipmentLabelPdf: shipmentLabelResponse.apiResponse.pdfUrl,
          shipmentTrackingNo: shipmentLabelResponse.apiResponse.trackingNumber
        };
        setLabelInfo(labelInfo);
        setLabelbtnEnabled(false);
        // setShowLabel(true);
      } else {
        toast.error(shipmentLabelResponse.apiMessage);
      }
      setLoad(false);
    }
  }, [shipmentLabelResponse]);

  useEffect(() => {
    //alert(JSON.stringify(cancelShipmentLabelResponse))
    if (cancelShipmentLabelResponse && cancelShipmentLabelResponse.apiStatus) {
      if (cancelShipmentLabelResponse.apiErrorCode === constants.STATUS_200) {        
        setVoidModalShow(false);
        navigateToPurchaseHistory();
      } else {
        toast.error(cancelShipmentLabelResponse.apiMessage);
      }
    } 
  }, [cancelShipmentLabelResponse]);

  useEffect(() => {
    setRateValues((prevValues) => ({
      ...prevValues,
      weight: {
        ...prevValues.weight, // spread the weight object
        value: weightValue, // update the value field
      },
    }));
  }, [weightValue]);

  useEffect(() => {
    setRateValues((prevValues) => ({
      ...prevValues,
      dimensions: {
        ...prevValues.dimensions,
        length: lengthValue,
      },
    }));
  }, [lengthValue]);

  useEffect(() => {
    setRateValues((prevValues) => ({
      ...prevValues,
      dimensions: {
        ...prevValues.dimensions,
        width: widthValue,
      },
    }));
  }, [widthValue]);

  useEffect(() => {
    setRateValues((prevValues) => ({
      ...prevValues,
      dimensions: {
        ...prevValues.dimensions,
        height: heightValue,
      },
    }));
  }, [heightValue]);


  useEffect(() => {
    if (place) {
      let address = place;
      let streetNumber = "";
      let routeInfo = "";
      let plusCode = "";
      let neighborhood = "";
      let business_city = "";
      let premise = "";
      address.forEach((item) => {
        if (item.types.indexOf("street_number") >= 0) {
          streetNumber = item.long_name + " ";
          setRateValues((rateValues) => ({
            ...rateValues,
            address: streetNumber
          }));
        }

        if (item.types.indexOf("route") >= 0) {
          routeInfo = streetNumber + item.long_name + " ";
          setRateValues((rateValues) => ({
            ...rateValues,
            address: routeInfo
          }));
        }
        if (item.types.indexOf("plus_code") >= 0) {
          if (streetNumber === "") {
            plusCode = streetNumber + routeInfo + item.long_name + " ";
          } else {
            plusCode = routeInfo + item.long_name + " ";
          }
          setRateValues((rateValues) => ({
            ...rateValues,
            address: plusCode
          }));
        }

        if (item.types.indexOf("neighborhood") >= 0) {
          if (streetNumber === "") {
            neighborhood =
              streetNumber + routeInfo + plusCode + item.long_name + " ";
          } else {
            neighborhood = routeInfo + plusCode + item.long_name + " ";
          }
          setRateValues((rateValues) => ({
            ...rateValues,
            address: neighborhood
          }));
        }
        if (item.types.indexOf("premise") >= 0) {
          if (streetNumber === "") {
            premise =
              streetNumber +
              routeInfo +
              plusCode +
              neighborhood +
              item.long_name +
              " ";
          } else {
            premise =
              routeInfo + plusCode + neighborhood + item.long_name + " ";
          }
          setRateValues((rateValues) => ({
            ...rateValues,
            address: premise
          }));
        }
        if (item.types.indexOf("administrative_area_level_1") >= 0) {
          setRateValues((rateValues) => ({
            ...rateValues,
            state: item.short_name
          }));
        }
        if (item.types.indexOf("locality") >= 0) {
          business_city = item.long_name;
          setRateValues((rateValues) => ({
            ...rateValues,
            city: item.long_name
          }));
        } else if (
          item.types.indexOf("administrative_area_level_3") >= 0 &&
          business_city === ""
        ) {
          setRateValues((rateValues) => ({
            ...rateValues,
            city: item.long_name
          }));
        }

        if (item.types.indexOf("country") >= 0) {
          setRateValues((rateValues) => ({
            ...rateValues,
            from_country_code: item.short_name,
            country: item.short_name
          }));
        }
        if (item.types.indexOf("postal_code") >= 0) {
          setRateValues((rateValues) => ({
            ...rateValues,
            from_postal_code: item.long_name,
            zip: item.long_name
          }));
        }
      });
    }
  }, [place]);


  const handleChanges = (e) => {
    setDimensionUnitValue({ value: e.value, label: e.label });

    setRateValues((prevValues) => ({
      ...prevValues,
      dimensions: {
        ...prevValues.dimensions,
        unit: e.label,
      },
    }));
  };

  const downloadImage = async () => {
    if (generatedLabel.shipmentLabelPdf !== "")
      window.open(generatedLabel.shipmentLabelPdf, "_blank");
  };

  const handleWeightChanges = (e) => {
    //alert(JSON.stringify(e))
    setWeightUnitValue({ value: e.value, label: e.label });

    setRateValues((prevValues) => ({
      ...prevValues,
      weight: {
        ...prevValues.weight, // spread the weight object
        unit: e.label, // update the unit field
      },
    }));
  };

  const handleWeightValueChange = (e) => {
    //alert(JSON.stringify(e.target.value))
    //const weight = (e.target.value === "")? 0 : parseFloat(e.target.value)
    if (DECIMAL_REGEX.test(e.target.value))
      setWeightValue(e.target.value)
  };

  const handleWidthValueChange = (e) => {
    if (DECIMAL_REGEX.test(e.target.value))
      setWidthValue(e.target.value)
  };

  const handleHeigthValueChange = (e) => {
    if (DECIMAL_REGEX.test(e.target.value))
      setHeightValue(e.target.value)
  };

  const handleLengthValueChange = (e) => {
    // const length = (e.target.value === "")? 0 :parseFloat(e.target.value)
    if (DECIMAL_REGEX.test(e.target.value))
      setLengthValue(e.target.value)
  };

  const updateSelectedCarrier = (event) => {
    if (shipmentCarriersResponse && shipmentCarriersResponse.apiStatus) {
      setSelectedCarrier({ value: event.value, label: event.label });
      const foundCarrier = shipmentCarriersResponse.apiResponse.filter(
        (detail) => detail.carrier_code === event.value
      );
      if (foundCarrier.length > 0)
        setRateValues((prevValues) => ({
          ...prevValues,
          carrier_ids: [foundCarrier[0].carrier_id],
          carrier_code: event.value,
        }));
    }
  };

  const handleCarrierChanges = (event) => {
    updateSelectedCarrier(event);
  };



  const handleVendorAddressChange = (e) => {
    //  if the selection is other display UI to enter address
    if (e.value === OTHER_ADDRESS_SELECTED) {
      setAddRateValuesError(intitalRateValuesError);
      if (!isEdit)
        setRateValues((rateValues) => ({
          ...rateValues,
          from_country_code: "",
          from_postal_code: "",
          businessname: "",
          phone: "",
          city: "",
          state: "",
          country: "",
          address: "",
          address2: "",
        }));
      setUserAddressDisplay(true);
    } else {
      setUserAddressDisplay(false);
      setRateValues((rateValues) => ({
        ...rateValues,
        from_country_code:
          vendorAddressResponse.apiResponse.vendorAddress.countryname,
        from_postal_code: vendorAddressResponse.apiResponse.vendorAddress.zip,
      }));
    }
    setVendorAddress({ value: e.value, label: e.label });
  };

  const generateLabel = () => {
    if (rateCardSelected.rate === 0) {
      // assuming that the rate is not selected
      toast.error(alerts.GENERATE_LABEL_VALIDATION);
    } else {
      setLoad(true);
      let payLoad = {
        purchaseId: purchaseId,
        weight: {
          value: rateValues.weight.value,
          unit: rateValues.weight.unit,
        },
        dimensions: {
          unit: rateValues.dimensions.unit,
          length: rateValues.dimensions.length,
          width: rateValues.dimensions.width,
          height: rateValues.dimensions.height,
        },
        service_code: rateCardSelected.serviceCode,
        package_type: rateCardSelected.packageType,
        carrier_code: rateCardSelected.carrierCode,
        carrier_name: rateCardSelected.carrierName,
        rate: rateCardSelected.rate,
        delivery_days: rateCardSelected.expectedDelivery,
        type: isEdit ? ShipmentLabel.Edit : ShipmentLabel.Add, // this should be enum
        other_address: 0, // other address is not selected
      };

      if (vendorAddress.value === OTHER_ADDRESS_SELECTED) {
        payLoad.other_address = 1;
        payLoad.businessname = rateValues.businessname;
        payLoad.phone = rateValues.phone;
        payLoad.address = rateValues.address;
        payLoad.address2 = rateValues.address2;
        payLoad.city = rateValues.city;
        payLoad.state = rateValues.state;
        payLoad.zip = rateValues.zip;
        payLoad.country = rateValues.country;
      }
      console.log(JSON.stringify(payLoad));
      generateShipmentLabel(payLoad);
    }
  };

  const cancelLabel = () => {
    cancelShipmentLabel({ labelId: generatedLabel.shipmentLabelId });
  };

  const handleViewLabel = () => {
    setShowLabel(true)
    setViewLabelBtn(true)
  };

  const viewShipmentRates = () => {
    let isValid = validateRateInputValues();
    if (isValid) {
      let payLoad = {
        carrier_ids: rateValues.carrier_ids,
        carrier_code: rateValues.carrier_code,
        from_country_code: rateValues.from_country_code,
        from_postal_code: rateValues.from_postal_code,
        to_country_code: rateValues.to_country_code,
        to_postal_code: rateValues.to_postal_code,
        to_city_locality: rateValues.to_city_locality,
        to_state_province: rateValues.to_state_province,
        weight: {
          value: rateValues.weight.value,
          unit: rateValues.weight.unit,
        },
        dimensions: {
          unit: rateValues.dimensions.unit,
          length: rateValues.dimensions.length,
          width: rateValues.dimensions.width,
          height: rateValues.dimensions.height,
        },
      };
      setLoad(true);
      shipmentRates(payLoad);
      if (!isEdit) setRateCardShow(false);
      console.log(JSON.stringify(payLoad));
    }
  };

  const validateRateInputValues = () => {
    let isValid = true;
    setAddRateValuesError(intitalRateValuesError);
    if (rateValues.carrier_ids.length === 0) {
      isValid = false;
      setAddRateValuesError((prevValues) => ({
        ...prevValues,
        carrier_idError: alerts.SELECT_CARRIER,
      }));
    }

    if (rateValues.from_country_code === "") {
      isValid = false;
      setAddRateValuesError((prevValues) => ({
        ...prevValues,
        from_CountryError: alerts.ENTER_FRON_COUNTRY,
      }));
    }

    if (rateValues.from_postal_code === "") {
      isValid = false;
      setAddRateValuesError((prevValues) => ({
        ...prevValues,
        from_ZipError: alerts.ENTER_FRON_ZIP,
      }));
    }

    if (rateValues.weight.value <= 0) {
      isValid = false;
      setAddRateValuesError((prevValues) => ({
        ...prevValues,
        weight_Error: alerts.ENTER_WEIGHT,
      }));
    }

    if (rateValues.dimensions.height <= 0) {
      isValid = false;
      setAddRateValuesError((prevValues) => ({
        ...prevValues,
        height_Error: alerts.ENTER_HEIGTH,
      }));
    }

    if (rateValues.dimensions.width <= 0) {
      isValid = false;
      setAddRateValuesError((prevValues) => ({
        ...prevValues,
        width_Error: alerts.ENTER_WIDTH,
      }));
    }

    if (rateValues.dimensions.length <= 0) {
      isValid = false;
      setAddRateValuesError((prevValues) => ({
        ...prevValues,
        length_Error: alerts.ENTER_LENGTH,
      }));
    }

    if (vendorAddress.value === OTHER_ADDRESS_SELECTED) {
      // If the use selected other address

      if (rateValues.businessname === "") {
        isValid = false;
        setAddRateValuesError((prevValues) => ({
          ...prevValues,
          businessname_Error: alerts.ENTER_BUSINESS_NAME,
        }));
      }

      if (rateValues.address === "") {
        isValid = false;
        setAddRateValuesError((prevValues) => ({
          ...prevValues,
          address_Error: alerts.ENTER_ADDRESS,
        }));
      }

      if (rateValues.city === "") {
        isValid = false;
        setAddRateValuesError((prevValues) => ({
          ...prevValues,
          city_Error: alerts.ENTER_CITY,
        }));
      }

      if (rateValues.state === "") {
        isValid = false;
        setAddRateValuesError((prevValues) => ({
          ...prevValues,
          state_Error: alerts.ENTER_STATE,
        }));
      }
      if (phoneNumberValidation(rateValues.phone) !== "") {
        isValid = false;
        setAddRateValuesError((prevValues) => ({
          ...prevValues,
          phone_Error: phoneNumberValidation(rateValues.phone),
        }));
      }
    }

    return isValid;
  };

  const fillShipmentDetails = (data) => {
    setRateCardValue((rateCardSelected) => ({
      ...rateCardSelected,
      carrierName: data.shippingDetails.carrierName,
      carrierCode: data.shippingDetails.carrier_code,
      serviceType: data.shippingDetails.service_type,
      packageType: data.shippingDetails.package_type,
      packageName: data.shippingDetails.packageName,
      rate: data.shippingDetails.rate,
      expectedDelivery: data.shippingDetails.delivery_days,
      serviceCode: data.shippingDetails.service_code,
    }));

    if (shipmentCarriersResponse && shipmentCarriersResponse.apiStatus) {
      // find the Carrier id from the Carrier list and add it in the state value
      const foundCarrier = shipmentCarriersResponse.apiResponse.filter(
        (detail) => detail.carrier_code === data.shippingDetails.carrier_code
      );
      if (foundCarrier.length > 0) {
        //alert(JSON.stringify(foundCarrier))
        setRateValues((prevValues) => ({
          ...prevValues,
          carrier_ids: [foundCarrier[0].carrier_id],
          carrier_code: data.shippingDetails.carrier_code,
        }));
      }
    }
    // Carrier selected
    setSelectedCarrier({
      value: data.shippingDetails.carrier_code,
      label: data.shippingDetails.carrierName,
    });

    setRateCardShow(true);

    const foundWeight = WeightOptions.filter(
      (detail) => detail.label === data.shippingDetails.weight_unit
    );
    if (foundWeight.length > 0) {
      setWeightValue(data.shippingDetails.weight);
      setWeightUnitValue({
        value: foundWeight[0].value,
        label: foundWeight[0].label,
      });
    }

    // Since height, width and length contain same unit, taking any one of this object for storing the unit
    const foundDimension = DimensionOptions.filter(
      (detail) => detail.label === data.shippingDetails.height_unit
    );
    if (foundDimension.length > 0) {
      setDimensionUnitValue({
        value: foundDimension[0].value,
        label: foundDimension[0].label,
      });
      setLengthValue(data.shippingDetails.length);
      setHeightValue(data.shippingDetails.height);
      setWidthValue(data.shippingDetails.width);
    }

    let srcAddress = null;
    // Address selected other than the vendor actual address
    if (data.vendorOtherAddress) {
      setVendorAddress({ value: 0, label: "Other" });
      setUserAddressDisplay(true);
      srcAddress = data.vendorOtherAddress;
    } else {
      srcAddress = data.vendorBusinessAddress;
    }
    setRateValues((prevValues) => ({
      ...prevValues,
      businessname: srcAddress.fullname,
      phone: srcAddress.phone,
      address: srcAddress.address1,
      address2: srcAddress.address2,
      city: srcAddress.city,
      state: srcAddress.statename,
      zip: srcAddress.zip,
      country: srcAddress.countryname,
      from_postal_code: srcAddress.zip,
      from_country_code: srcAddress.countryname,
    }));

    if (data.labelDetails.shipmentLabelId !== "") {
      setLabelInfo((prevValues) => ({
        ...prevValues,
        shipmentLabel: data.labelDetails.shipmentLabel,
        shipmentLabelId: data.labelDetails.shipmentLabelId,
        shipmentLabelPdf: data.labelDetails.pdfUrl,
        shipmentTrackingNo: data.labelDetails.ShipmentlabelTrackingNo,
      }));
      // setShowLabel(true);
    }
  };

  const fillShipmentRatesValues = (data) => {
    let tableContent = [];
    Object.keys(data.serviceTypes).map((index) => {
      const rowItem = data.serviceTypes[index];

      const cellItem = [];
      cellItem[0] = [
        {
          rate: "--",
          serviceType: rowItem,
          packageType: "",
          packageName: "",
          deliveryDays: 0,
          carrier: "",
          hasRate: false,
          isSelected: false,
          serviceCode: "",
        },
      ];
      Object.keys(data.packageTypes).map((itr) => {
        const filteredResults = data.shipment_rate_detail.filter(
          (item) =>
            item.packageName === data.packageTypes[itr] &&
            item.serviceType === rowItem
        );
        if (filteredResults.length > 0) {
          const rateItem = {
            rate: filteredResults[0].rate.toFixed(2),
            serviceType: filteredResults[0].serviceType,
            packageType: filteredResults[0].packageType,
            packageName: filteredResults[0].packageName,
            deliveryDays: filteredResults[0].deliveryDays,
            carrier: filteredResults[0].carrierName,
            hasRate: true,
            isSelected: false,
            serviceCode: filteredResults[0].serviceCode,
          };
          cellItem[0].push(rateItem);
        } else {
          const emptyItem = {
            rate: "--",
            serviceType: "",
            packageType: "",
            packageName: "",
            deliveryDays: 0,
            carrier: "",
            hasRate: false,
            isSelected: false,
            serviceCode: "",
          };
          cellItem[0].push(emptyItem);
        }
      });

      tableContent.push(cellItem[0]);
    });

    setRatesTableValues((ratesTableValues) => ({
      ...ratesTableValues,
      ratesColHeader: data.packageTypes,
      ratesRowValues: tableContent,
      carrierDetails: data.carrierDetails,
    }));
  };

  const handleCellClick = (selectedRate) => {
    const tableRowValues = ratesTableValues.ratesRowValues;
    // Loop through the outer array
    tableRowValues.forEach((innerArray) => {
      // Find the object in the inner array that matches packageName and serviceType
      const rateDetailToUpdate = innerArray.find((detail) => {
        if (
          detail.packageName === selectedRate.packageName &&
          detail.serviceType === selectedRate.serviceType
        )
          detail.isSelected = true;
        else detail.isSelected = false;
      });
    });

    setRatesTableValues((ratesTableValues) => ({
      ...ratesTableValues,
      ratesRowValues: tableRowValues,
    }));
  };

  const handleAddressChange = (event) => {
    setRateValues((rateValues) => ({
      ...rateValues,
      address: event,
    }));
  };

  const confirmRate = () => {
    // Initialize variable to hold the found object
    let foundObject = null;

    // Loop through the outer array
    ratesTableValues.ratesRowValues.forEach((innerArray) => {
      // Find the object in the inner array that matches the selected object
      const matchingObject = innerArray.find(
        (detail) => detail.isSelected === true
      );

      // If a match is found, store it
      if (matchingObject) {
        foundObject = matchingObject;
      }
    });
    if (foundObject) {
      setRateCardValue((rateCardSelected) => ({
        ...rateCardSelected,
        carrierName: ratesTableValues.carrierDetails.carrierName,
        carrierCode: ratesTableValues.carrierDetails.carrierCode,
        serviceType: foundObject.serviceType,
        packageType: foundObject.packageType,
        packageName: foundObject.packageName,
        rate: foundObject.rate,
        expectedDelivery: foundObject.deliveryDays,
        serviceCode: foundObject.serviceCode,
      }));

      setLabelbtnEnabled(true);
      setRatesModalShow(false);
      setRateCardShow(true);
    }
    else {
      toast.error(alerts.CONFIRM_VALIDATION);
    }
  };

  const closeRatesModal = () => {
    setRatesModalShow(false);
    rateCardSelected.rate > 0 ? setRateCardShow(true) : setRateCardShow(false);
  };

  const handleOtherAddressChange = (event) => {
    if (event.target.name === "phone") {
      if (/^[-\d\(\)\[\]\s]+$/.test(event.target.value)) {
        const numbers = event.target.value.match(/\d+/g);
        const fullNumber = numbers.join("");
        setRateValues((prevValues) => ({
          ...prevValues,
          [event.target.name]: fullNumber,
        }));
      }
    } else if (event.target.name === "country") {
      setRateValues((prevValues) => ({
        ...prevValues,
        from_country_code: event.target.value,
      }));
    } else if (event.target.name === "zip") {
      setRateValues((prevValues) => ({
        ...prevValues,
        from_postal_code: event.target.value,
      }));
    } else {
      setRateValues((prevValues) => ({
        ...prevValues,
        [event.target.name]: event.target.value,
      }));
    }
  };

  const navigateToPurchaseHistory = () => {
    navigate("/purchasehistory", { replace: true });
    window.location.reload();
  };


  return (
    <InnerLayout>
      {load && <Loader />}
      <Col className="action-wrap">
        <h1>{Strings.SetupShipent.Title}</h1>
      </Col>
      <Col className="inner-main shipment-setup">
        <h3>{Strings.SetupShipent.Subtitle1}</h3>
        <Row>
          <Col xl="4" md="6">
            <CustSelectLabel
              label={Strings.SetupShipent.Label1}
              labelinfo={Strings.SetupShipent.LabelInfo1}
              options={vendorAddressOptions}
              value={vendorAddress}
              onChange={(e) => {
                handleVendorAddressChange(e);
              }}
              isDisabled={(generatedLabel.shipmentLabelId !== "" && !labelbtnEnabled) ? true : false}
            />
          </Col>
          {vendorAddressResponse &&
            vendorAddressResponse.apiStatus &&
            vendorAddressResponse.apiResponse.userAddress ? (
            <Col xl="4" md="6" className="destination">
              <label>
                {Strings.SetupShipent.Label2}
                <span>{Strings.SetupShipent.LabelInfo2}</span>
              </label>
              <div className="address">
                <span>
                  {vendorAddressResponse.apiResponse.userAddress.fullname}
                </span>
                <p>
                  {vendorAddressResponse.apiResponse.userAddress.address1},
                  {vendorAddressResponse.apiResponse.userAddress.address2}
                  <br />
                  {vendorAddressResponse.apiResponse.userAddress.city},{" "}
                  {vendorAddressResponse.apiResponse.userAddress.statename},{" "}
                  {vendorAddressResponse.apiResponse.userAddress.zip}.
                </p>
              </div>
            </Col>
          ) : (
            ""
          )}
        </Row>
        {showUserAddress && (
          <Row>
            <Col xl="8">
              <Card className='add-address'>
                <Card.Body>
                  <Row>
                    <h3 className='mt-0'>{Strings.SetupShipent.Subtitle2}</h3>
                    <Col md="6">
                      <Input
                        label={Strings.SetupShipent.Label3}
                        id="businessname"
                        name="businessname"
                        type="text"
                        maxLength={MAX_BUSINESSNAME_LENGTH}
                        value={rateValues.businessname}
                        onChange={handleOtherAddressChange}
                        errorMessage={ratesValuesError.businessname_Error}
                      />
                    </Col>
                    <Col md="6" className='reg-address' style={{ marginBottom: 13 }}>
                      <label>{Strings.SetupShipent.Label4}</label>
                      <Autocomplete
                        apiKey={process.env.REACT_APP_GOOGLE_MAP_ACCESS_KEY}
                        style={{ width: "100%", borderColor: ratesValuesError.address_Error !== '' ? "red" : "" }}
                        onPlaceSelected={(place) => {
                          setRateValues((rateValues) => ({
                            ...rateValues,
                            address: "",
                            address2: "",
                            city: "",
                            state: "",
                            zip: "",
                            country: "",
                          }));
                          setPlace(place.address_components);
                        }}
                        onChange={(e) => handleAddressChange(e.target.value)}
                        name="address"
                        value={rateValues.address}
                        options={{
                          fields: ['formatted_address', 'address_components', 'geometry', 'name'],
                          strictBounds: false,
                          types: ['geocode', 'establishment'],
                          componentRestrictions: { country: 'us' }
                        }}
                      />
                      {ratesValuesError.address_Error && ratesValuesError.address_Error !== '' ? <Form.Text style={{ color: "red" }}>
                        {ratesValuesError.address_Error}
                      </Form.Text> : ""}
                    </Col>
                    <Col md="6">
                      <Input
                        label={Strings.SetupShipent.Label5}
                        id="address2"
                        name="address2"
                        type="text"
                        maxLength={MAX_ADDRESS_LENGTH}
                        value={rateValues.address2}
                        onChange={handleOtherAddressChange}
                      />
                    </Col>
                    <Col md="6">
                      <Input
                        label={Strings.SetupShipent.Label6}
                        id="phone"
                        name="phone"
                        type="text"
                        maxLength={MAX_PHONE_LENGTH}
                        value={formatPhoneNumber(rateValues.phone)}
                        onChange={handleOtherAddressChange}
                        onBlur={handleOtherAddressChange}
                        errorMessage={ratesValuesError.phone_Error}
                      />
                    </Col>
                    <Col md="6">
                      <Input
                        label={Strings.SetupShipent.Label7}
                        id="city"
                        name="city"
                        type="text"
                        value={rateValues.city}
                        //onChange={handleOtherAddressChange}
                        errorMessage={ratesValuesError.city_Error}
                      />
                    </Col>
                    <Col md="6">
                      <Input
                        label={Strings.SetupShipent.Label8}
                        id="state"
                        name="state"
                        type="text"
                        value={rateValues.state}
                        //onChange={handleOtherAddressChange}
                        errorMessage={ratesValuesError.state_Error}
                      />
                    </Col>
                    <Col md="6">
                      <Input
                        label={Strings.SetupShipent.Label9}
                        id="zip"
                        name="zip"
                        type="text"
                        value={rateValues.zip}
                        //onChange={handleOtherAddressChange}
                        errorMessage={ratesValuesError.from_ZipError}
                      />
                    </Col>
                    <Col md="6">
                      <Input
                        label={Strings.SetupShipent.Label10}
                        id="country"
                        name="country"
                        type="text"
                        value={rateValues.country}
                        //onChange={handleOtherAddressChange}
                        errorMessage={ratesValuesError.from_CountryError}
                      />
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        )}
        <h3>{Strings.SetupShipent.Subtitle3}</h3>
        <Row>
          <Col xl="2" lg="3" md="6">
            <InputSelect
              label={Strings.SetupShipent.Label11}
              options={WeightOptions}
              maxLength={MAX_DECIMAL_LENGTH}
              inputValue={weightValue} // Bind text input state
              onInputChange={handleWeightValueChange} // Handle text input change
              selectValue={weightUnitValue} // Bind select state
              onSelectChange={handleWeightChanges} // Handle dropdown change
              error={ratesValuesError.weight_Error}
              disabled={(generatedLabel.shipmentLabelId !== "" && !labelbtnEnabled) ? true : false}
              isDisabled={(generatedLabel.shipmentLabelId !== "" && !labelbtnEnabled) ? true : false}
            />
          </Col>
          <Col xl="2" lg="3" md="6">
            <InputSelect
              label={Strings.SetupShipent.Label12}
              options={DimensionOptions}
              maxLength={MAX_DECIMAL_LENGTH}
              inputValue={lengthValue} // Bind text input state
              onInputChange={handleLengthValueChange} // Handle text input change
              selectValue={dimensionUnitValue} // Bind select state
              onSelectChange={handleChanges} // Handle dropdown change
              error={ratesValuesError.length_Error}
              disabled={(generatedLabel.shipmentLabelId !== "" && !labelbtnEnabled) ? true : false}
              isDisabled={(generatedLabel.shipmentLabelId !== "" && !labelbtnEnabled) ? true : false}
            />
          </Col>
          <Col xl="2" lg="3" md="6">
            <InputSelect
              label={Strings.SetupShipent.Label13}
              options={DimensionOptions}
              maxLength={MAX_DECIMAL_LENGTH}
              inputValue={widthValue} // Bind text input state
              onInputChange={handleWidthValueChange} // Handle text input change
              selectValue={dimensionUnitValue} // Bind select state
              onSelectChange={handleChanges} // Handle dropdown change
              error={ratesValuesError.width_Error}
              disabled={(generatedLabel.shipmentLabelId !== "" && !labelbtnEnabled) ? true : false}
              isDisabled={(generatedLabel.shipmentLabelId !== "" && !labelbtnEnabled) ? true : false}
            />
          </Col>
          <Col xl="2" lg="3" md="6">
            <InputSelect
              label={Strings.SetupShipent.Label14}
              options={DimensionOptions}
              maxLength={MAX_DECIMAL_LENGTH}
              inputValue={heightValue} // Bind text input state
              onInputChange={handleHeigthValueChange} // Handle text input change
              selectValue={dimensionUnitValue} // Bind select state
              onSelectChange={handleChanges} // Handle dropdown change
              error={ratesValuesError.height_Error}
              disabled={(generatedLabel.shipmentLabelId !== "" && !labelbtnEnabled) ? true : false}
              isDisabled={(generatedLabel.shipmentLabelId !== "" && !labelbtnEnabled) ? true : false}
            />
          </Col>
        </Row>
        <h3>{Strings.SetupShipent.Subtitle4}</h3>
        <Row className="carried-dtls">
          <Col xl="4" md="6">
            <CustSelectLabel
              label={Strings.SetupShipent.Label15}
              value={selectedCarrier}
              options={shipmentCarriers}
              onChange={(e) => {
                handleCarrierChanges(e);
              }}
              error={ratesValuesError.carrier_idError}
              isDisabled={(generatedLabel.shipmentLabelId !== "" && !labelbtnEnabled) ? true : false}              
            />
          </Col>
          <Col xl="4" md="6">
            <Button
             onClick={viewShipmentRates}
             disabled={(generatedLabel.shipmentLabelId !== "" && !labelbtnEnabled) ? true : false}
             >
              {Strings.SetupShipent.Btn1}
            </Button>
          </Col>
        </Row>
        {showRateCard && (
          <Row>
            <Col xl="8" lg="12">
              <Card className="carrier-info">
                <Card.Body>
                  <Row>
                    <Col md="4">
                      <label>{Strings.SetupShipent.Label16}</label>
                      <span>{rateCardSelected.carrierName}</span>
                    </Col>
                    <Col md="4">
                      <div className="rate">
                        {Strings.SetupShipent.Label17} ${rateCardSelected.rate}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="4">
                      <label>{Strings.SetupShipent.Label18}</label>
                      <span>{rateCardSelected.serviceType}</span>
                    </Col>
                    <Col md="4">
                      <label>{Strings.SetupShipent.Label19}</label>
                      <span>{rateCardSelected.packageName}</span>
                    </Col>
                    <Col md="4">
                      <label>{Strings.SetupShipent.Label20}</label>
                      <span>{rateCardSelected.expectedDelivery} Days</span>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        )}
        {/* temporary hide the generatedLabel */}
        {/* <div className="btn-wrap">
          {(generatedLabel.shipmentLabelId != "") ?
            <Button variant="outline-primary" onClick={handleViewLabel} disabled={viewLabelBtn}>{Strings.SetupShipent.Btn2}</Button> : ""}
          <Button onClick={generateLabel}
            disabled={(generatedLabel.shipmentLabelId !== "" && !labelbtnEnabled) ? true : false}
          >{Strings.SetupShipent.Btn3}</Button>
        </div> */}
        {showLabel && (
          <div>
            <div className="generated-label">
              <img
                src={generatedLabel.shipmentLabel}
                alt=""
                height="250"
                width="200"
              />
            </div>
            <div className="btn-wrap">
              <Button variant="outline-primary" onClick={() => setVoidModalShow(true)}>
                {Strings.SetupShipent.Btn4}
              </Button>
              <Button onClick={downloadImage}>
                {Strings.SetupShipent.Btn5}
              </Button>
            </div>
          </div>
        )}
      </Col>
      {/* Rates Modal */}
      <Modal
        show={RatesModal}
        onHide={closeRatesModal}
        backdrop="static"
        keyboard={false}
        centered
        dialogClassName="rates-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>{Strings.SetupShipent.RatesModal.Title}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="ps-0 pe-0">
          {shipmentRatesResponse && shipmentRatesResponse.apiStatus && (
            <Table responsive>
              <thead>
                <tr>
                  <th></th>
                  {
                    // Iterate through colom headings
                    Object.keys(ratesTableValues.ratesColHeader).map(
                      (index) => (
                        <th key={index}>
                          {ratesTableValues.ratesColHeader[index]}
                        </th>
                      )
                    )
                  }
                </tr>
              </thead>
              <tbody>
                {Object.keys(ratesTableValues.ratesRowValues).map((index) => (
                  <tr>
                    {
                      //Iterate through row item
                      Object.keys(ratesTableValues.ratesRowValues[index]).map(
                        (itr) =>
                          itr == 0 ? (
                            <td>
                              {
                                ratesTableValues.ratesRowValues[index][itr]
                                  .serviceType
                              }
                            </td>
                          ) : ratesTableValues.ratesRowValues[index][itr]
                            .hasRate ? (
                            <td
                              className={
                                ratesTableValues.ratesRowValues[index][itr]
                                  .isSelected
                                  ? "rate-select active"
                                  : "rate-select"
                              }
                              onClick={() =>
                                handleCellClick(
                                  ratesTableValues.ratesRowValues[index][itr]
                                )
                              }
                            >
                              $ {ratesTableValues.ratesRowValues[index][itr].rate}
                            </td>
                          ) : (
                            <td>
                              {ratesTableValues.ratesRowValues[index][itr].rate}
                            </td>
                          )
                      )
                    }
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </Modal.Body>
        <Modal.Footer className="justify-content-start">
          <Button variant="primary" onClick={confirmRate}>
            {Strings.SetupShipent.RatesModal.Btn}
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Void Modal */}
      <Modal
        show={VoidModal}
        onHide={() => setVoidModalShow(false)}
        backdrop="static"
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className="text-center">
          <h5>{Strings.SetupShipent.VoidModal.Alert}</h5>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <Button variant="outline-primary" onClick={() => setVoidModalShow(false)}>Cancel</Button>
          <Button variant="primary" onClick={cancelLabel}>
            {Strings.SetupShipent.VoidModal.Btn}
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Void Message Response Modal */}
      {/* <Modal
        show={voidSuccessModal}
        onHide={() => { setVoidSuccessModal(false); navigate("/purchasehistory"); }}
        backdrop="static"
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className="text-center">
          <h6>{Strings.SetupShipent.VoidSuccessModal.Alert}</h6>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <Button variant="primary" onClick={() => { setVoidSuccessModal(false); navigateToPurchaseHistory() }}>
            {Strings.SetupShipent.VoidSuccessModal.Btn}
          </Button>
        </Modal.Footer>
      </Modal> */}

    </InnerLayout>
  );
}
export default SetupShipment;
